import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { parse, isValid, formatISO } from 'date-fns';
import { withStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import styles from "./styles";
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { formatDate, formatCPF, exportToExcel } from '../../utils/utils';
import Paper from '@mui/material/Paper';

// eslint-disable-next-line
const currencyRegex = /^\d{1,3}(\.\d{3})*(\,\d{2})?$|^\d+(\.\d{2})?$/;
// eslint-disable-next-line
const plateRegex = /^[A-Z]{3}\d{4}$|^[A-Z]{3}\d[A-Z]\d{2}$/;

const dateTimeWithTimezoneRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?([-+]\d{2}:\d{2}|Z)$/;

const validarData = (valor, formatos) => {
  const isValidFormat = formatos.some(formato => {
    const data = parse(valor, formato, new Date());
    return isValid(data);
  });
  const isValidDateTimeWithTimezone = dateTimeWithTimezoneRegex.test(valor);
  return isValidFormat || isValidDateTimeWithTimezone;
};

const validationSchema = Yup.object({
  OSCode: Yup.string().required("Campo obrigatório"),
  driverDocument: Yup.string().required("Campo obrigatório"),
  driverName: Yup.string().required("Campo obrigatório"),
  clientName: Yup.string().required("Campo obrigatório"),

  value: Yup.string()
    .required('O valor total é obrigatório')
    .matches(currencyRegex, 'O valor total deve estar no formato 1.234,56 ou 1234.56'),
  advanceValue: Yup.string()
    .required('O valor do adiantamento é obrigatório')
    .matches(currencyRegex, 'O valor do adiantamento deve estar no formato 1.234,56 ou 1234.56'),
  remainingValue: Yup.string()
    .required('O valor do saldo é obrigatório')
    .matches(currencyRegex, 'O valor do saldo deve estar no formato 1.234,56 ou 1234.56'),

  gatheringDate: Yup.string()
    .nullable()
    .test(
      'is-valid-date',
      'Data inválida (use dd/MM/yyyy, ISO8601 ou "2024-04-05T10:47:40-03:00")',
      valor => !valor || validarData(valor, ['dd/MM/yyyy', "yyyy-MM-dd'T'HH:mm:ss.SSSX"])
    ),
  deliveryDate: Yup.string()
    .nullable()
    .test(
      'is-valid-date',
      'Data inválida (use dd/MM/yyyy, ISO8601 ou "2024-04-05T10:47:40-03:00")',
      valor => !valor || validarData(valor, ['dd/MM/yyyy', "yyyy-MM-dd'T'HH:mm:ss.SSSX"])
    ),
  advancedRequestedPaymentDate: Yup.string()
    .nullable()
    .test(
      'is-valid-date',
      'Data inválida (use dd/MM/yyyy, ISO8601 ou "2024-04-05T10:47:40-03:00")',
      valor => !valor || validarData(valor, ['dd/MM/yyyy', "yyyy-MM-dd'T'HH:mm:ss.SSSX"])
    ),
  paymentSuccessfulBalance: Yup.string()
    .nullable()
    .test(
      'is-valid-date',
      'Data inválida (use dd/MM/yyyy, ISO8601 ou "2024-04-05T10:47:40-03:00")',
      valor => !valor || validarData(valor, ['dd/MM/yyyy', "yyyy-MM-dd'T'HH:mm:ss.SSSX"])
    ),

  numCte: Yup.string().nullable().max(50, 'Número de CTE deve ter no máximo 50 caracteres'),
  plate: Yup.string()
    .nullable()
    .matches(plateRegex, { message: 'Placa deve seguir o formato AAA1234 ou ABC1D23', excludeEmptyString: true }),
  typeContract: Yup.string().nullable().max(50, 'Tipo de contrato deve ter no máximo 50 caracteres'),
  typeOperation: Yup.string().nullable().max(50, 'Tipo de operação deve ter no máximo 50 caracteres'),
  gatheringAddress: Yup.string().nullable().max(100, 'Endereço de coleta deve ter no máximo 100 caracteres'),
  deliveryAddress: Yup.string().nullable().max(100, 'Endereço de entrega deve ter no máximo 100 caracteres'),
  contractNumber: Yup.string().nullable().max(50, 'Número do contrato deve ter no máximo 50 caracteres'),
  comment: Yup.string().nullable().max(500, 'Observações devem ter no máximo 500 caracteres'),
});

const OrderForm = ({ classes, order, onUpdateOrder, orderId, isLoading }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [changes, setChanges] = useState({});

  const formik = useFormik({
    initialValues: {
      ...order,
    },
    validationSchema,
    onSubmit: async () => {
      const formattedChanges = { ...changes };

      const dateFields = ['gatheringDate', 'deliveryDate', 'advancedRequestedPaymentDate', 'paymentSuccessfulBalance'];

      dateFields.forEach(field => {
        if (formattedChanges[field]) {
          const parsedDate = validarData(formattedChanges[field], ['dd/MM/yyyy'])
            ? parse(formattedChanges[field], 'dd/MM/yyyy', new Date())
            : parse(formattedChanges[field], "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date());

          formattedChanges[field] = formatISO(parsedDate, { representation: 'complete' });
        }
      });

      if (Object.keys(formattedChanges).length > 0) {
        await onUpdateOrder({ order: formattedChanges, id: orderId });
        setIsEditing(false);
        setChanges({});
      } else {
        alert("Nenhuma alteração foi feita.");
      }
    },
  });

  useEffect(() => {
    if (!isEditing) {
      formik.setValues(order);
      if (order.status === "FINISHED") {
        setIsFinished(true);
      } else {
        setIsFinished(false);
      }
    } // eslint-disable-next-line
  }, [order, isEditing]);

  return (
    <Paper elevation={3} className={classes.root}>
      <div className={classes.root}>
        {isEditing ? (
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <TextField
              margin="dense"
              className={classes.textField}
              label="Ordem de Serviço"
              type="text"
              name="OSCode"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.OSCode}
              error={formik.touched.OSCode && Boolean(formik.errors.OSCode)}
              helperText={formik.touched.OSCode && formik.errors.OSCode}
            />
            <TextField
              margin="dense"
              className={classes.textField}
              label="Número de CTE"
              type="text"
              name="numCte"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.numCte}
              error={formik.touched.numCte && !!formik.errors.numCte}
              helperText={formik.touched.numCte && formik.errors.numCte}
            />
            <TextField
              margin="dense"
              className={classes.textField}
              label="Placa do Veículo"
              type="text"
              name="plate"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.plate}
              error={formik.touched.plate && !!formik.errors.plate}
              helperText={formik.touched.plate && formik.errors.plate}
            />
            <TextField
              margin="dense"
              className={classes.textField}
              label="Tipo de Contrato"
              type="text"
              name="typeContract"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.typeContract}
              error={formik.touched.typeContract && Boolean(formik.errors.typeContract)}
              helperText={formik.touched.typeContract && formik.errors.typeContract}
            />
            <TextField
              margin="dense"
              className={classes.textField}
              label="Tipo de Operação"
              type="text"
              name="typeOperation"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.typeOperation}
              error={formik.touched.typeOperation && Boolean(formik.errors.typeOperation)}
              helperText={formik.touched.typeOperation && formik.errors.typeOperation}
            />
            <TextField
              margin="dense"
              className={classes.textField}
              label="Data de Coleta"
              type="text"
              name="gatheringDate"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.gatheringDate}
              error={formik.touched.gatheringDate && !!formik.errors.gatheringDate}
              helperText={formik.touched.gatheringDate && formik.errors.gatheringDate}
            />
            <TextField
              margin="dense"
              className={classes.textField}
              label={"Data de Entrega"}
              type="text"
              name="deliveryDate"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.deliveryDate}
              error={formik.touched.deliveryDate && !!formik.errors.deliveryDate}
              helperText={formik.touched.deliveryDate && formik.errors.deliveryDate}
            />
            <TextField
              margin="dense"
              className={classes.textField}
              label={"Nome do Cliente"}
              type="text"
              name="clientName"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.clientName}
              error={formik.touched.clientName && !!formik.errors.clientName}
              helperText={formik.touched.clientName && formik.errors.clientName}
            />
            <TextField
              margin="dense"
              className={classes.textField}
              label={"Valor Total"}
              type="text"
              name="value"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.value}
              error={formik.touched.value && !!formik.errors.value}
              helperText={formik.touched.value && formik.errors.value}
            />
            <TextField
              margin="dense"
              className={classes.textField}
              label={"Valor do Adiantamento"}
              type="text"
              name="advanceValue"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.advanceValue}
              error={formik.touched.advanceValue && !!formik.errors.advanceValue}
              helperText={formik.touched.advanceValue && formik.errors.advanceValue}
            />
            <TextField
              margin="dense"
              className={classes.textField}
              label={"Data de Pagamento do Adiantamento"}
              type="text"
              name="advancedRequestedPaymentDate"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.advancedRequestedPaymentDate || ""}
              error={formik.touched.advancedRequestedPaymentDate && !!formik.errors.advancedRequestedPaymentDate}
              helperText={formik.touched.advancedRequestedPaymentDate && formik.errors.advancedRequestedPaymentDate}
            />
            <TextField
              margin="dense"
              className={classes.textField}
              label={"Valor do Saldo"}
              type="text"
              name="remainingValue"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.remainingValue}
              error={formik.touched.remainingValue && !!formik.errors.remainingValue}
              helperText={formik.touched.remainingValue && formik.errors.remainingValue}
            />
            <TextField
              margin="dense"
              className={classes.textField}
              label={"Cidade de Origem"}
              type="text"
              name="gatheringAddress"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.gatheringAddress}
              error={formik.touched.gatheringAddress && !!formik.errors.gatheringAddress}
              helperText={formik.touched.gatheringAddress && formik.errors.gatheringAddress}
            />
            <TextField
              margin="dense"
              className={classes.textField}
              label={"Cidade de Destino"}
              type="text"
              name="deliveryAddress"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.deliveryAddress}
              error={formik.touched.deliveryAddress && !!formik.errors.deliveryAddress}
              helperText={formik.touched.deliveryAddress && formik.errors.deliveryAddress}
            />
            <TextField
              margin="dense"
              className={classes.textField}
              label={"Número de Contrato do Frete"}
              type="text"
              name="contractNumber"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.contractNumber}
              error={formik.touched.contractNumber && !!formik.errors.contractNumber}
              helperText={formik.touched.contractNumber && formik.errors.contractNumber}
            />
            <TextField
              multiline
              maxRows={4}
              margin="dense"
              className={classes.textField}
              label={"Observações"}
              type="text"
              name="comment"
              onChange={e => {
                formik.handleChange(e);
                setChanges(prev => ({ ...prev, [e.target.name]: e.target.value }));
              }}
              onBlur={formik.handleBlur}
              value={formik.values.comment}
              error={formik.touched.comment && !!formik.errors.comment}
              helperText={formik.touched.comment && formik.errors.comment}
            />
            <div className={classes.buttonsDiv}>
              <Button
                className={classes.button}
                variant="contained"
                color="success"
                size="large"
                type="submit"
              >
                Salvar
              </Button>

              <Button
                className={classes.button}
                variant="contained"
                color="error"
                size="large"
                onClick={() => setIsEditing(false)}
              >
                Cancelar
              </Button>
            </div>
          </form>
        ) : (
          <div className={classes.readOnlyDiv}>
            <div className={classes.readOnlyField}>
              <Typography>
                Ordem de Serviço: <strong>{order.OSCode}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Número de CTE: <strong>{order.numCte}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Placa do Veículo: <strong>{order.plate}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Documento do Motorista: <strong>{formatCPF(order.driverDocument)}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Nome do Motorista: <strong>{order.driverName}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Tipo de Contrato: <strong>{order.typeContract}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Tipo de Operação: <strong>{order.typeOperation}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Data de Coleta: <strong>{formatDate(order.gatheringDate)}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Data de Entrega: <strong>{formatDate(order.deliveryDate)}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Nome do Cliente: <strong>{order.clientName}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Valor Total: <strong>{order.value}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Valor do Adiantamento: <strong>{order.advanceValue}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Data de Pagamento do Adiantamento: <strong>{formatDate(order.advancedRequestedPaymentDate) || "Pendente"}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Valor do Saldo: <strong>{order.remainingValue}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Data de Pagamento do Saldo: <strong>{formatDate(order.paymentSuccessfulBalance) || "Pendente"}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Cidade de Origem: <strong>{order.gatheringAddress}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Cidade de Destino: <strong>{order.deliveryAddress}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyField}>
              <Typography>
                Número de Contrato do Frete: <strong>{order.contractNumber}</strong>
              </Typography>
            </div>
            <div className={classes.readOnlyFieldComment}>
              <Typography>
                Observações: <strong>{order.comment}</strong>
              </Typography>
            </div>
            <div className={classes.buttonsDiv}>
              <Button
                className={classes.button}
                variant="contained"
                size="large"
                onClick={() => setIsEditing(true)}
                disabled={isFinished}
              >
                Editar
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => exportToExcel(formik.values, `Ordem_Serviço_${order.OSCode}`)}
              >
                Exportar para Excel
              </Button>
            </div>
          </div>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          <Typography>
            Ordem Atualizada com Sucesso!
          </Typography>
        </Alert>
      </Snackbar>
      {isLoading && (
        <div className={classes.loadingOverlay}>
          <CircularProgress />
        </div>
      )}
    </Paper>
  );
};

export default withStyles(styles)(OrderForm);
