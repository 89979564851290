import api from '../api';

import {
  FREIGHTS_LIST_REQUEST,
  FREIGHTS_LIST_SUCCESS,
  FREIGHTS_LIST_SUCCESS_POOLING,
  FREIGHTS_LIST_FAILURE,
  FREIGHTS_CLEAR_LIST,
  FREIGHTS_CLEAR_FILTERS,
  FREIGHTS_SET_FILTER
} from './actionsTypes';

export const requestFreights = (payload = {}) => {
  return (dispatch) => {
    if (!payload.pooling) {
      dispatch({ type: FREIGHTS_LIST_REQUEST, payload });
    }

    const { limit, skip, filters } = payload;

    const query = { ...filters } || {};
    if (limit) query.limit = limit;
    if (skip) query.skip = skip;

    api
      .get(`/freights`, {
        params: query,
      })
      .then((res) => {
        if (!payload.pooling) {
          dispatch(requestFreightsSuccess(res.data));
        } else {
          dispatch(requestFreightsSuccessPooling(res.data));
        }
      })
      .catch((err) => {
        dispatch(requestFreightsFailure(err.message));
      });
  };
};

export const requestInvoiceCouponsSent = (payload = {}) => {
  return (dispatch) => {
    dispatch({ type: FREIGHTS_LIST_REQUEST, payload });

    const { limit, skip } = payload;

    const query = {
      status: 'INVOICE_COUPON_SENT,INVOICE_COUPON_REFUSED',
      limit,
      skip,
    };

    api
      .get(`/freights`, {
        params: query,
      })
      .then((res) => {
        dispatch(requestFreightsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(requestFreightsFailure(err.message));
      });
  };
};

export const requestFreightsSuccess = (payload) => {
  return { type: FREIGHTS_LIST_SUCCESS, payload };
};

export const requestFreightsSuccessPooling = (payload) => {
  return { type: FREIGHTS_LIST_SUCCESS_POOLING, payload };
};

export const requestFreightsFailure = (payload) => {
  return { type: FREIGHTS_LIST_FAILURE, payload };
};

export const clearFreightList = () => {
  return { type: FREIGHTS_CLEAR_LIST };
};

export const clearFreightsFilters = () => {
  return { type: FREIGHTS_CLEAR_FILTERS };
};

export const setFreightsFilter = (payload) => {
  return { type: FREIGHTS_SET_FILTER, payload };
};