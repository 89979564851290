import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { userLogout } from '../actions/auth';

const ForbidenScreen = (props) => {
  return (
    <>
      <h1>Acesso negado.</h1>
      <button onClick={props.userLogout}>logout</button>
    </>
  )
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  userLogout
}, dispatch);

export default connect(null, mapDispatchToProps)(ForbidenScreen);
