import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  requestOrders,
  clearOrdersList,
  clearOrdersFilters,
  setOrdersFilter,
} from '../actions/orders';
import { isFiltersEmpty } from '../utils/utils';
import OrderFilter from '../components/OrderFilter';
import OrdersList from '../components/OrdersList';
import VersionChecker, { useVersionChecker } from '../components/VersionChecker/VersionChecker';

const OrdersScreen = () => {
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const ordersCount = useSelector((state) => state.counter.orders);
  const ordersList = useSelector((state) => state.orders.ordersList);
  const filters = useSelector((state) => state.orders.filters);
  const skip = useSelector((state) => state.orders.skip);
  const limit = useSelector((state) => state.orders.filters.limit);
  const hasMore = useSelector((state) => state.orders.hasMore);
  const isLoading = useSelector((state) => state.orders.isLoading);
  const [counter, setCounter] = useState(ordersCount);
  const [OSCode, setOrderOSCode] = useState(filters.OSCode);
  const [numCte, setOrderNumCte] = useState(filters.numCte);
  const [driverName, setOrderDriverName] = useState(filters.driverName);
  const [plate, setOrderPlate] = useState(filters.plate);
  const [gatheringAddress, setOrderGatheringAddress] = useState(filters.gatheringAddress);
  const [deliveryAddress, setOrderDeliveryAddress] = useState(filters.deliveryAddress);
  const [creationDate, setOrderCreationDate] = useState(filters.creationDate);
  const [updateDate, setOrderUpdateDate] = useState(filters.updateDate);
  const [gatheringDate, setOrderGatheringDate] = useState(filters.gatheringDate);
  const [deliveryDate, setOrderDeliveryDate] = useState(filters.deliveryDate); // eslint-disable-next-line
  const [resetId, setResetId] = useState(0);

  const debouncedSetFilter = debounce((props) => {
    dispatch(setOrdersFilter(props));
  }, 1500);

  const getDebouncedGetData = () => debounce((props) => {
    dispatch(requestOrders(props));
  }, 1000);

  const debouncedGetDataRef = useRef(getDebouncedGetData());

  useVersionChecker('OrdersScreen');

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
      debouncedGetDataRef.current.cancel();
    }; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(clearOrdersList());
    debouncedGetDataRef.current({ limit, skip, filters }); // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (ordersCount > counter && mounted.current) {
      debouncedGetDataRef.current({ limit, skip: 0, filters });
    }
    setCounter(ordersCount); // eslint-disable-next-line
  }, [ordersCount]);

  const getOrders = (skipProp = skip) => {
    debouncedGetDataRef.current.cancel();
    debouncedGetDataRef.current = getDebouncedGetData();
    debouncedGetDataRef.current({ limit, skip: skipProp, filters });
  };

  const fieldSetters = {
    OSCode: setOrderOSCode,
    numCte: setOrderNumCte,
    driverName: setOrderDriverName,
    plate: setOrderPlate,
    gatheringAddress: setOrderGatheringAddress,
    deliveryAddress: setOrderDeliveryAddress,
    creationDate: setOrderCreationDate,
    updateDate: setOrderUpdateDate,
    gatheringDate: setOrderGatheringDate,
    deliveryDate: setOrderDeliveryDate,
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;

    if (fieldSetters[name]) {
      fieldSetters[name](value);
    }

    if (Object.keys(fieldSetters).includes(name)) {
      debouncedSetFilter({ [name]: value });
    } else {
      dispatch(setOrdersFilter({ [name]: value }));
    }
  };

  const handleClearFilters = () => {
    setOrderOSCode('');
    setOrderNumCte('');
    setOrderDriverName('');
    setOrderPlate('');
    setOrderGatheringAddress('');
    setOrderDeliveryAddress('');
    setOrderCreationDate('');
    setOrderUpdateDate('');
    setOrderGatheringDate('');
    setOrderDeliveryDate('');
    dispatch(clearOrdersFilters());
    dispatch(requestOrders({ filters: filters }));
  };

  const handleLoadMore = () => {
    if (hasMore && !isLoading) {
      getOrders(skip);
    }
  };

  const handleRefresh = () => {
    dispatch(clearOrdersList());
    getOrders("0");
  };

  const clearAndSearch = () => {
    dispatch(clearOrdersList());
  };

  return (
    <>
      <VersionChecker />
      <div className="main-filter-order">
        <OrderFilter
          disableClear={isFiltersEmpty(filters) || isLoading}
          disableRefresh={isLoading}
          status={filters.status}
          clearAndSearch={clearAndSearch}
          handleClearFilters={handleClearFilters}
          handleRefresh={handleRefresh}
          handleFilterChange={handleFilterChange}
          paymentSuccessfulBalance={filters.paymentSuccessfulBalance}
          advancedRequestedPaymentDate={filters.advancedRequestedPaymentDate}
          OSCode={OSCode}
          numCte={numCte}
          driverName={driverName}
          plate={plate}
          gatheringAddress={gatheringAddress}
          deliveryAddress={deliveryAddress}
          creationDate={creationDate}
          updateDate={updateDate}
          gatheringDate={gatheringDate}
          deliveryDate={deliveryDate}
          resetId={resetId}
        />
      </div>
      <main>
        <OrdersList
          ordersList={ordersList}
          hasMore={hasMore}
          getOrders={getOrders}
          isLoading={isLoading}
          handleLoadMore={handleLoadMore}
        />
      </main>
    </>
  );
};

export default OrdersScreen;
