import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import SearchIcon from "@material-ui/icons/Search";

import { FILTER_LABEL_FREIGHT, BRAZILIAN_STATES } from "../../constants";
import styles from "./styles";
import Filter from "../Filter";
import FilterSearch from "../Filter/FilterSearch";
import FilterSelect from "../Filter/FilterSelect";

const FreightsFilter = props => {
  const { classes } = props;

  const selectLabelClasses = {
    root: classes.selectLabel,
    focused: classes.focused
  };

  return (
    <Filter
      disableClear={props.disableClear}
      disableRefresh={props.disableRefresh}
      handleClearFilters={props.handleClearFilters}
      handleRefresh={props.handleRefresh}
    >
      <FilterSearch>
        <div className={classes.filterSearchList}>
          <FormControl
            fullWidth
            htmlFor="creatorName"
            className={classes.formCtlInput}
          >
            <Input
              autoFocus
              id="creatorName"
              name="creatorName"
              autoComplete="creatorName"
              placeholder="Criado por"
              onChange={props.handleFilterChange}
              value={props.creatorName}
              className={classes.input}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="pesquisa-criador"
                    onClick={props.clearAndSearch}
                    className={classes.iconButton}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl
            fullWidth
            htmlFor="freightCode"
            className={classes.formCtlInput}
          >
            <Input
              autoFocus
              id="freightCode"
              name="freightCode"
              autoComplete="freightCode"
              placeholder="Código do frete"
              onChange={props.handleFilterChange}
              value={props.freightCode}
              className={classes.input}
              type="number"
              inputProps={{ min: "1" }}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="pesquisa-frete"
                    onClick={props.clearAndSearch}
                    className={[classes.iconButton, classes.loteSearchIcon].join(
                      " "
                    )}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl
            fullWidth
            htmlFor="OSCode"
            className={classes.formCtlInput}
          >
            <Input
              autoFocus
              id="OSCode"
              name="OSCode"
              autoComplete="OSCode"
              placeholder="Ordem de Serviço"
              onChange={props.handleFilterChange}
              value={props.OSCode}
              className={classes.input}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="Pesquisa"
                    onClick={props.clearAndSearch}
                    className={classes.iconButton}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl
            fullWidth
            htmlFor="driverName"
            className={classes.formCtlInput}
          >
            <Input
              autoFocus
              id="driverName"
              name="driverName"
              autoComplete="driverName"
              placeholder="Nome do Motorista"
              onChange={props.handleFilterChange}
              value={props.driverName}
              className={classes.input}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="pesquisa-motorista"
                    onClick={props.clearAndSearch}
                    className={classes.iconButton}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      </FilterSearch>

      <FilterSelect>
        <div className={classes.filterList}>
          <FormControl className={classes.selectRoot}>
            <InputLabel htmlFor="status" classes={selectLabelClasses}>
              Status
            </InputLabel>

            <Select
              value={props.status}
              onChange={props.handleFilterChange}
              className={classes.input}
              inputProps={{ id: "status", name: "status" }}
            >
              {FILTER_LABEL_FREIGHT.status.map((option, i) => (
                <MenuItem key={i} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.selectRoot}>
            <InputLabel htmlFor="gatheringState" classes={selectLabelClasses}>
              Origem
            </InputLabel>

            <Select
              id="gatheringState"
              name="gatheringState"
              value={props.gatheringState}
              onChange={props.handleFilterChange}
              className={classes.input}
              inputProps={{ id: "gatheringState", name: "gatheringState" }}
            >
              {BRAZILIAN_STATES.map((brState, i) => (
                <MenuItem key={i} value={brState.uf}>
                  {brState.uf}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.selectRoot}>
            <InputLabel htmlFor="deliveryState" classes={selectLabelClasses}>
              Destino
            </InputLabel>

            <Select
              id="deliveryState"
              name="deliveryState"
              value={props.deliveryState}
              onChange={props.handleFilterChange}
              className={classes.input}
              inputProps={{ id: "deliveryState", name: "deliveryState" }}
            >
              {BRAZILIAN_STATES.map((brState, i) => (
                <MenuItem key={i} value={brState.uf}>
                  {brState.uf}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.selectRoot}>
            <InputLabel
              htmlFor="elegibleVehicles"
              classes={selectLabelClasses}>
              Tipo de Caminhão
            </InputLabel>

            <Select
              id="elegibleVehicles"
              name="elegibleVehicles"
              value={props.elegibleVehicles}
              onChange={props.handleFilterChange}
              className={classes.input}
              inputProps={{ id: "elegibleVehicles", name: "elegibleVehicles" }}
            >
              {FILTER_LABEL_FREIGHT.elegibleVehicles.map((option, i) => (
                <MenuItem key={i} value={option.value}>
                  {option.label.replace(/s$/, "")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.selectRoot}>
            <InputLabel
              htmlFor="invoiceCoupon"
              classes={selectLabelClasses}
            >
              Comprovantes
            </InputLabel>

            <Select
              id="invoiceCoupon"
              name="invoiceCoupon"
              value={props.invoiceCoupon}
              onChange={props.handleFilterChange}
              className={classes.input}
              inputProps={{
                id: "invoiceCoupon",
                name: "invoiceCoupon"
              }}
            >
              {FILTER_LABEL_FREIGHT.invoiceCoupon.map(
                (filter, i) => (
                  <MenuItem key={i} value={filter.value}>
                    {filter.label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>

          <FormControl className={classes.selectRoot}>
            <InputLabel
              htmlFor="updateAcknowledge"
              classes={selectLabelClasses}
            >
              Visualizado
            </InputLabel>

            <Select
              id="updateAcknowledge"
              name="updateAcknowledge"
              value={props.updateAcknowledge}
              onChange={props.handleFilterChange}
              className={classes.input}
              inputProps={{
                id: "updateAcknowledge",
                name: "updateAcknowledge"
              }}
            >
              <MenuItem value={true}>Sim</MenuItem>
              <MenuItem value={false}>Não</MenuItem>
            </Select>
          </FormControl>

          <FormControl className={classes.selectRoot}>
            <InputLabel
              htmlFor="advanceRequestedDate"
              classes={selectLabelClasses}
            >
              Adiantamento
            </InputLabel>

            <Select
              id="advanceRequestedDate"
              name="advanceRequestedDate"
              value={props.advanceRequestedDate}
              onChange={props.handleFilterChange}
              className={classes.input}
              inputProps={{
                id: "advanceRequestedDate",
                name: "advanceRequestedDate"
              }}
            >
              {FILTER_LABEL_FREIGHT.advanceRequestedDate.map((filter, i) => (
                <MenuItem key={i} value={filter.value}>
                  {filter.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.selectRoot}>
            <InputLabel htmlFor="type" classes={selectLabelClasses}>
              Carga
            </InputLabel>

            <Select
              id="type"
              name="type"
              value={props.type}
              onChange={props.handleFilterChange}
              className={classes.input}
              inputProps={{ id: "type", name: "type" }}
            >
              {FILTER_LABEL_FREIGHT.type.map((filter, i) => (
                <MenuItem key={i} value={filter.value}>
                  {filter.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </FilterSelect>
    </Filter>
  );
};

export default withStyles(styles)(FreightsFilter);
