import React from 'react';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './style';

const FilterSelect = (props) => {
  return (
    <div className={props.classes.selectFiltersContainer}>
      <Typography variant="body1" className={props.classes.filterLabel}>FILTROS</Typography>
      
      { props.children }
    </div>
  );
}

export default withStyles(styles)(FilterSelect);