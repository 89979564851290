import React from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/utils";
import styles from "./styles";

const OrderCard = props => {
  const { classes, order } = props;
  let statusBarText, statusBarColor, statusBarTextColor;

  switch (order.status) {
    case "USER_ORDER_SENT":
      statusBarText = "ORDEM RECEBIDA";
      statusBarColor = "#3B9B58";
      statusBarTextColor = "#000000";
      break;

    case "ADVANCE_PAYMENT":
      statusBarText = "ADIANTAMENTO LIBERADO";
      statusBarColor = "#fbe40a";
      statusBarTextColor = "#000000";
      break;

    case "USER_ORDER_SENT_REFUSED":
      statusBarText = "ALTERAÇÂO DE FOTO SOLICITADA";
      statusBarColor = "#C35454";
      statusBarTextColor = "#000000";
      break;

    case "FINISHED":
      statusBarText = "ORDEM FINALIZADA";
      statusBarColor = "#D5D5D5";
      statusBarTextColor = "#000000";
      break;

    default:
      statusBarText = "ORDEM DE SERVIÇO";
      statusBarColor = "#3574d1";
      break;
  }

  const orderLink = `/orders/${order.id}`;

  return (
    <Link to={orderLink} className={classes.linkFix}>
      <Paper className={classes.root}>
        {order._genericStatus === "_FINISHED" && (
          <div className={classes.cardOverlay} />
        )}
        <div className={classes.mainContent}>
          <Typography className={classes.line}>
            <strong> MOTORISTA:
            </strong> {order.driverName ? order.driverName : ''}
          </Typography>
          <Divider className={classes.divider} />

          <Typography noWrap className={classes.deliveryGatheringRegion}>
            <strong> Número da OS: {" "} {order.OSCode ? order.OSCode : 'OSCODE'}
            </strong>{" "}
          </Typography>
          <Divider className={classes.divider} />

          <Typography className={classes.line}>
            <span>
              Data da Programação:{" "}
              {order.gatheringDate
                ? formatDate(order.gatheringDate)
                : " "}
            </span>
            <span> - </span>
            <span>
              {order.deliveryDate
                ? formatDate(order.deliveryDate)
                : " "}
            </span>
          </Typography>
          <Divider className={classes.divider} />

          <Typography className={classes.line}>
            Destino: {order.deliveryAddress ? `${order.deliveryAddress} ` : ''}
          </Typography>
          <Divider className={classes.divider} />

          <Typography className={classes.line}>
            Cliente: {order.clientName ? order.clientName : ''}
          </Typography>
          <Divider className={classes.divider} />

          <Typography
            className={[classes.line, classes.fixBatchPos].join(" ")}
            style={{ color: "#1CAF27" }}
          > Valor: {" "} {order.value ? order.value : '$$$$$'}
          </Typography>
          <Divider className={classes.divider} />

          <Typography className={classes.line}>
            Tipo de Operação: {order.typeOperation ? order.typeOperation : ''}
          </Typography>
          <Divider className={classes.divider} />

          <Typography noWrap className={classes.line}>
            Obs: {order.comment ? ` ${order.comment} ` : ''}
          </Typography>
        </div>

        <Typography
          noWrap
          className={classes.statusBar}
          style={{ backgroundColor: statusBarColor }}
        >
          <span
            className={classes.footerLineCreationDate}
            style={{ color: statusBarTextColor }}
          >
            CRIADA EM: {formatDate(order.creationDate)}
          </span>
          <span
            className={classes.footerLineStatus}
            style={{ color: statusBarTextColor }}
          >
            {statusBarText}
          </span>
        </Typography>
      </Paper>
    </Link>
  );
};

export default withStyles(styles)(OrderCard);
