import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const CustomMaskedInput = ({ inputRef, mask, ...props }) => {
  const finalMask = Array.isArray(mask) ? mask : createNumberMask(mask);
  return (
    <MaskedInput
      {...props}
      mask={finalMask}
      placeholderChar={'\u2000'}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
};

CustomMaskedInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  mask: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired
};

export default CustomMaskedInput;
