import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Container from '@material-ui/core/Container';
import api from '../api';
import UserManagement from '../components/BoardUserManagement';
import { enqueueSnackbar } from '../actions/notifications';
import VersionChecker, { useVersionChecker } from '../components/VersionChecker/VersionChecker';

const RegisterScreen = (props) => {
  useVersionChecker('RegisterScreen');

  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    profile: '',
    isLoading: false,
    isFormValid: false,
  });

  const validateForm = () => {
    const { name, email, password, profile } = state;
    const isFormValid = name && email && password && profile;
    setState(prevState => ({ ...prevState, isFormValid }));
  };

  const handleChange = (field) => (event) => {
    const value = event.target.value ? event.target.value : '';
    setState(prevState => ({ ...prevState, [field]: value }), validateForm);
  };

  const handleSubmit = async () => {
    setState(prevState => ({ ...prevState, isLoading: true }));

    const { name, email, password, profile } = state;
    const user = { name, email, password, profile };

    try {
      const result = await validateEmail(email);
      if (result && result.data && result.data.userExists) {
        setState(prevState => ({ ...prevState, isLoading: false }));
        props.enqueueSnackbar({
          message: 'Email já cadastrado.',
          options: { variant: 'error' },
        });
        return false;
      }

      await api.post('/board/user', { user });
      setState({
        name: '',
        email: '',
        password: '',
        profile: '',
        isLoading: false,
        isFormValid: false,
      });
      props.enqueueSnackbar({
        message: 'Usuário criado com sucesso.',
        options: { variant: 'success' },
      });

      return true;
    } catch (error) {
      setState(prevState => ({ ...prevState, isLoading: false }));
      props.enqueueSnackbar({
        message: 'Algo inesperado aconteceu. Verifique se os dados estão corretos.',
        options: { variant: 'error' },
      });
      return false;
    }
  };

  const validateEmail = async (email) => {
    const { REACT_APP_API_URL: SERVER_URL } = process.env;
    return api.get(`${SERVER_URL}/user/validate/email/${email}`);
  };

  useEffect(() => {
    validateForm(); // eslint-disable-next-line
  }, [state.name, state.email, state.password, state.profile]);

  return (
    <Container>
      <VersionChecker />
      <UserManagement
        name={state.name}
        email={state.email}
        password={state.password}
        profile={state.profile}
        handleChangeName={handleChange('name')}
        handleChangeEmail={handleChange('email')}
        handleChangePassword={handleChange('password')}
        handleChangeProfile={handleChange('profile')}
        handleSubmit={handleSubmit}
        isLoading={state.isLoading}
        disabled={!state.isFormValid}
      />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enqueueSnackbar,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(RegisterScreen);
