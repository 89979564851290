const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "99%",
    backgroundColor: "#ffffff86",
    borderRadius: "5px",
    margin: theme.spacing(1),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down('xl')]: {
      fontSize: '0.9rem', // ajuste para tela grande
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.8rem', // ajuste para tela grande média
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem', // ajuste para tela média
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem', // ajuste para tela pequena
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.5rem', // ajuste para tela muito pequena
    },
  },
  textField: {
    width: "98%",
  },
  readOnlyDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    height: "100%",
    width: "100%",
    color: "#1A5B76",
    fontSize: '1rem',
  },
  readOnlyField: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    height: "auto",
    width: "100%",
    border: "1px groove",
    borderRadius: "5px",
  },
  readOnlyFieldComment: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    height: "auto",
    width: "100%",
    border: "1px groove",
    borderRadius: "5px",
  },
  buttonsDiv: {
    gap: theme.spacing(2),
    width: "100%",
    height: '9rem',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    height: '3rem',
    [theme.breakpoints.down('sm')]: {
      height: '2.5rem',
    },
  },
  loadingOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
});

export default styles;
