// import {cloneDeep} from 'lodash';

import { isEqual } from "lodash";
import {
  FREIGHTS_DETAIL_REQUEST,
  FREIGHTS_DETAIL_SUCCESS,
  FREIGHTS_DETAIL_FAILURE,
  FREIGHTS_DETAIL_DUPLICATE_REQUEST,
  FREIGHTS_DETAIL_DUPLICATE_SUCCESS,
  FREIGHTS_DETAIL_DUPLICATE_FAILURE,
  FREIGHTS_DETAIL_DELETE_REQUEST,
  FREIGHTS_DETAIL_DELETE_FAILURE,
  FREIGHTS_DETAIL_UPDATE_REQUEST,
  FREIGHTS_DETAIL_UPDATE_SUCCESS,
  FREIGHTS_DETAIL_UPDATE_FAILURE,
  FREIGHTS_DETAIL_UPDATE_STATUS_REQUEST,
  FREIGHTS_DETAIL_UPDATE_STATUS_SUCCESS,
  FREIGHTS_DETAIL_UPDATE_STATUS_FAILURE,
  FREIGHT_LOCATION_SUCCESS,
  CLEAR_FREIGHT_DETAIL
} from "../actions/actionsTypes";

const INITIAL_STATE = {
  isLoading: false,
  freightData: {},
  freightLocation: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FREIGHTS_DETAIL_REQUEST:
      return {
        ...state,
      };

    case FREIGHTS_DETAIL_UPDATE_REQUEST:
    case FREIGHTS_DETAIL_DUPLICATE_REQUEST:
    case FREIGHTS_DETAIL_DELETE_REQUEST:
    case FREIGHTS_DETAIL_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case FREIGHTS_DETAIL_SUCCESS:
    case FREIGHTS_DETAIL_DUPLICATE_SUCCESS:
    case FREIGHTS_DETAIL_UPDATE_SUCCESS:
    case FREIGHTS_DETAIL_UPDATE_STATUS_SUCCESS:
      const newFreightDataSuccess = { ...action.payload.freight };
      const newIsLoadingSuccess = false;
      const freightDetail = state.freightData;

      if (!isEqual(state.freightData, newFreightDataSuccess)) {
        return {
          ...state,
          freightData: { ...freightDetail, ...newFreightDataSuccess },
          isLoading: newIsLoadingSuccess
        };
      } else {
        return {
          ...state,
          isLoading: newIsLoadingSuccess
        };
      }

    case FREIGHTS_DETAIL_FAILURE:
    case FREIGHTS_DETAIL_DUPLICATE_FAILURE:
    case FREIGHTS_DETAIL_DELETE_FAILURE:
    case FREIGHTS_DETAIL_UPDATE_FAILURE:
    case FREIGHTS_DETAIL_UPDATE_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case FREIGHT_LOCATION_SUCCESS:
      return {
        ...state,
        freightLocation: action.payload.userFreightLocation
      }

    case CLEAR_FREIGHT_DETAIL:
      return {
        ...state,
        freightData: {},
        freightLocation: {},
      };

    default:
      return state;
  }
};
