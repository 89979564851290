export default theme => ({
  main: {
    display: "flex",
    justifyContent: "center"
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: '90vw',
    height: 'auto', 
    paddingTop: theme.spacing(5),
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      width: '70vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '50vw',
      height: '60vh',
    }
  },
  title: {
    fontWeight: 600,
    color: "#585858",
    textTransform: "uppercase",
    marginBottom: theme.spacing(4),
    textAlign: "center",
    height: 'auto', 
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: 'auto', 
  },
  formCtlInput: {
    marginBottom: theme.spacing(2),
    height: 'auto', 
  },
  submitButton: {
    marginTop: theme.spacing(2), // Adicionado um espaço acima do botão
    height: "47px",
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3), // Maior espaço em tablets e acima
    }
  },
  messageTitle: {
    color: "rgba(26, 91, 118, 1)"
  },
  messageArea: {
    resize: "none",
    "&:focus": {
      borderColor: "rgba(26, 91, 118, 1)"
    }
  }
});
