import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CreateOffer from '../components/CreateOffer';
import { requestNewFreight } from '../actions/createFreight';
import { requestFreightDetail } from '../actions/freightDetail';
import VersionChecker, { useVersionChecker } from '../components/VersionChecker/VersionChecker';

const CreateOfferScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { freightDetail, createFreightState, auth } = useSelector(state => ({
    createFreightState: state.createFreight,
    freightDetail: state.freightDetail,
    auth: state.auth
  }));

  useVersionChecker('CreateOfferScreen');

  useEffect(() => {
    if (id !== undefined) {
      dispatch(requestFreightDetail(id));
    }
  }, [id, dispatch]);

  const handleRequestFreightData = (data) => {
    const { token, ...userWithoutToken } = auth.user;
    const freightData = {
      ...data,
      boardUserCreating: userWithoutToken,
    };

    dispatch(requestNewFreight(freightData));
  };

  return (
    <main className="main-content">
      <VersionChecker />
      <CreateOffer
        freight={freightDetail?.freightData}
        requestNewFreight={handleRequestFreightData}
        createFreightState={createFreightState}
      />
    </main>
  );
};

export default CreateOfferScreen;
