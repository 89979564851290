import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { ORDER_USER_STATUS } from '../../utils/freightUtils';
import { dateNow } from '../../utils/utils'
import { timelineStyles, StyledCircle, lineStyles, modalStyle, buttonStyle, modalButtonStyle, modalStyleDiv } from './styles';

const statusLabels = {
  [ORDER_USER_STATUS.USER_ORDER_SENT]: 'Ordem Recebida',
  [ORDER_USER_STATUS.ADVANCE_PAYMENT]: 'Adiantamento',
  [ORDER_USER_STATUS.USER_ORDER_SENT_REFUSED]: 'Análise de Fotos',
  [ORDER_USER_STATUS.FINISHED]: 'Liberar Saldo e Finalizar',
};

const Circle = ({ label, isActive, onButtonClick }) => (
  <StyledCircle isActive={isActive} onClick={onButtonClick}>
    <Button style={buttonStyle}>
      <div><Typography>{label}</Typography></div>
    </Button>
  </StyledCircle>
);

const getMissingFieldsMessage = (order, nextStatus, currentStatus) => {
  const fields = [
    { key: 'clientName', label: 'Nome do Cliente' },
    { key: 'value', label: 'Valor Total' },
    { key: 'OSCode', label: 'Código OS' },
    { key: 'driverDocument', label: 'Documento do Motorista' },
    { key: 'driverName', label: 'Nome do Motorista' },
    { key: 'advanceValue', label: 'Valor do Adiantamento' },
    { key: 'remainingValue', label: 'Valor do Saldo' },
    { key: 'pdfUrl', label: 'PDF (deve estar gerado e disponivel para baixar)' },
  ];

  if (currentStatus === ORDER_USER_STATUS.FINISHED) {
    return 'Ordem finalizada'
  }

  if (nextStatus === ORDER_USER_STATUS.FINISHED && !order.advancedRequestedPaymentDate) {
    fields.push({ key: 'advancedRequestedPaymentDate', label: 'Data de Adiantamento' });
  }

  if (nextStatus === ORDER_USER_STATUS.ADVANCE_PAYMENT && !order.advancedRequestedPaymentDate) {
    return ''
  }

  if (nextStatus === ORDER_USER_STATUS.ADVANCE_PAYMENT && order.advancedRequestedPaymentDate) {
    return 'Data de Adiantamento já prenchida'
  }

  if (nextStatus === ORDER_USER_STATUS.USER_ORDER_SENT_REFUSED) {
    return ''
  }

  if (nextStatus === ORDER_USER_STATUS.USER_ORDER_SENT) {
    return ''
  }


  const missingFields = fields.filter(field => !order[field.key]).map(field => field.label);
  return missingFields.length > 0 ? `Verifique se os campos estão preenchidos:
  ${missingFields.join('; ')}.` : '';
};


const OrderProgress = ({ onUpdateStatus, onUpdateOrder, order }) => {
  const [currentStatus, setCurrentStatus] = useState(order.status);
  const [nextStatus, setNextStatus] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [missingFieldsMessage, setMissingFieldsMessage] = useState('');

  useEffect(() => {
    setCurrentStatus(order.status);
  }, [order.status]);

  const handleButtonClick = status => {
    setNextStatus(status);
    setIsModalOpen(true);
    setMissingFieldsMessage(getMissingFieldsMessage(order, status, currentStatus));
  };

  const moveToNextStatus = async () => {
    if (nextStatus) {
      if (nextStatus === "ADVANCE_PAYMENT") {
        const updatedOrder = {
          status: nextStatus,
          advancedRequestedPaymentDate: dateNow()
        };
        await onUpdateOrder({ order: updatedOrder, id: order.id })
        setCurrentStatus(nextStatus);

      } else if (nextStatus === "FINISHED") {
        const updatedOrder = {
          status: nextStatus,
          paymentSuccessfulBalance: dateNow()
        };
        await onUpdateOrder({ order: updatedOrder, id: order.id })
        setCurrentStatus(nextStatus);

      } else {
        await onUpdateStatus(nextStatus, order.id);
        setCurrentStatus(nextStatus);
      }

    }
    setIsModalOpen(false);
  };

  const isStatusChangeAllowed = () => {
    return missingFieldsMessage === '';
  };

  return (
    <div style={timelineStyles}>
      <div style={lineStyles}></div>
      {Object.entries(statusLabels).map(([status, label]) => (
        <Circle
          key={status}
          label={label}
          isActive={currentStatus === status}
          onButtonClick={() => handleButtonClick(status)}
        />
      ))}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} style={modalStyle}>
        <div style={{ textAlign: 'center' }}>
          <div style={modalStyleDiv}>
            <Typography id="transition-modal-title" variant="h4">
              Atualizar Status
            </Typography>
            <br />
            {missingFieldsMessage && (
              <Typography id="transition-modal-description" variant="h6">
                {missingFieldsMessage}
              </Typography>
            )}
            <Divider />
            <br />
            {isStatusChangeAllowed() && (
              <Button onClick={moveToNextStatus} style={modalButtonStyle} variant="contained" color="success">
                {statusLabels[nextStatus]}
              </Button>
            )}
            <Button onClick={() => setIsModalOpen(false)} style={modalButtonStyle} variant="contained">
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OrderProgress;
