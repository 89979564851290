import React, { useState } from 'react';
import {
  Paper,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import {
  StarTwoTone,
  WorkspacePremiumTwoTone,
  MilitaryTechTwoTone,
  EmojiEventsTwoTone,
  AssistantTwoTone,
  BeenhereTwoTone,
  GppGoodTwoTone,
  GppMaybeTwoTone,
  LocalPoliceTwoTone,
  ShieldTwoTone,
  VerifiedUserTwoTone,
} from '@mui/icons-material';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles';

const ModalRank = ({ driver }) => {
  const [isMinimized, setIsMinimized] = useState(true);

  const countFreightPoints = () => {
    const requestedFreights = driver?.requestedFreights || {};
    let totalPoints = 0;

    for (const key in requestedFreights) {
      if (requestedFreights.hasOwnProperty(key)) {
        const request = requestedFreights[key];
        totalPoints += calculatePoints(request.requestStatus);
      }
    }

    return totalPoints;
  };

  const calculatePoints = (status) => {
    switch (status) {
      case 'CANCELED':
        return 20;
      case 'WAITING':
        return 30;
      case 'APPROVED':
        return 40;
      case 'FINISHED':
        return 50;
      default:
        return 10;
    }
  };

  const rank = countFreightPoints();

  const getMedalIcon = () => {
    if (rank === 0) {
      return <VerifiedUserTwoTone color="primary" fontSize="large" />;
    } else if (rank <= 50) {
      return <ShieldTwoTone color="primary" fontSize="large" />;
    } else if (rank <= 150) {
      return <GppMaybeTwoTone color="primary" fontSize="large" />;
    } else if (rank <= 250) {
      return <AssistantTwoTone color="primary" fontSize="large" />;
    } else if (rank <= 350) {
      return <EmojiEventsTwoTone color="primary" fontSize="large" />;
    } else if (rank <= 450) {
      return <BeenhereTwoTone color="primary" fontSize="large" />;
    } else if (rank <= 550) {
      return <StarTwoTone color="primary" fontSize="large" />;
    } else if (rank <= 650) {
      return <GppGoodTwoTone color="primary" fontSize="large" />;
    } else if (rank <= 750) {
      return <MilitaryTechTwoTone color="primary" fontSize="large" />;
    } else if (rank <= 850) {
      return <WorkspacePremiumTwoTone color="primary" fontSize="large" />;
    } else {
      return <LocalPoliceTwoTone color="primary" fontSize="large" />;
    }
  };

  const handleToggle = () => {
    setIsMinimized(!isMinimized);
  };

  const CardHeader = () => (
    <>
      <CardMedia
        component="img"
        height="140"
        image={(driver && driver.userPhoto?.imageUrl) || ''}
      />
      <Typography variant="h5" component="div" style={styles.cardHeader}>
        {(driver && driver.name) || ' '}
      </Typography>
    </>
  );

  const CardBody = () => (
    <CardContent style={styles.cardBody}>
      <Typography gutterBottom>
        Pontuação: {rank !== 0 ? rank : 0}
      </Typography>
      <Typography gutterBottom>
        {getMedalIcon()}
      </Typography>
    </CardContent>
  );

  return (
    <>
      {isMinimized ? (
        <IconButton
          style={styles.minimizedButton}
          onClick={handleToggle}
        >
          <AddCircle color="primary" />
        </IconButton>
      ) : (
        <Paper
          elevation={3}
          style={styles.paper}
        >
          <Card style={styles.card}>
            <CardHeader />
            <CardBody />
          </Card>
          <IconButton
            style={styles.closeButton}
            onClick={handleToggle}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </Paper>
      )}
    </>
  );
};

export default ModalRank;
