import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Fab, CircularProgress, Button, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import OccurrenceCard from "../OccurrenceCard";
import styles from "./styles";
import { dateNow, exportManyToExcel } from '../../utils/utils';

const exportAndLog = (occurrencesList) => {
  exportManyToExcel(occurrencesList, `Ocorrências_${dateNow()}`, 'occurrences');
};

const OccurrencesList = props => {
  const { classes, occurrencesList, isLoading, hasMore, handleLoadMore } = props;

  return (
    <div id="scrollableDiv" className={classes.root}>
      <div className={classes.infiniteScrollContainer}>
        <Grid container spacing={3}>
          {occurrencesList.map(occurrence => (
            <Grid
              key={occurrence.id}
              item
              xs={12}
              sm={4}
              md={3}
              className={classes.relativePos}
            >
              {!occurrence.updateAcknowledge && (
                <div className={classes.statusIcon} />
              )}
              <OccurrenceCard occurrence={occurrence} />
            </Grid>
          ))}
        </Grid>
        {isLoading && (
          <div className={classes.progress}>
            <CircularProgress alt={"Loading..."} />
          </div>
        )}
        {!isLoading && hasMore && (
          <Button
            onClick={handleLoadMore}
            className={classes.loadMoreButton}
          >
            Carregar Mais
          </Button>
        )}
        {!isLoading && (
          <Button
            onClick={() => exportAndLog(occurrencesList)}
            className={classes.exportButton}
          > Exportar lista
          </Button>
        )}
        {!hasMore && (
          <Typography className={classes.noMoreItems}>
            Não há mais itens para carregar.
          </Typography>
        )}
        <Link to="/create/occurrences">
          <Fab aria-label="Criar ocorrência para um motorista" className={classes.addButton}>
            <AddIcon />
          </Fab>
        </Link>
      </div>
    </div>
  );
};

export default withStyles(styles, { name: "OccurrencesList" })(OccurrencesList);
