const parse = (key: string) => {
  try {
    return JSON.parse(sessionStorage.getItem(key) || '');
  } catch (e) {
    return undefined;
  }
};

const save = (key: string, data: any) => {
  try {
    return sessionStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    return undefined;
  }
};

const remove = (key: string) => sessionStorage.removeItem(key);

export default { parse, save, remove };
