import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { decode } from 'jsonwebtoken';
import ReCAPTCHA from 'react-google-recaptcha';
import { requestUserLogin, toggleRememberUser, clearErrorMessage } from '../actions/auth';
import storage from '../store/sessionStorage';

import LoginComponent from '../components/Login';

const LoginScreen: React.FC = ({ ...rest }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.auth);

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [captchaToken, setCaptchaToken] = useState<string | undefined>();

  const captchaRef = useRef<any>();
  const { authenticating, isAuthenticated, rememberUser, errorMessage } = auth;

  useEffect(() => {
    if (errorMessage && errorMessage !== '') {
      captchaRef.current.reset();
    }
  }, [errorMessage]);

  const handleChangeEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  }, []);

  const handleChangePassword = useCallback((event) => {
    setPassword(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        requestUserLogin({
          username: userName,
          password,
          captchaToken,
        }),
      );
      captchaRef.current.reset();
    },
    [userName, password, captchaToken, dispatch],
  );

  const token = storage.parse('_transport_user')?.token || '';
  const unformattedToken = (decode(token) as any) || { iat: Date.now() };
  const diff = Math.floor(
    Math.abs(Date.now() / 1000 - Number.parseInt(unformattedToken?.iat, 10)) / (60 * 60),
  );

  if (isAuthenticated && diff < 8) {
    return <Redirect to="/home" />;
  }

  const onChange = (value: any) => {
    setCaptchaToken(value);
  };

  return (
    <>
      <LoginComponent
        handleChangeEmail={handleChangeEmail}
        handleChangePassword={handleChangePassword}
        email={userName}
        password={password}
        handleSubmit={handleSubmit}
        rememberUser={rememberUser}
        handleToggleRemember={() => {
          dispatch(toggleRememberUser());
        }}
        error={errorMessage}
        clearError={() => {
          dispatch(clearErrorMessage());
        }}
        enabledSubmit={!!userName?.length && !!password?.length && !!captchaToken?.length}
        loading={authenticating}
        {...rest}
      >
        <ReCAPTCHA
          sitekey={
            String(process.env.REACT_APP_CAPTCHA)
          }
          onChange={onChange}
          ref={captchaRef}
        />
      </LoginComponent>
    </>
  );
};

export default LoginScreen;
