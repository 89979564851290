export default theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "1244px",
    paddingTop: theme.spacing(1),
  },
  cardContainer: {
    margin: "20px",
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      margin: "30px 70px 0px 70px"
    }
  },
  card: {
    width: "190px",
    height: "190px",
    padding: theme.spacing(1) * 3,
    borderRadius: "6px"
  },
  cardImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "70%"
  },
  cardLabel: {
    width: "100%",
    height: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  cardLabelTypography: {
    ...theme.typography.button,
    fontWeight: 600,
    color: "#1A5B76"
  },
  statusIcon: {
    "& > span": {
      width: "33px",
      height: "33px",
      fontWeight: 600,
      borderRadius: 50
    }
  }
});
