import { COUNTERS_REQUEST, COUNTERS_SUCCESS, COUNTERS_FAILURE } from './actionsTypes';

export const countersRequest = () => {
  return { type: COUNTERS_REQUEST };
};

export const countersSuccess = (payload: any) => {
  return { type: COUNTERS_SUCCESS, payload };
};

export const countersError = () => {
  return { type: COUNTERS_FAILURE };
};
