import { createTheme } from '@material-ui/core/styles'

const INPUT_TEXT_COLOR = '#707070';
const UNDERLINE_COLOR = '#3385A6';
const UNDERLINE_COLOR_ALPHA = 'rgba(204, 204, 204, 0.6)';

export const COLOR_BLUE = 'rgba(26, 91, 118, 1)';
export const COLOR_BLUE_ALPHA1 = 'rgba(26, 91, 118, 0.1)';
export const COLOR_BLUE_ALPHA5 = 'rgba(26, 91, 118, 0.5)';
export const COLOR_BLUE_ALPHA8 = 'rgba(26, 91, 118, 0.8)';
export const COLOR_ERROR = 'rgba(201, 88, 88, 1)';
export const COLOR_WARNING = 'rgba(255, 204, 19, 1)';
export const COLOR_SUCCESS = 'rgba(6, 172, 28, 1)';
export const COLOR_UPDATE = 'rgba(12, 0, 248, 1)';

export const btnRed = '#A33C3C';
export const btnRedHover = '#DA3E3E';
export const btnGreen = '#1EAD66';
export const btnGreenHover = '#1DBF6E';
export const btnYellow = '#bfb529';
export const btnYellowHover = '#cfc42a';
export const btnBlue = '#1A5B76';
export const btnBlueHover = '#1D6F91';
export const btnBlueDisabled = '#bbbbbbc7';
export const COLOR_DEFAULT = '#1A5B76';
export const COLOR_DEFAULT_HOVER = '#1D6F91';

export default function createGlobalTheme(darkMode) {
  return createTheme({
    palette: {
      type: darkMode ? 'dark' : 'light',
      text: {
        primary: darkMode ? '#ffffff' : 'rgba(0, 0, 0, 0.87)', 
        secondary: darkMode ? '#bbbbbb' : 'rgba(0, 0, 0, 0.54)', 
      },
      error: {
        main: COLOR_ERROR
      },
    },
    overrides: {
      /* 
        CircularProgres override
      */
      MuiCircularProgress: {
        colorPrimary: {
          color: COLOR_BLUE
        }
      },
      /* 
        Checkbox override
      */
      MuiCheckbox: {
        root: {
          color: COLOR_BLUE,
          "&:hover": {
            backgroundColor: COLOR_BLUE_ALPHA1
          }
        },
        checked: {},
        disabled: {},
        colorSecondary: {
          "&$checked": {
            color: COLOR_BLUE,
            "&:hover": {
              backgroundColor: COLOR_BLUE_ALPHA1
            }
          },
          "&$disabled": {
            color: COLOR_BLUE_ALPHA5
          }
        }
      },

      /* 
        Datepicker override
      */
      MuiPickersModal: {
        dialogRoot: {
          '& button > span': {
            color: COLOR_BLUE
          }
        }
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: COLOR_BLUE,
        },
      },
      MuiPickersDay: {
        day: {
          color: COLOR_BLUE_ALPHA8,
        },
        isDisabled: {
          '& > span > p': {
            color: COLOR_BLUE_ALPHA5
          }
        },
        isSelected: {
          backgroundColor: COLOR_BLUE_ALPHA8,
          '&:hover': {
            backgroundColor: COLOR_BLUE
          },
          '& > span > p': {
            color: '#FFF'
          }
        },
        current: {
          color: COLOR_BLUE,
          '& > span > p': {
            fontWeight: 600
          },
          '&$isDisabled': {
            backgroundColor: 'unset'
          }
        },
      },
      /*
        Input overrride
      */
      MuiInputLabel: {
        root: {
          color: COLOR_BLUE,
          '&$focused': {
            color: COLOR_BLUE,
          }
        },
        focused: {}
      },
      /* Hack para sobrescrever autofill dos campos com auto-complete */
      MuiInputBase: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
      MuiInput: {
        root: {
          color: INPUT_TEXT_COLOR
        },
        disabled: {},
        focused: {},
        error: {},
        underline: {
          '&:after': {
            borderBottom: `2px solid ${UNDERLINE_COLOR}`
          },
          '&$error:after': {
            borderBottom: `2px solid`
          },
          '&:before': {
            borderBottom: `1px solid ${UNDERLINE_COLOR_ALPHA}`
          },
          '&$error': {
            color: COLOR_ERROR
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `2px solid ${UNDERLINE_COLOR_ALPHA}`
          },
          '&:not($disabled) svg': {
            color: UNDERLINE_COLOR
          },
          '&:hover:not($disabled) svg': {
            color: UNDERLINE_COLOR
          }
        }
      }
    }
  });
}
