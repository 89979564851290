import {
  FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE,
  UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE,
  CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE, // eslint-disable-next-line
  DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE
} from '../actions/actionsTypes';

const initialState = {
  boardUsers: [],
  isLoading: false,
  error: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
    case UPDATE_USER_REQUEST:
    case CREATE_USER_REQUEST:
      return { ...state, isLoading: true, error: null };
    case FETCH_USERS_SUCCESS:
      return { ...state, boardUsers: action.payload, isLoading: false };
    case UPDATE_USER_SUCCESS:
      return { ...state, isLoading: false, boardUsers: state.boardUsers.map(boardUser => boardUser.id === action.payload.id ? action.payload : boardUser) };
    case CREATE_USER_SUCCESS:
      return { ...state, isLoading: false, boardUsers: [...state.boardUsers, action.payload] };
    case FETCH_USERS_FAILURE:
    case UPDATE_USER_FAILURE:
    case CREATE_USER_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        boardUsers: state.boardUsers.filter(user => user.id !== action.payload),
        isLoading: false,
      };
    default:
      return state;
  }
}
