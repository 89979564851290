export default theme => ({
  main: {
    display: "flex",
    justifyContent: "center"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 450,
    height: 460,
    paddingTop: theme.spacing(5),
    padding: theme.spacing(3)
  },
  title: {
    fontWeight: 600,
    color: "#000000",
    textTransform: "uppercase",
    marginBottom: theme.spacing(4)
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  formCtlInput: {
    marginBottom: theme.spacing(2)
  },
  submitButton: {
    height: "47px"
  }
});
