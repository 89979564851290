import storage from '../store/sessionStorage';
import { STORAGE_USER_KEY, STORAGE_REMEMBER_KEY, PROFILES } from '../constants';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  TOGGLE_REMEMBER_USER,
  CLEAR_ERROR_MESSAGE,
} from '../actions/actionsTypes';

const user = storage.parse(STORAGE_USER_KEY);

function getUserRoles(user) {
  return !!user && !!PROFILES[user.profile] ? PROFILES[user.profile].roles : [];
}

const INITIAL_STATE = {
  user: { ...user },
  authenticating: false,
  isAuthenticated: !!user,
  rememberUser: !!storage.parse(STORAGE_REMEMBER_KEY),
  errorMessage: '',
  userRoles: getUserRoles(user),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_REMEMBER_USER:
      storage.save(STORAGE_REMEMBER_KEY, !state.rememberUser);
      return {
        ...state,
        rememberUser: !state.rememberUser,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        authenticating: true,
        errorMessage: '',
      };

    case LOGIN_SUCCESS:
      storage.save(STORAGE_USER_KEY, action.payload);
      return {
        ...state,
        user: action.payload,
        userRoles: getUserRoles(action.payload),
        authenticating: false,
        isAuthenticated: true,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        authenticating: false,
        isAuthenticated: false,
        errorMessage: action.payload,
      };

    case LOGOUT:
      storage.remove(STORAGE_USER_KEY);
      storage.save(STORAGE_REMEMBER_KEY, false);
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        rememberUser: false,
      };

    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: '',
      };

    default:
      return state;
  }
};
