import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '../Button';
import { WeightInput } from '../../utils/utils';
import styles from './styles';

const ModalWeight = ({ classes, open, onClose, existingWeight = '' }) => {
  const [weight, setWeight] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (open) {
      setWeight(existingWeight);
      setError(false);
    }
  }, [open, existingWeight]);

  const handleChange = (event) => {
    setWeight(event.target.value);
    setError(false);
  };

  const handleSave = () => {
    if (weight && !isNaN(weight.replace(/\./g, '').replace(',', '.').replace(' Kg', ''))) {
      onClose(weight);
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') {
          onClose(null);
        }
      }}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle disableTypography className={classes.title}>
        Peso
      </DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          className={classes.input}
          variant="outlined"
          label="Digite o peso em kilos"
          value={weight}
          onChange={handleChange}
          error={error}
          helperText={error && "Por favor, insira um peso válido"}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: WeightInput,
          }}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          size="large"
          customColor="blue"
          onClick={handleSave}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ModalWeight);