import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/index.scss';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import GoogleMapsLoader from 'google-maps';
import { store } from './store';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

GoogleMapsLoader.KEY = 'AIzaSyBtNNarnAAqG4almAYbKLIkIZVV-ZtbWpA';
GoogleMapsLoader.VERSION = '3.55';
GoogleMapsLoader.load();

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <App />
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root'),
);
serviceWorkerRegistration.register();
