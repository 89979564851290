import React, { useState, DragEvent } from 'react';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import { generatePdfWithImages } from '../../utils/utils';
import useStyles from './styles';

interface Photo {
  imageUrl: string;
  status: string;
  message: string;
  sender: string;
}

interface PhotosToPdfProps {
  id: string;
  type: string;
  pdfUrlOld: string;
  isLoading: boolean;
  onUpdate: (data: any) => Promise<void>;
  setIsLoadingPdf: (loading: boolean) => void;
  isLoadingPdf: boolean;
}

const PhotosToPdf: React.FC<PhotosToPdfProps> = ({ id, type, pdfUrlOld, isLoading, onUpdate, setIsLoadingPdf, isLoadingPdf }) => {
  const classes = useStyles();
  const [sequence, setSequence] = useState<Photo[]>([]);
  const [pdfUrl, setPdfUrl] = useState<string>('');

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const photoUrl = e.dataTransfer.getData('text/plain');
    setSequence((prev) => [...prev, { imageUrl: photoUrl, status: "WAITING", message: "", sender: "" }]);
  };

  const handleDeletePhoto = (indexToDelete: number) => {
    setSequence((prev) => prev.filter((_, index) => index !== indexToDelete));
  };

  const clearAllPhotos = () => {
    setSequence([]);
  };

  const handleDownloadPdf = async (url: string) => {
    setIsLoadingPdf(true);
    if (!url) {
      console.error('A URL do PDF não foi fornecida.');
      setIsLoadingPdf(false);
      return;
    }
    try {
      const response = await fetch(url);
      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = url.split('/').pop() || 'download.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);
      } else {
        alert('Falha ao obter o PDF do servidor, tente novamente mais tarde.');
      }
    } catch (error: any) {
      console.error('Erro no método handleDownloadPdf:', error.message);
    } finally {
      setIsLoadingPdf(false);
    }
  };

  const handleUpdateAndGenerate = async () => {
    if (sequence.length === 0 || isLoading) return; // eslint-disable-next-line
    const validImageUrlRegex = /^https:\/\/[\w\-\.]+\/[\w\-\/]+\.jpeg(\?.*)?$/;

    const hasInvalidImageUrls = sequence.some(image => !validImageUrlRegex.test(image.imageUrl));
    if (hasInvalidImageUrls) {
      alert('Por favor, forneça uma imagem válida para todos os itens da sequência.');
      return;
    }

    setIsLoadingPdf(true);

    const preparedSequence = sequence.map(image => ({
      ...image,
      status: "APPROVED"
    }));

    try {
      await onUpdate({ id, type, order: { userImages: preparedSequence } });

      const pdfResponse = await generatePdfWithImages(id, type);
      if (pdfResponse.status !== 200) {
        alert('Falha ao gerar o PDF no servidor, tente novamente mais tarde.');
        throw new Error('Falha ao gerar o PDF.');
      }

      const newPdfUrl = pdfResponse.data.pdfUrl;
      setPdfUrl(newPdfUrl);

      await onUpdate({ id, type, order: { pdfUrl: newPdfUrl } });

    } catch (error: any) {
      console.error(error.message);
    } finally {
      clearAllPhotos();
      setIsLoadingPdf(false);
    }
  };

  const getDownloadPdfButton = () => {
    const urlToDownload = pdfUrl || pdfUrlOld;
    if (!urlToDownload) return null;
    const buttonLabel = pdfUrl ? "Baixar PDF" : "Baixar PDF";

    return (
      <button
        onClick={() => handleDownloadPdf(urlToDownload)}
        className={[classes.btn, classes.btnWhite].join(' ')}
      >
        {buttonLabel}
      </button>
    );
  };

  return (
    <div className={classes.sequenceContainer}>
      <div className={classes.dropArea} onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
        <Typography variant="h6">{"Para gerar um novo pdf > arraste as fotos > na sequencia desejada > clique em gerar pdf > aguarde o processamento > clique em baixar pdf"}</Typography>
      </div>
      <div className={classes.sequence}>
        {sequence.length > 0 && !isLoading ? (
          <button
            onClick={handleUpdateAndGenerate}
            className={classes.generatePdfButton}
          >
            {isLoadingPdf ? (
              <>
                <span>Gerando PDF</span>
                <CircularProgress />
              </>
            ) : (
              <span>Gerar PDF</span>
            )}
          </button>
        ) : (
          <>
            {getDownloadPdfButton()}
          </>
        )}
        {sequence.map((photo, index) => (
          <div key={index} className={classes.sequencePhotoContainer}>
            <span className={classes.sequenceNumber}>{index + 1}</span>
            <img src={photo.imageUrl} className={classes.sequencePhoto} draggable="true" alt="imageUrl" />
            <button onClick={() => handleDeletePhoto(index)} className={classes.deletePhotoButton}>Excluir</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotosToPdf;
