import { decode } from 'jsonwebtoken';
import sessionStorage from '../store/sessionStorage';

export const isUserLogged = (authState: any) => {
  const token = sessionStorage.parse('_transport_user')?.token || '';
  const unformattedToken = (decode(token) as any) || { iat: Date.now() };
  const diff = unformattedToken
    ? Math.floor(
        Math.abs(Date.now() / 1000 - Number.parseInt(unformattedToken?.iat, 10)) / (60 * 60),
      )
    : 8;

  return authState.isAuthenticated && diff < 8;
};
