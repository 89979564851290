import React from 'react';
import Modal from '@material-ui/core/Modal';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { formatDate } from '../../utils/utils';
import styles from './styles';

interface Update {
  fieldName: string;
  modifiedDate: string;
  whoChanged: string;
}

interface ModalUserUpdatesProps extends WithStyles<typeof styles> {
  updates: Update[];
  isOpen: boolean;
  handleClose: () => void;
}

const fieldLabels: { [key: string]: string } = {
  'cnhPhoto': 'Foto da CNH',
  'cpf': 'CPF',
  'creationDate': 'Data de Criação',
  'updateDate': 'Data de Atualização',
  'name': 'Nome',
  'phoneNumber': 'Telefone Primário',
  'phoneNumberSecondary': 'Telefone Secundário',
  'proofResidencePhoto': 'Foto de Comprovante de Residência',
  'rgPhoto': 'Foto do RG',
  'status': 'Status',
  'userPhoto': 'Foto do Usuário',
  'vehicle.anttPhoto': 'Foto do ANTT',
  'vehicle.documentPhoto': 'Foto do Documento do Veículo',
  'vehicle.ownedByUser': 'Veículo Próprio',
  'vehicle.semiTrailerDocumentPhotos': 'Fotos dos Documentos do Semi-Reboque',
  'vehicle.semiTrailerPhotos': 'Fotos do Semi-Reboque',
  'vehicle.truckUnitPhoto': 'Foto da Unidade do Caminhão',
  'vehicle.type': 'Tipo de Veículo',
  'vehicle.typeByBoard': 'Tipo de Carroceria',
  'vehicle.vehiclePhoto': 'Foto do Veículo',
  'vehicleOwner.email': 'E-mail do Proprietário do Veículo',
  'vehicleOwner.document': 'Documento do Proprietário do Veículo',
  'vehicleOwner.address': 'Endereço do Proprietário do Veículo',
  'vehicleOwner.contact': 'Contato do Proprietário do Veículo',
  'wallet.pixKey': 'Chave Pix',
  'wallet.cardPhoto': 'Foto do Cartão',
  'wallet.type': 'Tipo de Carteira',
  'wallet.account': 'Conta Bancária',
  'wallet.agency': 'Agência Bancária',
  'wallet.bank': 'Banco',
  'wallet.pis': 'PIS',
  'wallet.accountDocument': 'Documento da Conta Bancária',
  'wallet.accountName': 'Nome da Conta Bancária',
  'wallet.agreementDate': 'Data de Autorização',
  'fieldsRecentlyModified.fieldName': 'Nome do Campo Modificado Recentemente',
  'fieldsRecentlyModified.modifiedDate': 'Data de Modificação',
  'validated': 'Validado',
  'acknowledge': 'Reconhecido',
  'updateAcknowledge': 'Reconhecimento de Atualização',
  'hashPassword': 'Senha Criptografada',
  'token': 'Token de Acesso',
  'removeNotificationToken': 'Token de Acesso',
  'ignoreNotification': 'Token de Acesso',
};

const ModalUserUpdates: React.FC<ModalUserUpdatesProps> = ({
  updates, classes, isOpen, handleClose }) => {

  const filteredUpdates = updates.filter(update => update.fieldName !== 'boardRequest' &&
    update.fieldName !== 'hashPassword' && update.fieldName !== 'updateAcknowledge' &&
    update.fieldName !== 'removeNotificationToken' && update.fieldName !== 'ignoreNotification' &&
    update.fieldName !== 'whoChanged');

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={classes.modal}>
        <IconButton
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" className={classes.title}>
          Últimas Atualizações do Usuário
        </Typography>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="updates table">
            <TableHead
              className={classes.tableTitle}>
              <TableRow>
                <TableCell>Atualização</TableCell>
                <TableCell>Data da Modificação</TableCell>
                <TableCell>Quem Alterou</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUpdates.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    Nenhuma Atualização do Usuário
                  </TableCell>
                </TableRow>
              ) : (
                filteredUpdates.map((update, index) => (
                  <TableRow
                    key={index}
                    className={index % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd}
                  >
                    <TableCell>{fieldLabels[update.fieldName] || update.fieldName}</TableCell>
                    <TableCell>{formatDate(update.modifiedDate)}</TableCell>
                    <TableCell>{update.whoChanged || 'Motorista'}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(ModalUserUpdates);
