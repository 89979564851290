import api from "../api";

import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  NOTIFY_BROADCAST_REQUEST,
  NOTIFY_BROADCAST_SUCCESS,
  NOTIFY_BROADCAST_FAILURE
} from "./actionsTypes";

export const enqueueSnackbar = notification => ({
  type: ADD_NOTIFICATION,
  notification: {
    key: new Date().getTime() + Math.random(),
    ...notification
  }
});

export const removeSnackbar = key => ({
  type: REMOVE_NOTIFICATION,
  key
});

export const notificationBroadcastRequest = (payload) => {
  return async (dispatch) => {
    dispatch({ type: NOTIFY_BROADCAST_REQUEST, payload });

    api
      .post("/api/notifications", {
        payload
      })
      .then((res) => {
        dispatch(notificationBroadcastSuccess());

        dispatch(
          enqueueSnackbar({
            message: "Notificação enviada com sucesso",
            options: { variant: "success" }
          })
        );
      })
      .catch((err) => {
        dispatch(notificationBroadcastFailure());

        dispatch(
          enqueueSnackbar({
            message:
              "Problema ao enviar notificação. Favor entrar em contato com o suporte",
            options: { variant: "error" }
          })
        );
      });
  };
};

export const notificationBroadcastSuccess = () => {
  return { type: NOTIFY_BROADCAST_SUCCESS };
};

export const notificationBroadcastFailure = () => {
  return { type: NOTIFY_BROADCAST_FAILURE };
};
