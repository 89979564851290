import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import styles from './styles';
import Steps from '../FreightSteps/index';

class FreightProgress extends Component {
  state = {
    freight: this.transformFreight(this.props.freight),
  };

  static defaultProps = {
    zoom: 11,
  };

  searchInterval = null;

  componentDidMount() {
    this.getFreightsLocations();
    this.configInterval();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.freight !== this.props.freight) {
      this.setState({ freight: this.transformFreight(this.props.freight) });
    }
  }

  configInterval = () => {
    this.searchInterval = setInterval(() => {
      this.getFreightsLocations();
    }, 30000); // 30 seconds
  };

  componentWillUnmount() {
    this.searchInterval && clearInterval(this.searchInterval);
  }

  getFreightsLocations = () => {
    const { freight } = this.props;

    if (freight && freight.id && freight.driver) {
      this.props.getFreightsLocations(freight.id, freight.driver);
    }
  }

  transformFreight(freight) {
    return {
      ...freight,
      invoiceCouponPhotos: Array.isArray(freight.invoiceCouponPhotos)
        ? freight.invoiceCouponPhotos.map(photo => {
          if (typeof photo === 'string') {
            return {
              imageUrl: photo,
              status: 'WAITING',
              sender: '',
              message: ''
            };
          }
          return photo;
        })
        : []
    };
  }


  nextStep = (nextStatus, idFreight, content, showNotification) => {
    const newFreight = cloneDeep(this.state.freight);
    newFreight.status = nextStatus;

    this.props.updateStatus(nextStatus, idFreight, content, false, showNotification);
    this.setState({ freight: { ...newFreight } });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography className={classes.textHeader}>Frete em Andamento</Typography>
          <Divider className={classes.divider} />
        </div>
        <Paper className={classes.paper}>
          <Steps
            freight={this.state.freight}
            nextStep={this.nextStep}
            update={this.props.updateFreight}
            isLoading={this.props.isLoading}
            boardUser={this.props.boardUser}
          />
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(FreightProgress);
