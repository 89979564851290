import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const colors = {
  red: 'rgb(163, 60, 60)',
  redHover: 'rgba(163, 60, 60, 0.8)',
  redDisabled: 'rgba(163, 60, 60, 0.5)',
  green: 'rgb(30, 173, 102)',
  greenHover: 'rgb(30, 173, 102, 0.8)',
  greenDisabled: 'rgb(30, 173, 102, 0.5)',
  blue: 'rgb(26, 91, 118)',
  blueHover: 'rgba(26, 91, 118, 0.8)',
  blueDisabled: 'rgba(26, 91, 118, 0.5)',
};

const fontSizes = {
  small: '0.5375rem',
  medium: '0.7375rem',
  large: '0.8375rem',
};

const progressSizes = {
  small: 18,
  medium: 20,
  large: 24,
};

const useStyles = makeStyles(
  {
    disabled: {
      color: '#FFF',
      backgroundColor: (props) => colors[`${props.customColor}Disabled`],
    },
    button: {
      color: '#FFF',
      fontWeight: 600,
      fontSize: (props) => fontSizes[props.size],
      backgroundColor: (props) => colors[props.customColor],
      '&:hover': {
        backgroundColor: (props) => colors[`${props.customColor}Hover`],
      },
      '&$disabled': {
        color: '#FFF',
        backgroundColor: (props) => colors[`${props.customColor}Disabled`],
      },
    },
    progress: {
      position: 'absolute',
      left: '45%',
    },
  },
  { name: 'CustomButton' },
);

const CustomButton = (props) => {
  const classes = useStyles(props);
  const { isLoading, disabled, customColor, ...other } = props;
  return (
    <Button
      {...other}
      disabled={disabled || isLoading}
      classes={{ root: classes.button, disabled: classes.disabled }}
    >
      {!disabled && isLoading && (
        <CircularProgress size={progressSizes[props.size]} className={classes.progress} />
      )}
      {props.children}
    </Button>
  );
};

CustomButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  customColor: PropTypes.oneOf(['blue', 'green', 'red']),
};

export default CustomButton;
