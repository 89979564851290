import { combineReducers } from 'redux';
import auth from './auth';
import boardUserReducer from './boardUserReducer';
import drivers from './drivers';
import freights from './freights';
import orders from './orders';
import occurrences from './occurrences';
import driverDetail from './driverDetail';
import freightDetail from './freightDetail';
import orderDetail from './orderDetail';
import occurrenceDetail from './occurrenceDetail';
import createFreight from './createFreight';
import notifications from './notifications';
import counter from './counter';

export default combineReducers({
  auth,
  boardUserReducer,
  drivers,
  freights,
  orders,
  occurrences,
  driverDetail,
  freightDetail,
  orderDetail,
  occurrenceDetail,
  createFreight,
  notifications,
  counter,
});
