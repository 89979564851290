import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    modal: {
      position: 'absolute',
      width: 600,
      maxHeight: '80vh',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      overflowY: 'auto',
      borderRadius: '5px',
      display: 'ruby',
    },
    modalContent: {
      maxHeight: '60vh',
      overflowY: 'auto',
    },
    button: {
      position: 'fixed',
      top: '50%',
      left: '2px',
      transform: 'translateY(-50%)',
      zIndex: 1000,
      width: '58px',
      height: '58px',
      overflow: 'hidden',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0.2),
      top: theme.spacing(0.2),
      color: theme.palette.grey[500],
    },
    updateItem: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    title: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1.5),
    },
    tableContainer: {
      maxHeight: '50vh',
      borderRadius: '5px',
    },
    table: {
      minWidth: 500,
    },
    tableTitle: {
      backgroundColor: theme.palette.grey[300],
    },
    tableRowOdd: {
      backgroundColor: theme.palette.grey[200],
    },
    tableRowEven: {
      backgroundColor: theme.palette.grey[100],
    },
  });

export default styles;
