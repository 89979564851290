export default theme => ({
  container: {
    display: "flex",
    position: "absolute",
    zIndex: "10",
    top: "50px",
    flexWrap: "wrap",
    //maxWidth: "430px",
    backgroundColor: "#d2d2d2",
    borderRadius: "10px",
    padding: "10px",
    boxSizing: "border-box",
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    boxShadow: "4px 4px 10px -2px rgba(0,0,0,0.5)",
    "& p": {
      fontSize: "15px",
      color: "#000",
      margin: "0px"
    }
  }
});
