import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getPathValue, setPathValue } from 'pathval';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash';
import MaskedInput from 'react-text-mask';
import api from '../../api';
import ModalRank from '../DriverRank';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Place from '@material-ui/icons/Place';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Person from '@material-ui/icons/Person';
import Payment from '@material-ui/icons/Payment';
import Phone from '@material-ui/icons/Phone';
import EditIcon from '@material-ui/icons/Edit';
import { ReceitaFederal, AsteriskFilled } from '../Icons';
import styles from './styles';
import { FILTER_LABEL_DRIVER, MASK, VEHICLE_LABELS, TYPEBYBOARD_LABELS } from '../../constants';
import { Aside, AsideHeader, AsideRow } from '../Aside';
import iconWhatsapp from '../../assets/image/whatsapp-icon-buttom.svg';
import ModalImage from '../Image';
import Button from '../Button';
import ModalConfirmation from '../ModalConfirmation';
import ModalPhotoDriver from '../ModalPhotoDriver';
import AlertDialog from '../AlertDialog';
import ModalEditAccount from '../ModalEditAccount';
import ModalUserUpdates from '../ModalUserUpdates/index.tsx';
import { enqueueSnackbar } from '../../actions/notifications';
import { checkIfRegistred } from '../../actions/drivers';
import {
  isDocumentValid, validateDocumentType, sendToAWS, getFromAWS,
  downloadFromBuffer, bancos, formatDate, formatCPF
} from '../../utils/utils';

const DriverDetail = ({ classes, driver, requestDriver, requestUpdateDriver, boardUserName,
  requestDriverApproval, requestDriverBlocking, requestDriverImageBlocking, requestDriverImageApproval }) => {
  const dispatch = useDispatch();

  const [alertOpen, setAlertOpen] = useState(false);
  const [localDriver, setLocalDriver] = useState({});
  const [imgUploaded, setImgUploaded] = useState(null); // eslint-disable-next-line
  const [updatePayload, setUpdatePayload] = useState({});
  const [editing, setEditing] = useState({
    truckTypeEditing: false,
    typeByBoardEditing: false,
    walletTypeEditing: false,
    phoneNumberEditing: false,
    cpfEditing: false
  });
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    title: '',
    message: '',
    handleCancel: null,
    handleConfirm: null
  });
  const [photoModal, setPhotoModal] = useState({
    isOpen: false,
    label: '',
    photoName: '',
    photo: {}
  });
  const [modalEditAccount, setModalEditAccount] = useState({
    isOpen: false,
    title: '',
    handleChange: null,
    sumitPaymentForm: null,
    handleBlur: null,
    closeEditModal: null,
    setImage: null,
    upload: null,
    openFileSelector: null,
    canCloseEditModal: true,
    isCnpj: false
  });
  const [modalUserUpdates, setModalUserUpdates] = useState({
    isOpen: false
  });
  const [blockReasonModal, setBlockReasonModal] = useState({
    isOpen: false,
    reason: ''
  });

  useEffect(() => {
    if (Object.keys(localDriver).length === 0 && driver) {
      setLocalDriver(cloneDeep(driver));
    } // eslint-disable-next-line
  }, [driver, localDriver]);

  useEffect(() => {
    if (driver?.updateAcknowledge === false) {
      api.patch(`/user/${driver.id}`, { updateAcknowledge: true }).catch((err) => {
        console.error('ERRO ao atualizar dado de visualização do motorista', err.message);
      });
    }

    if (driver?.id) {
      const interval = setInterval(() => {
        dispatch(requestDriver(driver.id));
      }, 5000);

      return () => clearInterval(interval);
    } // eslint-disable-next-line
  }, [driver?.id, dispatch, requestDriver]);

  const handleEdit = (event) => {
    const name = (event.target.name || event.currentTarget.name).replace('Edit', '');
    setEditing((prev) => ({ ...prev, [`${name}Editing`]: true }));
  };

  const handleBlur = async (event) => {
    const name = event.target.name.replace('Secondary', '');
    setEditing((prev) => ({ ...prev, [`${name}Editing`]: false }));

    const updatedPayload = { id: localDriver.id, boardUser: boardUserName };

    switch (event.target.name) {
      case 'truckType':
        setPathValue(updatedPayload, 'vehicle.type', getPathValue(localDriver, 'vehicle.type'));
        break;
      case 'typeByBoard':
        setPathValue(updatedPayload, 'vehicle.typeByBoard', getPathValue(localDriver, 'vehicle.typeByBoard'));
        break;
      case 'walletType':
        setPathValue(updatedPayload, 'wallet.type', getPathValue(localDriver, 'wallet.type'));
        break;
      case 'banco':
        setPathValue(updatedPayload, 'wallet.bank', getPathValue(localDriver, 'wallet.bank'));
        break;
      case 'agencia':
        setPathValue(updatedPayload, 'wallet.agency', getPathValue(localDriver, 'wallet.agency'));
        break;
      case 'conta':
        setPathValue(updatedPayload, 'wallet.account', getPathValue(localDriver, 'wallet.account'));
        break;
      case 'pis':
        setPathValue(updatedPayload, 'wallet.pis', getPathValue(localDriver, 'wallet.pis'));
        break;
      case 'pix':
        setPathValue(updatedPayload, 'wallet.pixKey', getPathValue(localDriver, 'wallet.pixKey'));
        break;
      case 'cpf':
        const isRegistred = await checkIfRegistred(localDriver[event.target.name]);
        if (isRegistred) {
          dispatch(enqueueSnackbar({ message: 'O CPF inserido já foi cadastrado', options: { variant: 'error' } }));
          setLocalDriver((prevDriver) => ({ ...prevDriver, cpf: driver.cpf }));
          return false;
        } else {
          updatedPayload['cpf'] = localDriver['cpf'];
        }
        break;
      default:
        updatedPayload[event.target.name] = localDriver[event.target.name];
        break;
    }

    dispatch(requestUpdateDriver(sanitizeDriverData(updatedPayload)));
    setUpdatePayload(updatedPayload);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    updateDriverState(name, value);
  };

  const sanitizeDriverData = (payload) => {
    const sanitized = { ...payload };
    const caracters = /[-.() ]/g;

    if (payload.cpf) {
      sanitized.cpf = payload.cpf.replace(caracters, '');
    }

    if (payload.phoneNumber) {
      sanitized.phoneNumber = payload.phoneNumber.replace(caracters, '');
    }

    if (payload.phoneNumberSecondary) {
      sanitized.phoneNumberSecondary = payload.phoneNumberSecondary.replace(caracters, '');
    }

    if (payload.agency) {
      sanitized.agency = payload.agency.replace(caracters, '');
    }

    if (payload.account) {
      sanitized.account = payload.account.replace(caracters, '');
    }

    if (payload.pis) {
      sanitized.pis = payload.pis.replace(caracters, '');
    }

    return sanitized;
  };

  const updateDriverState = (driverProp, value) => {
    setLocalDriver((prevDriver) => {
      const updatedDriver = cloneDeep(prevDriver);
      switch (driverProp) {
        case 'truckType':
          updatedDriver.vehicle.type = value;
          break;
        case 'typeByBoard':
          updatedDriver.vehicle.typeByBoard = value;
          break;
        case 'walletType':
          updatedDriver.wallet.type = value;
          break;
        case 'banco':
          updatedDriver.wallet.bank = value;
          break;
        case 'agencia':
          updatedDriver.wallet.agency = value;
          break;
        case 'conta':
          updatedDriver.wallet.account = value;
          break;
        case 'pis':
          updatedDriver.wallet.pis = value;
          break;
        case 'pix':
          updatedDriver.wallet.pixKey = value;
          break;
        case 'imageUrl':
          updatedDriver.wallet.cardPhoto.imageUrl = value;
          break;
        case 'accountName':
          updatedDriver.wallet.accountName = value;
          break;
        case 'accountDocument':
          let newText = retirarFormatacao(value);
          if (newText.length <= 11) {
            updatedDriver.wallet.accountDocument = mascaraCpf(newText);
          } else {
            if (newText.length > 14) {
              newText = newText.slice(0, 14);
            }
            updatedDriver.wallet.accountDocument = mascaraCnpj(newText);
          }
          break;
        default:
          updatedDriver[driverProp] = value;
          break;
      }
      return updatedDriver;
    });
  };

  const mascaraCnpj = (valor) => {
    return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  };

  const mascaraCpf = (valor) => {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  };

  const retirarFormatacao = (text) => {
    return text.replace(/(\.|\/|-)/g, '');
  };

  const handleUserApproval = () => {
    if (driver.status !== 'APPROVED') {
      dispatch(requestDriverApproval(driver.id));
    } else {
      openBlockReasonModal();
    }
    closeConfimModal();
  };

  const handleDownloadPhoto = async () => {
    if (!photoModal.photo) return;

    dispatch(enqueueSnackbar({ message: 'Iniciando download...', options: { variant: 'info' } }));

    try {
      const arr = photoModal.photo.imageUrl.split('/');
      const resp = await getFromAWS(arr[arr.length - 1]);
      const jsonResp = JSON.parse(resp.data.body);
      downloadFromBuffer(arr[arr.length - 1], jsonResp.Body.data, jsonResp.ContentType);
    } catch (e) {
      try {
        dispatch(enqueueSnackbar({
          message: 'Tamanho de image incompatível. Utilize a opção "Salvar como" do navegador.',
          options: { variant: 'info' }
        }));
        setTimeout(() => {
          const link = document.createElement('a');
          link.href = photoModal.photo.imageUrl;
          link.target = '_blank';
          link.click();
          link.remove();
        }, 1500);
      } catch (e) {
        dispatch(enqueueSnackbar({ message: 'Não foi possível fazer o download do documento. Tente novamente.', options: { variant: 'error' } }));
      }
    }
  };

  const closeConfimModal = () => {
    setConfirmModal((prev) => ({ ...prev, isOpen: false }));
  };

  const openApproveModal = () => {
    setConfirmModal({
      isOpen: true,
      title: 'Tem certeza?',
      message: driver.status === 'APPROVED' ? 'Você confirma o bloqueio desse usuário?' : 'Você confirma a aprovação desse usuário?',
      handleCancel: closeConfimModal,
      handleConfirm: handleUserApproval,
    });
  };

  const closePhotoModal = () => {
    setPhotoModal((prev) => ({ ...prev, isOpen: false }));
  };

  const clearModalPhotoDriver = () => {
    setPhotoModal({ isOpen: false, label: '', photoName: '', photo: {} });
  };

  const openPhotoModal = useCallback((propertyName, index = null) => (event) => {
    const { wallet, vehicle, ...restDriver } = driver;
    const modalProps = { isOpen: true, photoName: propertyName };

    const isCompleted = wallet?.account || wallet?.cardPhoto?.imageUrl;
    if (!isCompleted) {
      setAlertOpen(true);
      return false;
    }

    switch (propertyName) {
      case 'anttPhoto':
        modalProps.label = 'ANTT';
        modalProps.photo = vehicle?.anttPhoto;
        break;
      case 'documentPhoto':
        modalProps.label = 'Documento do veículo';
        modalProps.photo = vehicle?.documentPhoto;
        break;
      case 'semiTrailerDocumentPhotos':
        modalProps.label = `Documento da carreta (${index + 1})`;
        modalProps.photo = { ...vehicle?.semiTrailerDocumentPhotos?.[index], index };
        break;
      case 'semiTrailerPhotos':
        modalProps.label = `Foto da carreta (${index + 1})`;
        modalProps.photo = { ...vehicle?.semiTrailerPhotos?.[index], index };
        break;
      case 'truckUnitPhoto':
        modalProps.label = 'Foto do cavalo';
        modalProps.photo = vehicle?.truckUnitPhoto;
        break;
      case 'vehiclePhoto':
        modalProps.label = 'Foto do veículo';
        modalProps.photo = vehicle?.vehiclePhoto;
        break;
      case 'userPhoto':
        modalProps.label = 'Foto de perfil';
        modalProps.photo = restDriver?.userPhoto;
        break;
      case 'cnhPhoto':
        modalProps.label = 'CNH';
        modalProps.photo = restDriver?.cnhPhoto;
        break;
      case 'rgPhoto':
        modalProps.label = 'Documento de identidade';
        modalProps.photo = restDriver?.rgPhoto;
        break;
      case 'proofResidencePhoto':
        modalProps.label = 'Comprovante de residência';
        modalProps.photo = restDriver?.proofResidencePhoto;
        break;
      case 'cardPhoto':
        modalProps.label = 'PAMCARD';
        modalProps.photo = wallet?.cardPhoto;
        break;
      default:
        break;
    }

    setPhotoModal(modalProps);
  }, [driver]);

  const handleBlurEditModal = (event) => {
    const name = event.target.name.replace('Secondary', '');
    setEditing((prev) => ({ ...prev, [`${name}Editing`]: false }));

    const updatedPayload = { id: localDriver.id, boardUser: boardUserName };

    switch (event.target.name) {
      case 'truckType':
        setPathValue(updatedPayload, 'vehicle.type', getPathValue(localDriver, 'vehicle.type'));
        break;
      case 'typeByBoard':
        setPathValue(updatedPayload, 'vehicle.typeByBoard', getPathValue(localDriver, 'vehicle.typeByBoard'));
        break;
      case 'walletType':
        setPathValue(updatedPayload, 'wallet.type', getPathValue(localDriver, 'wallet.type'));
        break;
      case 'banco':
        setPathValue(updatedPayload, 'wallet.bank', getPathValue(localDriver, 'wallet.bank'));
        break;
      case 'agencia':
        setPathValue(updatedPayload, 'wallet.agency', getPathValue(localDriver, 'wallet.agency'));
        break;
      case 'conta':
        setPathValue(updatedPayload, 'wallet.account', getPathValue(localDriver, 'wallet.account'));
        break;
      case 'pis':
        setPathValue(updatedPayload, 'wallet.pis', getPathValue(localDriver, 'wallet.pis'));
        break;
      case 'pix':
        setPathValue(updatedPayload, 'wallet.pixKey', getPathValue(localDriver, 'wallet.pixKey'));
        break;
      default:
        updatedPayload[event.target.name] = localDriver[event.target.name];
        break;
    }

    setUpdatePayload(updatedPayload);
  };

  const openEditModal = (event) => {
    handleEdit(event);
    setModalEditAccount({
      isOpen: true,
      title: 'Editar informação de recebimento',
      handleBlur: handleBlurEditModal,
      handleChange,
      sumitPaymentForm,
      closeEditModal,
      setImage,
      upload,
      openFileSelector,
      canCloseEditModal,
    });
  };

  const closeEditModal = (fromSubmit = false) => {
    if (!fromSubmit) {
      setLocalDriver((prevDriver) => ({ ...prevDriver, wallet: driver?.wallet }));
    }
    setModalEditAccount((prev) => ({ ...prev, isOpen: false }));
    setEditing((prev) => ({ ...prev, walletTypeEditing: false }));
  };

  const setImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImgUploaded(file);
    } else {
      setImgUploaded(null);
    }
  };

  const upload = async () => {
    const imageToUpload = imgUploaded;

    if (!isDocumentValid(imageToUpload)) {
      setImgUploaded(null);
      dispatch(enqueueSnackbar({ message: 'Os formatos de arquivo permitidos são: PNG, JPG e PDF.', options: { variant: 'error' } }));
      return false;
    }

    try {
      const response = await validateDocumentType(imageToUpload);

      if (response.data && response.data.signedUrl) {
        const { imageUrl, signedUrl } = response.data;
        await sendToAWS(signedUrl, imageToUpload);

        updateDriverState('imageUrl', imageUrl);

        const updatedPayload = { id: localDriver.id };
        setPathValue(updatedPayload, 'wallet.cardPhoto.imageUrl', getPathValue(localDriver, 'wallet.cardPhoto.imageUrl'));
        setPathValue(updatedPayload, 'wallet.type', 'PAMCARD');

        dispatch(requestUpdateDriver(sanitizeDriverData(updatedPayload)));
        setLocalDriver((prevDriver) => ({
          ...prevDriver,
          wallet: { ...prevDriver.wallet, type: 'PAMCARD', cardPhoto: { imageUrl } },
        }));
        setImgUploaded(null);
        setUpdatePayload(updatedPayload);
        closeEditModal();
      } else {
        console.error('Sem URL assinada');
      }
    } catch (err) {
      dispatch(enqueueSnackbar({ message: 'Não foi possível enviar a foto. Tente novamente.', options: { variant: 'error' } }));
    }
  };

  const openFileSelector = () => {
    const btnFileSelector = document.querySelector('#PAYMENT_CARD_DRIVER');
    btnFileSelector.click();
  };

  const canCloseEditModal = () => {
    closeEditModal();
  };

  const sumitPaymentForm = () => {
    if (localDriver.wallet?.type === 'CONTA') {
      if (localDriver.wallet.bank && localDriver.wallet.agency && localDriver.wallet.account && localDriver.wallet.accountDocument) {
        if (localDriver.wallet.accountDocument.length <= 11 && (!localDriver.wallet.pis || localDriver.wallet.pis.length === 0)) {
          dispatch(enqueueSnackbar({ message: 'O campo PIS é obrigatório para CPF', options: { variant: 'error' } }));
          return;
        }

        dispatch(requestUpdateDriver(sanitizeDriverData({
          id: localDriver.id,
          wallet: localDriver.wallet,
          boardRequest: true,
          boardUser: boardUserName,
        })));
        closeEditModal(true);
      } else {
        dispatch(enqueueSnackbar({ message: 'Os campos devem ser preenchidos', options: { variant: 'error' } }));
      }
    }
  };

  const openModalUserUpdates = () => {
    setModalUserUpdates({ isOpen: true });
  };

  const closeModalUserUpdates = () => {
    setModalUserUpdates({ isOpen: false });
  };

  const openBlockReasonModal = () => {
    setBlockReasonModal({ isOpen: true, reason: '' });
  };

  const closeBlockReasonModal = () => {
    setBlockReasonModal({ isOpen: false, reason: '' });
  };

  const handleBlockReasonChange = (event) => {
    const value = event?.target?.value ?? '';
    setBlockReasonModal((prev) => ({ ...prev, reason: value }));
  };

  const confirmBlockUser = () => {
    dispatch(requestDriverBlocking(driver.id, blockReasonModal.reason));
    closeBlockReasonModal();
  };

  const isCompleted = localDriver.wallet?.account || localDriver.wallet?.cardPhoto?.imageUrl;
  let finishedFreights = 0;
  if (driver?.requestedFreights) {
    Object.values(driver.requestedFreights).forEach((freight) => {
      if (freight.requestStatus === 'FINISHED') finishedFreights++;
    });
  }

  let bankName = bancos.filter((i) => i.IdBank === localDriver.wallet?.bank);
  if (bankName && bankName[0]) {
    bankName = bankName[0].Name;
  } else {
    bankName = '';
  }

  const { vehicleOwner } = localDriver;

  return (
    <div className={classes.root}>
      <ModalUserUpdates
        updates={driver?.fieldsRecentlyModified || []}
        isOpen={modalUserUpdates.isOpen}
        handleOpen={openModalUserUpdates}
        handleClose={closeModalUserUpdates}
      />
      <ModalRank
        driver={driver}
        driverName={driver?.name}
      />
      <ModalConfirmation
        open={confirmModal.isOpen}
        title={confirmModal.title}
        message={confirmModal.message}
        handleMessageChange={() => { }}
        handleCancel={confirmModal.handleCancel}
        handleConfirm={confirmModal.handleConfirm}
        setImage={() => { }}
        imgUploaded={imgUploaded}
      />
      <ModalPhotoDriver
        driverId={driver?.id}
        driverStatus={driver?.status}
        photo={photoModal.photo}
        photoName={photoModal.photoName}
        open={photoModal.isOpen}
        label={photoModal.label}
        TransitionProps={{ onExited: clearModalPhotoDriver }}
        handleCancel={closePhotoModal}
        handleDownloadPhoto={handleDownloadPhoto}
        handleConfirm={requestDriverImageBlocking}
        handleConfirmApproval={requestDriverImageApproval}
        requestDriverBlocking={requestDriverBlocking}
      />
      <AlertDialog open={alertOpen} handleClose={() => setAlertOpen(false)} />
      <ModalEditAccount
        isOpen={modalEditAccount.isOpen}
        title={modalEditAccount.title}
        type={localDriver.wallet?.type || ''}
        isCnpj={modalEditAccount.isCnpj}
        bank={localDriver.wallet?.bank || ''}
        agency={localDriver.wallet?.agency || ''}
        account={localDriver.wallet?.account || ''}
        accountName={localDriver.wallet?.accountName || ''}
        agreementDate={localDriver.wallet?.agreementDate || ''}
        accountDocument={localDriver.wallet?.accountDocument || ''}
        pis={localDriver.wallet?.pis || ''}
        pix={localDriver.wallet?.pixKey || ''}
        handleChange={modalEditAccount.handleChange}
        sumitPaymentForm={modalEditAccount.sumitPaymentForm}
        handleBlur={modalEditAccount.handleBlur}
        imgUploaded={imgUploaded}
        setImage={modalEditAccount.setImage}
        upload={modalEditAccount.upload}
        openFileSelector={modalEditAccount.openFileSelector}
        canCloseEditModal={modalEditAccount.canCloseEditModal}
      />
      <BlockReasonModal
        isOpen={blockReasonModal.isOpen}
        reason={blockReasonModal.reason}
        handleCancel={closeBlockReasonModal}
        handleConfirm={confirmBlockUser}
        handleReasonChange={handleBlockReasonChange}
      />
      {driver?.name && (
        <Paper className={classes.paper}>
          <Aside className={classes.driverDataContainer}>
            <AsideHeader backTo="/drivers">
              <Typography className={classes.asideHeaderText}>{driver.name}</Typography>
            </AsideHeader>
            <AsideRow icon={<LocalShipping />} className={classes.asideRow}>
              <Typography variant="subtitle2" className={classes.driverRowContentTitle}>Tipo do Veículo</Typography>
              {editing.truckTypeEditing ? (
                <Select
                  id="truckType"
                  name="truckType"
                  value={localDriver.vehicle?.type || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.editFieldsSelect}
                >
                  {FILTER_LABEL_DRIVER.truckType.map((profile, i) => (
                    <MenuItem key={i} value={profile.value}>{profile.label}</MenuItem>
                  ))}
                </Select>
              ) : (
                <Typography variant="body2" className={classes.driverRowContentText}>
                  {localDriver.vehicle && VEHICLE_LABELS[localDriver.vehicle.type]}
                </Typography>
              )}
              {!editing.truckTypeEditing && (
                <IconButton
                  aria-label="edit-info"
                  onClick={handleEdit}
                  className={classes.editContent}
                  name="truckTypeEdit"
                >
                  <EditIcon fontSize="small" className={classes.editIcon} />
                </IconButton>
              )}
            </AsideRow>
            <AsideRow icon={<LocalShipping />} className={classes.asideRow}>
              <Typography variant="subtitle2" className={classes.driverRowContentTitle}>Tipo de Carroceria</Typography>
              {editing.typeByBoardEditing ? (
                <Select
                  id="typeByBoard"
                  name="typeByBoard"
                  value={localDriver.vehicle?.typeByBoard || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.editFieldsSelect}
                >
                  {FILTER_LABEL_DRIVER.typeByBoardType.map((profile, i) => (
                    <MenuItem key={i} value={profile.value}>{profile.label}</MenuItem>
                  ))}
                </Select>
              ) : (
                <Typography variant="body2" className={classes.driverRowContentText}>
                  {localDriver.vehicle && TYPEBYBOARD_LABELS[localDriver.vehicle.typeByBoard]}
                </Typography>
              )}
              {!editing.typeByBoardEditing && (
                <IconButton
                  aria-label="edit-info"
                  onClick={handleEdit}
                  className={classes.editContent}
                  name="typeByBoardEdit"
                >
                  <EditIcon fontSize="small" className={classes.editIcon} />
                </IconButton>
              )}
            </AsideRow>
            {vehicleOwner && vehicleOwner.document ? (
              <AsideRow icon={<LocalShipping />} className={classes.asideRowFull}>
                <>
                  <Typography variant="subtitle2" className={classes.driverRowContentTitle}>Proprietário do Veículo</Typography>
                  <Typography className={classes.accountText}>
                    <span>CPF / CNPJ:</span> {vehicleOwner.document}
                  </Typography>
                  <Typography className={classes.accountText}>
                    <span>email:</span> {vehicleOwner.email}
                  </Typography>
                  <Typography className={classes.accountText}>
                    <span>Contato:</span> {localDriver.vehicleOwner.contact}
                  </Typography>
                  <Typography className={classes.accountText}>
                    <span>Endereço:</span> <br /> {vehicleOwner.address}
                  </Typography>
                </>
              </AsideRow>
            ) : null}
            <AsideRow icon={<Person />} className={classes.asideRow}>
              <Typography variant="subtitle2" className={classes.driverRowContentTitle}>Data de Cadastro</Typography>
              <Typography variant="body2" className={classes.driverRowContentText}>
                {formatDate(localDriver.creationDate)}
              </Typography>
            </AsideRow>
            <AsideRow icon={<Payment />} className={classes.asideRow} style={{ height: localDriver.wallet?.type === 'CONTA' ? '166px' : '52px' }}>
              <Typography variant="subtitle2" className={classes.driverRowContentTitle}>Forma de Recebimento</Typography>
              {editing.walletTypeEditing || ModalEditAccount.isOpen ? (
                <Typography variant="body2" className={classes.driverRowContentText}>
                  Editando as informações de recebimento...
                </Typography>
              ) : localDriver.wallet && localDriver.wallet.type === 'CONTA' ? (
                <>
                  <Typography className={classes.accountText}>
                    <span>Nome do Favorecido / Razão Social</span>:<br /> {localDriver.wallet?.accountName}
                  </Typography>
                  <Typography className={classes.accountText}>
                    <span>CPF / CNPJ: </span>{localDriver.wallet?.accountDocument}
                  </Typography>
                  <Typography className={classes.accountText}>
                    <span>Banco</span>: {bankName}
                  </Typography>
                  <Typography className={classes.accountText}>
                    <span>Agência</span>: {localDriver.wallet?.agency && localDriver.wallet.agency.replace(/^(\d{4})(\d{1})/, '$1-$2')}
                    {'  '}
                    <span>Conta</span>: {localDriver.wallet?.account && localDriver.wallet.account.replace(/^(\d{5})(\d{1})/, '$1-$2')}
                  </Typography>
                  <Typography className={classes.accountText}>
                    <span>PIS</span>: {localDriver.wallet?.pis && localDriver.wallet.pis.replace(/^(\d{3})(\d{4})(\d{3})(\d{1})/, '$1.$2.$3.$4')}
                  </Typography>
                  <Typography className={classes.accountText}>
                    <span>PIX: </span>{localDriver.wallet?.pixKey}
                  </Typography>
                </>
              ) : (
                <Typography className={classes.driverRowContentText}>
                  {localDriver.wallet?.type}
                </Typography>
              )}
              {!editing.walletTypeEditing && (
                <IconButton
                  aria-label="edit-info"
                  onClick={openEditModal}
                  className={classes.editContent}
                  name="walletTypeEdit"
                >
                  <EditIcon fontSize="small" className={classes.editIcon} />
                </IconButton>
              )}
            </AsideRow>
            <AsideRow icon={<Place />} className={classes.asideRow}>
              <Typography variant="subtitle2" className={classes.driverRowContentTitle}>Fretes Completados</Typography>
              <Typography variant="body2" className={classes.driverRowContentText}>
                {finishedFreights}
              </Typography>
            </AsideRow>
            <AsideRow icon={<Phone />} className={classes.asideRow}>
              <Typography variant="subtitle2" className={classes.driverRowContentTitle}>Telefone</Typography>
              <div className={classes.row}>
                {editing.phoneNumberEditing ? (
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    className={classes.editFieldsInput}
                    value={localDriver.phoneNumber || ''}
                    margin="normal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{ inputComponent: PhoneInput }}
                  />
                ) : (
                  <Typography variant="body2" className={classes.driverRowContentText}>
                    {localDriver.phoneNumber && localDriver.phoneNumber.replace(/^(\d{2})(\d)(\d{4})(\d{4})/, '($1) $2 $3-$4')}
                  </Typography>
                )}
                <Typography variant="body2" className={classes.driverRowContentText}>
                  <a target="_blank" href={`https://wa.me/55${localDriver.phoneNumber}`} rel="noopener noreferrer">
                    <img src={iconWhatsapp} alt="whatsapp" style={{ margin: '-4px 15px -4px 5px' }} />
                  </a>
                </Typography>
                {editing.phoneNumberEditing ? (
                  <TextField
                    id="phoneNumberSecondary"
                    name="phoneNumberSecondary"
                    value={localDriver.phoneNumberSecondary || ''}
                    className={classes.editFieldsInput}
                    margin="normal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{ inputComponent: PhoneInput }}
                  />
                ) : (
                  <Typography variant="body2" className={classes.driverRowContentText}>
                    {localDriver.phoneNumberSecondary && localDriver.phoneNumberSecondary.replace(/^(\d{2})(\d)(\d{4})({\d{3}} || \d{4})/, '($1) $2 $3-$4')}
                  </Typography>
                )}
                {localDriver.phoneNumberSecondary && (
                  <Typography variant="body2" className={classes.driverRowContentText}>
                    <a target="_blank" href={`https://wa.me/55${localDriver.phoneNumberSecondary}`} rel="noopener noreferrer">
                      <img src={iconWhatsapp} alt="whatsapp" style={{ margin: '-4px 5px' }} />
                    </a>
                  </Typography>
                )}
              </div>
              {!editing.phoneNumberEditing && (
                <IconButton
                  aria-label="edit-info"
                  onClick={handleEdit}
                  className={classes.editContent}
                  name="phoneNumberEdit"
                >
                  <EditIcon fontSize="small" className={classes.editIcon} />
                </IconButton>
              )}
            </AsideRow>
            <AsideRow icon={<ReceitaFederal />} className={classes.asideRow}>
              <Typography variant="subtitle2" className={classes.driverRowContentTitle}>CPF</Typography>
              <div className={classes.row}>
                {editing.cpfEditing ? (
                  <TextField
                    id="cpf"
                    name="cpf"
                    value={localDriver.cpf || ''}
                    className={classes.editFieldsInput}
                    margin="normal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{ inputComponent: CpfInput }}
                  />
                ) : (
                  <Typography variant="body2" className={classes.driverRowContentText}>
                    {formatCPF(localDriver.cpf)}
                  </Typography>
                )}
              </div>
              {!editing.cpfEditing && (
                <IconButton
                  aria-label="edit-info"
                  onClick={handleEdit}
                  className={classes.editContent}
                  name="cpfEdit"
                >
                  <EditIcon fontSize="small" className={classes.editIcon} />
                </IconButton>
              )}
            </AsideRow>
            <AsideRow icon={<AsteriskFilled />} className={classes.asideRow}>
              <Typography variant="subtitle2" className={classes.driverRowContentTitle}>Data de autorização</Typography>
              <div className={classes.row}>
                <Typography variant="body2" className={classes.driverRowContentText}>
                  {localDriver.wallet?.agreementDate ? formatDate(localDriver.wallet.agreementDate) : formatDate(localDriver.creationDate)}
                </Typography>
              </div>
            </AsideRow>
            <AsideRow icon={<Person />} className={classes.asideRow}>
              <Typography variant="subtitle2" className={classes.driverRowContentTitle}>Atualizações do usuário</Typography>
              <div className={classes.row}>
                <Typography variant="body2" className={classes.driverRowContentText} onClick={openModalUserUpdates}>
                  Clique para visualizar as atualizações
                </Typography>
              </div>
            </AsideRow>
            {driver.status === "DENIED" && (
              <AsideRow icon={<EditIcon />} className={classes.asideRow}>
                <Typography variant="subtitle2" className={classes.driverRowContentTitle}>Motivo do Bloqueio</Typography>
                <div className={classes.row}>
                  <Typography variant="body2" className={classes.driverRowContentText}>
                    {driver.blockReason}
                  </Typography>
                </div>
              </AsideRow>
            )}
            <div className={classes.buttonContainer}>
              <Button
                size="large"
                variant="contained"
                customColor={driver.status === 'APPROVED' ? 'blue' : 'green'}
                disabled={!isCompleted}
                onClick={openApproveModal}
                className={classes.actionButton}
              >
                {driver.status === 'APPROVED' ? 'Bloquear usuário' : 'Aprovar usuário'}
              </Button>
            </div>
          </Aside>
          <section className={classes.driverImagesContainer}>
            <div className={classes.imagesSection}>
              <ImageSectionTitle>Dados Pessoais</ImageSectionTitle>
              <div className={classes.imagesContainer}>
                <ModalImage label="Foto de Perfil" photo={driver?.userPhoto} onClick={openPhotoModal('userPhoto')} />
                <ModalImage label="CNH" photo={driver?.cnhPhoto} onClick={openPhotoModal('cnhPhoto')} />
                <ModalImage label="RG" photo={driver?.rgPhoto} onClick={openPhotoModal('rgPhoto')} />
                <ModalImage label="Comprovante de residência" photo={driver?.proofResidencePhoto} onClick={openPhotoModal('proofResidencePhoto')} />
                {driver?.wallet?.type === 'PAMCARD' && (
                  <ModalImage label="PAMCARD" photo={driver?.wallet?.cardPhoto} onClick={openPhotoModal('cardPhoto')} />
                )}
              </div>
            </div>
            <div className={classes.imagesSection}>
              <ImageSectionTitle>Documentos do veículo</ImageSectionTitle>
              <div className={classes.imagesContainer}>
                <ModalImage label="ANTT" photo={driver?.vehicle?.anttPhoto} onClick={openPhotoModal('anttPhoto')} />
                <ModalImage label="Documento do veículo" photo={driver?.vehicle?.documentPhoto} onClick={openPhotoModal('documentPhoto')} />
                {driver?.vehicle?.semiTrailerDocumentPhotos?.map((photo, index) => (
                  <ModalImage key={index} label={`Documento da carreta (${index + 1})`} photo={photo} onClick={openPhotoModal('semiTrailerDocumentPhotos', index)} />
                ))}
              </div>
            </div>
            <div className={classes.imagesSection}>
              <ImageSectionTitle>Fotos do veículo</ImageSectionTitle>
              <div className={classes.imagesContainer}>
                <ModalImage label={driver?.vehicle?.type === 'cavalo' ? 'Foto lateral do conjunto' : 'Foto lateral do veículo'} photo={driver?.vehicle?.vehiclePhoto} onClick={openPhotoModal('vehiclePhoto')} />
                <ModalImage label={driver?.vehicle?.type === 'cavalo' ? 'Foto frontal do cavalo' : 'Foto frontal do veículo'} photo={driver?.vehicle?.truckUnitPhoto} onClick={openPhotoModal('truckUnitPhoto')} />
                {driver?.vehicle?.semiTrailerPhotos?.map((photo, index) => (
                  <ModalImage key={index} label={`Foto da carreta (${index + 1})`} photo={photo} onClick={openPhotoModal('semiTrailerPhotos', index)} />
                ))}
              </div>
            </div>
          </section>
        </Paper>
      )}
    </div>
  );
};

const BlockReasonModal = ({ isOpen, reason, handleCancel, handleConfirm, handleReasonChange }) => (
  <ModalConfirmation
    open={isOpen}
    title="Qual motivo?"
    message={
      <TextField
        label="Escreva resumidamente o motivo do bloqueio."
        fullWidth
        value={reason}
        onChange={handleReasonChange}
      />
    }
    handleCancel={handleCancel}
    handleConfirm={handleConfirm}
  />
);

const ImageSectionTitle = withStyles(styles)((props) => {
  const { classes } = props;
  return (
    <header>
      <Typography variant="h6" className={classes.imagesSectionTitle}>
        {props.children}
      </Typography>
      <Divider />
    </header>
  );
});

const PhoneInput = (props) => {
  const { inputRef, ...others } = props;
  return <MaskedInput {...others} mask={MASK.cellPhone} />;
};

const CpfInput = (props) => {
  const { inputRef, ...others } = props;
  return <MaskedInput {...others} mask={MASK.cpf} />;
};

export default withStyles(styles)(withRouter(DriverDetail));
