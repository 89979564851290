import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  userImagesContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingLeft: theme.spacing(1),
    marginBotton: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px 5px 0px 0px',
  },
}));

export default useStyles;
