import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from './containers/PrivateRoute';
import LoginScreen from './containers/LoginScreen';
import HomeScreen from './containers/HomeScreen';
import DriversScreen from './containers/DriversScreen';
import DriverDetailScreen from './containers/DriverDetailScreen';
import OffersScreens from './containers/OffersScreens';
import CreateOfferScreen from './containers/CreateOfferScreen';
import OffersDetailScreen from './containers/OffersDetailScreen';
import OrdersScreen from './containers/OrdersScreen';
import OrdersDetailScreen from './containers/OrdersDetailScreen';
import OccurrencesScreen from './containers/OccurrencesScreen';
import OccurrenceDetailScreen from './containers/OccurrenceDetailScreen';
import ConfigScreen from './containers/ConfigScreen';
import NotFoundScreen from './containers/NotFoundScreen';
import ForbidenScreen from './containers/ForbidenScreen';
import Logout from './containers/Logout';
import CreateNotificationScreen from './containers/CreateNotificationScreen';
import CreateOccurrenceScreen from './containers/CreateOccurrenceScreen';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={LoginScreen} />
    <Route exact path="/forbiden" component={ForbidenScreen} />
    <Route exact path="/logout" component={Logout} />
    <PrivateRoute path="/home" component={HomeScreen} />
    <PrivateRoute exact path="/drivers" component={DriversScreen} />
    <PrivateRoute exact path="/drivers/:id" component={DriverDetailScreen} />
    <PrivateRoute exact path="/offers" component={OffersScreens} />
    <PrivateRoute exact path="/offers/create" component={CreateOfferScreen} />
    <PrivateRoute exact path="/offers/edit/:id" component={CreateOfferScreen} />
    <PrivateRoute exact path="/offers/:id" component={OffersDetailScreen} />
    <PrivateRoute exact path="/orders" component={OrdersScreen} />
    <PrivateRoute exact path="/orders/:id" component={OrdersDetailScreen} />
    <PrivateRoute exact path="/occurrences" component={OccurrencesScreen} />
    <PrivateRoute exact path="/occurrence/:id" component={OccurrenceDetailScreen} />
    <PrivateRoute exact path="/configuration" component={ConfigScreen} />
    <PrivateRoute path="/configuration" component={ConfigScreen} />
    <PrivateRoute exact path="/notifications" component={CreateNotificationScreen} />
    <PrivateRoute exact path="/create/occurrences" component={CreateOccurrenceScreen} />
    <Route component={NotFoundScreen} />
  </Switch>
);

export default Routes;
