const LABEL_TEXT_COLOR = '#2B596F';

export default (theme) => {
  return ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      overflow: 'auto',
      minHeight: '800px',
    },
    paper: {
      overflow: 'hidden',
      display: 'flex',
      width: '100%',
      maxWidth: '1224px',
      marginTop: theme.spacing(1) * 2,
      marginBottom: theme.spacing(1) * 2,
      padding: 0,
      '@media (max-width: 600px)': {
        height: '1070px',
        flexDirection: 'column',
        overflow: 'initial'
      }
    },
    asideHeaderText: {
      color: '#FFF',
      textTransform: 'uppercase',
      fontWeight: 600
    },
    asideRow: {
      height: '70px',
      width: '100%',
      '& > div:nth-child(1)': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      },
      '& > div:nth-child(2)': {
        display: 'flex',
        justifyContent: 'space-between'
      },
      '&:last-child': {
      }
    },
    asideRowRemunaration: {
      height: '205px'
    },
    asideRowWeight: {
      minHeight: '52px'
    },
    checked: {},
    disabled: {},
    mainCheckbox: {
      position: 'absolute',
      right: '3px',
      color: '#FFF',
      '& > span > svg': {
        fontSize: '17px'
      },
      "&$checked": {
        color: '#FFF'
      },
      "&$disabled": {
        color: 'rgba(255, 255, 255, 0.5)'
      }
    },
    checkboxContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    checkboxRoot: {
      '& > span > svg': {
        fontSize: '17px'
      }
    },
    checkboxElegibleVehicles: {
      position: 'absolute',
      right: 0,
      top: 0
    },
    inputsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      width: '100%',
    },
    inputsLine: {
      display: 'flex',
      '& > div:first-child': {
        marginRight: theme.spacing(2)
      }
    },
    formControl: {
      '& > div': {
        // Altura da div do input
        height: '20px'
      }
    },
    selectLabel: {
      color: LABEL_TEXT_COLOR,
      fontSize: '16px',
      textTransform: 'uppercase',
      fontWeight: 600,
      '&$focused': {
        color: LABEL_TEXT_COLOR,
      }
    },
    focused: {},
    input: {
      fontSize: '0.8rem'
    },
    rightSideContainer: {
      padding: theme.spacing(2),
      paddingBottom: '120px',
      backgroundColor: '#ebebeb',
      width: '60%',
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      '@media (max-width: 600px)': {
        width: '100%',
      }
    },
    gridItem: {
      position: 'relative',
      width: '100%',
      maxHeight: '170px',
      minHeight: '100px',
      paddingLeft: `${theme.spacing(2)}px !important`
    },
    gridCell: {
      width: '100%',
      height: '100%',
      minHeight: '100px',
    },
    rightSideTitle: {
      position: 'relative',
      left: `-${theme.spacing(1)}px`,
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase',
      fontSize: '12px',
      fontWeight: 600,
      color: '#2B596F'
    },
    commentArea: {
      width: '98%',
      height: '130px',
      borderRadius: theme.shape.borderRadius,
      border: 0,
      resize: 'none',
      overflow: 'auto',
    },
    elegibleVehiclesContainer: {
      height: '150px',
      width: '300px',
      overflowY: 'auto',
    },
    chipsContainer: {
      height: '95px',
      overflowY: 'auto',
    },
    chip: {
      color: '#fff',
      margin: '5px 5px 0 0',
      height: '18px',
      fontSize: '0.8rem',
      backgroundColor: '#2B596F',
      '& > svg': {
        color: '#B9B9B9',
        width: '0.98rem'
      },
      '& > svg:hover': {
        color: '#fff'
      }
    },
    freightModeContainer: {
      width: '100%'
    },
    freightModeBottomLine: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    buttonLine: {
      display: 'flex',
      justifyContent: 'center'
    }
  });
}