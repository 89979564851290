import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import { fetchUsers, updateUser, deleteUser } from '../../actions/boardUserActions';
import BoardUserUpdate from './BoardUserUpdate';
import BoardUsersList from './BoardUsersList';
import BoardUserSearch from './BoardUserSearch';
import RegisterComponent from '../Register';

const UserManagement = ({ classes, name, email, password, profile, handleChangeName, handleChangeEmail, handleChangePassword,
  handleChangeProfile, handleSubmit, isLoading, disabled }) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filterName, setFilterName] = useState('name');
  const [filterValue, setFilterValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const itemsPerPageOptions = [5, 10, 15, 20];

  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(users.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const handleOpenUpdateModal = (user) => {
    setSelectedUser(user);
    setUpdateModalOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setUpdateModalOpen(false);
    setSelectedUser(null);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
  };

  useEffect(() => {
    handleSearch(`${filterName}=${filterValue}`); // eslint-disable-next-line
  }, [filterValue]);

  const handleSearch = (filterValue) => {
    setLoading(true);
    dispatch(fetchUsers(filterValue))
      .then((result) => {
        if (result.type === 'FETCH_USERS_SUCCESS') {
          setUsers(result.payload);
          setLoading(false);
        } else {
          console.error(result.error || 'Erro buscando usuários');
          setLoading(false);
        }
      });
  };

  const handleUpdateUser = async (userData) => {
    setLoading(true);
    dispatch(updateUser(selectedUser.id, userData))
      .then(() => {
        setSelectedUser(null);
        setUpdateModalOpen(false);
        handleSearch(`${filterName}=${filterValue}`);
      })
      .catch(error => console.error("Erro ao atualizar usuário:", error));
  };

  const handleDelete = (boardUserId) => {
    if (window.confirm('Tem certeza que deseja deletar este usuário?')) {
      setLoading(true);
      dispatch(deleteUser(boardUserId))
        .then(() => {
          handleSearch(`${filterName}=${filterValue}`);
        })
        .catch(error => console.error("Erro ao deletar usuário:", error));
    }
  };

  return (
    <div className={classes.managementContainer}>
      <div className={classes.loadingOverlay} style={{ display: loading ? 'flex' : 'none' }}>Carregando...</div>
      <BoardUserSearch
        filterName={filterName}
        filterValue={filterValue}
        setFilterName={setFilterName}
        setFilterValue={setFilterValue}
        handleSearch={handleSearch}
        handleOpenModal={handleOpenModal} />
      <BoardUsersList
        users={currentUsers}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageNumbers={pageNumbers}
        handleDelete={handleDelete}
        onEdit={(user) => handleOpenUpdateModal(user)} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}>
        <form>
          <label htmlFor="items-per-page">Itens por página:</label>
          <select
            id="items-per-page"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            style={{ marginLeft: '10px' }}
          >
            {itemsPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </form>
      </div>
      <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <RegisterComponent
          onCancel={handleCloseModal}
          handleSearch={handleSearch}
          filterName={filterName}
          filterValue={filterValue}
          name={name}
          email={email}
          password={password}
          profile={profile}
          handleChangeName={handleChangeName}
          handleChangeEmail={handleChangeEmail}
          handleChangePassword={handleChangePassword}
          handleChangeProfile={handleChangeProfile}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          disabled={disabled}
        />
      </Dialog>
      <Dialog open={updateModalOpen} onClose={handleCloseUpdateModal} aria-labelledby="form-dialog-title">
        <BoardUserUpdate
          user={selectedUser}
          onSubmit={handleUpdateUser}
          onCancel={handleCloseUpdateModal}
          loading={loading}
        />
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(UserManagement);