import {
  CREATE_FREIGHT_REQUEST,
  CREATE_FREIGHT_SUCCESS,
  CREATE_FREIGHT_FAILURE
} from '../actions/actionsTypes';

const INITIAL_STATE = {
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case CREATE_FREIGHT_REQUEST:
      return {
        isLoading: true
      };

    case CREATE_FREIGHT_SUCCESS:
    case CREATE_FREIGHT_FAILURE:
      return {
        isLoading: false
      };
    
    default:
      return state
  }

}