import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { enqueueSnackbar } from '../../actions/notifications';

const UpdateNotification: React.FC = () => {
  const dispatch = useDispatch();
  const counter = useSelector((state: any) => state.counter);

  const [counters, setCounters] = useState({
    users: 0,
    freights: 0,
    orders: 0,
    occurrences: 0,
  });

  useEffect(() => {
    if (counters.users < counter.users) {
      dispatch(
        enqueueSnackbar({
          message: 'Existe uma atualização de usuário.',
          options: { variant: 'info' },
        }),
      );
    }

    if (counters.freights < counter.freights) {
      dispatch(
        enqueueSnackbar({
          message: 'Existe uma atualização de frete.',
          options: { variant: 'info' },
        }),
      );
    }

    if (counters.orders < counter.orders) {
      dispatch(
        enqueueSnackbar({
          message: 'Existe uma atualização de ordem de serviço.',
          options: { variant: 'info' },
        }),
      );
    }

    if (counters.occurrences < counter.occurrences) {
      dispatch(
        enqueueSnackbar({
          message: 'Existe uma atualização de ocorrência.',
          options: { variant: 'info' },
        }),
      );
    }
    setCounters(counter);
  }, [counter, counters.freights, counters.orders, counters.occurrences, counters.users, dispatch]);

  return <></>;
};

export default UpdateNotification;
