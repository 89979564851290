import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BrokenImage from '@material-ui/icons/BrokenImage';
import logoImage from '../../assets/image/logo.jpg';
import useStyles from './styles';

const ModalImage = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.openButton} onClick={props.onClick}>
        <Typography>
          Abrir
        </Typography>
      </div>
      <img
        src={props.photo.imageUrl ? props.photo.imageUrl : logoImage}
        alt={props.label}
        className={classes.image}
        draggable="true"
        onDragStart={props.onDragStart}
      />
      <BrokenImage className={classes.imageError} />
      <Typography className={classes.label}>
        {props.label}
      </Typography>
    </div>
  );
};

ModalImage.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string.isRequired,
  photo: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onDragStart: PropTypes.func,
};

export default ModalImage;
