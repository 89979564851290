const styles = {
  minimizedButton: {
    position: 'fixed',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    zIndex: 1000,
    width: '58px',
    height: '58px',
    overflow: 'hidden',
  },
  paper: {
    position: 'fixed',
    top: '60%',
    right: '20px',
    transform: 'translateY(-50%)',
    zIndex: 1000,
    transition: 'right 0.3s ease',
    width: '300px',
    overflow: 'hidden',
  },
  card: {
    padding: '10px',
  },
  closeButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  cardHeader: {
    padding: '10px',
  },
  cardBody: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
};

export default styles;
