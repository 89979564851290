import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from 'react';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Paper, Typography, TextField, Button, CircularProgress, IconButton } from '@material-ui/core';
import { AttachFile, CloudDownload } from '@material-ui/icons';
import { FILTER_LABEL_OCCURRENCE } from '../../constants';
import styles from './styles';
import { formatDate, formatHour, dateNow, isDocumentValid, sendToAWS, validateDocumentType, downloadFromBuffer, getFromAWS } from '../../utils/utils';
import api from "../../api";

interface Message {
  message: string;
  admin: boolean;
  boardUser?: string | null;
  createdDate?: string;
}

interface FileItem {
  admin: boolean;
  name: string;
  url: string;
  type: string;
  sender: string;
  createdDate: string;
  boardUser: string;
}

interface User {
  id: string;
  name: string;
}

interface Occurrence {
  _id: string;
  active: boolean;
  creationDate: string;
  updateDate: string;
  id: string;
  messages: Message[];
  status: keyof typeof FILTER_LABEL_OCCURRENCE.status;
  type: keyof typeof FILTER_LABEL_OCCURRENCE.category;
  userId: string;
  updateAcknowledge: boolean;
  files: FileItem[];
  user: User;
}

interface OccurrenceDetailProps extends WithStyles<typeof styles> {
  occurrence: Occurrence;
  replyToOccurrence: (id: string, data: any) => Promise<void>;
  UpdateOccurrenceDetail: (id: string, data: any) => Promise<void>;
  isLoading: boolean;
  boardUser: string;
}

const OccurrenceDetail: React.FC<OccurrenceDetailProps> = ({
  classes, occurrence, replyToOccurrence, UpdateOccurrenceDetail, isLoading, boardUser }) => {
  const [newMessage, setNewMessage] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!occurrence.updateAcknowledge && occurrence.id !== undefined) {
      api
        .patch(`/occurrence/${occurrence.id}`, { updateAcknowledge: true })
        .catch(err => console.error("ERRO: ", err.message));
    }
  }, [occurrence.id, occurrence.updateAcknowledge]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [occurrence.messages, occurrence.files]);

  const handleMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewMessage(event.target.value);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files ?? []);
    setSelectedFiles(files);
    setFileNames(files.map(file => file.name));
  };

  const handleSubmitMessage = async (event: FormEvent) => {
    event.preventDefault();
    if (newMessage.trim() !== '') {
      setLocalLoading(true);
      await replyToOccurrence(occurrence.id, { message: newMessage, admin: true, boardUser });
      setNewMessage('');
      setTimeout(() => setLocalLoading(false), 3000);
    }
  };

  const handleSaveFiles = async () => {
    if (selectedFiles.length > 0) {
      setLocalLoading(true);
      const files = await Promise.all(selectedFiles.map(async (file) => {
        if (!isDocumentValid(file)) {
          console.error("Invalid file type");
          return null;
        }
        const validated = await validateDocumentType(file);
        if (!validated.data.signedUrl) {
          console.error("No signed URL returned");
          return null;
        }
        await sendToAWS(validated.data.signedUrl, file);
        return {
          admin: true,
          name: file.name,
          url: validated.data.imageUrl,
          type: file.type,
          sender: 'admin',
          createdDate: dateNow(),
          boardUser,
        };
      }));
      const validFiles = files.filter(file => file !== null) as FileItem[];
      await UpdateOccurrenceDetail(occurrence.id, { files: validFiles });
      setSelectedFiles([]);
      setFileNames([]);
      setTimeout(() => setLocalLoading(false), 3000);
    }
  };

  const handleUpdateStatus = async (newStatus: keyof typeof FILTER_LABEL_OCCURRENCE.status) => {
    setLocalLoading(true);
    await UpdateOccurrenceDetail(occurrence.id, { status: newStatus, boardUser });
    setTimeout(() => setLocalLoading(false), 3000);
  };

  const combineMessagesAndFiles = () => {
    const combined: any[] = [];

    if (occurrence?.messages) {
      combined.push(...occurrence.messages.map((msg: any) => ({ ...msg, type: 'message' })));
    }

    if (occurrence?.files) {
      combined.push(...occurrence.files.map((file: any) => ({ ...file, type: 'file' })));
    }

    return combined.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());
  };

  const handleDownloadFile = async (url: string) => {
    try {
      const fileName = url.split('/').pop();
      if (fileName) {
        const response = await getFromAWS(fileName);
        const contentType = response.data.headers['Content-Type'];
        const base64 = response.data.body;
        downloadFromBuffer(base64, fileName, contentType);
      }
    } catch (err) {
      console.error("Error downloading file: ", err);
    }
  };

  const renderCombinedItems = () => {
    const combinedItems = combineMessagesAndFiles();

    return combinedItems.map((item, index) => {
      const senderName = item.boardUser || (item.admin ? '' : occurrence.user?.name);
      if (item.type === 'message') {
        return (
          <div key={index}
            className={`${classes.message} ${item.admin ? classes.adminMessage : classes.userMessage}`}>
            <Typography>
              {item.message} <br />
              <small>{item.createdDate ?
                `${formatDate(item.createdDate)} ${formatHour(item.createdDate)} - ${senderName}` :
                `${formatDate(occurrence.creationDate)} ${formatHour(occurrence.creationDate)} - ${senderName}`}</small>
            </Typography>
          </div>
        );
      } else if (item.type === 'file') {
        return (
          <div key={index} className={`${classes.fileItem} ${item.admin ? classes.adminFile : classes.userFile}`}>
            <Typography variant="body2">{item.name}</Typography>
            <IconButton
              onClick={() => handleDownloadFile(item.url)}
              className={classes.downloadButton}
            >
              <CloudDownload />
            </IconButton>
            <Typography>
              <small>{`${formatDate(item.createdDate)} ${formatHour(item.createdDate)} - ${senderName}`}</small>
            </Typography>
          </div>
        );
      }
      return null;
    });
  };

  if (!occurrence) {
    return (
      <Paper className={classes.root}>
        <Typography>
          Carregando dados...
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerTop}>
          <div className={classes.leftColumn}>
            {occurrence.userId && (
              <Typography>
                <strong>Motorista: </strong>
                <a href={`/drivers/${occurrence.userId}`}>
                  {occurrence.user ? occurrence.user.name : 'Sender'}
                </a>
              </Typography>
            )}
            <Typography>
              <strong>Data de Criação: </strong> {occurrence.creationDate ?
                `${formatDate(occurrence.creationDate)} ${formatHour(occurrence.creationDate)}` : 'Carregando Dados...'}
            </Typography>
            <Typography>
              <strong>Última Atualização: </strong> {occurrence.updateDate ?
                `${formatDate(occurrence.updateDate)} ${formatHour(occurrence.updateDate)}` : 'Carregando Dados...'}
            </Typography>
          </div>
          <div className={classes.rightColumn}>
            <Typography>
              <strong>Tipo: </strong> {FILTER_LABEL_OCCURRENCE.category[occurrence.type] || 'Carregando Dados...'}
            </Typography>
            <Typography>
              <strong>Status: </strong> {FILTER_LABEL_OCCURRENCE.status[occurrence.status] || 'Carregando Dados...'}
            </Typography>
          </div>
        </div>
        {isLoading || localLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <CircularProgress />
          </div>
        ) : (
          <div className={classes.statusButtonContainer}>
            {FILTER_LABEL_OCCURRENCE.status[occurrence.status] === FILTER_LABEL_OCCURRENCE.status.RESOLVED ? (
              <Button
                className={classes.updateButtonRed}
                onClick={() => handleUpdateStatus("UNRESOLVED")}
              >
                Reabrir Ocorrência
              </Button>
            ) : (
              <Button
                disabled={FILTER_LABEL_OCCURRENCE.status[occurrence.status] === FILTER_LABEL_OCCURRENCE.status.RESOLVED}
                className={classes.updateButtonGreen}
                onClick={() => handleUpdateStatus("RESOLVED")}
              >
                Marcar como Resolvido
              </Button>
            )}
          </div>
        )}
      </div>
      <div className={classes.messagesContainer}>
        {renderCombinedItems()}
        <div ref={messagesEndRef} />
      </div>
      <form className={classes.form} onSubmit={handleSubmitMessage}>
        <TextField
          className={classes.input}
          variant="outlined"
          placeholder="Digite sua resposta..."
          value={newMessage}
          onChange={handleMessageChange}
          multiline
          minRows={4}
        />
        <div className={classes.buttonContainer}>
          {isLoading || localLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Button
                variant="contained"
                type="submit"
                className={classes.sendButton}
                disabled={newMessage.trim() === ''}
              >
                Enviar Mensagem
              </Button>
              <label htmlFor="file-input" className={classes.fileInputLabel}>
                <AttachFile /> Anexar Arquivos
              </label>
              <input
                id="file-input"
                type="file"
                multiple
                onChange={handleFileChange}
                className={classes.fileInput}
              />
              <Button
                variant="contained"
                onClick={handleSaveFiles}
                className={classes.saveButton}
                disabled={selectedFiles.length === 0}
              >
                Salvar Arquivos
              </Button>
            </>
          )}
        </div>
      </form>
      {fileNames.length > 0 && (
        <Typography variant="body2" className={classes.fileName}>
          {`Arquivos anexados: ${fileNames.join(', ')}`}
        </Typography>
      )}
    </Paper>
  );
};

const mapStateToProps = (state: any) => ({
  boardUser: state.auth.user.name,
});

export default connect(mapStateToProps)(withStyles(styles)(OccurrenceDetail));
