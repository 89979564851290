import React from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '../Button';
import ReactImageMagnify from 'react-image-magnify';
import { useState, useEffect } from 'react';
import { FREIGHT_STATUS } from '../../constants';
import useStyles from './styles'

const ModalPhotoDriver = (props) => {
  const classes = useStyles(props);
  const [messageShow, setMessageShow] = useState(false);
  const [message, setMessage] = useState('');
  const [isZoomEnabled, setIsZoomEnabled] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setMessageShow(!!props.photo.message);
    setMessage(props.photo.message || '');
  }, [props.photo]);

  const {
    label,
    photo,
    photoName,
    driverId,
    driverStatus,
    freightStatus,
    handleCancel,
    handleDownloadPhoto,
    handleConfirm,
    handleConfirmApproval,
    requestDriverBlocking,
    ...other
  } = props;

  const requestImageBlocking = () => {
    let photoPath = '';
    let data = {};
    switch (props.photoName) {
      case 'anttPhoto':
      case 'documentPhoto':
      case 'truckUnitPhoto':
      case 'vehiclePhoto':
        photoPath = `vehicle.${props.photoName}`;
        data.vehicle = {
          [props.photoName]: {
            status: 'DENIED',
            message,
          },
        };
        break;

      case 'semiTrailerDocumentPhotos':
      case 'semiTrailerPhotos':
        photoPath = `vehicle.${props.photoName}`;
        data.vehicle = {
          [props.photoName]: [
            {
              index: props.photo.index,
              status: 'DENIED',
              message,
            },
          ],
        };
        break;

      case 'cardPhoto':
        photoPath = `wallet.${props.photoName}`;
        data.wallet = {
          [props.photoName]: {
            status: 'DENIED',
            message,
          },
        };
        break;

      default:
        photoPath = props.photoName;
        data = {
          [props.photoName]: {
            status: 'DENIED',
            message,
          },
        };
        break;
    }

    dispatch(handleConfirm({
      data,
      driverId,
      photoPath,
    }));

    handleCancel();

    if (props.driverStatus === 'APPROVED') {
      dispatch(requestDriverBlocking(driverId));
    }
  };

  const requestImageApproval = () => {
    let photoPath = '';
    let data = {};
    switch (props.photoName) {
      case 'anttPhoto':
      case 'documentPhoto':
      case 'truckUnitPhoto':
      case 'vehiclePhoto':
        photoPath = `vehicle.${props.photoName}`;
        data.vehicle = {
          [props.photoName]: {
            status: 'APPROVED',
            message: 'Foto aprovada',
          },
        };
        break;

      case 'semiTrailerDocumentPhotos':
      case 'semiTrailerPhotos':
        photoPath = `vehicle.${props.photoName}`;
        data.vehicle = {
          [props.photoName]: [
            {
              index: props.photo.index,
              status: 'APPROVED',
              message: 'Foto aprovada',
            },
          ],
        };
        break;

      case 'cardPhoto':
        photoPath = `wallet.${props.photoName}`;
        data.wallet = {
          [props.photoName]: {
            status: 'APPROVED',
            message: 'Foto aprovada',
          },
        };
        break;

      default:
        photoPath = props.photoName;
        data = {
          [props.photoName]: {
            status: 'APPROVED',
            message: 'Foto aprovada',
          },
        };
        break;
    }

    dispatch(handleConfirmApproval({
      data,
      driverId,
      photoPath,
    }));

    handleCancel();
  };

  const handleImageClick = () => {
    setIsZoomEnabled(!isZoomEnabled);
  };

  return (
    <Dialog disableEscapeKeyDown maxWidth={'lg'} {...other}>
      <DialogContent className={classes.dialogContent}>
        {!!photo.imageUrl && (
          <div onClick={handleImageClick}>
            <ReactImageMagnify
              className={classes.imageContainer}
              smallImage={{
                src: isZoomEnabled ? photo.imageUrl : photo.thumbnailUrl || photo.imageUrl,
                width: isZoomEnabled ? 1100 : 804,
                height: isZoomEnabled ? 1600 : 834,
              }}
              largeImage={{
                src: isZoomEnabled ? photo.imageUrl : photo.imageUrl,
                width: isZoomEnabled ? 1100 : 804,
                height: isZoomEnabled ? 1600 : 834,
              }}
              hoverDelayInMs={500000}
              enlargedImagePosition="over"
              shouldHideHintAfterFirstActivation={false}
              isActivatedOnTouch={isZoomEnabled}
              isEnlargedImagePortalEnabledForTouch={false}
              {...(isZoomEnabled ? {} : { enlargedImageContainerDimensions: { width: '0%', height: '0%' } })}
            />
          </div>
        )}
        <Divider />
      </DialogContent>

      {props.photo.status !== 'DENIED' && (
        <DialogActions className={classes.dialogActions}>
          <Button
            size="large"
            customColor="green"
            variant="contained"
            onClick={() => requestImageApproval()}
            className={classes.buttons}>
            Aprovar foto
          </Button>
          <Button
            size="large"
            customColor="blue"
            variant="contained"
            onClick={handleDownloadPhoto}
            className={classes.buttons}
          ><DownloadIcon />
            Download
          </Button>
          <Button
            size="large"
            customColor="blue"
            variant="contained"
            disabled={(!!messageShow && !message) || freightStatus === FREIGHT_STATUS.FINISHED}
            onClick={!messageShow ? () => setMessageShow(true) : () => requestImageBlocking()}
            className={classes.buttons}
          >
            Solicitar foto
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={handleCancel}
            className={classes.closeButton}
          >
            Fechar
          </Button>
        </DialogActions>
      )}

      {props.photo.status === 'DENIED' && (
        <DialogActions className={classes.dialogActions}>
          <Button
            size="large"
            customColor="green"
            variant="contained"
            onClick={!messageShow ? () => setMessageShow(true) : () => requestImageApproval()}
            className={classes.buttons}
          >
            Aprovar foto
          </Button>
          <Button
            size="large"
            customColor="blue"
            variant="contained"
            onClick={handleDownloadPhoto}
            className={classes.buttons}
          ><DownloadIcon />
            Download
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={handleCancel}
            className={classes.closeButton}
          >
            Fechar
          </Button>
        </DialogActions>
      )}

      {!!messageShow && (
        <div className={classes.messageContainer}>
          <Typography className={classes.messageLabel}>Motivo:</Typography>
          <textarea
            disabled={props.photo.status === 'DENIED'}
            value={message}
            maxLength={236}
            onChange={({ target }) => setMessage(target.value)}
            className={classes.messageInput}
          />
        </div>
      )}

      {props.photo.status === 'DENIED' && (
        <div className={classes.goBackButtonContainer}>
          <Button
            size="large"
            customColor="blue"
            variant="contained"
            disabled={!!messageShow && !message}
            onClick={props.handleCancel}
          >
            Voltar
          </Button>
        </div>
      )}
    </Dialog>
  );
};

export default ModalPhotoDriver;