import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  requestFreights,
  clearFreightList,
  clearFreightsFilters,
  setFreightsFilter,
} from '../actions/freights';
import { isFiltersEmpty } from '../utils/utils';
import FreightsFilter from '../components/FreightFilter';
import FreightsList from '../components/FreightsList';
import VersionChecker, { useVersionChecker } from '../components/VersionChecker/VersionChecker';

const OffersScreens = () => {
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const freightsCount = useSelector((state) => state.counter.freights);
  const freightsList = useSelector((state) => state.freights.freightsList);
  const filters = useSelector((state) => state.freights.filters);
  const skip = useSelector((state) => state.freights.skip);
  const limit = useSelector((state) => state.freights.filters.limit);
  const hasMore = useSelector((state) => state.freights.hasMore);
  const isLoading = useSelector((state) => state.freights.isLoading);

  const [counter, setCounter] = useState(freightsCount);
  const [creatorName, setCreatorName] = useState(filters.creatorName);
  const [freightCode, setFreightCode] = useState(filters.freightCode);
  const [OSCode, setOSCode] = useState(filters.OSCode);
  const [driverName, setDriverName] = useState(filters.driverName);

  const debouncedSetFilter = useRef(
    debounce((props) => {
      dispatch(setFreightsFilter(props));
    }, 1500)
  ).current;

  const debouncedRequestFreights = useRef(
    debounce((filters) => {
      dispatch(requestFreights({ limit, skip: 0, filters }));
    }, 1600)
  ).current;

  useVersionChecker('OffersScreens');

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      debouncedSetFilter.cancel();
      debouncedRequestFreights.cancel();
    };
  }, [debouncedSetFilter, debouncedRequestFreights]);

  useEffect(() => {
    if (mounted.current) {
      dispatch(clearFreightList());
      debouncedRequestFreights(filters);
    }
  }, [filters, dispatch, debouncedRequestFreights]);

  useEffect(() => {
    if (freightsCount > counter && mounted.current) {
      getFreights(0);
    }
    setCounter(freightsCount); // eslint-disable-next-line
  }, [freightsCount]);

  const getFreights = (skipProp = skip) => {
    dispatch(requestFreights({ limit, skip: skipProp, filters }));
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    if (name === 'freightCode') {
      setFreightCode(value);
    } else if (name === 'OSCode') {
      setOSCode(value);
    } else if (name === 'creatorName') {
      setCreatorName(value);
    } else if (name === 'driverName') {
      setDriverName(value);
    }
    debouncedSetFilter({ [name]: value });
  };

  const handleClearFilters = () => {
    setCreatorName('');
    setFreightCode('');
    setOSCode('');
    setDriverName('');
    dispatch(clearFreightsFilters());
    dispatch(requestFreights({ filters: {} }));
  };

  const handleLoadMore = () => {
    if (hasMore && !isLoading) {
      getFreights(skip);
    }
  };

  const handleRefresh = () => {
    dispatch(clearFreightList());
    getFreights(0);
  };

  const clearAndSearch = () => {
    dispatch(clearFreightList());
  };

  const sortedFreightsList =
    [...freightsList].sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));

  return (
    <>
      <VersionChecker />
      <div className="main-filter-freight">
        <FreightsFilter
          disableClear={isFiltersEmpty(filters) || isLoading}
          disableRefresh={isLoading}
          clearAndSearch={clearAndSearch}
          handleClearFilters={handleClearFilters}
          handleRefresh={handleRefresh}
          handleFilterChange={handleFilterChange}
          creatorName={creatorName}
          freightCode={freightCode}
          OSCode={OSCode}
          driverName={driverName}
          status={filters.status}
          gatheringState={filters.gatheringState}
          deliveryState={filters.deliveryState}
          elegibleVehicles={filters.elegibleVehicles}
          invoiceCoupon={filters.invoiceCoupon}
          updateAcknowledge={filters.updateAcknowledge}
          advanceRequestedDate={filters.advanceRequestedDate}
          type={filters.type}
        />
      </div>
      <main className="main-content">
        <FreightsList
          freightsList={sortedFreightsList}
          hasMore={hasMore}
          getFreights={getFreights}
          isLoading={isLoading}
          handleLoadMore={handleLoadMore}
        />
      </main>
    </>
  );
};

export default OffersScreens;
