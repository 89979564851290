import api from '../api';
import { enqueueSnackbar } from './notifications';
import {
  ORDERS_DETAIL_REQUEST,
  ORDERS_DETAIL_SUCCESS,
  ORDERS_DETAIL_FAILURE,
  ORDERS_DETAIL_UPDATE_REQUEST,
  ORDERS_DETAIL_UPDATE_SUCCESS,
  ORDERS_DETAIL_UPDATE_FAILURE,
  ORDERS_DETAIL_UPDATE_STATUS_REQUEST,
  ORDERS_DETAIL_UPDATE_STATUS_SUCCESS,
  ORDERS_DETAIL_UPDATE_STATUS_FAILURE,
  ORDER_CLEAR_DATA
} from './actionsTypes';

export const requestOrderDetail = (id) => async (dispatch) => {
  dispatch({ type: ORDERS_DETAIL_REQUEST });
  try {
    const res = await api.get(`/order/${id}`);
    dispatch(orderDetailSuccess(res.data));
  } catch (err) {
    dispatch(orderDetailFailure(err.toString()));
    dispatch(enqueueSnackbar({
      message: 'Erro ao buscar detalhes da ordem. Tente novamente.',
      options: { variant: 'error' },
    }));
  }
};

export const requestUpdateOrderDetail = (payload) => async (dispatch) => {
  const { order, id } = payload;
  dispatch({ type: ORDERS_DETAIL_UPDATE_REQUEST });
  try {
    const res = await api.patch(`/order/${id}`, order);
    dispatch(updateOrderDetailSuccess(res.data));
    dispatch(enqueueSnackbar({
      message: 'Ordem de serviço atualizada com sucesso.',
      options: { variant: 'success' },
    }));
  } catch (err) {
    dispatch(updateOrderDetailFailure());
    dispatch(enqueueSnackbar({
      message: 'Erro ao atualizar a ordem de serviço. Tente novamente.',
      options: { variant: 'error' },
    }));
  }
};

export const requestUpdateStatusOrderDetail = (status, orderId,
) => async (dispatch) => {
  const data = {
    status,
    boardRequest: true,
  };
  dispatch({ type: ORDERS_DETAIL_UPDATE_STATUS_REQUEST });
  try {
    const res = await api.patch(`/order/${orderId}/status`, data);
    dispatch(updateStatusOrderDetailSuccess(res.data));
    dispatch(enqueueSnackbar({
      message: 'Ordem de serviço atualizada com sucesso.',
      options: { variant: 'success' },
    }));
  } catch (err) {
    dispatch(updateStatusOrderDetailFailure());
    dispatch(enqueueSnackbar({
      message: 'Erro ao atualizar a ordem de serviço. Tente novamente.',
      options: { variant: 'error' },
    }));
  }
};

export const requestOrderImageBlocking = (payload) => async (dispatch) => {
  const { order, id } = payload;
  dispatch({ type: ORDERS_DETAIL_UPDATE_REQUEST });
  try {
    const res = await api.patch(`/order/${id}`, order);
    dispatch(updateOrderDetailSuccess(res.data));
    dispatch(enqueueSnackbar({
      message: "Nova foto solicitada.",
      options: { variant: "success" },
    }));
  } catch (err) {
    dispatch(updateOrderDetailFailure());
    dispatch(enqueueSnackbar({
      message: 'Erro ao atualizar a ordem de serviço. Tente novamente.',
      options: { variant: 'error' },
    }));
  }
};

const orderDetailSuccess = (payload) => ({ type: ORDERS_DETAIL_SUCCESS, payload });
const orderDetailFailure = (payload) => ({ type: ORDERS_DETAIL_FAILURE, payload });
const updateOrderDetailSuccess = (payload) => ({ type: ORDERS_DETAIL_UPDATE_SUCCESS, payload });
const updateOrderDetailFailure = () => ({ type: ORDERS_DETAIL_UPDATE_FAILURE });
const updateStatusOrderDetailSuccess = (payload) => ({ type: ORDERS_DETAIL_UPDATE_STATUS_SUCCESS, payload });
const updateStatusOrderDetailFailure = () => ({ type: ORDERS_DETAIL_UPDATE_STATUS_FAILURE });
export const clearOrderData = () => ({ type: ORDER_CLEAR_DATA });