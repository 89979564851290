import React, { useEffect, useState } from 'react';
import { overlayStyle, messageStyle } from './styles';
import { version as localVersion } from '../../../package.json';

const VersionChecker: React.FC = () => {
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);
  const [countdown, setCountdown] = useState(5); // eslint-disable-next-line
  const [screenUpdated, setScreenUpdated] = useState<{ [key: string]: boolean }>({});

  const fetchServerVersion = async () => {
    const versionUrl = process.env.REACT_APP_VERSION_URL;
    if (!versionUrl) {
      console.error('Version URL not set in environment variables');
      return null;
    }

    try {
      const response = await fetch(versionUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data.version;
    } catch (error) {
      console.error('Failed to fetch server version', error);
      return null;
    }
  };

  useEffect(() => {
    const checkVersion = async () => {
      const versionApp = sessionStorage.getItem('versionApp');
      let serverVersion = await fetchServerVersion();

      if (!serverVersion) {
        console.warn('Using local version as fallback');
        serverVersion = localVersion;
      }

      if (serverVersion && versionApp !== serverVersion) {
        setShowUpdateMessage(true);
        const interval = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown <= 1) {
              clearInterval(interval);
              sessionStorage.setItem('versionApp', serverVersion);
              sessionStorage.removeItem('updatedScreens'); 
              setScreenUpdated({});
              window.location.reload();
              return 0;
            }
            return prevCountdown - 1;
          });
        }, 1000);
      }
    };

    checkVersion();
  }, []);

  useEffect(() => {
    const updatedScreens = JSON.parse(sessionStorage.getItem('updatedScreens') || '{}');
    setScreenUpdated(updatedScreens);
  }, []);

  if (!showUpdateMessage) {
    return null;
  }

  return (
    <div style={overlayStyle}>
      <div style={messageStyle}>
        <h1>Nova versão encontrada! Atualizando em {countdown} segundos!</h1>
      </div>
    </div>
  );
};

export const useVersionChecker = (screenName: string) => {
  useEffect(() => {
    const updatedScreens = JSON.parse(sessionStorage.getItem('updatedScreens') || '{}');
    if (!updatedScreens[screenName]) {
      const updateScreen = () => {
        updatedScreens[screenName] = true;
        sessionStorage.setItem('updatedScreens', JSON.stringify(updatedScreens));
        window.location.reload();
      };
      updateScreen();
    }
  }, [screenName]);
};

export default VersionChecker;
