import headerbackground from "../../assets/image/fretador-fundo-small.png";
import {
  btnRed,
  btnRedHover,
  btnGreen,
  btnGreenHover,
  btnYellow,
  btnYellowHover,
  btnBlue,
  btnBlueHover,
  btnBlueDisabled,
  COLOR_DEFAULT,
  COLOR_DEFAULT_HOVER,
  COLOR_BLUE,
  COLOR_BLUE_ALPHA8,
} from '../../globalTheme'

export default theme => {
  return {
    mainContent: {
      height: "500px",
      "& $line:nth-child(2n - 1)": {
        backgroundColor: "#F4F4F4"
      }
    },
    line: {
      display: "flex",
      width: "100%",
      height: "auto",
      minHeight: "100px",
      position: "relative",
      "& input[type=file]": {
        display: "none"
      }
    },
    timeStatus: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      width: "80px",
      padding: "20px 10px",
      "& p": {
        margin: "2px 0px",
        padding: "0",
        color: COLOR_DEFAULT,
        fontSize: "12px",
        fontWeight: "bold"
      }
    },
    divider: {
      width: "3px",
      height: "80%",
      backgroundColor: "#ccc",
      opacity: "0.5",
      margin: "auto 0px"
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      width: "100%",
      padding: "10px",
      "& p": {
        padding: "0",
        fontSize: "14px"
      }
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: "100%",
      minHeight: "250px",
      maxHeight: "834px",
      backgroundColor: "#fff",
      flexDirection: "column",
      border: "1px solid black"
    },
    modalContent: {
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[4],
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '90%',
      width: '80vw',
      "& img": {
        padding: "15px",
        objectFit: "contain"
      },
      "& p": {
        padding: "10px",
        fontSize: "15px"
      },
      "& span": {
        fontWeight: "bold",
        textTransform: "uppercase"
      }
    },
    modalIframe: {
      width: '100%',
      height: '80vh',
      marginBottom: theme.spacing(2),
    },
    modalHeader: {
      backgroundImage: `url(${headerbackground})`,
      backgroundColor: COLOR_DEFAULT,
      width: "100%",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& p": {
        textTransform: "uppercase",
        color: "#fff",
        fontSize: "18px",
        fontWeight: "bold"
      }
    },
    modalFooter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: "0",
      marginTop: "auto",
      padding: "25px",
      gap: "40px"
    },
    btn: {
      padding: "8px",
      fontSize: "10px",
      fontWeight: "bold",
      color: "#fff",
      borderRadius: "5px",
      outline: "none",
      border: "none",
      textTransform: "uppercase",
      backgroundColor: COLOR_DEFAULT,
      position: "relative",
      cursor: "pointer",
      margin: "4px",
      textDecoration: "none",
      gap: "10px",
      "&:active": {
        top: "3px"
      },
      "&:hover": {
        backgroundColor: COLOR_DEFAULT_HOVER
      }
    },
    btnBlue: {
      backgroundColor: btnBlue,
      color: "#fff",
      "&:hover": {
        backgroundColor: btnBlueHover
      }
    },
    btnRed: {
      backgroundColor: btnRed,
      color: "#fff",
      "&:hover": {
        backgroundColor: btnRedHover
      }
    },
    btnGreen: {
      backgroundColor: btnGreen,
      color: "#fff",
      "&:hover": {
        backgroundColor: btnGreenHover
      }
    },
    btnYellow: {
      backgroundColor: btnYellow,
      color: "#fff",
      "&:hover": {
        backgroundColor: btnYellowHover
      }
    },
    btnWhite: {
      backgroundColor: '#ffffff',
      color: '#1B556D',
      border: "2px solid #1B556D",
      "&:hover": {
        backgroundColor: "#858585b3"
      }
    },
    btnDisabled: {
      backgroundColor: btnBlueDisabled,
      cursor: "not-allowed",
      pointerEvents: "none"
    },
    btnApproved: {
      pointerEvents: "none"
    },
    reproved: {
      color: "red"
    },
    invoiceCouponPhotos: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    invoiceCouponButtons: {
      display: "flex",
      flexDirection: "row",
      height: "maxContent"
    },
    messageContainer: {
      padding: '16px 24px',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    messageLabel: {
      fontWeight: 'bold',
      marginBottom: '8px',
    },
    messageInput: {
      width: '100%',
      minHeight: '100px',
      padding: '8px',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '4px',
    },
    rejectSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
      marginTop: '16px',
      paddingLeft: '10px',
    },
    rejectInput: {
      padding: '8px',
      fontSize: '14px',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.divider}`,
      width: '100%',
      maxWidth: '300px',
    },
    pdfContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '8px',
      marginBottom: '8px',
    },
    closeButton: {
      backgroundColor: COLOR_BLUE,
      color: theme.palette.error.contrastText,
      alignSelf: 'center',
      '&:hover': {
        backgroundColor: COLOR_BLUE_ALPHA8,
      },
    },
    disabled: {
      position: "absolute",
      width: "100%",
      height: "auto",
      minHeight: "100px",
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      zIndex: "2"
    },
  };
};
