export const colors = {
  white: "#FFF",
  whiteAlpha: "rgba(255, 255, 255, 0.6)",
  error: "red",
};

const dimensions = {
  filterDateWidthLarge: "100%",
  filterDateWidthSmall: "100%",
  asideWidthLarge: "50rem",
  asideWidthSmall: "20rem",
  asideWidth: "20rem",
  asideHeight: "7rem",
  selectWidth: "8rem",
  filterSelectWidth: "auto",
  selectHeight: "auto",
  filterListWidth: "45rem",
  loteFieldWidth: "8rem",
  loteFieldHeight: "3rem",
};

export default theme => ({
  selectRoot: {
    width: dimensions.selectWidth,
    marginLeft: "0.5rem",
  },
  filterSearch: {
    display: "flex",
    maxWidth: "975px",
  },
  filterSelect: {
    display: "flex",
    height: dimensions.asideHeight,
    width: dimensions.filterSelectWidth,
  },
  asideRight: {
    display: "flex",
    flexDirection: "row",
  },
  selectLabel: {
    color: colors.white,
    fontSize: "0.8rem",
  },
  input: {
    color: colors.white,
    fontSize: "0.8rem",
    borderBottom: `1px solid ${colors.whiteAlpha}`,
    "&:hover": {
      borderBottom: `2px solid ${colors.whiteAlpha}`,
    },
    "&:focus": {
      borderBottom: `2px solid ${colors.white}`,
    },
    "&.error": {
      color: colors.error,
      borderBottom: `1px solid ${colors.error}`,
    },
  },
  iconButton: {
    padding: "1.5%",
  },
  searchNameContainer: {
    paddingTop: "2.8125rem", // 45px to rem
  },
  filterList: {
    display: "flex",
    width: dimensions.filterListWidth,
  },
  loteField: {
    paddingTop: "0.9375rem", // 15px to rem
    marginRight: "1.875rem", // 30px to rem
    width: dimensions.loteFieldWidth,
    height: dimensions.loteFieldHeight,
  },
  loteSearchIcon: {
    fontSize: "1rem",
  },
  loteFilterContainer: {
    width: dimensions.asideWidth,
    padding: "0.7rem",
  },
  dateFilterContainer: {
    display: "flex",
    flexDirection: "row",
    width: dimensions.filterDateWidthLarge,
  },
  dateSelectContainer: {
    display: "flex",
    flexDirection: "row",
  },
  dateSelect: {
    display: "flex",
    flexDirection: "column",
    margin: "0.7rem",
  },
  imgFilterContainer: {
    padding: "0.5rem",
    margin: "0.5rem",
    width: "8rem",
    height: "7rem",
    display: "flex",
    flexDirection: "column",
    gap: "0.4rem",
  },
  imgFilter: {
    width: dimensions.imageFilterSize,
    height: dimensions.imageFilterSize,
  },
  nameFilterInputContainer: {
    width: dimensions.nameFilterInputWidth,
    display: "flex",
    flexDirection: "column",
  },
  filterLabel: {
    fontWeight: "bold",
    color: colors.text,
    fontSize: "0.8rem",
  },
  "@media (max-width: 480px)": {
    filterList: {
      flexDirection: "column",
      width: "100%",
    },
    dateFilterContainer: {
      width: dimensions.filterDateWidthSmall,
      padding: "0.7rem",
      flexDirection: "column",
    },
    dateSelectContainer: {
      display: "flex",
      flexDirection: "column",
    },
  },
  "@media (max-width: 768px)": {
  },
  "@media (max-width: 920px)": {
    filterList: {
      width: "auto",
      justifyContent: "center",
    },
    asideRight: {
      flexDirection: "column",
    },
    dateSelectContainer: {
      display: "flex",
      flexDirection: "column",
    },
  },
  "@media (max-width: 1200px)": {
  },
  "@media (max-width: 1400px)": {
    filterList: {
      width: "80rem",
    },
  },
});
