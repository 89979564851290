import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OccurrenceDetail from '../components/OccurrenceDetail/index.tsx';
import {
  requestOccurrenceDetail,
  replyToOccurrence,
  clearOccurrenceData,
  UpdateOccurrenceDetail,
  requestOccurrenceMessages
} from '../actions/occurrenceDetail';
import VersionChecker, { useVersionChecker } from '../components/VersionChecker/VersionChecker';

const OccurrenceDetailScreen = (props) => {
  useVersionChecker('OccurrenceDetailScreen');

  const timerID = useRef(null);
  const { id } = props.match.params;

  const startMessagesPolling = (id) => {
    clearInterval(timerID.current);
    timerID.current = setInterval(() => props.requestOccurrenceMessages(id), 6000);
  };

  useEffect(() => {
    props.clearOccurrenceData();
    props.requestOccurrenceDetail(id);
    startMessagesPolling(id);

    return () => {
      props.clearOccurrenceData();
      clearInterval(timerID.current);
    }; // eslint-disable-next-line
  }, [id]);

  const { isLoading, occurrenceData, replyToOccurrence, UpdateOccurrenceDetail } = props;

  return (
    <main className="main-content">
      <VersionChecker />
      <OccurrenceDetail
        isLoading={isLoading}
        occurrence={occurrenceData}
        replyToOccurrence={replyToOccurrence}
        UpdateOccurrenceDetail={UpdateOccurrenceDetail}
        occurrenceId={id}
      />
    </main>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.occurrenceDetail.isLoading,
  occurrenceData: state.occurrenceDetail.occurrenceData,
  error: state.occurrenceDetail.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    requestOccurrenceDetail,
    replyToOccurrence,
    UpdateOccurrenceDetail,
    clearOccurrenceData,
    requestOccurrenceMessages,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OccurrenceDetailScreen);
