import { uniqBy } from 'lodash';
import {
  FREIGHTS_LIST_REQUEST,
  FREIGHTS_LIST_SUCCESS,
  FREIGHTS_LIST_SUCCESS_POOLING,
  FREIGHTS_LIST_FAILURE,
  FREIGHTS_CLEAR_LIST,
  FREIGHTS_CLEAR_FILTERS,
  FREIGHTS_SET_FILTER,
} from '../actions/actionsTypes';

import { ROWS_PER_PAGE_IN_CANVAS } from '../constants';

const INITIAL_STATE = {
  freightsList: [],
  freightLocation: {
    location: [],
  },
  filters: {
    creatorName: '',
    freightCode: '',
    OSCode: '',
    driverName: '',
    status: '',
    gatheringState: '',
    deliveryState: '',
    elegibleVehicles: '',
    invoiceCoupon: '',
    updateAcknowledge: '',
    advanceRequestedDate: '',
    type:'',
    limit: ROWS_PER_PAGE_IN_CANVAS,
  },
  isLoading: false,
  hasMore: true,
  skip: 0,
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case FREIGHTS_CLEAR_LIST:
      return {
        ...state,
        skip: 0,
        hasMore: true,
        freightsList: [],
      };

    case FREIGHTS_CLEAR_FILTERS:
      return {
        ...state,
        skip: 0,
        hasMore: true,
        freightsList: [],
        filters: {
          creatorName: '',
          freightCode: '',
          OSCode: '',
          driverName: '',
          status: '',
          gatheringState: '',
          deliveryState: '',
          elegibleVehicles: '',
          invoiceCoupon: '',
          updateAcknowledge: '',
          advanceRequestedDate: '',
          type: '',
          limit: ROWS_PER_PAGE_IN_CANVAS,
        },
      };

    case FREIGHTS_SET_FILTER:
      return {
        ...state,
        skip: 0,
        hasMore: true,
        freightsList: [],
        filters: {
          ...state.filters,
          ...payload,
        },
      };

    case FREIGHTS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FREIGHTS_LIST_SUCCESS_POOLING:
      if (state.isLoading) {
        return { ...state };
      }
      return {
        ...state,
        isLoading: false,
        freightsList: uniqBy([...state.freightsList, ...payload.freights], 'id'),
      };

    case FREIGHTS_LIST_SUCCESS:
      const freightsList = uniqBy([...state.freightsList, ...payload.freights], 'id');
      return {
        ...state,
        isLoading: false,
        skip: freightsList.length,
        hasMore: payload.freights.length === state.filters.limit,
        freightsList,
      };

    case FREIGHTS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
