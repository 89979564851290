import api from '../api';

import {
  ORDERS_LIST_REQUEST,
  ORDERS_LIST_SUCCESS,
  ORDERS_LIST_SUCCESS_POOLING,
  ORDERS_LIST_FAILURE,
  ORDERS_CLEAR_LIST,
  ORDERS_CLEAR_FILTERS,
  ORDERS_SET_FILTER
} from './actionsTypes';

export const requestOrders = (payload = {}) => {
  return (dispatch) => {
    if (!payload.pooling) {
      dispatch({ type: ORDERS_LIST_REQUEST, payload });
    }

    const { limit, skip, filters } = payload;

    const query = { ...filters } || {};
    if (limit) query.limit = limit;
    if (skip) query.skip = skip;

    api
      .get(`/orders`, {
        params: query,
      })
      .then((res) => {
        if (!payload.pooling) {
          dispatch(requestOrdersSuccess(res.data));
        } else {
          dispatch(requestOrdersSuccessPooling(res.data));
        }
      })
      .catch((err) => {
        dispatch(requestOrdersFailure(err.message));
      });
  };
};

export const requestOrdersSuccess = (payload) => {
  return { type: ORDERS_LIST_SUCCESS, payload };
};

export const requestOrdersSuccessPooling = (payload) => {
  return { type: ORDERS_LIST_SUCCESS_POOLING, payload };
};

export const requestOrdersFailure = (payload) => {
  return { type: ORDERS_LIST_FAILURE, payload };
};

export const clearOrdersList = () => {
  return { type: ORDERS_CLEAR_LIST };
};

export const clearOrdersFilters = () => {
  return { type: ORDERS_CLEAR_FILTERS };
};

export const setOrdersFilter = (payload) => {
  return { type: ORDERS_SET_FILTER, payload };
};