import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "../Button";
import { func, bool } from "prop-types";
import { FILTER_LABEL_OCCURRENCE } from "../../constants";
import { formatCPF } from "../../utils/utils";
import api from "../../api";
import styles from "./styles";

const useStyles = makeStyles(styles);

const CreateOccurrence = ({
  enqueueSnackbar,
  handleRefresh,
  occurrenceBroadcastRequest,
  isLoading,
  infoDeleteOffer,
  toggleCreateOccurrenceModal,
  message: initialMessage = "",
  type: initialType = "",
  cpf: initialCpf = "",
  boardUserName
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [message, setMessage] = useState(initialMessage);
  const [type, setType] = useState(initialType);
  const [cpf, setCpf] = useState(initialCpf);
  const [searchName, setSearchName] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const cpfFromUrl = params.get('cpf');
    if (cpfFromUrl) {
      setCpf(cpfFromUrl);
    }
  }, [location]);

  const validate = () => {
    return message.trim().length && type.trim().length && cpf.trim().length;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "message") setMessage(value);
    else if (name === "type") setType(value);
    else if (name === "cpf") setCpf(value);
    else if (name === "searchName") setSearchName(value);
  };

  const handleDriverSelect = (driver) => {
    setSelectedDriver(driver);
    setCpf(driver.cpf);
    setDrivers([]);
  };

  const searchDrivers = async () => {
    try {
      const response = await api.get(`/users?name=${searchName}`);
      if (response.data.users.length === 0) {
        enqueueSnackbar({
          message: "Nenhum motorista encontrado",
          options: { variant: "warning" },
        });
      } else {
        enqueueSnackbar({
          message: "Um ou mais motoristas encontrados, selecione um motorista.",
          options: { variant: "success" },
        });
        setDrivers(response.data.users);
      }
    } catch (error) {
      enqueueSnackbar({
        message: "Erro ao buscar motoristas",
        options: { variant: "error" },
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchDrivers();
    }
  };

  const notify = () => {
    if (!validate()) {
      enqueueSnackbar({
        message: "Todos os campos devem ser preenchidos",
        options: { variant: "error" },
      });
      return;
    }

    const occurrence = {
      messages: [
        {
          message,
          boardUser: boardUserName,
          admin: true
        }
      ],
      type,
      cpf,
      active: true,
      status: "UNRESOLVED",
      updateAcknowledge: false,
    };

    if (type === "FREIGHT_CANCELLATION" && infoDeleteOffer) {
      occurrenceBroadcastRequest(occurrence);
      infoDeleteOffer();
      toggleCreateOccurrenceModal();
      history.push(`/offers`);
      handleRefresh();
    } else {
      occurrenceBroadcastRequest(occurrence);
      clearOccurrence();
      history.push(`/occurrences`);
      handleRefresh();
    }
  };

  const clearOccurrence = () => {
    setMessage("");
    setType("");
    setCpf("");
    setSearchName("");
    setDrivers([]);
    setSelectedDriver(null);
  };

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <div className={classes.inputsContainer}>
          <Typography className={classes.title}>
            Envio de Ocorrência
          </Typography>

          <FormControl className={classes.formCtlInput} fullWidth>
            <div className={classes.searchContainer}>
              <InputLabel htmlFor="searchName">Buscar por Nome</InputLabel>
              <Input
                id="searchName"
                name="searchName"
                autoComplete="name"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={searchName}
                className={classes.searchInput}
              />
              <Button
                onClick={searchDrivers}
                size="large"
                variant="contained"
                customColor="blue"
                isLoading={isLoading}
                disabled={!searchName.trim()}
                className={classes.searchButton}
              >
                Buscar
              </Button>
            </div>
          </FormControl>

          {drivers.length > 0 && (
            <FormControl className={classes.formCtlInput}>
              <Typography className={classes.messageTitle}>
                Selecionar Motorista
              </Typography>
              <Select
                id="driverSelect"
                name="driverSelect"
                value={selectedDriver ? selectedDriver.id : ""}
                onChange={(event) => handleDriverSelect(drivers.find(driver => driver.id === event.target.value))}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                {drivers.map(driver => (
                  <MenuItem key={driver.id} value={driver.id}>
                    {driver.name} - {`CPF: ${formatCPF(driver.cpf)}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl className={classes.formCtlInput}>
            <InputLabel htmlFor="cpf">CPF</InputLabel>
            <Input
              id="cpf"
              name="cpf"
              autoComplete="cpf"
              onChange={handleChange}
              value={cpf}
            />
          </FormControl>

          <FormControl className={classes.formCtlInput}>
            <Typography noWrap className={classes.messageTitle}>
              Tipo de Ocorrência
            </Typography>
            <Select
              id="type"
              name="type"
              value={type}
              onChange={handleChange}
              className={classes.selectInput}
              displayEmpty
            >
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {Object.keys(FILTER_LABEL_OCCURRENCE.category).map(key => (
                <MenuItem key={key} value={key}>
                  {FILTER_LABEL_OCCURRENCE.category[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.formCtlInput}>
            <Typography noWrap className={classes.messageTitle}>
              Digite a mensagem da ocorrência:
            </Typography>
            <textarea
              name="message"
              className={classes.messageArea}
              onChange={handleChange}
              value={message}
              rows={10}
              maxLength={400}
            />
          </FormControl>
        </div>

        <Button
          size="large"
          variant="contained"
          customColor="blue"
          onClick={notify}
          isLoading={isLoading}
          className={classes.submitButton}
        >
          Enviar Ocorrência
        </Button>
      </Paper>
    </main>
  );
};

CreateOccurrence.propTypes = {
  occurrenceBroadcastRequest: func.isRequired,
  isLoading: bool.isRequired,
  infoDeleteOffer: func,
  toggleCreateOccurrenceModal: func,
  message: func,
  type: func,
  cpf: func,
  boardUserName: func.isRequired,
};

export default CreateOccurrence;
