import React from 'react';

interface FooterProps {
  versionApp: string | null;
}

const Footer: React.FC<FooterProps> = ({ versionApp }) => {
  return (
    <footer style={footerStyle}>
      <p>Versão do Aplicativo: {versionApp}</p>
    </footer>
  );
};

const footerStyle: React.CSSProperties = {
  backgroundColor: '#f8f8f8',
  textAlign: 'center',
  padding: '10px 0',
  borderTop: '1px solid #e7e7e7',
  fontSize: '12px',
  marginTop: 'auto',
};

export default Footer;
