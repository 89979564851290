import { cloneDeep } from "lodash";

import {
  DRIVER_DETAIL_REQUEST,
  DRIVER_DETAIL_SUCCESS,
  DRIVER_DETAIL_FAILURE,
  DRIVER_DELETE_REQUEST,
  DRIVER_DELETE_SUCCESS,
  DRIVER_DELETE_FAILURE,
  DRIVER_UPDATE_REQUEST,
  DRIVER_UPDATE_FAILURE,
  DRIVER_APPROVE_REQUEST,
  DRIVER_APPROVE_SUCCESS,
  DRIVER_UPDATE_SUCCESS,
  DRIVER_APPROVE_FAILURE,
  DRIVER_BLOCK_REQUEST,
  DRIVER_BLOCK_SUCCESS,
  DRIVER_BLOCK_FAILURE,
  DRIVER_IMAGE_APPROVAL_REQUEST,
  DRIVER_IMAGE_APPROVAL_SUCCESS,
  DRIVER_IMAGE_APPROVAL_FAILURE,
  DRIVER_IMAGE_BLOCKING_REQUEST,
  DRIVER_IMAGE_BLOCKING_SUCCESS,
  DRIVER_IMAGE_BLOCKING_FAILURE,
  DRIVER_CLEAR_DETAIL_DATA
} from "../actions/actionsTypes";

const INITIAL_STATE = {
  loaded: false,
  isLoading: false,
  driverData: {}
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case DRIVER_CLEAR_DETAIL_DATA:
      return {
        ...state,
        isLoading: true,
        driverData: {},
        loaded: false,
      };

    case DRIVER_IMAGE_APPROVAL_REQUEST:
    case DRIVER_IMAGE_BLOCKING_REQUEST:
    case DRIVER_DETAIL_REQUEST:
    case DRIVER_DELETE_REQUEST:
    case DRIVER_UPDATE_REQUEST:
    case DRIVER_APPROVE_REQUEST:
    case DRIVER_BLOCK_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case DRIVER_DETAIL_SUCCESS:
      return {
        loaded: true,
        isLoading: false,
        driverData: cloneDeep(payload.user)
      };
    case DRIVER_UPDATE_SUCCESS:
    case DRIVER_APPROVE_SUCCESS:
    case DRIVER_BLOCK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        driverData: cloneDeep(payload.user)
      };

    case DRIVER_IMAGE_APPROVAL_SUCCESS:
    case DRIVER_IMAGE_BLOCKING_SUCCESS:
      const { photoPath, data } = payload;

      const [depth0, depth1] = photoPath.split(".");
      const newDriverData = cloneDeep(state.driverData);

      if (!depth1) {
        newDriverData[depth0].status = data[depth0].status;
        newDriverData[depth0].message = data[depth0].message;
      } else {
        if (Array.isArray(data[depth0][depth1])) {
          data[depth0][depth1].forEach(photo => {
            newDriverData[depth0][depth1][photo.index].status = photo.status;
            newDriverData[depth0][depth1][photo.index].message = photo.message;
          });
        } else {
          newDriverData[depth0][depth1].status = data[depth0][depth1].status;
          newDriverData[depth0][depth1].message = data[depth0][depth1].message;
        }
      }

      return {
        ...state,
        isLoading: false,
        driverData: newDriverData
      };

    case DRIVER_IMAGE_APPROVAL_FAILURE:
    case DRIVER_IMAGE_BLOCKING_FAILURE:
    case DRIVER_DETAIL_FAILURE:
    case DRIVER_DELETE_SUCCESS:
    case DRIVER_DELETE_FAILURE:
    case DRIVER_UPDATE_FAILURE:
    case DRIVER_APPROVE_FAILURE:
    case DRIVER_BLOCK_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
};
