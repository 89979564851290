import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestDriverList, clearDriversList, clearDriversFilters } from '../../actions/drivers';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '../Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/styles';
import { FILTER_LABEL_USER_STATUS } from '../../constants';
import styles from './styles';

function ModalTargetedUser({ classes, open, onClose }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchType, setSearchType] = useState('name');
  const [dataConfirmed, setDataConfirmed] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const drivers = useSelector(state => state.drivers.driversList);
  const isLoading = useSelector(state => state.drivers.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!open) {
      setSearchQuery('');
      setDataConfirmed(false);
      setSelectedDriver(null);
      dispatch(clearDriversList());
      dispatch(clearDriversFilters());
    }
  }, [open, dispatch]);

  const handleSearch = () => {
    handleClear();
    dispatch(requestDriverList({ filters: { [searchType]: searchQuery } }));
  };

  const handleDriverSelect = (driver) => {
    setSelectedDriver(driver);
  };

  const handleSave = () => {
    if (dataConfirmed && selectedDriver) {
      onClose(selectedDriver);
    }
  };

  const handleClear = () => {
    setSearchQuery('');
    setDataConfirmed(false);
    setSelectedDriver(null);
    dispatch(clearDriversList());
    dispatch(clearDriversFilters());
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={() => handleClear() && onClose(null)}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle className={classes.title}>
        Dados do Motorista
      </DialogTitle>
      <DialogActions className={classes.actions}>
        <Select
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
          displayEmpty
          className={classes.select}
        >
          <MenuItem value="name">Nome</MenuItem>
          <MenuItem value="cpf">CPF</MenuItem>
        </Select>
        <TextField
          required
          variant="filled"
          label={`Buscar por ${searchType === 'name' ? 'Nome' : 'CPF'}:`}
          id="searchQuery"
          name="searchQuery"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className={classes.textField}
          InputLabelProps={{ shrink: true }}
        />
        <Button
          className={classes.button}
          variant="contained"
          size="large"
          disabled={searchQuery.trim() === '' || isLoading}
          onClick={handleSearch}
        >
          Buscar
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          size="large"
          disabled={!dataConfirmed || !selectedDriver || isLoading}
          onClick={handleSave}
        >
          Salvar
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          size="large"
          onClick={handleClear}
        >
          Limpar
        </Button>
        <IconButton
          onClick={() => {
            handleClear();
            onClose(null);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.content}>
        {isLoading && <CircularProgress className={classes.loading} />}
        {drivers.length > 0 && !selectedDriver && (
          <List className={classes.driverList}>
            {drivers.map((driver) => (
              <ListItem
                button
                key={driver.id}
                onClick={() => handleDriverSelect(driver)}
              >
                <ListItemText
                  primary={`${driver.name} - CPF: ${driver.cpf}`}
                />
              </ListItem>
            ))}
          </List>
        )}
        {drivers.length === 0 && !isLoading && (
          <Typography className={classes.noDrivers}>
            Nenhum motorista encontrado
          </Typography>
        )}
        {selectedDriver && (
          <div className={classes.driverData}>
            <Checkbox
              checked={dataConfirmed}
              onChange={(e) => setDataConfirmed(e.target.checked)}
              color="primary"
            />Confirmo que os dados estão corretos
            {selectedDriver.status !== 'APPROVED' && (
              <p className={classes.alerts}>
                * O motorista deve ter o cadastro aprovado para receber fretes *
              </p>
            )}
            <p>Nome: <strong>{selectedDriver.name || 'Não informado'}</strong></p>
            <p>CPF: <strong>{selectedDriver.cpf || 'Não informado'}</strong></p>
            <p>Fretes em Andamento: <strong>{selectedDriver.requestedFreightsInProgress || '0'}</strong></p>
            <p>Fretes Concluídos: <strong>{selectedDriver.requestedFreightsFinished || '0'}</strong></p>
            <p>Fretes Direcionados em Andamento: <strong>{selectedDriver.targetedFreightsInProgress || '0'}</strong></p>
            <p>Fretes Direcionados Concluídos: <strong>{selectedDriver.targetedFreightsFinished || '0'}</strong></p>
            <p>Telefone: <strong>{selectedDriver.phoneNumber || 'Não informado'}</strong></p>
            <p>Status: <strong>{FILTER_LABEL_USER_STATUS[selectedDriver.status] || 'Não informado'}</strong></p>
            <p>Validado: <strong>{selectedDriver.validated ? "Sim" : "Não"}</strong></p>
            <p>Tipo de Veículo: <strong>{selectedDriver.vehicle?.type || 'Não informado'}</strong></p>
            <p>Tipo de Carroceria: <strong>{selectedDriver.vehicle?.typeByBoard || 'Não informado'}</strong></p>
            <p>Carteira: <strong>{selectedDriver.wallet?.type || 'Não informado'}</strong></p>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default withStyles(styles)(ModalTargetedUser);
