const styles = (theme) => ({
  paper: {
    width: '514px',
    height: '326px',
  },
  title: {
    color: '#fff',
    height: '88px',
    backgroundColor: '#2B596F',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: '24px',
    padding: '24px',
  },
  content: {
    paddingTop: theme.spacing(6),
    fontSize: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > div': {
      marginBottom: theme.spacing(1)
    }
  },
  actions: {
    justifyContent: 'center',
    paddingBottom: theme.spacing(6),
  },
  input: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#2B596F',
      },
      '&:hover fieldset': {
        borderColor: '#2B596F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2B596F',
      },
    },
  },
});

export default styles;