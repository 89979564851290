export const register = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  fetch('/manifest.json')
                    .then(response => response.json())
                    .then(data => console.log(data.version))
                    .then(manifest => {
                      const currentVersion = sessionStorage.getItem('appVersion');
                      if (currentVersion !== manifest.version) {
                        sessionStorage.setItem('appVersion', manifest.version);
                        window.location.reload(true);
                      }
                    });
                }
              }
            };
          };
        })
        .catch(registrationError => {
          console.log(registrationError);
        });
    });
  }
};
