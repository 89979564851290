import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import VersionChecker, { useVersionChecker } from '../components/VersionChecker/VersionChecker';
import DriverDetail from '../components/DriverDetail';
import {
  requestDriver,
  requestDeleteDriver,
  requestUpdateDriver,
  requestDriverApproval,
  requestDriverBlocking,
  requestDriverImageBlocking,
  requestDriverImageApproval,
  clearDriverData,
} from '../actions/driverDetail';

const DriverDetailScreen = ({ match }) => {
  useVersionChecker('DriverDetailScreen');
  const dispatch = useDispatch();
  const driverDetail = useSelector((state) => state.driverDetail);
  const boardUserName = useSelector((state) => state.auth.user.name);

  const fetchDriverData = () => {
    const { id } = match.params;
    dispatch(requestDriver(id));
  };

  useEffect(() => {
    fetchDriverData(); // eslint-disable-next-line
  }, [match.params.id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearDriverData());
    };
  }, [dispatch]);

  return (
    <main className="main-content">
      <VersionChecker />
      {!driverDetail.loaded ? (
        <CircularProgress />
      ) : (
        <DriverDetail
          boardUserName={boardUserName}
          driver={driverDetail.driverData}
          requestDriver={requestDriver}
          requestDeleteDriver={requestDeleteDriver}
          requestUpdateDriver={requestUpdateDriver}
          requestDriverApproval={requestDriverApproval}
          requestDriverBlocking={requestDriverBlocking}
          requestDriverImageBlocking={requestDriverImageBlocking}
          requestDriverImageApproval={requestDriverImageApproval}
          clearDriverData={clearDriverData} />
      )}
    </main>
  );
};

export default DriverDetailScreen;
