import React from "react";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import SearchIcon from "@material-ui/icons/Search";

import { FILTER_LABEL_OCCURRENCE } from "../../constants";
import styles from "./style";
import Filter from "../Filter";
import FilterSearch from "../Filter/FilterSearch";
import FilterSelect from "../Filter/FilterSelect";

const OccurrenceFilterComponent = props => {
  const { classes } = props;

  const selectLabelClasses = {
    root: classes.selectLabel,
    focused: classes.focused
  };

  return (
    <Filter
      disableClear={props.disableClear}
      disableRefresh={props.disableRefresh}
      handleClearFilter={props.handleClearFilters}
      handleRefresh={props.handleRefresh}
    >
      <FilterSearch>
        <FormControl
          fullWidth
          htmlFor="message"
          className={clsx(classes.formCtlInput, classes.searchNameContainer)}
        >
          <Input
            autoFocus
            id="message"
            name="message"
            autoComplete="message"
            placeholder="Busca de ocorrências"
            onChange={props.handleFilterChange}
            onKeyDown={props.handleSerchNameKeyDown}
            value={props.message}
            className={clsx(classes.input, classes.searchName)}
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  aria-label="Pesquisa"
                  onClick={props.clearAndSearch}
                  className={classes.iconButton}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </FilterSearch>

      <FilterSelect>
        <div className={classes.filterList}>
          <FormControl className={classes.selectRoot}>
            <InputLabel htmlFor="type" classes={selectLabelClasses}>
              Categoria
            </InputLabel>

            <Select
              value={props.type}
              onChange={props.handleFilterChange}
              className={classes.input}
              inputProps={{ id: "type", name: "type" }}
            >
              {Object.entries(FILTER_LABEL_OCCURRENCE.category).map(
                (option, i) => (
                  <MenuItem key={i} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>

          <FormControl className={classes.selectRoot}>
            <InputLabel htmlFor="status" classes={selectLabelClasses}>
              Status
            </InputLabel>

            <Select
              value={props.status}
              onChange={props.handleFilterChange}
              className={classes.input}
              inputProps={{ id: "status", name: "status" }}
            >
              {Object.entries(FILTER_LABEL_OCCURRENCE.status).map(
                (option, i) => (
                  <MenuItem key={i} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </div>
      </FilterSelect>
    </Filter>
  );
};

export default withStyles(styles)(OccurrenceFilterComponent);
