const styles = (theme) => ({
  managementContainer: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  createButton: {
    backgroundColor: 'rgb(26, 91, 118)',
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1, 1),
    margin: theme.spacing(2, 0),
    borderRadius: "5px",
    '&:hover': {
      backgroundColor: 'rgba(26, 91, 118, 0.8)',
    },
  },
  loadingOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.h6.fontSize,
    zIndex: 1000,
  },
});

export default styles;