export const FREIGHT_STATUS = {
  WAITING: 'WAITING',
  CANCELED: 'CANCELED',
  DRIVER_SELECTED: 'DRIVER_SELECTED',
  APPROVED: 'APPROVED',
  PICKUP_ORDER_SENT: 'PICKUP_ORDER_SENT',
  DRIVER_ARRIVED: 'DRIVER_ARRIVED',
  LOADING_STARTED: 'LOADING_STARTED',
  LOADING_FINISHED: 'LOADING_FINISHED',
  INVOICE_SENT: 'INVOICE_SENT',
  DELIVERY_STARTED: 'DELIVERY_STARTED',
  DELIVERY_FINISHED: 'DELIVERY_FINISHED',
  UNLOADING_STARTED: 'UNLOADING_STARTED',
  UNLOADING_FINISHED: 'UNLOADING_FINISHED',
  INVOICE_COUPON_SENT: 'INVOICE_COUPON_SENT',
  INVOICE_COUPON_REFUSED: 'INVOICE_COUPON_REFUSED',
  FINISHED: 'FINISHED',
};

export const FREIGHT_USER_STATUS = {
  WAITING: 'WAITING',
  USER_ORDER_SENT: 'USER_ORDER_SENT',
  USER_ORDER_SENT_REFUSED: 'USER_ORDER_SENT_REFUSED',
  FINISHED: 'FINISHED',
};

export const ORDER_USER_STATUS = {
  USER_ORDER_SENT: 'USER_ORDER_SENT',
  USER_ORDER_SENT_REFUSED: 'USER_ORDER_SENT_REFUSED',
  ADVANCE_PAYMENT: 'ADVANCE_PAYMENT',
  FINISHED: 'FINISHED',
};

export const REQUEST_FREIGHT_STATUS = {
  WAITING: 'WAITING',
  CANCELED: 'CANCELED',
  NEGATED: 'NEGATED',
  DENIED: 'DENIED',
  COMPLETED: 'COMPLETED',
};
