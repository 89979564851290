import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Register from './RegisterScreen';
import VersionChecker, { useVersionChecker } from '../components/VersionChecker/VersionChecker';

const ConfigScreen = (props) => {
  useVersionChecker('ConfigScreen');

  return (
    <main className="main-content">
      <VersionChecker />
      <Register />
    </main>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(null, mapDispatchToProps)(ConfigScreen);
