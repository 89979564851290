import React from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 28px;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 48px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const Button = styled.button`
  padding: 8px;
  background-color: rgb(26, 91, 118);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 48px;
  min-width: 70px;

  &:hover {
  background-color: rgba(26, 90, 118, 0.748);
  border: 1px solid #000000b5;
  color: #000000b5;
  }

  @media (max-width: 768px) { 
    max-width: 88px;
  }
`;

const ClearButton = styled(Button)`
  background-color: #dc3545;

  &:hover {
  background-color: rgba(220, 53, 70, 0.734);
  border: 1px solid #000000b5;
  color: #000000b5;
  }
`;

const BoardUserSearch = ({ filterName, setFilterName, filterValue, setFilterValue, handleSearch, handleOpenModal }) => {
  const handleSearchClick = () => {
    handleSearch(`${filterName}=${filterValue}`);
  };

  const handleClearSearch = () => {
    setFilterName('name');
    setFilterValue('');
    handleSearch('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  return (
    <SearchContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <label style={{ paddingRight: "8px" }}>
          <input
            type="radio"
            value="name"
            checked={filterName === 'name'}
            onChange={() => setFilterName('name')}
          />
          Nome
        </label>
        <label style={{ paddingRight: "8px" }}>
          <input
            type="radio"
            value="email"
            checked={filterName === 'email'}
            onChange={() => setFilterName('email')}
          />
          Email
        </label>
        <label style={{ paddingRight: "8px" }}>
          <input
            type="radio"
            value="profile"
            checked={filterName === 'profile'}
            onChange={() => setFilterName('profile')}
          />
          Perfil
        </label>
      </div>

      <Input
        type="text"
        placeholder="Filtrar usuários..."
        value={filterValue}
        onChange={(e) => setFilterValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <ButtonsContainer>
        <Button onClick={handleSearchClick}>Buscar</Button>
        <ClearButton onClick={handleClearSearch}>Limpar</ClearButton>
        <Button onClick={handleOpenModal} style={{ minWidth: "120px" }}>Criar Usuário</Button>
      </ButtonsContainer>
    </SearchContainer>
  );
};

export default BoardUserSearch;
