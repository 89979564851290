import asideHeaderbackground from '../../assets/image/fretador-fundo-small.png';
import {
  btnRed,
  btnRedHover,
  btnGreen,
  btnGreenHover,
  btnBlue,
  btnBlueHover,
  btnBlueDisabled,
  COLOR_DEFAULT,
} from '../../globalTheme'
const asideHeaderbackgroundColor = '#1A5B76';

export default (theme) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    paper: {
      display: 'flex',
      width: '100%',
      maxWidth: '1024px',
      height: '100%',
      marginTop: theme.spacing(1) * 2,
      padding: 0,
      '@media (max-width: 600px)': {
        flexDirection: 'column',
        overflow: 'initial',
      },
    },
    driverDataContainer: {
      width: '40%',
      minWidth: '400px',
      '@media (max-width: 600px)': {
        width: '100%',
        display: 'block',
      },
    },
    asideHeader: {
      backgroundImage: `url(${asideHeaderbackground})`,
      backgroundSize: '120%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: asideHeaderbackgroundColor,
      height: '60px',
    },
    asideHeaderIcon: {
      width: '50px',
      textAlign: 'center',
      lineHeight: 0,
      color: '#FFF',
    },
    asideHeaderText: {
      textTransform: 'uppercase',
      fontWeight: 600,
      color: '#FFF',
    },
    driverRow: {
      display: 'flex',
      padding: '2px',
    },
    driverRowIcon: {
      backgroundColor: '#EFEFEF',
      width: '48px',
      height: '48px',
      textAlign: 'center',
      lineHeight: '62px',
      color: '#1A5B76',
    },
    editContent: {
      position: 'absolute',
      right: '0',
      top: '4px',
    },
    editIcon: {
      color: '#FFF',
      backgroundColor: COLOR_DEFAULT,
    },
    driverRowContent: {
      marginLeft: theme.spacing(1),
      width: '90%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    accountText: {
      color: '#707070',
      fontSize: '13px',
      '& > span': {
        fontWeight: 600,
      },
    },
    driverRowContentTitle: {
      fontSize: '12px',
      color: '#1A5B76',
      fontWeight: 600,
      margin: '2px 0 2px 0',
    },
    driverRowContentText: {
      color: '#707070',
    },
    driverImagesContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '60%',

      minWidth: '520px',
      '@media (max-width: 768px)': {
        width: '100%',
        display: 'block',
        height: '180px',
      },
    },
    imagesSection: {
      backgroundColor: '#d4d4d4',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: theme.spacing(1) * 2,
      '&:nth-child(odd)': {
        backgroundColor: '#ebebeb',
      },
    },
    imagesSectionTitle: {
      textTransform: 'uppercase',
      fontSize: '12px',
      color: '#1A5B76',
      fontWeight: 600,
    },
    imagesContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
      height: 137,
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
    },
    actionButton: {
      width: 174,
      fontSize: '0.697rem',
      lineHeight: `${theme.spacing(2)}px`,
      minHeight: '47px',
    },
    modalHeaderContainer: {
      backgroundImage: `url(${asideHeaderbackground})`,
      backgroundSize: '120%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: asideHeaderbackgroundColor,
    },
    modalButtonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    buttonModalCancel: {
      width: 100,
      backgroundColor: btnRed,
      '&:hover': {
        backgroundColor: btnRedHover,
      },
    },
    modalDocument: {
      margin: '0 auto',
      width: '80%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1) * 4,
      outline: 'none',
    },
    imageDocument: {
      width: '100%',
      '&:hover': {
        // overflow: 'hidden',
        // transform: 'scale(2)',
        // opacity: 1,
        // left: -574,
        // top: -117
      },
    },
    modalDocButtonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    modalDocContent: {
      flexDirection: 'column',
      marginBottom: 20,
    },
    modalDocImageTitle: {
      fontWeight: 900,
      fontSize: '0.9rem',
      marginBottom: 10,
      color: btnBlue,
    },
    modalDocVoltar: {
      fontSize: '1rem',
      marginRight: 35,
      height: 42,
      backgroundColor: btnBlue,
      '&:hover': {
        backgroundColor: btnBlueHover,
      },
    },
    modalDocOk: {
      backgroundColor: btnRed,
      '&:hover': {
        backgroundColor: btnRedHover,
      },
    },
    modalDocButton: {
      width: 230,
    },
    modalDocCancel: {
      fontSize: '1rem',
      marginRight: 35,
      height: 42,
      backgroundColor: btnRed,
      '&:hover': {
        backgroundColor: btnRedHover,
      },
    },
    buttonModalOk: {
      width: 100,
      backgroundColor: btnGreen,
      '&:hover': {
        backgroundColor: btnGreenHover,
      },
    },
    buttonModal: {
      padding: 10,
      fontSize: '0.85rem',
      height: 45,
      fontWeight: 750,
    },
    modalContentRoot: {
      padding: 12,
    },
    modalContentText: {
      marginTop: 10,
    },
    modalDocProsecute: {
      backgroundColor: btnBlue,
      '&:hover': {
        backgroundColor: btnBlueHover,
      },
      '&:disabled': {
        backgroundColor: btnBlueDisabled,
        color: '#FFF',
      },
    },
    modalDocReasonField: {
      marginBottom: 10,
      border: '2px solid #ccc',
      padding: '5px 10px 0',
    },
    askNewImageReasonLabel: {
      fontSize: '0.8rem',
    },
    driverImageRedBorder: {
      '& > img': {
        border: 'solid 3px #A33C3C !important',
      },
      '& > p': {
        color: '#A33C3C !important',
      },
    },
    waitingResendContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
      justifyContent: 'center',
    },
    waitingResendLabel: {
      fontSize: '0.85rem',
      color: btnRed,
      textTransform: 'uppercase',
      fontWeight: 800,
      paddingBottom: 10,
    },
    waitingResendBtnVoltar: {
      fontSize: '1rem',
      height: 42,
      backgroundColor: btnBlue,
      '&:hover': {
        backgroundColor: btnBlueHover,
      },
    },
    editFieldsSelect: {
      width: '90%',
    },
    editFieldsInput: {
      marginTop: -2,
      marginBottom: 0,
      width: '90%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      width: '90%',
    },
    asideRow: {
      height: '52px',
      '&:nth-child(2)': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    asideRowFull: {
      height: '130px',
      '&:nth-child(2)': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    iconSize: {
      fontSize: '2rem',
      height: 48,
    },
    fileInput: {
      display: 'none',
    },
    editModalSelect: {
      width: '100%',
    },
    editModalBtnUp: {
      margin: '10px 0',
    },
    formCtlInput: {
      width: '49%',
    },
    buttonConfirm: {
      fontSize: '12px',
      textAlign: 'center',
      fontWeight: 700,
      backgroundColor: '#FFF',
      color: COLOR_DEFAULT,
      border: '2px solid #1B556D',
    }
  }
};
