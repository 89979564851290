import React from 'react';
import { cloneDeep } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import Truck from '@material-ui/icons/LocalShipping';
import Calendar from '@material-ui/icons/DateRange';
import Dolar from '@material-ui/icons/AttachMoney';
import TimeToLeave from '@material-ui/icons/TimeToLeave';
import { Store } from '@material-ui/icons';
import moment from 'moment-timezone';
import { Link, withRouter } from 'react-router-dom';
import api from '../../api';
import styles from './styles';
import { endOfDay, startOfDay } from 'date-fns/esm';
import { Weight, PackageFilled, AsteriskFilled, GatheringPlace, DeliveryPlace } from '../Icons';
import { downloadFromBuffer, formatCoordinate, formatDate, formatHour, getFromAWS, WeightInput, formatWeight, DateInput, formatCurrency, parseCurrency } from '../../utils/utils';
import { getLatitudeLongitudePromise, getOneDistancePromise } from '../../googleApiUtils';
import { ELEGIBLE_VEHICLES, ELEGIBLE_TYPEBYBOARDS } from '../../constants';
import { FREIGHT_USER_STATUS } from '../../utils/freightUtils';
import Modal from '../Modal';
import ModalConfirm from '../ModalConfirmation';
import { AsideHeader, AsideRow } from '../Aside';
import FreightProgress from '../FreightProgress';
import SelectGroups from '../SelectGroups';
import CreateOccurrence from '../CreateOccurrence';
import OthersDocuments from '../OthersDocuments';
import FreightDirections from '../FreightDirections';

class FreightDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      freight: {},
      localFreight: {},
      editingOSCode: false,
      editingWeight: false,
      editingPackage: false,
      editingCalendar: false,
      editingTruck: false,
      editingTypeByBoard: false,
      editingValue: false,
      editingObservation: false,
      selectedDriver: null,
      isModalOpen: {
        duplicate: false,
        delete: false,
        closeBatch: false,
        advancedRequestedPaymentDate: false,
      },
      newGatheringDeadline: null,
      newDeliveryDeadline: null,
      interval: null,
      photoModal: {
        isOpen: false,
        photo: {},
        photoName: '',
        label: '',
      },
      isCreateOccurrenceOpen: false,
      addressModalTarget: 'gathering',
      gatheringResume: '',
      gatheringAddress: '',
      gatheringState: '',
      gatheringCity: '',
      gatheringCep: '',
      gatheringLatitude: '',
      gatheringLongitude: '',
      deliveryResume: '',
      deliveryAddress: '',
      deliveryState: '',
      deliveryCity: '',
      deliveryCep: '',
      deliveryLatitude: '',
      deliveryLongitude: '',
      preservAll: false,
      preservGathering: false,
      preservDelivery: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.freight.status === props.freight.status) {
      return null;
    }

    let selectedDriver = null;
    if (props.freight.driver) {
      selectedDriver = props.freight.driver;
    }

    let gatheringResume = '';
    let deliveryResume = '';

    if (props.freight?.gatheringAddress) {
      gatheringResume = `${props.freight?.gatheringAddress} - ${props.freight?.gatheringCity} - ${props.freight?.gatheringCity}/${props.freight?.gatheringState}`;
    } else if (props.freight?.gatheringCity) {
      gatheringResume = `${props.freight?.gatheringCity} - ${props.freight?.gatheringState}`;
    }

    if (props.freight?.deliveryAddress) {
      deliveryResume = `${props.freight?.deliveryAddress} - ${props.freight?.deliveryCity} - ${props.freight?.deliveryCity}/${props.freight?.deliveryState}`;
    } else if (props.freight?.deliveryCity) {
      deliveryResume = `${props.freight?.deliveryCity} - ${props.freight?.deliveryState}`;
    }

    return {
      ...state,
      freight: { ...props.freight },
      localFreight: { ...props.freight },
      gatheringResume,
      deliveryResume,
      clientName: props.freight?.clientName,
      OSCode: props.freight?.OSCode,
      licensePlate: props.freight?.licensePlate,
      selectedDriver,
    };
  }

  componentDidMount() {
    const { freightId } = this.props;
    if (this.state.freight.updateAcknowledge !== true) {
      api.patch(`/freight/${freightId}`, { updateAcknowledge: true }).catch((err) => {
        console.error('ERRO ao atualizar dado de vizualização do frete', err.message);
      });
    }
    this.configInterval();
  }

  componentWillUnmount() {
    this.configInterval('clear');
    this.setState({});
  }

  configInterval = (action) => {
    const { interval: stateInterval } = this.state;
    if (stateInterval) {
      clearInterval(stateInterval);
    }

    const interval = setInterval(() => {
      const { requestFreight, freightId } = this.props;
      requestFreight(freightId);
    }, 10000);

    if (action === 'clear') {
      clearInterval(interval);
    }

    this.setState({ interval });
  };

  handleDateChange = (property, date) => {
    const newDate = property === 'deliveryDeadline' ? endOfDay(date) : startOfDay(date);
    this.setState((prevState) => ({
      localFreight: {
        ...prevState.localFreight,
        [property]: newDate,
      },
    }), () => {
      this.updateFreightHandler(property, newDate);
    });
  };

  updateFreightHandler = async (type, value) => {
    const { localFreight } = this.state;
    const newFreight = cloneDeep(localFreight);

    const data = {};

    if (type) data[type] = value;

    if (type === 'value') {
      try {
        data[type] = parseCurrency(value);
      } catch (e) {
        console.error(e.message);
        return;
      }
    }
    
    if (type === 'packageWeight') {
      try {
        data[type] = parseFloat(
          value.toString().replace(/\./g, '').replace(',', '.').replace('Kg', ''),
        );
      } catch (e) {
        console.error(e.message);
        return;
      }
    }

    if (type === 'gatheringDeadline' || type === 'deliveryDeadline') {
      data[type] = moment(value).tz('America/Sao_Paulo').format();
    }

    if (type === 'advanceRequestedDate') {
      data[type] = new Date();
    }

    if (type === 'elegibleVehicles') {
      data[type] = localFreight.elegibleVehicles;
    }
    
    if (type === 'elegibleTypeByBoards') {
      data[type] = localFreight.elegibleTypeByBoards;
    }

    if (type === 'requestedPhotoResend') {
      data[type] = true;
    }

    if (type === 'advancedRequestedPaymentDate') {
      data[type] = new Date();
    }

    if (type === 'paymentSuccessfulBalance') {
      data[type] = new Date();
    }

    if (type === 'deliveryLocation' || type === 'gatheringLocation') {
      const origin = value.gatheringAddress
        ? `${value.gatheringAddress} - ${value.gatheringCity}/${value.gatheringState}`
        : `${localFreight.gatheringAddress} - ${localFreight.gatheringCity}/${localFreight.gatheringState}`;

      const delivery = value.deliveryAddress
        ? `${value.deliveryAddress} - ${value.deliveryCity}/${value.deliveryState}`
        : `${localFreight.deliveryAddress} - ${localFreight.deliveryCity}/${localFreight.deliveryState}`;

      if (type === 'gatheringLocation') {
        data.gatheringAddress = value.gatheringAddress;
        data.gatheringCep = value.gatheringCep;
        data.gatheringCity = value.gatheringCity;
        data.gatheringState = value.gatheringState;

        const result = await getLatitudeLongitudePromise(origin);

        data.gatheringLongitude = formatCoordinate(result.longitude);
        data.gatheringLatitude = formatCoordinate(result.latitude);

        delete data.gatheringLocation;
      }

      if (type === 'deliveryLocation') {
        data.deliveryAddress = value.deliveryAddress;
        data.deliveryCep = value.deliveryCep;
        data.deliveryCity = value.deliveryCity;
        data.deliveryState = value.deliveryState;

        const result = await getLatitudeLongitudePromise(origin);

        data.deliveryLatitude = formatCoordinate(result.latitude);
        data.deliveryLongitude = formatCoordinate(result.longitude);

        delete data.deliveryLocation;
      }

      const result = await getOneDistancePromise(origin, delivery);

      if (result.distance?.value) {
        data.travelDistance = result.distance?.value / 1000;
      }
    }

    const { update } = this.props;
    update({ data, freight: newFreight });

    this.setState({ localFreight: newFreight });
  };

  inputHandler = (e, property) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      localFreight: {
        ...prevState.localFreight,
        [property]: value,
      },
    }));
  };

  duplicateOffer = (freight) => {
    const { newGatheringDeadline, newDeliveryDeadline } = this.state;

    if (!newGatheringDeadline || !newDeliveryDeadline) {
      alert('Por favor, selecione ambas as datas de coleta e entrega.');
      return;
    }

    this.modalHandler('duplicate');

    const newFreight = cloneDeep(freight);
    delete newFreight.id;
    delete newFreight.active;
    delete newFreight.interestedUsers;
    delete newFreight.requestingUsers;
    delete newFreight.creationDate;
    delete newFreight.updateDate;
    delete newFreight.status;
    delete newFreight.statusHistory;
    delete newFreight.shortStatusHistory;
    delete newFreight.deliveryDate;
    delete newFreight.gatheringDate;
    delete newFreight.driver;
    delete newFreight.invoicePhoto;
    delete newFreight.invoiceCouponPhotos;
    delete newFreight.pickupOrderPhoto;
    delete newFreight.refusedInvoiceCouponPhotos;
    delete newFreight.advanceRequestedDate;
    delete newFreight.OSCode;
    delete newFreight.userImages;
    delete newFreight.clientName;
    delete newFreight.licensePlate;
    delete newFreight.invoiceCouponRejectedPhotos;
    delete newFreight.paymentSuccessfulBalance;
    delete newFreight.advancedRequestedPaymentDate;
    delete newFreight.otherDocuments;
    delete newFreight.invoiceCouponPhotosHistory;
    delete newFreight.invoiceCouponDocs;

    newFreight.gatheringDeadline = newGatheringDeadline;
    newFreight.deliveryDeadline = newDeliveryDeadline;
    newFreight.freightType = "OFFER";

    const { duplicateOffer, history } = this.props;
    duplicateOffer({ ...newFreight }, history);
  };

  deleteOffer = (id) => {
    const { deleteOffer, history } = this.props;
    deleteOffer(id, history);
  };

  infoDeleteOffer = (id) => {
    const { updateStatus, freight, history, boardUser } = this.props;
    const { token, ...userWithoutToken } = boardUser;
    const dataToSend = {
      boardUser: userWithoutToken,
    };
    updateStatus('CANCELED', freight.id, dataToSend);
    history.push('/offers');
  };

  closeBatch = () => {
    const { updateStatus, freight, history } = this.props;
    updateStatus('FINISHED', freight.id, {}, history);
  };

  handleVehiclesChange = (values) => {
    this.setState((prevState) => ({
      ...prevState,
      localFreight: {
        ...prevState.localFreight,
        elegibleVehicles: values,
      },
    }));
  };

  handleTypeByBoardsChange = (values) => {
    this.setState((prevState) => ({
      ...prevState,
      localFreight: {
        ...prevState.localFreight,
        elegibleTypeByBoards: values,
      },
    }));
  };

  stopEditingTruck = () => {
    this.setState(
      {
        editingTruck: false,
        isEditing: false,
      },
      () => {
        this.updateFreightHandler('elegibleVehicles', this.state.localFreight.elegibleVehicles);
      },
    );
  };

  stopEditingTypeByBoard = () => {
    this.setState(
      {
        editingTypeByBoard: false,
        isEditing: false,
      },
      () => {
        this.updateFreightHandler('elegibleTypeByBoards', this.state.localFreight.elegibleTypeByBoards);
      },
    );
  };

  modalHandler = (type) => {
    if (!this.state.isModalOpen[type]) {
      this.configInterval('clear');
    } else {
      this.configInterval();
    }

    const modal = { ...this.state.isModalOpen };
    modal[type] = !this.state.isModalOpen[type];
    this.setState({ isModalOpen: modal });
  };

  selectUser = (user, action, status) => {
    if (action === 'select') {
      this.props.updateStatus(status, this.props.freight.id, {
        userId: user.id,
      });
      return this.setState({
        selectedDriver: user.id,
      });
    }
    this.props.updateStatus(status, this.props.freight.id, {});
    return this.setState({ selectedDriver: null });
  };

  validateInput = (e, type, editingType) => {
    let value = e;
    if (e.target) value = e.target.value;

    if (type === 'gatheringDeadline' || type === 'deliveryDeadline' || type === 'elegibleVehicles' || type === 'elegibleTypeByBoards') {
      this.updateFreightHandler(type, value);
      const editing = { ...this.state };
      editing[editingType] = false;
      editing.isEditing = false;
      this.setState({ ...editing });
    } else {
      if (e.key === 'Enter') {
        if (this.state.localFreight[type] !== '') {
          this.updateFreightHandler(type, value);
          const editing = { ...this.state };
          editing[editingType] = false;
          editing.isEditing = false;
          this.setState({ ...editing });
        } else {
          e.target.focus();
        }
      }
    }
  };

  clearDate = () => {
    this.setState((prevState) => ({
      localFreight: {
        ...prevState.localFreight,
        deliveryDeadline: null,
        gatheringDeadline: null
      }
    }));
  };

  clearModalPhoto = () => {
    this.setState((prevState) => ({
      photoModal: {
        freightUserImages: [],
        userImageIndex: undefined,
        isOpen: false,
        label: '',
        photoName: '',
        photo: {},
      },
    }));
  };

  closePhotoModal = () => {
    this.setState((prevState) => ({
      photoModal: {
        ...prevState.photoModal,
        isOpen: false,
      },
    }));
  };

  openPhotoModal = (photoIndex) => (event) => {
    const { userImages } = this.props.freight;
    const modalProps = { isOpen: true };

    modalProps.freightUserImages = this.props.freight.userImages;
    modalProps.freight = this.props.freight;
    modalProps.userImageIndex = photoIndex;
    modalProps.photoName = 'userPhoto';
    modalProps.label = 'Foto do usuário';
    modalProps.photo = userImages[photoIndex];

    this.setState({ photoModal: modalProps });
  };

  handleChange = (event) => {
    const { name, type, value, checked } = event.target;

    this.setState((prevState) => ({
      localFreight: {
        ...prevState.localFreight,
        [name]: type === 'checkbox' ? checked : value,
      },
    }));
  };

  handleDownloadPhoto = async () => {
    if (!this.state.photoModal.photo) return;

    this.props.enqueueSnackbar({
      message: 'Iniciando download...',
      options: { variant: 'info' },
    });

    try {
      const arr = this.state.photoModal.photo.imageUrl.split('/');
      const resp = await getFromAWS(arr[arr.length - 1]);
      const jsonResp = JSON.parse(resp.data.body);
      downloadFromBuffer(arr[arr.length - 1], jsonResp.Body.data, jsonResp.ContentType);
    } catch (e) {
      try {
        await this.props.enqueueSnackbar({
          message: 'Tamanho de image incompatível. Utilize a opção "Salvar como" do navegador.',
          options: { variant: 'info' },
        });
        setTimeout(() => {
          const link = document.createElement('a');
          link.href = this.state.photoModal.photo.imageUrl;
          link.target = '_blank';
          link.click();
          link.remove();
        }, 1500);
      } catch (e) {
        this.props.enqueueSnackbar({
          message: 'Não foi possível fazer o download do documento. Tente novamente.',
          options: { variant: 'error' },
        });
      }
    }
  };

  handleDocumentsChange = (documents) => {
    this.setState((prevState) => ({
      ...prevState,
      localFreight: {
        ...prevState.localFreight,
        otherDocuments: documents,
      },
    }));
  };

  toggleCreateOccurrenceModal = () => {
    this.setState((prevState) => ({
      isCreateOccurrenceOpen: !prevState.isCreateOccurrenceOpen,
    }));
  };

  getLastLocation() {
    const { freightLocation, freight } = this.props;
    if (!freight) return { location: [null, null] };
    if (!freightLocation || !freightLocation[0] || !freightLocation[0].location)
      return { location: [null, null] };
    return freightLocation[0];
  }

  render() {
    const { classes, freight } = this.props;
    const { driver } = freight;
    const { isCreateOccurrenceOpen, localFreight } = this.state;

    const inputClasses = {
      root: classes.modelInput,
    };

    const dateInputProps = {
      classes: inputClasses,
    };

    return (
      <>
        {isCreateOccurrenceOpen && (
          <div className={classes.modalOverlay}>
            <div className={classes.modalOccurrenceContent}>
              <CreateOccurrence
                cpf={freight.requestingUsers[driver].cpf ? freight.requestingUsers[driver].cpf : ''}
                type={"FREIGHT_CANCELLATION"}
                message={`Infelizmente o frete #${this.props.freight.freightCode} foi cancelado pelo cliente.`}
                infoDeleteOffer={this.infoDeleteOffer}
                toggleCreateOccurrenceModal={this.toggleCreateOccurrenceModal}
                isLoading={this.props.isOccurrenceLoading}
                occurrenceBroadcastRequest={this.props.occurrenceBroadcastRequest}
                enqueueSnackbar={this.props.enqueueSnackbar}
                handleRefresh={this.props.handleRefresh}
              />
            </div>
          </div>
        )}
        {this.state.isModalOpen.duplicate && (
          <Modal modalHandler={this.modalHandler}>
            <div className={classes.modal}>
              <div className={classes.modalHeader}>
                <Typography>DEFINA NOVAS DATAS DE COLETA E ENTREGA</Typography>
              </div>
              <div className={classes.modalContent}>
                <>
                  <div className={classes.containerVertical}>
                    <DatePicker
                      autoOk
                      disablePast
                      label="Data de coleta"
                      format="dd/MM/yyyy"
                      cancelLabel="Cancelar"
                      value={this.state.newGatheringDeadline}
                      onAccept={(date) => this.setState({ newGatheringDeadline: date })}
                      onChange={(date) => this.setState({ newGatheringDeadline: date })}
                      onYearChange={(date) => this.setState({ newGatheringDeadline: date })}
                      InputProps={dateInputProps}
                    />
                  </div>
                  <div className={classes.containerVertical}>
                    <DatePicker
                      autoOk
                      label="Data de entrega"
                      format="dd/MM/yyyy"
                      cancelLabel="Cancelar"
                      minDate={this.state.newGatheringDeadline || new Date()}
                      value={this.state.newDeliveryDeadline}
                      onAccept={(date) => this.setState({ newDeliveryDeadline: date })}
                      onChange={(date) => this.setState({ newDeliveryDeadline: date })}
                      onYearChange={(date) => this.setState({ newDeliveryDeadline: date })}
                      InputProps={dateInputProps}
                    />
                  </div>
                </>
              </div>
              <div className={classes.modalFooter}>
                <Button
                  className={[classes.btn, classes.btnRedColor].join(' ')}
                  size="small"
                  variant="contained"
                  onClick={() => this.modalHandler('duplicate')}
                >
                  CANCELAR
                </Button>
                <Button
                  className={[classes.btn, classes.btnGreenColor].join(' ')}
                  size="small"
                  variant="contained"
                  onClick={() => this.duplicateOffer(freight)}
                >
                  SALVAR
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {this.state.isModalOpen.delete && (
          <ModalConfirm
            open={this.state.isModalOpen.delete}
            title="Tem certeza?"
            message={
              <span className={classes.spanDeleteConfirm}>
                Gostaria de confirmar a exclusão do frete? <br /> Essa ação não poderá ser revertida.
              </span>
            }
            handleCancel={() => this.modalHandler('delete')}
            handleConfirm={() => {
              if (!freight.driver) {
                this.deleteOffer(freight.id);
              } else if (freight.status === 'CANCELED') {
                this.deleteOffer(freight.id);
              } else {
                this.setState({ isCreateOccurrenceOpen: true });
                this.modalHandler('delete');
              }
            }}
          />
        )}
        {this.state.isModalOpen.closeBatch && (
          <ModalConfirm
            open={this.state.isModalOpen.closeBatch}
            title="Tem certeza?"
            message="Você confirma a finalização dessa oferta em lote?"
            handleCancel={() => this.modalHandler('closeBatch')}
            handleConfirm={this.closeBatch}
          />
        )}
        {this.state.isModalOpen.advancedRequestedPaymentDate && (
          <ModalConfirm
            open={this.state.isModalOpen.advancedRequestedPaymentDate}
            title="Tem certeza?"
            message="Você confirma a exclusão deste frete?"
            handleCancel={() => this.modalHandler('advancedRequestedPaymentDate')}
            handleConfirm={() => this.deleteOffer(freight.id)}
          />
        )}

        <div className={classes.root}>
          <div className={classes.mainContainer}>
            <div className={classes.freightContainer}>
              <Paper className={classes.paper}>
                <AsideHeader className={classes.asideHeader} backTo="/offers">
                  {freight.gatheringCity !== undefined && (
                    <div>
                      <Typography noWrap>
                        De <span>{`${freight.gatheringCity} / ${freight.gatheringState}`}</span>{' '}
                        para
                      </Typography>
                      <Typography
                        noWrap
                      >{`${freight.deliveryCity} / ${freight.deliveryState}`}</Typography>
                    </div>
                  )}
                </AsideHeader>

                {freight.OSCode && (
                  <AsideRow className={classes.asideRow} icon={<AsteriskFilled />}>
                    <Typography className={classes.textTitle}>
                      <strong>Ordem de Serviço</strong>
                    </Typography>
                    <>
                      {this.state.editingOSCode ? (
                        <Input
                          className={classes.modelInput}
                          type="text"
                          value={this.state.localFreight.OSCode}
                          autoFocus
                          onChange={(e) => this.inputHandler(e, 'OSCode')}
                          onBlur={(e) =>
                            this.validateInput(e, 'OSCode', 'editingOSCode')
                          }
                          onKeyDown={(e) => this.validateInput(e, 'OSCode', 'editingOSCode')}
                          inputProps={{ maxLength: 75 }}
                        />
                      ) : (
                        <Typography className={classes.textContent}>
                            <strong>{localFreight.OSCode}</strong>
                        </Typography>
                      )}
                    </>
                    <Checkbox
                      classes={{
                        root: classes.checkBox,
                        checked: classes.checkboxChecked,
                      }}
                      icon={<EditIcon />}
                      checked={this.state.editingOSCode}
                      disabled={
                        !!(this.state.localFreight.OSCode === '' || this.state.isEditing)
                      }
                      onClick={(e) =>
                        this.state.localFreight.OSCode !== ''
                          ? this.setState({
                            isEditing: true,
                            editingOSCode: true,
                          })
                          : e.stopPropagation()
                      }
                    />
                  </AsideRow>
                )}
                
                {freight.freightCode && (
                  <AsideRow icon={<AsteriskFilled />} className={classes.asideRow}>
                    <Typography className={classes.textTitle}>
                      <strong>Código do Frete</strong>
                    </Typography>
                    <Typography className={classes.textContent}>
                      <strong>
                        {this.state.localFreight.freightCode ? `#${this.state.localFreight.freightCode}` : ''}
                      </strong>
                    </Typography>
                  </AsideRow>
                )}

                {freight.boardUserCreating?.name && (
                  <AsideRow icon={<AsteriskFilled />} className={classes.asideRow}>
                    <Typography className={classes.textTitle}>
                      <strong>Criador do Frete</strong>
                    </Typography>
                    <Typography className={classes.textContent}>
                      <strong>
                        {this.state.localFreight.boardUserCreating.name ? this.state.localFreight.boardUserCreating.name : ''}
                      </strong>
                    </Typography>
                  </AsideRow>
                )}

                <AsideRow icon={<GatheringPlace />} className={classes.asideRow}>
                  <Typography className={classes.textTitle}>
                    <strong>Origem</strong>
                  </Typography>
                  <Typography className={classes.textContent}>
                    <strong>
                      {this.state.gatheringResume}
                    </strong>
                  </Typography>
                </AsideRow>

                <AsideRow icon={<DeliveryPlace />} className={classes.asideRow}>
                  <Typography className={classes.textTitle}>
                    <strong>Destino</strong>
                  </Typography>
                  <Typography className={classes.textContent}>
                    <strong>
                      {this.state.deliveryResume}
                    </strong>
                  </Typography>
                </AsideRow>

                <AsideRow className={classes.asideRow} icon={<Weight />}>
                  <Typography className={classes.textTitle}>
                    <strong>peso (Kg)</strong>
                  </Typography>
                  <>
                    {this.state.editingWeight ? (
                      <TextField
                        id="standard-bare"
                        className={classes.textField}
                        defaultValue="Bare"
                        autoFocus
                        value={this.state.localFreight.packageWeight}
                        onChange={(e) => this.inputHandler(e, 'packageWeight')}
                        onBlur={(e) => this.validateInput(e, 'packageWeight', 'editingWeight')}
                        onKeyDown={(e) => this.validateInput(e, 'packageWeight', 'editingWeight')}
                        InputProps={{
                          classes: inputClasses,
                          inputComponent: WeightInput,
                        }}
                      />
                    ) : (
                      <Typography className={classes.textContent}>
                        <strong>
                            {localFreight.packageWeight ? `${formatWeight(localFreight.packageWeight)}` : ''}
                        </strong>
                      </Typography>
                    )}
                  </>
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checkboxChecked,
                    }}
                    checked={this.state.editingWeight}
                    icon={<EditIcon />}
                    disabled={!!(this.state.localFreight.packageWeight === '' || this.state.isEditing)}
                    onClick={(e) =>
                      this.state.localFreight.packageWeight !== ''
                        ? this.setState({
                          isEditing: true,
                          editingWeight: true,
                        })
                        : e.stopPropagation()
                    }
                  />
                </AsideRow>

                <AsideRow className={classes.asideRow} icon={<PackageFilled />}>
                  <Typography className={classes.textTitle}>
                    <strong>tipo de carga</strong>
                  </Typography>
                  <>
                    {this.state.editingPackage ? (
                      <Input
                        className={classes.modelInput}
                        type="text"
                        value={this.state.localFreight.packageDescription}
                        autoFocus
                        onChange={(e) => this.inputHandler(e, 'packageDescription')}
                        onBlur={(e) =>
                          this.validateInput(e, 'packageDescription', 'editingPackage')
                        }
                        onKeyDown={(e) => this.validateInput(e, 'packageDescription', 'editingPackage')}
                        inputProps={{ maxLength: 75 }}
                      />
                    ) : (
                      <Typography className={classes.textContent}>
                          <strong>{localFreight.packageDescription}</strong>
                      </Typography>
                    )}
                  </>
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checkboxChecked,
                    }}
                    icon={<EditIcon />}
                    checked={this.state.editingPackage}
                    disabled={
                      !!(this.state.localFreight.packageDescription === '' || this.state.isEditing)
                    }
                    onClick={(e) =>
                      this.state.localFreight.packageDescription !== ''
                        ? this.setState({
                          isEditing: true,
                          editingPackage: true,
                        })
                        : e.stopPropagation()
                    }
                  />
                </AsideRow>

                <AsideRow className={classes.asideRow} icon={<Calendar />}>
                  <>
                    <DatePicker
                      label="Data de coleta"
                      format="dd/MM/yyyy"
                      cancelLabel="Cancelar"
                      disablePast
                      minDateMessage=""
                      value={this.state.localFreight.gatheringDeadline}
                      onChange={(date) => this.handleDateChange('gatheringDeadline', date)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: inputClasses,
                        inputComponent: DateInput,
                      }}
                    />
                    <DatePicker
                      label="Data de entrega"
                      format="dd/MM/yyyy"
                      cancelLabel="Cancelar"
                      disablePast
                      minDateMessage=""
                      value={this.state.localFreight.deliveryDeadline}
                      onChange={(date) => this.handleDateChange('deliveryDeadline', date)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: inputClasses,
                        inputComponent: DateInput,
                      }}
                    />
                  </>
                </AsideRow>

                <AsideRow className={classes.asideRow} icon={<Truck />}>
                  <Typography className={classes.textTitle}>
                    <strong>caminhões elegíveis</strong>
                  </Typography>
                  <>
                    {this.state.editingTruck ? (
                      <SelectGroups
                        fullWidth
                        placeholder="Selecione uma opção"
                        options={ELEGIBLE_VEHICLES}
                        values={this.state.localFreight?.elegibleVehicles || []}
                        onChange={this.handleVehiclesChange}
                        onClose={this.stopEditingTruck}
                      />
                    ) : (
                      <Typography className={classes.textContent}>
                          <strong>{localFreight.elegibleVehicles?.join(', ')}</strong>
                      </Typography>
                    )}
                  </>
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checkboxChecked,
                    }}
                    icon={<EditIcon />}
                    checked={this.state.editingTruck}
                    disabled={
                      !!(this.state.localFreight.elegibleVehicles === '' || this.state.isEditing)
                    }
                    onClick={(e) => {
                      this.state.localFreight.elegibleVehicles !== '' ||
                        !this.state.localFreight.elegibleVehicles ||
                        this.state.editingTruck
                        ? this.setState({
                          isEditing: true,
                          editingTruck: !this.state.editingTruck,
                        })
                        : e.stopPropagation();
                    }}
                  />
                </AsideRow>

                <AsideRow className={classes.asideRow} icon={<Truck />}>
                  <Typography className={classes.textTitle}>
                    <strong>Carrocerias elegíveis</strong>
                  </Typography>
                  <>
                    {this.state.editingTypeByBoard ? (
                      <SelectGroups
                        fullWidth
                        placeholder="Selecione uma opção"
                        options={ELEGIBLE_TYPEBYBOARDS}
                        values={this.state.localFreight?.elegibleTypeByBoards || []}
                        onChange={this.handleTypeByBoardsChange}
                        onClose={this.stopEditingTypeByBoard}
                      />
                    ) : (
                      <Typography className={classes.textContent}>
                          <strong>{localFreight.elegibleTypeByBoards?.join(', ')}</strong>
                      </Typography>
                    )}
                  </>
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checkboxChecked,
                    }}
                    icon={<EditIcon />}
                    checked={this.state.editingTypeByBoard}
                    disabled={
                      !!(this.state.localFreight.elegibleTypeByBoards === '' || this.state.isEditing)
                    }
                    onClick={(e) => {
                      this.state.localFreight.elegibleTypeByBoards !== '' ||
                        !this.state.localFreight.elegibleTypeByBoards ||
                        this.state.editingTypeByBoard
                        ? this.setState({
                          isEditing: true,
                          editingTypeByBoard: !this.state.editingTypeByBoard,
                        })
                        : e.stopPropagation();
                    }}
                  />
                </AsideRow>

                <AsideRow className={classes.asideRow} icon={<Dolar />}>
                  <Typography className={classes.textTitle}>
                    <strong>Valor do frete</strong>
                  </Typography>
                  <>
                    {this.state.editingValue ? (
                      <TextField
                        id="standard-bare"
                        className={classes.textField}
                        value={formatCurrency(`${this.state.localFreight.value}`)}
                        autoFocus
                        onChange={(e) => this.inputHandler(e, 'value')}
                        onBlur={(e) => this.validateInput(e, 'value', 'editingValue')}
                        onKeyDown={(e) => this.validateInput(e, 'value', 'editingValue')}
                        InputProps={{
                          classes: inputClasses,
                          inputProps: { maxLength: 15 },
                        }}
                      />
                    ) : (
                      <Typography className={classes.textContent}>
                        <strong>
                            {localFreight.value ? `R$ ${formatCurrency(`${localFreight.value}`)}` : ''}
                        </strong>
                      </Typography>
                    )}
                  </>
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checkboxChecked,
                    }}
                    icon={<EditIcon />}
                    checked={this.state.editingValue}
                    disabled={!!this.state.isEditing}
                    onClick={(e) =>
                      this.setState({
                        isEditing: true,
                        editingValue: !this.state.editingValue,
                      })
                    }
                  />
                </AsideRow>

                <AsideRow className={classes.asideRow} icon={<Dolar />}>
                  <Typography className={classes.textTitle}>
                    <strong>Adiantamento ( % )</strong>
                  </Typography>
                  <>
                    {this.state.editingPaymentAdvance ? (
                      <TextField
                        id="standard-bare"
                        className={classes.textField}
                        value={this.state.localFreight.paymentAdvance}
                        autoFocus
                        onChange={(e) => this.inputHandler(e, 'paymentAdvance')}
                        onBlur={(e) => this.validateInput(e, 'paymentAdvance', 'editingPaymentAdvance')}
                        onKeyDown={(e) => this.validateInput(e, 'paymentAdvance', 'editingPaymentAdvance')}
                        InputProps={{
                          classes: inputClasses,
                          inputProps: { maxLength: 15 },
                        }}
                      />
                    ) : (
                      <Typography className={classes.textContent}>
                        <strong>
                            {localFreight.paymentAdvance}
                        </strong>
                      </Typography>
                    )}
                  </>
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checkboxChecked,
                    }}
                    icon={<EditIcon />}
                    checked={this.state.editingPaymentAdvance}
                    disabled={!!this.state.isEditing}
                    onClick={(e) =>
                      this.setState({
                        isEditing: true,
                        editingPaymentAdvance: !this.state.editingPaymentAdvance,
                      })
                    }
                  />
                </AsideRow>

                <AsideRow className={classes.asideRow} icon={<Store />}>
                  <Typography className={classes.textTitle}>
                    <strong>nome do cliente</strong>
                  </Typography>
                  <>
                    {this.state.editingClientName ? (
                      <TextField
                        id="standard-bare"
                        className={classes.textField}
                        value={this.state.localFreight.clientName}
                        autoFocus
                        onChange={(e) => this.inputHandler(e, 'clientName')}
                        onBlur={(e) => this.validateInput(e, 'clientName', 'editingClientName')}
                        onKeyDown={(e) => this.validateInput(e, 'clientName', 'editingClientName')}
                        InputProps={{
                          classes: inputClasses,
                          inputProps: { maxLength: 25 },
                        }}
                      />
                    ) : (
                      <Typography className={classes.textContent}>
                        <strong>
                            {localFreight.clientName}
                        </strong>
                      </Typography>
                    )}
                  </>
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checkboxChecked,
                    }}
                    icon={<EditIcon />}
                    checked={this.state.editingClientName}
                    disabled={!!(this.state.localFreight.clientName === '' || this.state.isEditing)}
                    onClick={(e) =>
                      this.state.localFreight.clientName !== ''
                        ? this.setState({
                          isEditing: true,
                          editingClientName: !this.state.editingClientName,
                        })
                        : e.stopPropagation()
                    }
                  />
                </AsideRow>

                <AsideRow className={classes.asideRow} icon={<TimeToLeave />}>
                  <Typography className={classes.textTitle}>
                    <strong>placa do veículo</strong>
                  </Typography>
                  <>
                    {this.state.editingLicensePlate ? (
                      <TextField
                        id="standard-bare"
                        className={classes.textField}
                        value={this.state.localFreight.licensePlate}
                        autoFocus
                        onChange={(e) => this.inputHandler(e, 'licensePlate')}
                        onBlur={(e) => this.validateInput(e, 'licensePlate', 'editingLicensePlate')}
                        onKeyDown={(e) => this.validateInput(e, 'licensePlate', 'editingLicensePlate')}
                        InputProps={{
                          classes: inputClasses,
                          inputProps: { maxLength: 8 },
                        }}
                      />
                    ) : (
                      <Typography className={classes.textContent}>
                        <strong>
                            {localFreight.licensePlate}
                        </strong>
                      </Typography>
                    )}
                  </>
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checkboxChecked,
                    }}
                    icon={<EditIcon />}
                    checked={this.state.editingLicensePlate}
                    disabled={!!(this.state.localFreight.licensePlate === '' || this.state.isEditing)}
                    onClick={(e) =>
                      this.state.localFreight.licensePlate !== ''
                        ? this.setState({
                          isEditing: true,
                          editingLicensePlate: !this.state.editingLicensePlate,
                        })
                        : e.stopPropagation()
                    }
                  />
                </AsideRow>

                <AsideRow
                  className={classes.asideRow}
                  icon={<AsteriskFilled />}
                  style={{ height: '110px' }}
                >
                  <Typography className={classes.textTitle}>
                    <strong>observações</strong>
                  </Typography>
                  <>
                    {this.state.editingObservation ? (
                      <Input
                        autoFocus
                        fullWidth
                        multiline
                        rowsMax={5}
                        className={classes.modelInput}
                        type="text"
                        value={this.state.localFreight.comment}
                        onChange={(e) => this.inputHandler(e, 'comment')}
                        onBlur={(e) => this.validateInput(e, 'comment', 'editingObservation')}
                        onKeyDown={(e) => this.validateInput(e, 'comment', 'editingObservation')}
                        inputProps={{
                          maxLength: 1024,
                        }}
                      />
                    ) : (
                      <div className={classes.commentContainer}>
                        <Typography className={classes.textContent}>
                            <strong>{localFreight.comment}</strong>
                        </Typography>
                      </div>
                    )}
                  </>
                  <Checkbox
                    classes={{
                      root: classes.checkBox,
                      checked: classes.checkboxChecked,
                    }}
                    icon={<EditIcon />}
                    checked={this.state.editingObservation}
                    disabled={!!(this.state.localFreight.comment === '' || this.state.isEditing)}
                    onClick={(e) =>
                      this.state.localFreight.comment !== ''
                        ? this.setState({
                          isEditing: true,
                          editingObservation: !this.state.editingObservation,
                        })
                        : e.stopPropagation()
                    }
                  />
                </AsideRow>

                {freight.status === "CANCELED" && (
                  <AsideRow icon={<AsteriskFilled />} className={classes.asideRow}>
                    <Typography className={classes.textContent}>
                      <strong className={classes.alertText}>
                        {`Frete cancelado por ${this.props.freight.boardUserDeleted ? this.props.freight.boardUserDeleted.name : ''}
                        em ${this.props.freight.statusHistory ? `${formatDate((this.props.freight.statusHistory.find(item => item.status === "CANCELED") || {}).date)}
                        as ${formatHour((this.props.freight.statusHistory.find(item => item.status === "CANCELED") || {}).date)}` : ''}`}
                      </strong>
                    </Typography>
                    <Typography className={classes.textContent}>
                      <strong className={classes.alertText}>
                        {'Aguardando a confirmação do motorista.'}
                      </strong>
                    </Typography>
                  </AsideRow>
                )}

                <div className={classes.container}>
                  {freight.status === 'CANCELED' && (
                    <>
                      <Button
                        className={[classes.btn, classes.btnBlueColor].join(' ')}
                        disabled={freight.status === 'FINISHED'}
                        size="small"
                        variant="contained"
                        onClick={() =>
                          this.props.history.push(`/create/occurrences?cpf=${freight.requestingUsers[driver].cpf ?
                            freight.requestingUsers[driver].cpf : ''}`)}
                      >
                        Falar com o motorista
                      </Button>
                      <Button
                        className={[classes.btn, classes.btnRedColor].join(' ')}
                        disabled={freight.status === 'FINISHED'}
                        size="small"
                        variant="contained"
                        onClick={() => this.modalHandler('delete')}
                      >
                        Excluir definitivamente
                      </Button>
                    </>
                  )}

                  {freight.status !== 'CANCELED' && (
                    <Button
                      className={[classes.btn, classes.btnRedColor].join(' ')}
                      disabled={freight.status === 'FINISHED'}
                      size="small"
                      variant="contained"
                      onClick={() => this.modalHandler('delete')}
                    >
                      Excluir oferta
                    </Button>
                  )}

                  {freight.type === 'Batch' && freight.status !== 'CANCELED' && (
                    <Button
                      className={[classes.btn, classes.btnGreenColor].join(' ')}
                      disabled={
                        freight.status !== 'WAITING' && freight.status !== 'DRIVER_SELECTED'
                      }
                      size="small"
                      variant="contained"
                      onClick={() => this.modalHandler('closeBatch')}
                    >
                      Finalizar frete em lote
                    </Button>
                  )}

                  {freight.type === 'Unique' && freight.status !== 'CANCELED' && (
                    <Button
                      className={[classes.btn, classes.btnBlueColor].join(' ')}
                      size="small"
                      variant="contained"
                      onClick={() => this.modalHandler('duplicate')}
                    >
                      Duplicar Oferta
                    </Button>
                  )}
                </div>
              </Paper>
            </div>
            <div className={classes.listContainer}>
              <Typography className={classes.textHeader}>
                {freight && !!freight.driver ? 'Responsável pelo frete' : 'Fila de Solicitações'}
              </Typography>
              <Divider className={classes.divider} />
              {freight && freight.driver ? (
                <>
                  <div className={classes.container}>
                    <Paper className={classes.item}>
                      <div className={classes.halfHeight}>
                        <Typography>
                          <Link to={`/drivers/${freight.driver}`} className={classes.textItemTitle}>
                            {freight.requestingUsers[driver].name}
                          </Link>
                        </Typography>
                      </div>
                      <Divider className={classes.dividerLight} />
                      <div className={classes.halfHeight}>
                        <Typography className={classes.textItemContent}>
                          <span>
                            Tipo do Veículo:{' '}
                            <strong>{freight.requestingUsers[driver].vehicle.type}</strong>
                          </span>
                          <span>
                            Proprietário do Veículo:{' '}
                            <strong>
                              {freight.requestingUsers[driver].vehicle.ownedByUser ? 'Sim' : 'Não'}
                            </strong>
                          </span>
                          <span>
                            Telefone: <strong>{freight.requestingUsers[driver].phoneNumber}</strong>
                          </span>
                        </Typography>
                      </div>
                    </Paper>
                    <Button
                      className={
                        freight.status !== 'WAITING' &&
                          freight.status !== 'DRIVER_SELECTED' &&
                          freight.status === FREIGHT_USER_STATUS.FINISHED &&
                          freight.status === FREIGHT_USER_STATUS.USER_ORDER_SENT
                          ? [classes.btnSelect, classes.btnDisabled].join(' ')
                          : [classes.btnSelect, classes.btnRedColor].join(' ')
                      }
                      size="small"
                      variant="contained"
                      onClick={() => this.selectUser(null, 'cancel', 'WAITING')}
                    >
                      CANCELAR
                    </Button>
                  </div>
                  {freight.status !== FREIGHT_USER_STATUS.WAITING && (
                    <div className={freight.status === 'CANCELED' ?
                      classes.containerDisable : classes.containerProgress}>
                      {freight.status === 'CANCELED' && (
                        <div className={classes.overlay}>
                          <span>Confirmação do motorista em andamento</span>
                        </div>
                      )}
                      <FreightProgress
                        freight={this.props.freight}
                        getFreightsLocations={this.props.getFreightsLocations}
                        updateFreight={this.updateFreightHandler}
                        updateStatus={this.props.updateStatus}
                        isLoading={this.props.isLoading}
                        boardUser={this.props.boardUser}
                      />
                      <Typography className={classes.textHeader}>
                        {'Outros documentos'}
                      </Typography>
                      <Divider className={classes.divider} />
                      <OthersDocuments
                        freight={this.state.localFreight}
                        onDocumentsChange={this.handleDocumentsChange}
                        updateFreightHandler={this.updateFreightHandler}
                      />
                      <Paper className={classes.paper} style={{ width: '100%', height: '500px' }}>
                        <FreightDirections
                          freight={this.props.freight}
                          freightLocation={this.props.freightLocation}
                        />
                      </Paper>
                    </div>
                  )}
                </>
              ) : (
                <div className={classes.itemsContainer}>
                  {freight &&
                    freight.requestingUsers &&
                    Object.keys(freight.requestingUsers)
                      .filter((userId) => {
                        return (
                          freight.requestingUsers[userId].requestStatus !== 'CANCELED' &&
                          freight.requestingUsers[userId].requestStatus !== 'DENIED'
                        );
                      })
                      .map((userId) => (
                        <div className={classes.container} key={freight.requestingUsers[userId].id}>
                          <Paper className={classes.item}>
                            <div className={classes.halfHeight}>
                              <Typography className={classes.textItemTitle}>
                                {freight.requestingUsers[userId].name
                                  ? freight.requestingUsers[userId].name
                                  : null}
                              </Typography>
                            </div>
                            <Divider className={classes.dividerLight} />
                            <div className={classes.halfHeight}>
                              <Typography className={classes.textItemContent}>
                                {freight.requestingUsers[userId] &&
                                  freight.requestingUsers[userId].vehicle ? (
                                  <>
                                    <span>
                                      Tipo do Veículo:{' '}
                                      <strong>
                                        {freight.requestingUsers[userId].vehicle.type}
                                      </strong>
                                    </span>
                                    <span>
                                      Proprietário do Veículo:{' '}
                                      <strong>
                                        {freight.requestingUsers[userId].vehicle.ownedByUser
                                          ? 'Sim'
                                          : 'Não'}
                                      </strong>
                                    </span>
                                    <span>
                                      Telefone:{' '}
                                      <strong>{freight.requestingUsers[userId].phoneNumber}</strong>
                                    </span>
                                  </>
                                ) : null}
                              </Typography>
                            </div>
                          </Paper>
                          <Button
                            className={[classes.btnSelect, classes.btnBlueColor].join(' ')}
                            size="small"
                            variant="contained"
                            onClick={() =>
                              this.selectUser(
                                freight.requestingUsers[userId],
                                'select',
                                'DRIVER_SELECTED',
                              )
                            }
                          >
                            SELECIONAR
                          </Button>
                        </div>
                      ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(FreightDetail));
