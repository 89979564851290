const COLOR_DEFAULT = "#1A5B76";
const COLOR_DEFAULT_HOVER = "#3896BB";

const styles = (theme) => ({
  root: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  invoiceCoupon: {
    padding: theme.spacing(1),
    borderRadius: '4px',
    boxShadow: theme.shadows[2],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    backgroundColor: '#ffffffff',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
    position: 'relative',
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    color: '#1A5B76',
    zIndex: 1,
    borderRadius: '4px',
    padding: `0 ${theme.spacing(2)}px`,
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#1A5B76',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(0.5),
    },
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },
  fieldName: {
    color: '#000000',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      marginRight: theme.spacing(0.5),
    },
  },
  fieldValue: {
    color: '#1A5B76',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      marginLeft: 'auto',
    },
  },
  statusLabel: {
    color: '#1A5B76',
    marginRight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      fontSize: '0.875rem',
    },
  },
  photoSummary: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    borderRadius: '4px',
    border: '1px solid #1A5B76',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.5),
    },
  },
  summaryItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.25),
    },
  },
  approved: {
    color: 'green',
  },
  altered: {
    color: 'blue',
  },
  denied: {
    color: 'red',
  },
  waiting: {
    color: 'orange',
  },
  button: {
    margin: theme.spacing(1),
    color: '#ffffff',
    backgroundColor: '#1A5B76',
    '&:hover': {
      backgroundColor: '#297694',
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.5),
      padding: theme.spacing(1),
    },
  },
  loadMoreButton: {
    display: "block",
    margin: theme.spacing(1, "auto"),
    padding: theme.spacing(1, 3),
    color: theme.palette.common.white,
    border: "2px solid #1A5B76",
    backgroundColor: COLOR_DEFAULT,
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLOR_DEFAULT_HOVER,
    },
  },
});

export default styles;
