import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '../Button';
import Autocomplete from '@mui/material/Autocomplete';
import CustomRadioGroup from '../CustomRadioGroup';
import InputMask from 'react-input-mask';
import { BRAZILIAN_CITIES_STATES } from '../../utils/brazilian_cities_states';
import styles from './styles';

const ModalAddress = ({ classes, target, open, onClose, existingData = {} }) => {
  const [location, setLocation] = useState('');
  const [informSender, setInformSender] = useState('nao');
  const [cnpj, setCnpj] = useState('');
  const [corporateName, setCorporateName] = useState('');
  const [address, setAddress] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (open) {
      const { location = '', informSender = 'nao', cnpj = '', corporateName = '', address = '' } = existingData;
      setLocation(location);
      setInformSender(informSender);
      setCnpj(cnpj);
      setCorporateName(corporateName);
      setAddress(address);
      setError(false);
    }
  }, [open, existingData]);

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setInformSender(value);
    if (value === 'nao') {
      setCnpj('');
      setCorporateName('');
      setAddress('');
    }
  };

  const handleSave = () => {
    if (BRAZILIAN_CITIES_STATES.includes(location)) {
      const lastHyphenIndex = location.lastIndexOf('-');
      const city = location.substring(0, lastHyphenIndex).trim();
      const state = location.substring(lastHyphenIndex + 1).trim();
      const addressData = {
        [`${target}City`]: city,
        [`${target}State`]: state,
        [`${target}Resume`]: location,
        informSender,
      };

      if (cnpj.trim() !== '') {
        addressData[`${target}CNPJ`] = cnpj;
      }

      if (corporateName.trim() !== '') {
        addressData[`${target}CorporateName`] = corporateName;
      }

      if (address.trim() !== '') {
        addressData[`${target}Address`] = address;
      }

      onClose(addressData);
    } else {
      setError(true);
    }
  };

  const title = target === 'gathering'
    ? 'Origem - Local de coleta'
    : 'Destino - Local de entrega';
  const label = target === 'gathering'
    ? 'Cidade origem'
    : 'Cidade destino';
  const entityLabel = target === 'gathering' ? 'Remetente' : 'Destinatário';

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') {
          onClose({});
        }
      }}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle disableTypography className={classes.title}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.customLabel}>{label}</div>
        <Autocomplete
          freeSolo
          options={BRAZILIAN_CITIES_STATES}
          value={location}
          onChange={(event, value) => setLocation(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.input}
              variant="outlined"
              error={error}
              helperText={error && "Formato inválido. Use 'Cidade - Estado'"}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        <CustomRadioGroup
          name="informSender"
          value={informSender}
          onChange={handleRadioChange}
          options={[
            { value: 'nao', label: `Não informar dados do ${entityLabel.toLowerCase()}` },
            { value: 'sim', label: `Informar dados do ${entityLabel.toLowerCase()}` },
          ]}
        />
        {informSender === 'sim' && (
          <>
            <div className={classes.customLabel}>CNPJ</div>
            <InputMask
              mask="99.999.999/9999-99"
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value)}
            >
              {() => (
                <TextField
                  className={classes.widerInput}
                  variant="standard"
                  name="cnpj"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="00.000.000/0000-00"
                />
              )}
            </InputMask>
            <div className={classes.customLabel}>Razão Social</div>
            <TextField
              className={classes.widerInput}
              variant="standard"
              name="corporateName"
              value={corporateName}
              onChange={(e) => setCorporateName(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Digite a razão social"
            />
            <div className={classes.customLabel}>Endereço</div>
            <TextField
              className={classes.widerInput}
              variant="standard"
              name="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Digite o endereço"
            />
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          size="large"
          customColor="blue"
          onClick={handleSave}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ModalAddress);