export default (theme) => {
  return ({
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    paper: {
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: '8px',
      padding: '0',
      backgroundColor: '#fff',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch'
    },
    textHeader: {
      color: '#2B596F',
      textTransform: 'uppercase',
      fontSize: '18px',
      fontWeight: 'bold'
    },
    divider: {
      height: '3px',
      backgroundColor: '#1A5B76'
    }
  });
}