import React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { formatDate, formatCPF } from '../../utils/utils';
import Typography from '@mui/material/Typography';
import styles from './styles';

const verifyInfo = (info) => info || "Buscando Dados...";

const OrderRequester = ({ classes, driver }) => {
  const vehicleImageUrl = driver.vehicle?.vehiclePhoto?.imageUrl || '';
  const driverPhotoUrl = driver.userPhoto?.imageUrl || '';

  return (
    <Paper elevation={3} className={classes.paper}>
      <div className={classes.contentContainer}>
        <div className={classes.infoContainer}>
          <div className={classes.header}>
            <Typography variant="h5">{verifyInfo(driver.name)}</Typography>
          </div>
          <div className={classes.infoSection}>
            <Typography>
              <strong>CPF:</strong> {verifyInfo(formatCPF(driver.cpf))}
            </Typography>
            <Typography>
              <strong>Contato:</strong> {verifyInfo(driver.phoneNumber)}
            </Typography>
            <Typography>
              <strong>Tipo de Veículo:</strong> {verifyInfo(driver.vehicle?.type)}
            </Typography>
            <Typography>
              <strong>Data de Cadastro:</strong> {verifyInfo(formatDate(driver.creationDate))}
            </Typography>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            component={Link}
            to={`/drivers/${driver.id}`}
          >
            Ver Detalhes
          </Button>
        </div>
        <div className={classes.avatarContainer}>
          <img
            className={classes.img}
            src={vehicleImageUrl}
            alt="Vehicle"
          />
          <img
            className={classes.img}
            src={driverPhotoUrl}
            alt="Driver"
          />
        </div>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(OrderRequester);
