import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import Image from '../Image';
import PhotosModal from '../ModalPhotos/index';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { downloadFromBuffer, getFromAWS } from '../../utils/utils';

const OrderPhotos = ({ order, onUpdate, onRequestImageBlocking, orderId, onUpdateStatus }) => {
  const classes = useStyles();
  const [photos, setPhotos] = useState(order.userImages || []);
  const [photoIndex, setPhotoIndex] = useState(undefined);
  const [modalOpen, setModalOpen] = useState({
    userImages: false,
  });

  useEffect(() => {
    setPhotos(order.userImages);
  }, [order.userImages]);

  const handleApprovePhoto = (index) => {
    let updatedPhotos = [...order.userImages];
    updatedPhotos[index] = { ...updatedPhotos[index], status: 'APPROVED', message: '', sender: 'MESA' };
    onUpdate({ id: orderId, order: { userImages: updatedPhotos } });
    setPhotos(updatedPhotos);
  };

  const handleRejectPhoto = (index, message) => {
    let updatedPhotos = [...order.userImages];
    updatedPhotos[index] = { ...updatedPhotos[index], status: 'DENIED', message, sender: 'MESA' };
    onRequestImageBlocking({ id: orderId, order: { userImages: updatedPhotos } });
    if (order.status !== "USER_ORDER_SENT_REFUSED") {
      onUpdateStatus("USER_ORDER_SENT_REFUSED", orderId);
    }
    setPhotos(updatedPhotos);
  };

  const handleDownloadPhoto = async (imageUrl) => {
    const response = await getFromAWS(imageUrl.split('/').pop());
    downloadFromBuffer(response.data.body, imageUrl, "application/pdf");
  };

  const modalHandler = (type, index) => {
    setModalOpen((prev) => ({ ...prev, [type]: !prev[type] }));
    setPhotoIndex(index);
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className={classes.userImagesContainer}>
          {order?.userImages &&
            order.userImages.map((photo, index) => (
              <Image
                key={index}
                photo={{ ...photo, status: photo.status }}
                label={`Foto ${index + 1}`}
                onClick={() => modalHandler('userImages', index)}
                draggable="true"
              />
            ))}
          {modalOpen.userImages ? (
            <PhotosModal
              open={modalOpen.userImages}
              photos={photos}
              initialIndex={photoIndex}
              onApprovePhoto={handleApprovePhoto}
              onRejectPhoto={handleRejectPhoto}
              onDownloadPhoto={handleDownloadPhoto}
              onClose={() => setModalOpen({ ...modalOpen, userImages: false })}
              status={order.status}
            />
          ) : null}
        </div>
      </DndProvider>
    </>
  );
};

export default OrderPhotos;
