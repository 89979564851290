import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { decode } from 'jsonwebtoken';
import storage from '../store/sessionStorage';

interface IToken {
  iat: string;
}

const PrivateRoute: React.FC<RouteProps> = ({ ...rest }) => {
  const auth = useSelector((state: any) => state.auth);
  const location = useLocation();

  const isAllowedRoute = auth.userRoles.some((role: string) => location.pathname.match(role));
  const token = storage.parse('_transport_user')?.token || '';
  const unformattedToken = (decode(token) as any) || { iat: Date.now() };
  const diff = unformattedToken
    ? Math.floor(
        Math.abs(Date.now() / 1000 - Number.parseInt(unformattedToken?.iat, 10)) / (60 * 60),
      )
    : 8;

  if (auth.isAuthenticated && isAllowedRoute) {
    if (diff < 8) {
      // Renderiza a rota com o Component informado
      return <Route {...rest} />;
    }
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;

    // Se houver usuário na store mas o profile dele não exister na relação
  }
  if (auth.isAuthenticated && !isAllowedRoute) {
    return <Redirect to={{ pathname: '/forbiden', state: { from: location } }} />;
  }
  return <Redirect to={{ pathname: '/', state: { from: location } }} />;
};

export default PrivateRoute;
