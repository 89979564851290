import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Button, CircularProgress, Typography } from "@material-ui/core";
import { dateNow, exportManyToExcel } from '../../utils/utils';
import OrderCard from '../OrderCard';
import styles from './styles';

const exportAndLog = (ordersList) => {
  exportManyToExcel(ordersList, `Ordens_${dateNow()}`, 'orders');
};

const OrdersList = (props) => {
  const { classes, ordersList, isLoading, hasMore, handleLoadMore } = props;
  return (
    <div id="root" className={classes.root}>
      <div className={classes.infiniteScrollContainer}>
        <Grid container spacing={3}>
          {ordersList.map((order) => (
            <Grid key={order.id} item xs={12} sm={6} md={3} className={classes.relativePos}>
              {order.paymentSuccessfulBalance && order.remainingValue &&
                order.advancedRequestedPaymentDate && order.advanceValue && (
                  <div className={classes.statusIconPaymentSuccessfulBalance} />
                )}
              {order.advancedRequestedPaymentDate &&
                !order.paymentSuccessfulBalance && (
                  <div className={classes.statusIconPaymentSuccessfulAdvance} />
                )}
              {!order.advancedRequestedPaymentDate &&
                !order.paymentSuccessfulBalance && (
                  <div className={classes.statusIconPaymentUnsuccessful} />
                )}
              {!order.updateAcknowledge &&
                <div className={classes.statusIcon} />
              }
              <OrderCard order={order} />
            </Grid>
          ))}
        </Grid>
        {isLoading && (
          <div className={classes.progress}>
            <CircularProgress alt={"Loading..."} />
          </div>
        )}
        {!isLoading && hasMore && (
          <Button
            onClick={handleLoadMore}
            className={classes.loadMoreButton}
          >
            Carregar Mais
          </Button>
        )}
        {!isLoading && (
          <Button
            onClick={() => exportAndLog(ordersList)}
            className={classes.exportButton}
          > Exportar lista
          </Button>
        )}
        {!hasMore && (
          <Typography className={classes.noMoreItems}>
            Não há mais itens para carregar.
          </Typography>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles, { name: 'OrdersList' })(OrdersList);