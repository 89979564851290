import { makeStyles } from '@material-ui/styles';
import { COLOR_DEFAULT, COLOR_DEFAULT_HOVER } from '../../globalTheme';

const useStyles = makeStyles((theme) => ({
  sequenceContainer: {
    backgroundColor: '#ffffff',
    padding: '8px',
    borderRadius: '0px 0px 5px 5px',
  },
  dropArea: {
    border: '2px dashed #1A5B76',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: '4px',
  },
  sequence: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
  },
  sequencePhotoContainer: {
    position: 'relative',
  },
  sequencePhoto: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    cursor: 'grab',
    border: '2px solid #1A5B76',
    borderRadius: '5px',
  },
  deletePhotoButton: {
    width: '100%',
    backgroundColor: '#ff4444',
    color: 'white',
    fontSize: '10px',
    border: 'none',
    borderRadius: '0 0 5px 5px',
    padding: '5px',
    cursor: 'pointer',
    position: 'absolute',
    bottom: '0',
    left: '0',
  },
  sequenceNumber: {
    position: 'absolute',
    top: '0',
    left: '0',
    backgroundColor: '#1A5B76',
    color: 'white',
    fontSize: '14px',
    padding: '4px',
    borderRadius: '5px 0 0 0',
  },
  generatePdfButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: '#1A5B76',
    color: 'white',
    textDecoration: 'none',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#37a2c5',
    },
  },
  btn: {
    padding: '8px',
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#fff',
    borderRadius: '5px',
    outline: 'none',
    border: 'none',
    textTransform: 'uppercase',
    backgroundColor: COLOR_DEFAULT,
    position: 'relative',
    cursor: 'pointer',
    margin: '4px',
    textDecoration: 'none',
    gap: '10px',
    '&:active': {
      top: '3px',
    },
    '&:hover': {
      backgroundColor: COLOR_DEFAULT_HOVER,
    },
  },
  btnWhite: {
    backgroundColor: '#ffffff',
    color: '#1B556D',
    border: '2px solid #1B556D',
    '&:hover': {
      backgroundColor: '#858585b3',
    },
  },
}));

export default useStyles;
