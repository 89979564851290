import styled from '@emotion/styled';

export const timelineStyles = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
  flexWrap: 'wrap',
};

export const StyledCircle = styled.div`
  width: 9rem;
  height: 8rem;
  border-radius: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin: 8px 16px;
  color: white;
  cursor: pointer;
  background-color: ${props => props.isActive ? '#3B9B58' : '#195A76'};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  &:hover {
    background-color: ${props => props.isActive ? '#2e8b47' : '#163d54'};
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  }
`;

export const lineStyles = {
  position: 'absolute',
  height: '5px',
  background: '#2B596F',
  top: '50%',
  left: '8%',
  right: '8%',
  zIndex: -1
};

export const modalStyleDiv = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '20px',
  background: '#ffffffff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  zIndex: 999,
  width: '500px',
  borderRadius: '5px',
};

export const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  boxShadow: 24,
  zIndex: 999,
};

export const buttonStyle = {
  cursor: 'pointer',
  border: 'none',
  borderRadius: '5px',
  color: "#ffffff",
};

export const modalButtonStyle = {
  margin: '8px',
  padding: '5px 15px',
  cursor: 'pointer',
  fontSize: '18px',
  alignSelf: 'center',
  justifySelf: 'center',
};

export const labelStyle = {
  position: 'absolute',
}
