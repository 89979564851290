import React, { Component } from 'react';
import styles from './style';
import withStyles from '@material-ui/core/styles/withStyles';

class Tooltip extends Component {
  state = {
    isHovering: false
  }

  showTooltip = () => {
    this.setState({isHovering: true})
  }

  closeTooltip = () => {
    this.setState({isHovering: false})
  }

  render(){
    const { classes } = this.props;

    return (
      <div onMouseEnter={() => this.showTooltip()} onMouseLeave={() => this.closeTooltip()}>
        {this.props.children}
        {this.state.isHovering && this.props.text.length >= this.props.minCharacters &&
          <div className={classes.container}>
            <p>{this.props.text}</p>
          </div>
        }
      </div>
    )
  }
  
}

export default withStyles(styles)(Tooltip);