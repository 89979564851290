import {
  OCCURRENCE_DETAIL_REQUEST,
  OCCURRENCE_DETAIL_SUCCESS,
  OCCURRENCE_DETAIL_FAILURE,
  OCCURRENCE_REPLY_REQUEST,
  OCCURRENCE_REPLY_SUCCESS,
  OCCURRENCE_REPLY_FAILURE,
  OCCURRENCE_MESSAGES_REQUEST,
  OCCURRENCE_MESSAGES_SUCCESS,
  OCCURRENCE_MESSAGES_FAILURE,
  CLEAR_OCCURRENCE_DATA,
} from "../actions/actionsTypes";

const INITIAL_STATE = {
  isLoading: true,
  occurrenceData: {},
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OCCURRENCE_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case OCCURRENCE_REPLY_REQUEST:
      return {
        ...state,
        error: null,
      };
    case OCCURRENCE_MESSAGES_REQUEST:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case OCCURRENCE_DETAIL_SUCCESS:
    case OCCURRENCE_REPLY_SUCCESS:
      return {
        ...state,
        occurrenceData: { ...action.payload },
        isLoading: false,
      };
    case OCCURRENCE_MESSAGES_SUCCESS:
      return {
        ...state,
        occurrenceData: { ...action.payload },
        isLoading: false,
      };

    case OCCURRENCE_DETAIL_FAILURE:
    case OCCURRENCE_REPLY_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case OCCURRENCE_MESSAGES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case CLEAR_OCCURRENCE_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};