import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CreateOccurrence from "../components/CreateOccurrence";
import {
  requestOccurrences,
  clearOccurrencesList,
  requestListOccurrences,
  occurrenceBroadcastRequest
} from '../actions/occurrences';
import { enqueueSnackbar } from "../actions/notifications";
import VersionChecker, { useVersionChecker } from '../components/VersionChecker/VersionChecker';

const CreateOccurrenceScreen = ({ occurrenceBroadcastRequest, enqueueSnackbar }) => {
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const filters = useSelector((state) => state.occurrences.filters);
  const skip = useSelector((state) => state.occurrences.skip);
  const limit = useSelector((state) => state.occurrences.filters.limit);
  const isLoading = useSelector((state) => state.occurrences.isLoading);
  const boardUserName = useSelector((state) => state.auth.user.name);

  const debouncedGetData = debounce((props) => dispatch(requestOccurrences(props)), 500);

  useVersionChecker('CreateOccurrenceScreen');

  useEffect(() => {
    mounted.current = true;

    dispatch(clearOccurrencesList());
    getOccurrences();

    const updateInterval = setInterval(() => {
      if (mounted.current) {
        updateOccurrences();
      }
    }, 15000);

    return () => {
      mounted.current = false;
      clearInterval(updateInterval);
    }; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(clearOccurrencesList());
    getOccurrences(); // eslint-disable-next-line
  }, [filters]);

  const getOccurrences = (skipProp = skip) => {
    debouncedGetData({ limit, skip: skipProp, filters });
  };

  const updateOccurrences = () => {
    dispatch(requestListOccurrences({ limit, skip, filters }));
  };

  const handleRefresh = () => {
    dispatch(clearOccurrencesList());
    getOccurrences("0");
  };

  return (
    <main className="main-content">
      <VersionChecker />
      <CreateOccurrence
        isLoading={isLoading}
        occurrenceBroadcastRequest={occurrenceBroadcastRequest}
        enqueueSnackbar={enqueueSnackbar}
        handleRefresh={handleRefresh}
        boardUserName={boardUserName}
      />
    </main>
  );
};

const mapStateToProps = ({ occurrences }) => ({
  isLoading: occurrences.isLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      occurrenceBroadcastRequest,
      enqueueSnackbar,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOccurrenceScreen);
