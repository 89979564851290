import api from "../api";

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  TOGGLE_REMEMBER_USER,
  CLEAR_ERROR_MESSAGE
} from "./actionsTypes";

function handleErros(err) {
  if (!!err.response && !err.response.data) {
    // Retorna a mensagem de erro padrão do http
    return err.response.statusText;
  } else if (!!err.response && !!err.response.data) {
    switch (err.response.status) {
      case 403:
        return "Login ou senha incorretos";

      // Retorna a mensagem de erro do servidor
      default:
        return err.response.data.message || "Resposta inesperada";
    }
  } else if (!!err.message) {
    // Retorna a mensagem de erro em tempo de execução
    return err.message;
  } else {
    return "Erro desconhecido";
  }
}

export const requestUserLogin = user => {
  return dispatch => {
    // Sinaliza que a operação iniciou para que os reducers possam ouvir
    dispatch({ type: LOGIN_REQUEST, user });
    // Faz o resquest para a rota de autenticação
    api
      .post("/board/session", { ...user })
      .then(res => {
        const { name, email, profile, token } = res.data;
        dispatch(userLoginSuccess({ name, email, profile, token }));
      })
      .catch(err => {
        dispatch(userLoginFailure(handleErros(err)));
      });
  };
};

export const clearErrorMessage = () => {
  return { type: CLEAR_ERROR_MESSAGE };
};

export const userLoginSuccess = payload => {
  return { type: LOGIN_SUCCESS, payload };
};

export const userLoginFailure = payload => {
  return { type: LOGIN_FAILURE, payload };
};

export const userLogout = () => {
  return { type: LOGOUT };
};

export const toggleRememberUser = () => {
  return { type: TOGGLE_REMEMBER_USER };
};
