import React from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import { isValid } from "date-fns/esm";
import { formatDate, formatWeight } from "../../utils/utils";
import { FREIGHT_STATUS_LABELS } from '../../constants';
import styles from "./styles";

const FreightCard = props => {
  const { classes, freight } = props;
  let statusBarText, statusBarColor, statusBarTextColor, backgroundColor, label;
  let batchFlagLabel = `LOTE | ${freight.freightCode}`;
  if (freight.type === "BatchItem") {
    batchFlagLabel = `${batchFlagLabel} | ${freight.batchItemCode}`;
  }

  switch (freight.status) {
    case "WAITING":
    case "DRIVER_SELECTED":
      statusBarText = "FRETE DISPONÍVEL";
      statusBarColor = "#BA8501";
      label = `CRIADA EM: ${formatDate(freight.creationDate)}`;
      break;

    case "CANCELED":
      statusBarText = " AGUARDANDO OK MOTORISTA";
      statusBarColor = '#716B6B';
      statusBarTextColor = "#CAC4C4";
      backgroundColor = "rgba(57, 55, 55, 0.80)";
      label = "FRETE EXCLUÍDO -";
      break;

    case "FINISHED":
      statusBarText = "FRETE FINALIZADO";
      statusBarColor = '#5e6162b5';
      statusBarTextColor = "#CAC4C4";
      backgroundColor = "#D9D9D9";
      label = `CRIADA EM: ${formatDate(freight.creationDate)}`;
      break;

    default:
      statusBarText = "FRETE EM CURSO";
      statusBarColor = "#3B9B58";
      backgroundColor = '#bbccd4';
      label = `CRIADA EM: ${formatDate(freight.creationDate)}`;
      break;
  }

  const freightLink = `/offers/${freight.id}`;

  return (
    <Link to={freightLink} className={classes.linkFix}>
      <Paper className={classes.root} style={{ backgroundColor: backgroundColor }}>
        {freight._genericStatus === "_FINISHED" && (
          <div className={classes.cardOverlay} />
        )}
        <div className={classes.mainContent}>
          <Typography className={classes.deliveryGatheringLine}>
            <strong>
              #{freight.freightCode}{" "}
              {freight.type === "BatchItem" ? `| ${freight.batchItemCode}` : ""}
            </strong>{" "}
            - De{" "}
            <span
              className={classes.deliveryGatheringRegion}
            >{`${freight.gatheringCity} / ${freight.gatheringState}`}</span>{" "}
            para{" "}
            <span
              className={classes.deliveryGatheringRegion}
            >{`${freight.deliveryCity} / ${freight.deliveryState}`}</span>
          </Typography>
          <Divider className={classes.divider} />

          <Typography className={classes.line}>
            <span>
              Coleta:
              {isValid(new Date(freight.gatheringDeadline))
                ? formatDate(freight.gatheringDeadline)
                : " indefinida"}
            </span>
            <span> - </span>
            <span>
              Entrega:
              {isValid(new Date(freight.deliveryDeadline))
                ? formatDate(freight.deliveryDeadline)
                : " indefinida"}
            </span>
          </Typography>
          <Divider className={classes.divider} />

          <Typography className={classes.line}>
            Ordem de Serviço: {freight.OSCode}
          </Typography>
          <Divider className={classes.divider} />

          <Typography className={classes.line}>
            Status: {FREIGHT_STATUS_LABELS[freight.status]}
          </Typography>
          <Divider className={classes.divider} />

          {freight.value ? (
            <>
              <Typography
                className={[classes.line, classes.fixBatchPos].join(" ")}
                style={{ color: "#29835f" }}
              >
                {freight.value
                  ? freight.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                  : null}
                {(freight.type === "Batch" || freight.type === "BatchItem") && (
                  <span className={classes.batchLabel}>{batchFlagLabel}</span>
                )}
              </Typography>
              <Divider className={classes.divider} />
            </>
          ) : <></>}

          <Typography noWrap className={classes.line}>
            {formatWeight(freight.packageWeight)} - {freight.packageDescription}
          </Typography>
          <Divider className={classes.divider} />

          <Typography className={classes.line}>
            Adiantamento de {freight.paymentAdvance}%{" "}
            {freight.toll ? "- Pedágio incluso" : ""}
          </Typography>
          <Divider className={classes.divider} />

          <Typography noWrap className={classes.line}>
            Obs: {freight.comment ? ` ${freight.comment} ` : ''}
          </Typography>
        </div>

        <Typography
          noWrap
          className={classes.statusBar}
          style={{ backgroundColor: statusBarColor }}
        >
          <span
            className={classes.footerLineCreationDate}
            style={{ color: statusBarTextColor }}
          >
            {label}
          </span>
          <span
            className={classes.footerLineStatus}
            style={{ color: statusBarTextColor }}
          >
            {statusBarText}
          </span>
        </Typography>
      </Paper>
    </Link>
  );
};

export default withStyles(styles)(FreightCard);
