import React, { useState, useEffect, useRef } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@mui/icons-material/Download';
import useStyles from './styles';

const ModalPhotos = ({
  open,
  photos = [],
  initialIndex = 0,
  onApprovePhoto,
  onRejectPhoto,
  onDownloadPhoto,
  onClose,
  status,
  ...other
}) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [currentPhoto, setCurrentPhoto] = useState({});
  const [isZoomEnabled, setIsZoomEnabled] = useState(false);
  const [localMessage, setLocalMessage] = useState('');
  const [messageShow, setMessageShow] = useState(false);
  const [isRejectClicked, setIsRejectClicked] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const messageRef = useRef('');

  useEffect(() => {
    if (open && photos.length > 0) {
      const initialPhoto = photos[initialIndex] || {};
      setCurrentIndex(initialIndex);
      setCurrentPhoto(initialPhoto);
      const shouldShowMessage = ['DENIED', 'ALTERED'].includes(initialPhoto.status) || !!initialPhoto.message;
      setMessageShow(shouldShowMessage);
      setLocalMessage(initialPhoto.message || '');
      messageRef.current = initialPhoto.message || '';
    }
  }, [open, initialIndex, photos]);

  useEffect(() => {
    if (photos.length > 0) {
      const photo = photos[currentIndex] || {};
      setCurrentPhoto(photo);
      const shouldShowMessage = ['DENIED', 'ALTERED'].includes(photo.status) || !!photo.message;
      setMessageShow(shouldShowMessage);
      setLocalMessage(photo.message || '');
      messageRef.current = photo.message || '';
      setIsRejectClicked(false);
    }
  }, [currentIndex, photos]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : photos.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  const handleImageClick = () => {
    setIsZoomEnabled(!isZoomEnabled);
  };

  const handleDownloadPhoto = async () => {
    if (currentPhoto && currentPhoto.imageUrl) {
      try {
        await onDownloadPhoto(currentPhoto.imageUrl);
      } catch (e) {
        console.error('Error downloading photo:', e);
      }
    }
  };

  const handleApprove = () => {
    if (onApprovePhoto) {
      onApprovePhoto(currentIndex);
      onClose();
    }
  };

  const handleReject = () => {
    if (onRejectPhoto) {
      onRejectPhoto(currentIndex, localMessage);
      setMessageShow(false);
      setLocalMessage('');
      messageRef.current = '';
      setIsRejectClicked(false);
      onClose();
    }
  };

  const getBorderColor = (status) => {
    switch (status) {
      case 'DENIED':
        return '#A33C3C';
      case 'APPROVED':
        return '#1EAD66';
      case 'ALTERED':
        return '#bfb529';
      default:
        return '#bbbbbb';
    }
  };

  const getStatusDescription = (status) => {
    switch (status) {
      case 'DENIED':
        return 'REPROVADA';
      case 'APPROVED':
        return 'APROVADA';
      case 'ALTERED':
        return 'ALTERADA';
      default:
        return '';
    }
  };

  const calculateImageDimensions = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const maxWidth = window.innerWidth * 0.8;
      const maxHeight = window.innerHeight * 0.8;

      let width = maxWidth;
      let height = maxHeight;

      if (aspectRatio > 1) {
        // Landscape
        height = maxWidth / aspectRatio;
      } else {
        // Portrait
        width = maxHeight * aspectRatio;
      }

      setImageDimensions({ width, height });
    };
  };

  useEffect(() => {
    if (currentPhoto && currentPhoto.imageUrl) {
      calculateImageDimensions(currentPhoto.imageUrl);
    }
  }, [currentPhoto]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'} {...other} >
      <DialogContent className={classes.dialogContent}
        style={{ border: `4px solid ${getBorderColor(currentPhoto.status)}` }}>
        {currentPhoto && currentPhoto.imageUrl && (
          <div className={classes.imageContainer} onClick={handleImageClick}
            style={{
              width: imageDimensions.width,
              height: imageDimensions.height,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto'
            }}>
            <div className={classes.statusBubble}
              style={{ border: `4px solid ${getBorderColor(currentPhoto.status)}` }}>
              {`Foto ${currentIndex + 1}: ${getStatusDescription(currentPhoto.status)}`}
            </div>
            <ReactImageMagnify
              smallImage={{
                src: currentPhoto.imageUrl,
                width: imageDimensions.width,
                height: imageDimensions.height,
              }}
              largeImage={{
                src: currentPhoto.imageUrl,
                width: imageDimensions.width * (isZoomEnabled ? 1.5 : 1),
                height: imageDimensions.height * (isZoomEnabled ? 1.5 : 1),
              }}
              hoverDelayInMs="500000"
              enlargedImagePosition="over"
              shouldHideHintAfterFirstActivation={false}
              isActivatedOnTouch={isZoomEnabled}
              isEnlargedImagePortalEnabledForTouch={false}
              {...(isZoomEnabled ? {} : { enlargedImageContainerDimensions: { width: '0%', height: '0%' } })}
            />
            {messageShow && (
              <div className={classes.currentMessageContainer}>
                <Typography className={classes.currentMessageLabel}>Descrição:</Typography>
                <Typography className={classes.currentMessageText}>{messageRef.current}</Typography>
              </div>
            )}
          </div>
        )}
        <div className={classes.navigationButtons}>
          <IconButton onClick={handlePrevious} disabled={photos.length <= 1}>
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton onClick={handleNext} disabled={photos.length <= 1}>
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={handleApprove}
          className={classes.approveButton}
          disabled={status === 'FINISHED'}
        >
          Aprovar foto
        </Button>
        <Button
          size="large"
          variant="contained"
          color="default"
          onClick={handleDownloadPhoto}
          className={classes.downloadButton}
        >
          <DownloadIcon />
          Download
        </Button>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => {
            setMessageShow(true);
            setIsRejectClicked(true);
          }}
          className={classes.rejectButton}
          disabled={currentPhoto.status === 'DENIED' || isRejectClicked || status === 'FINISHED'}
        >
          Reprovar foto
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={onClose}
          className={classes.closeButton}
        >
          Fechar
        </Button>
      </DialogActions>

      {isRejectClicked && (
        <div className={classes.messageContainer}>
          <Typography className={classes.messageLabel}>Novo Motivo:</Typography>
          <textarea
            onChange={(e) => {
              setLocalMessage(e.target.value);
            }}
            className={classes.messageInput}
            placeholder="Adicionar motivo da rejeição"
          />
          <div className={classes.confirmRejectButtonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReject}
              className={classes.confirmRejectButton}
              disabled={localMessage.trim() === '' || localMessage.trim() === messageRef.current.trim()}
            >
              Confirmar rejeição
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default ModalPhotos;
