import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ArrowBack from '@material-ui/icons/ArrowBack';

import styles from './styles'; 

export const Aside = withStyles(styles, { name: 'Aside' })(({ 
  classes, 
  className,
  ...props 
}) => {
  return (
    <section className={clsx(classes.root, className)} style={props.style}>
      {props.children}
    </section>
  )
});

export const AsideHeader = withStyles(styles, { name: 'AsideHeader' })(({ 
  classes, 
  className,
  ...props
}) => (
  <header className={clsx(classes.header, className)}>
    <Link to={props.backTo} className={classes.headerIcon}>
      <ArrowBack />
    </Link>
    {props.children}
  </header>
));

AsideHeader.propTypes = {
  backTo: PropTypes.string.isRequired
}

export const AsideRow = withStyles(styles, { name: 'AsideRow' })(({ 
  classes, 
  className,
  ...props 
}) => (
  <>
    <div className={clsx(classes.row, className)} style={props.style} >
      <div className={classes.rowIcon}>{props.icon}</div>
      <div className={classes.rowContent}>
        {props.children}
      </div>
    </div>
    <Divider />
  </>
));