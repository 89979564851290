import { COUNTERS_FAILURE, COUNTERS_SUCCESS } from '../actions/actionsTypes';

type CounterType = {
  users: number;
  freights: number;
  orders: number;
  occurrences: number;
  pooling: boolean;
};

type CounterActionType = {
  type: string;
  payload: CounterType;
};

const INITIAL_STATE = { users: 0, freights: 0, occurrences: 0, orders: 0 };

export default (state = INITIAL_STATE, action: CounterActionType) => {
  switch (action.type) {
    case COUNTERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case COUNTERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
