const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    backgroundColor: "#ffffff00",
    borderRadius: theme.shape.borderRadius,
    paddingBottom: theme.spacing(6.25),
    [theme.breakpoints.down('xl')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  asideLeft: {
    display: "flex",
    width: "40%",
    flexDirection: "column",
    [theme.breakpoints.down('xl')]: {
      width: '50%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  asideRight: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    [theme.breakpoints.down('xl')]: {
      width: '50%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  imagesContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    padding: theme.spacing(1),
  },
  userImagesContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingLeft: theme.spacing(1),
  },
  buttonNewPhoto: {
    cursor: "pointer",
  },
  divButtons: {
    display: "flex",
    margin: theme.spacing(1.25),
    flexDirection: "row",
    justifyContent: "space-around",
  },
  loadingOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  paperRadio: {
    display: "flex",
    height: "auto",
    width: "98%",
    margin: "auto",
    marginTop: "0.6rem",
    backgroundColor: "rgba(255, 255, 255, 1)",
    justifyContent: "center",
  }
});

export default styles;
