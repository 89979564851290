import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

/* 
  Função utilitária utilizada internamente pelo pacote @material-ui/icons para gerar os ícones
  Foi copiada aqui pois não foi possível importá-la

  https://github.com/mui-org/material-ui/blob/master/packages/material-ui-icons/src/utils/createSvgIcon.js
*/
function createSvgIcon(path, displayName) {
  const Component = React.memo(
    React.forwardRef((props, ref) => (
      <SvgIcon {...props} data-mui-test={`${displayName}Icon`} ref={ref}>
        {path}
      </SvgIcon>
    )),
  );

  if (process.env.NODE_ENV !== 'production') {
    Component.displayName = `${displayName}Icon`;
  }

  Component.muiName = SvgIcon.muiName;

  return Component;
}

export const DeliveryPlace = createSvgIcon(
  <React.Fragment>
    <path d="M 10.186165,16.88063 6.0181045,10.841093 C 5.7355463,10.288832 5.5277479,9.7014199 5.4001824,9.0943295 5.0999471,7.8306425 5.2404564,6.5023051 5.7984304,5.329398 6.3332127,4.2475986 7.1342482,3.3195191 8.1262678,2.6323662 8.531453,2.3457943 8.9745886,2.1170715 9.4428955,1.9527936 10.14917,1.709925 10.891365,1.5879826 11.63822,1.5921028 c 1.062975,-0.010759 2.110109,0.25805 3.036464,0.7794889 1.076505,0.6266728 1.940421,1.5616776 2.480192,2.6842765 0.252234,0.5010718 0.435995,1.0337646 0.54635,1.5837798 0.20476,1.3373359 0.03984,2.7052052 -0.476905,3.955552 -0.306661,0.664433 -2.0563,3.516652 -2.0563,3.516652 -0.309223,-0.265626 -1.14045,-1.041146 -1.14045,-1.041146 -0.841406,0.739612 -1.335938,1.359375 -3.841406,3.809924 z M 11.556732,4.4365288 C 9.1783359,4.4099316 7.9622879,7.2781863 9.6344809,8.9692894 11.306674,10.660393 14.188421,9.4766741 14.18857,7.0981292 14.194076,5.637494 13.017337,4.447448 11.556732,4.4365288 Z"/>
    <path d="m 19.114252,19.263823 v -1.743929 h -6.145916 l 2.346261,-2.346971 -1.254977,-1.254977 -4.489856,4.489857 4.489856,4.489856 1.254977,-1.254977 -2.378149,-2.378859 z"/>
  </React.Fragment>
);

export const GatheringPlace = createSvgIcon(
  <React.Fragment>
    <path d="M 10.210945,17.653241 6.0181047,10.841091 C 5.7352593,10.288268 5.5272496,9.7002593 5.3995545,9.0925517 5.099014,7.8275801 5.2396661,6.4978924 5.7982073,5.323793 6.3335333,4.240894 7.135383,3.3118712 8.128411,2.6240198 8.5340081,2.3371566 8.9775941,2.1082012 9.446377,1.9437563 c 0.706992,-0.2431155 1.449942,-0.3651818 2.197556,-0.3610574 1.064056,-0.01077 2.112254,0.2583125 3.03955,0.7802813 1.077599,0.6273098 1.942394,1.563265 2.482713,2.687005 0.252491,0.5015811 0.436439,1.0348154 0.546907,1.5853897 0.204967,1.3386955 0.03988,2.7079553 -0.477391,3.9595731 -0.293394,0.635686 -1.057864,1.915963 -1.935793,3.323954 l -0.527923,-0.440687 -2.451153,2.599166 0.984367,0.93352 c -0.141523,0.218374 -0.41688,0.644352 -0.41688,0.644352 z M 11.562353,4.4300163 C 9.1815392,4.4033918 7.9642549,7.2745621 9.6381476,8.9673844 11.31204,10.660207 14.196717,9.4752849 14.196866,7.0943222 14.202377,5.6322024 13.024442,4.4409467 11.562353,4.4300163 Z"/>
    <path d="m 9.5733089,18.26799 v 1.745702 h 6.1521631 l -2.348646,2.349356 1.256252,1.256253 4.494421,-4.49442 -4.494421,-4.49442 -1.256252,1.256252 2.380567,2.381277 z"/>
  </React.Fragment>
);

export const PackageFilled = createSvgIcon(
  <React.Fragment>
    <path d="m 3.5570111,9.4494778 6.3486613,0.018622 v 1.9696522 l -2.8018781,-0.01206 v 1.325651 h 5.3026037 v -1.325651 l -2.5007256,0.01206 V 9.4681 l 6.4611116,-0.021233 -0.0077,10.620221 -12.8029583,-4.37e-4 z"/>
    <path d="M 3.627356,9.1033647 16.524438,9.0998442 20.618832,4.0253834 7.6790186,4.0218628 Z"/>
    <path d="M 16.724052,9.4150568 16.723837,19.893472 20.749274,14.852093 20.75275,4.43565 Z"/>
  </React.Fragment>
);

export const PackageOutlined = createSvgIcon(
  <React.Fragment>
    <path d="M 7.637927,3.9746392 3.9347039,8.6523974 4.6248311,10.04212 5.1033781,8.9412317 8.1856825,5.0871155 19.012812,5.0921854 15.905724,8.929312 5.1033781,8.9412317 4.6248311,10.04212 l 5.7884219,0.0022 -0.06971,1.407901 -2.8675951,0.0077 v 1.211696 h 4.8471491 v -1.211696 l -1.979554,-0.0077 0.0697,-1.407964 5.139633,0.002 0.0023,8.934351 1.106724,0.05126 -0.0015,-9.2688054 2.893044,-3.5807576 0.0054,8.268425 -3.613063,4.522067 L 4.6276053,18.973671 4.6248453,10.04214 3.9347181,8.6524174 3.5138526,9.1616277 3.5177626,20.0783 H 16.488801 l 4.202907,-5.225192 -0.01563,-10.8940738 z"/>
  </React.Fragment>
);

export const PackagesOutlined = createSvgIcon(
  <React.Fragment>
    <path d="m 7.6934459,6.5754289 -2.6926861,3.40129 0.8849221,0.2100171 2.1904231,-2.7906855 7.872618,0.0037 -2.259224,2.7900495 -7.8038171,-0.0031 -0.3479608,0.800523 4.1580937,0.01332 -0.050688,1.023713 -2.0850845,0.0056 v 0.881047 H 11.0845 v -0.881047 l -1.4393727,-0.0056 0.05068,-1.023758 3.7371287,0.0014 0.0017,6.496343 0.804721,0.03728 -0.0011,-6.739529 2.103588,-2.6036393 0.004,6.0121343 -2.627129,3.288061 -8.2296674,6.36e-4 0.048672,-6.50596 0.3479608,-0.800523 -0.8849221,-0.2100171 -0.32163,0.3818831 1.136e-4,0.317213 -1.5747615,0.0025 v 0.523198 l 1.5749503,0.0016 9.176e-4,2.562468 -2.6413137,0.0078 -0.00508,-3.541894 2.7795593,0.0065 0.6299489,-0.8109517 -3.0370181,0.00699 1.0883004,-1.3498681 3.0246644,0.0051 0.6189691,-0.7968609 -4.0206374,-0.014208 -1.8789023,2.3322669 -0.011469,4.9441148 3.4532681,0.0078 0.00134,3.733826 h 9.4314995 l 3.056015,-3.799341 0.0016,-3.29386 1.939208,-0.0016 v -0.523198 l -1.935036,-0.0064 -0.0028,-2.3049984 0.224243,-0.2805591 4.264862,-5.037e-4 -1.085469,1.3394922 -3.417365,0.00986 -0.03262,0.802751 3.837571,-0.0026 1.128245,-1.4327737 -0.01448,3.1197447 -0.935208,1.166295 0.0038,-3.2392214 -0.802113,0.3664554 0.0044,3.545748 -3.208517,0.0075 -0.189145,0.790402 4.041001,0.002 L 22.87107,12.191119 22.85681,7.3004126 17.180315,7.2884166 17.173715,6.564 Z"/>
  </React.Fragment>
);

export const Weight = createSvgIcon(
  <React.Fragment>
    <path d="M12,3A4,4 0 0,1 16,7C16,7.73 15.81,8.41 15.46,9H18C18.95,9 19.75,9.67 19.95,10.56C21.96,18.57 22,18.78 22,19A2,2 0 0,1 20,21H4A2,2 0 0,1 2,19C2,18.78 2.04,18.57 4.05,10.56C4.25,9.67 5.05,9 6,9H8.54C8.19,8.41 8,7.73 8,7A4,4 0 0,1 12,3M12,5A2,2 0 0,0 10,7A2,2 0 0,0 12,9A2,2 0 0,0 14,7A2,2 0 0,0 12,5Z"/>
  </React.Fragment>
);

export const AsteriskFilled = createSvgIcon(
  <React.Fragment>
    <path d="m 7.3682391,15.954812 2.2869999,1.629 2.294,-2.981 2.3,3.079 2.272,-1.727 -2.029,-3.03 3.557,-1.175 -0.913,-2.7750001 -3.449,1.1250001 -0.234,-3.9270001 h -2.768 v 3.9270001 l -3.7999999,-1.2240001 -0.8,2.6090001 3.436,1.254 z"/>
  </React.Fragment>
);

export const ReceitaFederal = createSvgIcon(
  <React.Fragment>
    <g transform="matrix(0.40913629,0,0,0.40913629,-46.954893,-365.83803)">
      <path d="m 143.9732,943.97283 v 0 c -1.9066,-1.97688 -5.43533,-5.46609 -11.41431,-10.85968 -3.59903,-3.2465 -6.36996,-5.63521 -6.3985,-5.65938 0.033,-0.0625 3.62428,-6.75875 5.31561,-10.23696 -0.78746,0.11861 -3.07515,1.53758 -10.21389,6.31286 a 17.341661,17.341661 0 0 0 -3.4343,-2.90713 c -1.31133,-0.93573 -2.49086,-1.69792 -2.54029,-1.72978 l 0.008,-0.006 c 0.3196,-0.21307 2.04937,-1.35528 4.75331,-2.91372 a 115.44999,115.44999 0 0 1 11.86129,-5.96468 h 0.47117 c 5.61325,0 11.01892,0.40305 11.07274,0.40746 l -6.281,-2.61939 h 0.0131 c 0.59305,-0.20978 5.83181,-2.05376 7.2365,-2.05376 h 0.007 c 0.93244,0.21417 3.19706,0.95768 5.91088,1.94063 2.82255,1.02251 5.41557,2.04499 6.93666,2.73579 -0.17792,1.61776 -0.99612,6.18106 -1.00491,6.22609 a 4.9993185,4.9993185 0 0 0 1.10487,-0.47225 c 0.83577,-0.41184 2.4722,-1.28169 5.52208,-3.07515 3.39255,1.70122 10.06563,5.76151 10.13263,5.80215 -0.0791,0.0604 -8.71257,6.56105 -12.02275,9.25182 -4.47433,-1.28169 -16.45755,-3.28384 -16.57836,-3.3047 l 11.05407,8.4369 -0.0351,0.0331 c -0.82591,0.76878 -4.09544,3.83075 -11.47362,10.6532 z m 0.12849,-33.38736 v 0 c -5.43203,2.52602 -11.81847,6.12065 -11.88326,6.15689 h 0.008 c 0.60625,0.36571 6.09319,3.69457 11.87337,7.85702 3.62978,-2.41619 11.89206,-7.8076 11.97115,-7.86142 -0.0144,-0.008 -2.1087,-1.23225 -4.62702,-2.59959 a 56.857363,56.857363 0 0 0 -7.34741,-3.5573 z"/>
      <path d="m 144.61015,912.53169 c 0,0 -7.59967,0.17304 -7.71114,4.33009 -0.11236,4.18856 7.65184,4.33011 7.65184,4.33011 0,0 6.70275,-0.29658 6.99934,-4.09284 0.29659,-3.79625 -5.93165,-4.56736 -6.94004,-4.56736 z"/>
    </g>
  </React.Fragment>
);

export const Whatsapp = createSvgIcon(
  <React.Fragment>
    <path d="M16.75,13.96C17,14.09 17.16,14.16 17.21,14.26C17.27,14.37 17.25,14.87 17,15.44C16.8,16 15.76,16.54 15.3,16.56C14.84,16.58 14.83,16.92 12.34,15.83C9.85,14.74 8.35,12.08 8.23,11.91C8.11,11.74 7.27,10.53 7.31,9.3C7.36,8.08 8,7.5 8.26,7.26C8.5,7 8.77,6.97 8.94,7H9.41C9.56,7 9.77,6.94 9.96,7.45L10.65,9.32C10.71,9.45 10.75,9.6 10.66,9.76L10.39,10.17L10,10.59C9.88,10.71 9.74,10.84 9.88,11.09C10,11.35 10.5,12.18 11.2,12.87C12.11,13.75 12.91,14.04 13.15,14.17C13.39,14.31 13.54,14.29 13.69,14.13L14.5,13.19C14.69,12.94 14.85,13 15.08,13.08L16.75,13.96M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C10.03,22 8.2,21.43 6.65,20.45L2,22L3.55,17.35C2.57,15.8 2,13.97 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12C4,13.72 4.54,15.31 5.46,16.61L4.5,19.5L7.39,18.54C8.69,19.46 10.28,20 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z"/>
  </React.Fragment>
);