import api from "../api";
import {
  FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE,
  UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE,
  CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE,
  DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE
} from "./actionsTypes";
import { enqueueSnackbar } from './notifications';

export const fetchUsers = (query) => async (dispatch) => {
  dispatch({ type: FETCH_USERS_REQUEST });
  try {
    const response = await api.get(`/boardusers?${query}`);
    dispatch({ type: FETCH_USERS_SUCCESS, payload: response.data });

    return { type: 'FETCH_USERS_SUCCESS', payload: response.data };
  } catch (error) {
    dispatch({ type: FETCH_USERS_FAILURE, payload: error.toString() });
    dispatch(
      enqueueSnackbar({
        message: 'Ocorreu um erro ao buscar usuários, tente novamente mais tarde.',
        options: { variant: 'error' },
      }),
    );
    return { type: 'FETCH_USERS_FAILURE', error: error.toString() };
  }
};

export const updateUser = (boardUserId, userData) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_REQUEST });

  try {
    const { creationDate, updateDate, id, ...dataToUpdate } = userData.user;

    const response = await api.patch(`/boardusers/${boardUserId}`, dataToUpdate);
    dispatch({ type: UPDATE_USER_SUCCESS, payload: response.data });

    dispatch(
      enqueueSnackbar({
        message: 'Usuário atualizado com sucesso',
        options: { variant: 'success' },
      }),
    );
    return true;
  } catch (error) {
    dispatch({ type: UPDATE_USER_FAILURE, payload: error.toString() });
    dispatch(
      enqueueSnackbar({
        message: 'Usuário não atualizado, tente novamente mais tarde.',
        options: { variant: 'error' },
      }),
    );
    return false;
  }
};

export const createUser = (userData) => async (dispatch) => {
  dispatch({ type: CREATE_USER_REQUEST });
  try {
    const response = await api.post('/boardusers', userData);
    dispatch({ type: CREATE_USER_SUCCESS, payload: response.data });
    dispatch(
      enqueueSnackbar({
        message: 'Usuário criado com sucesso',
        options: { variant: 'success' },
      }),
    );
    return true;
  } catch (error) {
    dispatch({ type: CREATE_USER_FAILURE, payload: error.toString() });
    dispatch(
      enqueueSnackbar({
        message: 'Ocorreu um erro ao criar usuário, tente novamente mais tarde.',
        options: { variant: 'error' },
      }),
    );
    return false;
  }
};

export const deleteUser = (boardUserId) => async (dispatch) => {
  dispatch({ type: DELETE_USER_REQUEST });
  try {
    await api.delete(`/boardusers/${boardUserId}`);
    dispatch({ type: DELETE_USER_SUCCESS, payload: boardUserId });
    dispatch(
      enqueueSnackbar({
        message: 'Usuário deletado com sucesso',
        options: { variant: 'success' },
      }),
    );
    return true;
  } catch (error) {
    dispatch({ type: DELETE_USER_FAILURE, payload: error.toString() });
    dispatch(
      enqueueSnackbar({
        message: 'Ocorreu um erro ao deletar usuário, tente novamente mais tarde.',
        options: { variant: 'error' },
      }),
    );
    return false;
  }
};
