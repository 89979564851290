export const getLatitudeLongitudePromise = (address) => {
  return new Promise((resolve, reject) => {
    
    if (!window.google) {
      reject('Maps javascript API not loaded!');
    }

    const service = new window.google.maps.Geocoder();
    
    if (service) {
      service.geocode({address}, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const response = {
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng()
          };
          resolve(response);
        } else {
          reject(status);
        }
      });
    }
  });
}

export const getOneDistancePromise = (origin, destination) => {
  return new Promise((resolve, reject) => {
    
    if (!window.google) {
      reject('Maps javascript API not loaded!');
    }

    const service = new window.google.maps.DistanceMatrixService();
    
    if (service) {
      service.getDistanceMatrix({
        origins: [origin],
        destinations: [destination],
        travelMode: 'DRIVING'
      }, (results, status) => {
        if (status === window.google.maps.DistanceMatrixStatus.OK) {
          const response = {
            origin: results.originAddresses[0],
            destination: results.destinationAddresses[0],
            distance: { ...results.rows[0].elements[0].distance },
            duration: { ...results.rows[0].elements[0].duration }
          };

          resolve(response);
        } else {
          reject(status);
        }
      });
    }
  });
}