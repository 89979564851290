import { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import sessionStorage from '../store/sessionStorage';
import { STORAGE_USER_KEY } from '../constants';
import { countersSuccess } from '../actions/counter';

const SERVICE_URL = process.env.REACT_APP_WEBSOCKET_URL || '';

const useSocketConnection = () => {
  const socket = useRef<WebSocket | null>(null);
  const dispatch = useDispatch();
  const [isConnected, setIsConnected] = useState(false);
  const [connectionId, setConnectionId] = useState<string | undefined>(undefined);

  useEffect(() => {
    connectSocket();

    return () => {
      disconnectSocket();
    }; // eslint-disable-next-line
  }, []);

  const connectSocket = () => {
    if (!socket.current || socket.current.readyState !== WebSocket.OPEN) {
      // @ts-ignore
      socket.current = new WebSocket(SERVICE_URL);
      socket.current.addEventListener('open', onSocketOpen);
      socket.current.addEventListener('close', onSocketClosed);
      socket.current.addEventListener('error', onSocketError);
      socket.current.addEventListener('message', onSocketMessage);
    }
  };

  const disconnectSocket = () => {
    if (socket.current) {
      socket.current.removeEventListener('open', onSocketOpen);
      socket.current.removeEventListener('close', onSocketClosed);
      socket.current.removeEventListener('error', onSocketError);
      socket.current.removeEventListener('message', onSocketMessage);
      socket.current.close();
    }
  };

  const sendMessage = (message: object) => {
    if (socket.current && socket.current.readyState === WebSocket.OPEN) {
      socket.current.send(JSON.stringify(message));
    }
  };

  const onSocketOpen = () => {
    const user = sessionStorage.parse(STORAGE_USER_KEY);
    const token = user?.token || null;
    sendMessage({
      action: 'saveConnectionId',
      token,
    });
    sendMessage({
      action: 'getCounters',
    });
    setIsConnected(true);
  };

  const onSocketClosed = () => {
    setIsConnected(false);
    setConnectionId(undefined);
  };

  const onSocketError = (error: Event) => {
    console.error('onSocketError - error', error);
  };

  const onSocketMessage = (event: MessageEvent) => {
    const data = event.data;
    if (data) {
      const response = JSON.parse(data);
      const payload = response?.payload;

      if (response.connectionId && response.connectionId !== connectionId) {
        setConnectionId(response.connectionId);
      }

      switch (response.action) {
        case 'getCounters':
          if (payload) {
            setCounters(payload);
          }
          break;
      }
    }
  };

  const setCounters = (payload: any) => {
    dispatch(countersSuccess(payload));
  };

  return { isConnected, connectionId, connectSocket, disconnectSocket, sendMessage };
};

export default useSocketConnection;
