// const COLOR_DEFAULT = '#1A5B76';

export default (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      // overflow: 'hidden',
      position: 'relative'
    },
    mainContent: {
      padding: theme.spacing(1) * 2,
      height: '100%',
      minHeight: '150px',
      display: 'flex',
      flexDirection: 'column'
    },
    container: {
      marginBottom: '15px',
      '&:last-of-type': {
        marginBottom: '0px',
        marginTop: 'auto'
      }
    },
    horizontalFlex: {
      display: 'flex',
      flexWrap: 'wrap',
        '& p':{
          width: '50%',
          flexShrink: '0'
        },
        '& div':{
          width: '50%',
          flexShrink: '0'
        },
        '& $text':{
          textTransform: 'capitalize'
        }
    },
    cardOverlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.5)'
    },
    bold: {
      fontWeight: 'bold'
    },
    labels: {
      textTransform: 'uppercase',
      color: '#1A5B76',
      fontSize: '9px',
      textAlign: 'left',
      fontFamily: 'Arial'
    },
    text: {
      fontSize: '12px',
      textAlign: 'left',
      color: '#707070'
    },
    userName: {
      color: '#000',
      fontSize: '15px',
      lineHeight: '14px'
    },
    statusIcon: {
      width: '8px',
      height: '8px',
      borderRadius: '100%',
      display: 'inline-block',
      marginRight: '5px'
    },
    negative: {
      backgroundColor: 'red'
    },
    positive: {
      backgroundColor: 'green'
    },
    moreDetails: {
      position: 'absolute',
      bottom: '0',
      right: '0',
      cursor: 'pointer',
      margin: '10px'
    }
  });
}