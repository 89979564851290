import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {},
  menuItem: {
    paddingLeft: theme.spacing(3),
    fontSize: '16px',
    minHeight: '30px',
    color: '#2B596F',
    fontWeight: 600,
    borderRadius: '12px',
    margin: '4px',
    '&:hover': {
      backgroundColor: '#2b596fb3',
      color: '#00000093',
      fontWeight: 600,
      borderRadius: '12px',
      margin: '4px',
    },
    '&.Mui-selected': {
      backgroundColor: '#2b596fb3',
      color: '#00000093',
      fontWeight: 600,
      borderRadius: '12px',
      margin: '4px',
    },
  },
  groupTitle: {
    minHeight: '30px',
    color: '#000000',
    fontWeight: 700,
    fontSize: '16px',
    paddingLeft: theme.spacing(2)
  }
}), { name: 'SelectGroups' });

function SelectGroups(props) {
  const classes = useStyles();

  function handleChange(event) {
    const { value: receivedValues } = event.target;
    let newValues = [];

    if (receivedValues.includes('all')) {
      if (props.values.length !== 0) {
        newValues = [];
      } else {
        Object.keys(props.options).forEach(key =>
          props.options[key].forEach(value => {
            newValues.push(value);
          })
        );
      }
    } else {
      newValues = [...receivedValues];
    }

    props.onChange(newValues);
  }

  return (
    <div className={classes.root}>
      <FormControl fullWidth={props.fullWidth} className={classes.formControl}>
        <Select
          multiple
          displayEmpty
          value={props.values}
          onChange={handleChange}
          input={<Input {...props.InputProps} />}
          renderValue={() => props.placeholder}
          onClose={props.onClose}
        >
          <MenuItem disabled value="" className={classes.menuItem}>
            {props.placeholder}
          </MenuItem>

          <Divider />

          <MenuItem value="all" className={classes.menuItem}>
            {props.values?.length !== 0 ? 'Nenhum' : 'Todos'}
          </MenuItem>

          {Object.keys(props.options).map(key => ([
            <MenuItem disabled value="" className={classes.groupTitle}>
              {key}
            </MenuItem>,
            props.options[key].map(value => (
              <MenuItem key={value} value={value} className={classes.menuItem}>
                {value}
              </MenuItem>
            ))
          ]))}
        </Select>
      </FormControl>
    </div>
  );
}

SelectGroups.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  values: PropTypes.array,
  onChange: PropTypes.func.isRequired
};

export default SelectGroups;
