import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './style';

const FilterSearch = (props) => {
  return (
    <div className={props.classes.nameFilterInptContainer}>
      { props.children }
    </div>
  );
}

export default withStyles(styles)(FilterSearch);