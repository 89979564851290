import {
  OCCURRENCES_REQUEST,
  OCCURRENCES_SUCCESS,
  OCCURRENCES_FAILURE,
  OCCURRENCES_CLEAR_LIST,
  OCCURRENCES_CLEAR_FILTERS,
  OCCURRENCES_SET_FILTER,
  OCCURRENCE_LIST_REQUEST,
  OCCURRENCE_LIST_SUCCESS,
  OCCURRENCE_LIST_FAILURE,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  NOTIFY_BROADCAST_REQUEST,
  NOTIFY_BROADCAST_SUCCESS,
  NOTIFY_BROADCAST_FAILURE
} from "../actions/actionsTypes";
import { uniqBy } from "lodash";

import { ROWS_PER_PAGE_IN_CANVAS } from "../constants";

const INITIAL_STATE = {
  occurrenceList: [],
  occurrences: [],
  filters: {
    message: "",
    status: "",
    type: "",
    limit: ROWS_PER_PAGE_IN_CANVAS,
  },
  isLoading: false,
  hasMore: true,
  skip: 0
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case OCCURRENCES_CLEAR_LIST:
      return {
        ...state,
        skip: 0,
        hasMore: true,
        occurrenceList: []
      };

    case OCCURRENCES_CLEAR_FILTERS:
      return {
        ...state,
        skip: 0,
        hasMore: true,
        occurrenceList: [],
        filters: {
          message: "",
          status: "",
          type: ""
        }
      };

    case OCCURRENCES_SET_FILTER:
      return {
        ...state,
        skip: 0,
        hasMore: true,
        occurrenceList: [],
        filters: {
          ...state.filters,
          ...payload
        }
      };

    case OCCURRENCES_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case OCCURRENCES_SUCCESS:
      const occurrenceList = uniqBy([...state.occurrenceList, ...payload.occurrences], "id");
      return {
        ...state,
        isLoading: false,
        skip: occurrenceList.length,
        hasMore: payload.occurrences.length === state.filters.limit,
        occurrenceList,
      };

    case OCCURRENCES_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case OCCURRENCE_LIST_REQUEST:
      return {
        ...state,
        isLoading: false
      };

    case OCCURRENCE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        skip: occurrenceList.length,
        hasMore: payload.occurrences.length === state.filters.limit,
        occurrenceList,
      };

    case OCCURRENCE_LIST_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case ADD_NOTIFICATION:
      return {
        ...state,
        occurrences: [...state.occurrences, { ...action.occurrence }]
      };

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        occurrences: state.occurrences.filter(
          occurrence => occurrence.key !== action.key
        )
      };

    case NOTIFY_BROADCAST_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case NOTIFY_BROADCAST_SUCCESS:
    case NOTIFY_BROADCAST_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
};
