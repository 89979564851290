import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  requestDriverList,
  clearDriversList,
  clearDriversFilters,
  setDriversFilter,
} from '../actions/drivers';
import { isFiltersEmpty } from '../utils/utils';
import DriversFilter from '../components/DriverFilter';
import DriversList from '../components/DriversList';
import VersionChecker, { useVersionChecker } from '../components/VersionChecker/VersionChecker';

const DriverScreen = () => {
  const dispatch = useDispatch();
  const mounted = useRef(false);

  const driversList = useSelector((state) => state.drivers.driversList);
  const filters = useSelector((state) => state.drivers.filters);
  const skip = useSelector((state) => state.drivers.skip);
  const limit = useSelector((state) => state.drivers.filters.limit);
  const hasMore = useSelector((state) => state.drivers.hasMore);
  const isLoading = useSelector((state) => state.drivers.isLoading);

  const [name, setName] = useState(filters.name);
  const [cpf, setCpf] = useState(filters.cpf);

  const debouncedSetFilter = debounce((props) => dispatch(setDriversFilter(props)), 1500);
  const debouncedGetData = debounce((props) => dispatch(requestDriverList(props)), 1000);

  useVersionChecker('DriverScreen');

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    dispatch(clearDriversList());
    dispatch(requestDriverList({ filters }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const fieldSetters = {
    name: setName,
    cpf: setCpf,
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;

    if (fieldSetters[name]) {
      fieldSetters[name](value);
    }

    if (Object.keys(fieldSetters).includes(name)) {
      debouncedSetFilter({ [name]: value });
    } else {
      dispatch(setDriversFilter({ [name]: value }));
    }
  };

  const handleClearFilters = () => {
    setName('');
    setCpf('');
    dispatch(clearDriversFilters());
    dispatch(requestDriverList({ filters: filters }));
  };

  const getDrivers = (skipProp = skip) => {
    debouncedGetData({ limit, skip: skipProp, filters });
  };

  const handleLoadMore = () => {
    if (hasMore && !isLoading) {
      getDrivers(skip);
    }
  };

  const handleRefresh = () => {
    dispatch(clearDriversList());
    getDrivers("0");
  };

  return (
    <>
      <VersionChecker />
      <div className="main-filter">
        <DriversFilter
          disableClear={isFiltersEmpty(filters) || isLoading}
          disableRefresh={isLoading}
          name={name}
          cpf={cpf}
          vehicleType={filters.vehicleType}
          vehicleOwnedByUser={filters.vehicleOwnedByUser}
          walletType={filters.walletType}
          status={filters.status}
          handleClearFilters={handleClearFilters}
          handleRefresh={handleRefresh}
          handleFilterChange={handleFilterChange}
        />
      </div>
      <main className="main-content">
        <DriversList
          driversListNull={driversList}
          driversList={driversList}
          hasMore={hasMore}
          getDrivers={getDrivers}
          isLoading={isLoading}
          handleLoadMore={handleLoadMore}
        />
      </main>
    </>
  );
};

export default DriverScreen;
