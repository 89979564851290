import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import { userLogout } from '../actions/auth';

const Logout = (props) => {
  props.userLogout();
  return <Redirect to="/" />
};

const mapDispatchToProps = (dispatch) => 
  bindActionCreators({ userLogout }, dispatch);

export default connect(null, mapDispatchToProps)(Logout)
