import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { withStyles, TextField } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import MaskedInput from 'react-text-mask';
import styles from './styles';
import Button from '../Button';
import { bancos } from '../../utils/utils';

import { FILTER_LABEL_DRIVER, MASK } from '../../constants';

const PisInput = (props) => {
  const { inputRef, ...others } = props;
  return <MaskedInput {...others} mask={MASK.pis} />;
};

const checkEditor = (props) => {
  const { accountDocument, bank, agency, account, pis } = props;
  if ((accountDocument && accountDocument.length <= 14) || !accountDocument) {
    if (bank && agency.length && account.length && pis.length) return true;
  } else if (bank && agency.length && account.length) return true;
  return false;
};

const ModalEditConta = (props) => {
  return (
    <Dialog
      disableEscapeKeyDown
      open={props.isOpen}
      onClose={props.closeEditModal}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={props.classes.modalHeaderContainer}>
        <Typography variant="body1" className={props.classes.asideHeaderText}>
          {props.title}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <FormControl
          fullWidth
          style={{
            marginTop: 10,
          }}
        >
          <InputLabel htmlFor="walletType">Tipo de pagamento</InputLabel>
          <Select
            id="walletType"
            name="walletType"
            value={props.type}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            className={props.classes.editModalSelect}
          >
            {FILTER_LABEL_DRIVER.paymentType.map((profile, i) => (
              <MenuItem key={i} value={profile.value}>
                {profile.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {props.type === 'CONTA' ? (
          <>
            <FormControl fullWidth>
              <TextField
                label="CPF/CNPJ do favorecido"
                id="accountDocument"
                name="accountDocument"
                value={props.accountDocument}
                margin="normal"
                onBlur={props.handleBlur}
                onChange={props.handleChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Nome/Razão social do destinatário"
                id="accountName"
                name="accountName"
                value={props.accountName}
                margin="normal"
                onBlur={props.handleBlur}
                onChange={props.handleChange}
              />
            </FormControl>
            <FormControl className={props.classes.formCtlInput} style={{ marginTop: '15px' }}>
              <InputLabel htmlFor="banco">Banco</InputLabel>

              <Select
                name="banco"
                value={props.bank}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              >
                {bancos.map((option, i) => (
                  <MenuItem key={i} value={option.IdBank}>
                    {option.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={props.classes.formCtlInput}>
              <TextField
                label="Agência"
                id="agencia"
                name="agencia"
                value={props.agency}
                margin="normal"
                onBlur={props.handleBlur}
                onChange={props.handleChange}
              />
            </FormControl>{' '}
            <FormControl className={props.classes.formCtlInput}>
              <TextField
                label="Conta"
                id="conta"
                name="conta"
                value={props.account}
                margin="normal"
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                style={{
                  marginRight: 15,
                }}
              />
            </FormControl>{' '}
            <FormControl className={props.classes.formCtlInput}>
              <TextField
                label="PIS"
                id="pis"
                name="pis"
                value={props.pis}
                margin="normal"
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                InputProps={{
                  maxLength: 20,
                  inputComponent: PisInput,
                }}
              />
            </FormControl>{' '}
            <FormControl className={props.classes.formCtlInput}>
              <TextField
                label="PIX"
                id="pix"
                name="pix"
                value={props.pix}
                margin="normal"
                onBlur={props.handleBlur}
                onChange={props.handleChange}
                InputProps={{
                  maxLength: 256,
                }}
              />
            </FormControl>{' '}
          </>
        ) : (
          <div className={props.classes.editModalBtnUp}>
            <Button
              size="medium"
              variant="contained"
              customColor="blue"
              onClick={props.openFileSelector}
            >
              SELECIONAR ARQUIVO
              <input
                type="file"
                id="PAYMENT_CARD_DRIVER"
                className={props.classes.fileInput}
                onChange={props.setImage}
              />
            </Button>
          </div>
        )}
      </DialogContent>

      <DialogActions className={props.classes.modalContentRoot}>
        {props.type === 'CONTA' ? (
          <Button
            size="large"
            customColor="blue"
            variant="contained"
            disabled={!checkEditor(props)}
            onClick={props.sumitPaymentForm}
          >
            Salvar
          </Button>
        ) : (
          <Button
            id="imageUrl"
            name="imageUrl"
            size="large"
            variant="contained"
            customColor="blue"
            onClick={props.upload}
            disabled={!props.imgUploaded}
            style={{ marginRight: 10 }}
          >
            Salvar
          </Button>
        )}

        <Button
          size="large"
          customColor="red"
          variant="contained"
          onClick={props.canCloseEditModal}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ModalEditConta);
