const styles = (theme) => ({
  paper: {
    padding: theme.spacing(1.25),
    margin: theme.spacing(1.25),
    display: "flex",
    backgroundColor: "#fff",
    borderRadius: 8,
    boxShadow: `0 4px 8px ${theme.palette.common.black}0.1`,
    "&:hover": {
      boxShadow: `0 8px 16px ${theme.palette.common.black}0.2`,
    },
  },
  contentContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  infoContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: theme.spacing(1.25),
  },
  avatarContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "space-between",
    margin: theme.spacing(1.25),
  },
  img: {
    width: 180,
    height: 180,
    borderRadius: '5px',
    border: "solid 4px #797979",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    marginBottom: theme.spacing(1.25),
    "& h2": {
      color: "#333",
      margin: 0,
    },
  },
  infoSection: {
    textAlign: "left",
    marginBottom: 16,
    "& p": {
      color: "#555",
      margin: "8px 0",
    },
    "& strong": {
      color: "#333",
    },
  },
  button: {
    marginTop: 16,
  },
});

export default styles;
