import headerbackground from "../../assets/image/fretador-fundo-small.png";

export default theme => {
  return {
    root: {
      display: "flex",
      justifyContent: "center",
      fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
      width: "100%",
      flexDirection: "column",
      height: "100%",
    },
    paper: {
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      padding: 0,
      "@media (max-width: 800px)": {
        overflow: "initial"
      }
    },
    itemsContainer: {
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    item: {
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "70px",
      padding: "5px 20px 5px 10px",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
      "@media (max-width: 800px)": {
        overflow: "initial"
      }
    },
    asideRow: {
      minHeight: "52px",
      "& > div:first-child": {
        height: "auto"
      }
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: "1",
      height: "100%",
    },
    containerVertical: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: "1",
      flexDirection: "column",
      "& label": {
        fontWeight: "bold",
        textTransform: "uppercase",
        color: "#2b596f"
      }
    },
    mainContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      flex: "1",
      height: "100%",
      padding: "10px",
      "@media (max-width: 800px)": {
        flexDirection: "column",
        padding: "0px 20px",
        justifyContent: "flex-start",
        overflow: "auto"
      }
    },
    freightContainer: {
      width: "40%",
      height: "1029px",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      marginTop: theme.spacing(1) * 2,
      "@media (max-width: 800px)": {
        width: "100%",
        display: "block"
      }
    },
    listContainer: {
      width: "60%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      marginTop: theme.spacing(1) * 2,
      marginLeft: "20px",
      "@media (max-width: 800px)": {
        width: "100%",
        display: "block",
        marginLeft: "0px"
      }
    },
    imagesContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      margin: "auto",
      padding: "10px",
      "@media (max-width: 800px)": {
        width: "100%",
        display: "block",
        marginLeft: "0px"
      }
    },
    textHeader: {
      color: "#2B596F",
      textTransform: "uppercase",
      fontSize: "18px",
      fontWeight: "bold",
      marginTop: '4px'
    },
    textTitle: {
      color: "#2B596F",
      textTransform: "uppercase",
      fontSize: "9px",
      marginTop: "5px",
      marginBottom: "5px"
    },
    textItemTitle: {
      color: "#2B596F",
      textTransform: "uppercase",
      fontSize: "15px",
      fontWeight: "bold",
      height: "100%",
      display: "flex",
      alignItems: "center",
      textDecoration: "none"
    },
    textItemContent: {
      color: "#707070",
      textTransform: "capitalize",
      fontSize: "10px",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& span": {
        maxWidth: "230px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
      }
    },
    textContent: {
      color: "#707070",
      fontSize: "12px",
      marginTop: "5px",
      marginBottom: "5px",
      paddingRight: "20px"
    },
    alertText: {
      color: 'var(--Perigo, #A33830)',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    commentContainer: {
      height: "82px",
      overflow: "auto"
    },
    modelInput: {
      fontSize: "12px",
      fontWeight: "bold",
      color: "#707070"
    },
    inputModal: {
      fontSize: "12px",
      fontWeight: "bold",
      color: "#707070",
      padding: "0",
      margin: "0",
      flexShrink: "0"
    },
    asideHeader: {
      color: "#ffffff",
      fontSize: "14px",
      height: "auto",
      minHeight: "44px",
      "& div": {
        flex: 1,
        lineHeight: "0px"
      },
      "& div p:first-child": {
        fontSize: "13px",
        marginTop: "10px"
      },
      "& div p": {
        display: "flex",
        alignItems: "center"
      },
      "& p span": {
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "inline-block",
        lineHeight: "normal",
        padding: "0px 4px"
      },
      "& p:nth-last-child(1)": {
        fontWeight: "bold",
        marginBottom: "10px"
      }
    },
    btn: {
      flexShrink: "0",
      // width: "125px",
      height: "40px",
      fontSize: "11px",
      fontWeight: "bold",
      lineHeight: "0px",
      textTransform: "uppercase",
      color: "#fff",
      margin: theme.spacing(2)
    },
    btnDisabled: {
      backgroundColor: "#ccc",
      cursor: "not-allowed",
      pointerEvents: "none"
    },
    btnSelect: {
      width: "125px",
      height: "70px",
      fontSize: "12px",
      fontWeight: "bold",
      lineHeight: "0px",
      textTransform: "uppercase",
      color: "#fff",
      margin: "10px 0px",
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
      boxShadow:
        "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
      backgroundImage: `url(${headerbackground})`,
      backgroundSize: "400%"
    },
    btnRedColor: {
      backgroundColor: "#A33C3C",
      "&:hover": {
        backgroundColor: "#632323"
      }
    },
    btnBlueColor: {
      backgroundColor: "#195A76",
      "&:hover": {
        backgroundColor: "#103a4c"
      }
    },
    btnGreenColor: {
      backgroundColor: "#3ea36d",
      "&:hover": {
        backgroundColor: "#235c3e"
      }
    },
    checkBox: {
      position: "absolute",
      right: "0",
      top: "0",
      "& > span > svg": {
        fontSize: "20px"
      }
    },
    divider: {
      height: "3px",
      backgroundColor: "#1A5B76"
    },
    dividerLight: {
      height: "1px",
      backgroundColor: "rgba(0,0,0,0.2)"
    },
    halfHeight: {
      height: "50%"
    },
    halfWidth: {
      width: "50%"
    },
    modal: {
      width: "500px",
      height: "250px",
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "column"
    },
    modalHeader: {
      backgroundImage: `url(${headerbackground})`,
      backgroundColor: "#1A5B76",
      width: "100%",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& p": {
        textTransform: "uppercase",
        color: "#fff",
        fontSize: "18px",
        fontWeight: "bold"
      }
    },
    modalContent: {
      display: "flex",
      justifyContent: "space-between",
      height: "70px",
      margin: "auto 0px",
      "& > $containerVertical > p": {
        fontSize: "14px",
        fontWeight: "bold",
        padding: "0",
        margin: "0"
      },
      "& > $containerVertical > p:nth-child(1)": {
        color: "#1A5B76"
      },
      "& > $containerVertical > p:nth-child(2)": {
        color: "#707070"
      }
    },
    modalFooter: {
      display: "flex",
      justifyContent: "space-between",
      flexShrink: "0",
      marginTop: "auto"
    },
    userImagesContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      paddingLeft: "15px",
    },
    driverImagesContainer: {
      padding: "10px",
      marginBottom: "120px",
    },
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    },
    modalOccurrenceContent: {
      maxWidth: '700px',
      width: '100%',
      height: '100%',
      maxHeight: '700px',
    },
    containerProgress: {},
    containerDisable: {
      position: 'relative',
      height: '100%',
      padding: "8px",
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 999,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      fontSize: '1.5rem',
      borderRadius: '4px'
    },
    spanDeleteConfirm: {
      color: 'var(--Black, var(--Main-Black, #0D0D0D))',
      fontFamily: 'Roboto',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    }
  };
};
