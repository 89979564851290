import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';

import logo from '../../assets/image/fretador-logo.png';
import styles from './styles';

class LoginComponent extends Component {
  state = {
    showPassword: false,
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  clearError = () => {
    if (this.props.error) {
      this.props.clearError();
    }
  };

  render() {
    const { classes, loading, ...props } = this.props;
    const inputLabelClasses = {
      root: classes.label,
      focused: classes.focused,
    };
    const inputClasses = {
      root: classes.input,
      disabled: classes.disabled,
      focused: classes.focused,
      error: classes.error,
    };

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <div className={classes.imgContainer}>
            <img className={classes.imgLogo} src={logo} alt="fretador logo" />
          </div>

          <form className={classes.formContainer}>
            <div className={classes.inputsContainer}>
              <FormControl className={classes.formCtlInput} fullWidth>
                <InputLabel shrink htmlFor="email" classes={inputLabelClasses}>
                  Login
                </InputLabel>
                <Input
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={props.handleChangeEmail}
                  value={props.email}
                  classes={inputClasses}
                  error={!!props.error}
                  onClick={this.clearError}
                />
              </FormControl>

              <FormControl fullWidth>
                <InputLabel shrink htmlFor="password" classes={inputLabelClasses}>
                  Senha
                </InputLabel>
                <Input
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={props.password}
                  onChange={props.handleChangePassword}
                  classes={inputClasses}
                  error={!!props.error}
                  onClick={this.clearError}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={this.handleClickShowPassword}>
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <div className={classes.passOptions}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      classes={{
                        root: classes.checkboxRoot,
                        checked: classes.checkboxChecked,
                      }}
                      checked={props.rememberUser}
                      onChange={props.handleToggleRemember}
                    />
                  }
                  label="LEMBRAR LOGIN"
                  classes={{ label: classes.checkboxLabel }}
                />
              </div>
              {props.error && (
                <div className={classes.authErrorMsg}>
                  <p>{props.error.toUpperCase()}</p>
                </div>
              )}
            </div>
            {this.props.children}
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              onClick={props.handleSubmit}
              className={classes.btnPrimary}
              disabled={!props.enabledSubmit || loading}
            >
              Entrar
              {loading && <CircularProgress size={18} className={classes.loading} />}
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

// LoginComponent.propTypes = {
//   classes: PropTypes.object.isRequired
// };

export default withStyles(styles)(LoginComponent);
