const UNDERLINE_COLOR = "#FFF";
const UNDERLINE_COLOR_ALPHA = "rgba(255,255,255,0.6)";
const INPUT_TEXT_COLOR = "#FFF";
const ERROR_COLOR = "red";
const TEXT_COLOR = "#FFF";

export default theme => {
  return {
    selectRoot: {
      width: "125px",
      marginRight: "50px"
    },
    selectLabel: {
      color: TEXT_COLOR,
      fontSize: "0.8rem",
      "&$focused": {
        color: TEXT_COLOR
      }
    },
    disabled: {},
    focused: {},
    error: {},
    input: {
      color: INPUT_TEXT_COLOR,
      fontSize: "0.8rem",
      "&$error": {
        color: ERROR_COLOR
      },
      "&$error:after": {
        borderBottom: `1px solid ${UNDERLINE_COLOR_ALPHA}`
      },
      "&:before": {
        borderBottom: `1px solid ${UNDERLINE_COLOR_ALPHA}`
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: `2px solid ${UNDERLINE_COLOR_ALPHA}`
      },
      "&:after": {
        borderBottom: `2px solid ${UNDERLINE_COLOR}`
      },
      "&:not($disabled) svg": {
        color: UNDERLINE_COLOR
      },
      "&:hover:not($disabled) svg": {
        color: UNDERLINE_COLOR
      }
    },
    iconButton: {
      padding: "1.5%"
    },
    searchNameContainer: {
      paddingTop: 45
    },
    filterList: {
      display: "flex",
      flexFlow: "wrap",
    },
    "@media (max-width: 768px)": {
      container: {
        flexDirection: "column",
        width: "768px"
      },
      nameFilterInptContainer: {
        marginLeft: "10px",
        marginTop: "10px"
      },
      selectFiltersContainer: {
        display: "flex",
        flexDirection: "row",
        margin: "10px 10px"
      },
      filterLabel: {
        fontSize: "0.65rem",
        marginRight: 15,
        marginTop: 13
      }
    }
  };
};
