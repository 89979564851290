import React, { Component } from "react";
import { withRouter } from "react-router";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Button from "../Button";
import { func, bool } from "prop-types";

class CreateNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      message: "",
      cpf: "",
    };
  }

  validate = (state) => {
    return state.title.trim().length && state.message.trim().length
      ? true
      : false;
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  notify = () => {
    const isValid = this.validate(this.state);

    if (!isValid) {
      this.props.enqueueSnackbar({
        message: "Os campos devem ser preenchidos",
        options: { variant: "error" },
      });
      return;
    }

    const notification = {
      title: this.state.title,
      message: this.state.message,
      cpf: this.state.cpf,
    };

    this.props.notificationBroadcastRequest(notification);
    this.clearNotification();
  };

  clearNotification = () => {
    this.setState({
      title: "",
      message: "",
      cpf: "",
    });
  };

  render() {
    const { classes, ...props } = this.props;

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <div className={classes.inputsContainer}>
            <Typography className={classes.title}>
              Envio de Notificação
            </Typography>
            <FormControl className={classes.formCtlInput}>
              <InputLabel htmlFor="title">Título</InputLabel>
              <Input
                id="title"
                name="title"
                autoComplete="title"
                autoFocus
                onChange={this.handleChange}
                value={this.state.title}
                inputProps={{
                  maxLength: 40,
                }}
              />
            </FormControl>

            <FormControl className={classes.formCtlInput}>
              <InputLabel htmlFor="cpf">CPF (Não informar para envio geral)</InputLabel>
              <Input
                id="cpf"
                name="cpf"
                autoComplete="cpf"
                onChange={this.handleChange}
                value={this.state.cpf}
              /> <br />
            </FormControl>

            <FormControl className={classes.formCtlInput}>
              <Typography noWrap className={classes.messageTitle}>
                Digite a mensagem da notificação:
              </Typography>
              <textarea
                name="message"
                className={classes.messageArea}
                onChange={this.handleChange}
                value={this.state.message}
                rows={10}
                maxLength={400}
              />
            </FormControl>
          </div>

          <Button
            size="large"
            variant="contained"
            customColor="blue"
            onClick={this.notify}
            isLoading={props.isLoading}
            className={classes.submitButton}
          >
            Enviar Notificação
          </Button>
        </Paper>
      </main>
    );
  }
}

CreateNotification.propTypes = {
  notificationBroadcastRequest: func.isRequired,
  isLoading: bool.isRequired,
};

export default withStyles(styles)(withRouter(CreateNotification));
