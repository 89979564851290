import { makeStyles } from '@material-ui/core/styles';
import { COLOR_BLUE, COLOR_BLUE_ALPHA8, COLOR_ERROR, COLOR_SUCCESS } from '../../globalTheme';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
  },
  uploadButton: {
    display: 'none',
  },
  uploadLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 2),
    backgroundColor: COLOR_BLUE,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    boxShadow: theme.shadows[3],
    textTransform: 'uppercase',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    '&:hover': {
      backgroundColor: COLOR_BLUE_ALPHA8,
      boxShadow: theme.shadows[4],
    },
  },
  documentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  documentItem: {
    position: 'relative',
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 150,
    height: 150,
    textAlign: 'center',
  },
  documentIcon: {
    fontSize: 48,
    color: COLOR_BLUE,
    marginBottom: theme.spacing(1),
  },
  documentName: {
    wordBreak: 'break-all',
    marginTop: 'auto',
    paddingTop: theme.spacing(1),
  },
  documentActions: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  viewButton: {
    color: COLOR_BLUE,
  },
  downloadButton: {
    color: COLOR_SUCCESS,
  },
  deleteButton: {
    color: COLOR_ERROR,
  },
  saveButton: {
    position: 'relative',
    backgroundColor: COLOR_BLUE,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 2),
    '&:hover': {
      backgroundColor: COLOR_BLUE_ALPHA8,
    },
  },
  addButton: {
    position: 'relative',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 2),
    '&:hover': {
      backgroundColor: COLOR_BLUE_ALPHA8,
    },
  },
  buttonProgress: {
    margin: 8,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalImage: {
    maxWidth: '100%',
    maxHeight: '80vh',
    marginBottom: theme.spacing(2),
  },
  modalIframe: {
    width: '100%',
    height: '80vh',
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    backgroundColor: COLOR_BLUE,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: COLOR_BLUE_ALPHA8,
    },
  },
  labelAlert: {
    marginLeft: 4,
    color: COLOR_ERROR,
  },
}));

export default useStyles;
