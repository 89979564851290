import React from 'react';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';

/**
 ### How to use ###
 *  1 - [OBRIGATÓRIO] Chamar o componente modal como open/close tag e dentro
       das tags passar a estrutura da modal.
    2 - [OPCIONAL] Passar a função para toggle da modal em props como
        atributo "<Modal modalHandler={myFuncName}> ... </Modal>"
 */

const Modal = (props) => {

  const {classes} = props;

  /*[WIP] 
  logica para ser passado o valor para a propriedade "top" quando 
  instancia o componente
  */
  let {position} = props;

  if (position > 100) position = 100;
  if (position < 0) position = 0;

  /* [WIP] */

  return (
    <>
    <div className={classes.backdrop} 
         onClick={() => props.modalHandler()}
    ></div>
    <div className={classes.main}>
      {props.children}
    </div>
    </>
  )
}

export default withStyles(styles)(Modal)