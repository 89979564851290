import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import VersionChecker, { useVersionChecker } from '../components/VersionChecker/VersionChecker';
import CreateNotification from '../components/CreateNotification';
import { notificationBroadcastRequest, enqueueSnackbar } from '../actions/notifications';

const CreateNotificationScreen = (props) => {
  useVersionChecker('CreateNotificationScreen');

  return (
    <main className="main-content">
      <VersionChecker />
      <CreateNotification
        isLoading={props.isLoading}
        notificationBroadcastRequest={props.notificationBroadcastRequest}
        enqueueSnackbar={props.enqueueSnackbar}
      />
    </main>
  );
};

const mapStateToProps = ({ notifications }) => ({
  isLoading: notifications.isLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      notificationBroadcastRequest,
      enqueueSnackbar,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNotificationScreen);
