import headerbackground from '../../assets/image/fretador-fundo.png';

export default () => ({
  tabsRoot: {
    margin: 'auto',
    width: 'auto',
    borderRadius: '10px',
    '@media (max-width: 1100px)': {
      width: '100%',
      display: 'none',
    },
  },
  tabsIndicator: {
    backgroundColor: '#1A5B76',
    height: '7px',
    '@media (max-width: 768px)': {
      height: '5px',
    },
  },
  tabRoot: {
    color: '#1A5B76',
    fontWeight: 600,
    '&$tabSelected': {
      color: '#1A5B76',
    },
    '@media (max-width: 768px)': {
      fontSize: '0.7em',
    },
    '&:hover': {
      backgroundColor: '#1a5a7672',
      borderRadius: '6px',
    },
  },
  tabSelected: {},
  menuButton: {
    display: 'none',
    '@media (max-width: 1098px)': {
      display: 'block',
      color: '#1A5B76',
      alignSelf: 'flex-start',
    },
  },
  drawer: {
    width: '250px',
  },
  listItem: {
    color: '#1A5B76',
    backgroundImage: `url(${headerbackground})`,
    margin: '10px auto',
    '&:hover': {
      backgroundColor: '#1A5B76',
      color: '#ffffff',
    },
  },
});
