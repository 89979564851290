import headerbackground from '../../assets/image/fretador-fundo-small.png';

export default (theme) => {
  return ({
    root: {
      width: '40%',
      '@media (max-width: 600px)': {
        width: '100%',
        display: 'block'
      }
    },
    header: {
      position: 'relative',
      backgroundImage: `url(${headerbackground})`,
      backgroundSize: '120%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#1A5B76',
      height: '60px',
    },
    headerIcon: {
      width: '56px',
      textAlign: 'center',
      lineHeight: 0,
      color: '#FFF'
    },
    headerText: {
      textTransform: 'uppercase',
      fontWeight: 600,
      color: '#FFF'
    },
    row: {
      position: 'relative',
      display: 'flex',
      padding: '2px'
    },
    rowIcon: {
      backgroundColor: '#EFEFEF',
      minWidth: '55px',
      height: '100%',
      textAlign: 'center',
      color: '#1A5B76',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        padding: '2px'
      }
    },
    rowContent: {
      marginLeft: theme.spacing(1),
      width: "calc(100% - 100px)",
      flex: 1
    },
  });
}