const UNDERLINE_COLOR = '#3385A6';
const UNDERLINE_COLOR_ALPHA = '#CCC';
const INPUT_TEXT_COLOR = '#3385A6';
const TEXT_COLOR = '#707070';
const ERROR_COLOR = '#C95858';

export default (theme) => ({
  main: {
    height: '100%',
    minHeight: '90vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    width: '400px',
    height: '480px',
    display: 'flex',
    flexDirection: 'column',
    padding: '5%',
    borderRadius: 0,
    [theme.breakpoints.up('md')]: { 
      padding: '3.8%'
    },
    [theme.breakpoints.up('lg')]: { 
      padding: '2.8%'
    }
  },
  imgContainer: {
    padding: '5% 3% 5%',
  },
  imgLogo: {
    width: '100%',
  },
  formContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    '&>$formCtlInput': {
      marginBottom: '5%',
    }
  },
  formCtlInput: {},
  label: {
    color: TEXT_COLOR,
    '&$focused': {
      color: TEXT_COLOR,
    }
  },
  disabled: {},
  focused: {},
  error: {},
  input: {
    color: INPUT_TEXT_COLOR,
    '&$error': {
      color: ERROR_COLOR
    },
    '&$error:after': {
      borderBottom: `1px solid ${UNDERLINE_COLOR_ALPHA}`
    },
    '&:before': {
      borderBottom: `1px solid ${UNDERLINE_COLOR_ALPHA}`,
      paddingBottom: '4%',
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `2px solid ${UNDERLINE_COLOR_ALPHA}`
    },
    '&:after': {
      borderBottom: `2px solid ${UNDERLINE_COLOR}`
    },
    '&:not($disabled) svg': {
      color: UNDERLINE_COLOR
    },
    '&:hover:not($disabled) svg': {
      color: UNDERLINE_COLOR
    }
  },
  passOptions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  forgotPasswordLink: {
    color: TEXT_COLOR,
    lineHeight: '4.46429em',
    fontSize: '0.8em'
  },
  // marginForgotPassword: {
  //   marginTop: 15
  // },
  checkboxChecked: {},
  checkboxRoot: {
    '&$checkboxChecked': {
      color: UNDERLINE_COLOR
    }
  },
  checkboxLabel: {
    fontSize: '0.7em',
    color: TEXT_COLOR
  },
  authErrorMsg: {
    backgroundColor: ERROR_COLOR,
    borderRadius: '9px',
    width: '70%',
    margin: '0 auto',
    color: 'white',
    '&>p': { 
      ...theme.typography.caption,
      textAlign: 'center',
      color: '#FFF'
    },
    animationDelay: '3s',
    animationDuration: '0.5s',
    animationFillMode: 'both',
    /* MESA-65 - removido à pedido do QA */
    // animationName: 'fadeOut'
  },
  '@keyframes fadeOut': {
    from: {opacity: 1},
    to: {opacity: 0}
  },
  btnPrimary: {
    fontSize: '1rem',
    height: '27%',
    backgroundImage: 'linear-gradient(to bottom, #3896BB, #2A627D)',
  },
  loading: {
    marginLeft: 8,
  }
});