import { makeStyles } from '@material-ui/core/styles';
import { COLOR_BLUE } from '../../globalTheme';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(1),
  },
  imageContainer: {
    display: 'flex',
    width: '804px',
    height: '834px',
    backgroundColor: theme.palette.grey[800],
    borderRadius: 5,
    border: (props) =>
      props.photo.status === 'APPROVED' ? 'solid 3px #797979' : 'solid 3px #A33C3C',
    '& > img': {
      width: 'unset !important',
      height: 'unset !important',
      margin: '0 auto',
    },
  },
  photoLabel: {
    padding: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: '12px',
    color: theme.palette.primary.main,
  },
  dialogActions: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  buttons: {
    height: '43px',
    width: '156px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 700,
  },
  closeButton: {
    height: '43px',
    width: '156px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 700,
    backgroundColor: '#FFF',
    color: COLOR_BLUE,
    border: '2px solid #1B556D',
  },
  messageContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  messageLabel: {
    fontSize: '11px',
    textTransform: 'uppercase',
    fontWeight: '600',
    color: '#565656',
    paddingLeft: '2px',
    paddingBottom: '2px',
  },
  messageInput: {
    borderRadius: theme.shape.borderRadius,
    resize: 'none',
    width: '100%',
    height: '70px',
    border: '1px solid #ccc',
  },
  goBackButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 12,
    paddingTop: 0,
  },
}), { name: 'ModalPhoto' });

export default useStyles;