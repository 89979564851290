import React from 'react';
import { useSelector } from 'react-redux';
import Home from '../components/Home';
import VersionChecker, { useVersionChecker } from '../components/VersionChecker/VersionChecker';

const HomeScreen: React.FC = () => {
  const auth = useSelector((state: any) => state.auth);

  useVersionChecker('HomeScreen');

  return (
    <main className="main-content">
      <VersionChecker />
      <Home roles={auth.userRoles} />
    </main>
  );
};

export default HomeScreen;
