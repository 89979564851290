export default theme => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      position: "relative"
    },
    mainContent: {
      padding: theme.spacing(1) * 2,
      height: "235px"
    },
    gatheringLine: {
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.6)",
      display: "flex",
      alignItems: "center",
      marginRight: "15px"
    },
    gatheringRegion: {
      fontSize: "12px",
      fontWeight: 600,
      color: "#000",
      maxWidth: "150px",
      display: "inline-block",
      padding: "0px 4px"
    },
    deliveryLine: {
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.6)",
      display: "flex",
      alignItems: "center",
      marginRight: "15px"
    },
    deliveryRegion: {
      fontWeight: 600,
      marginBottom: "4px",
      fontSize: "12px",
      color: "#000",
      marginLeft: "5px"
    },
    deliveryGatheringLine: {
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.6)",
      marginRight: "15px"
    },
    deliveryGatheringRegion: {
      fontWeight: 600,
      fontSize: "12px",
      color: "#000",
      padding: "2px"
    },
    line: {
      fontSize: "12px",
      margin: "4px 0",
      color: "rgba(0, 0, 0, 0.6)"
    },
    statusBar: {
      padding: "2px 1px",
      display: "flex",
      justifyContent: "space-around"
    },
    footerLineCreationDate: {
      color: "#FFF",
      fontSize: "12px"
    },
    footerLineStatus: {
      fontWeight: 700,
      color: "#FFF",
      fontSize: "12px"
    },
    divider: {
      backgroundColor: "rgba(0, 0, 0, 0.06)"
    },
    checkboxRoot: {
      position: "absolute",
      right: 0,
      "& > span > svg": {
        fontSize: "20px"
      }
    },
    cardOverlay: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.5)"
    },
    linkFix: {
      textDecoration: "none"
    },
    batchLabel: {
      backgroundColor: "#B01",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#fff",
      textTransform: "uppercase",
      padding: "1px 5px",
      borderRadius: "3px"
    },
    fixBatchPos: {
      display: "flex",
      justifyContent: "space-between"
    }
  };
};
