import { cloneDeep } from "lodash";
import {
  ORDERS_DETAIL_REQUEST,
  ORDERS_DETAIL_SUCCESS,
  ORDERS_DETAIL_FAILURE,
  ORDERS_DETAIL_UPDATE_REQUEST,
  ORDERS_DETAIL_UPDATE_SUCCESS,
  ORDERS_DETAIL_UPDATE_FAILURE,
  ORDERS_DETAIL_UPDATE_STATUS_REQUEST,
  ORDERS_DETAIL_UPDATE_STATUS_SUCCESS,
  ORDERS_DETAIL_UPDATE_STATUS_FAILURE,
  ORDER_CLEAR_DATA
} from "../actions/actionsTypes";

const INITIAL_STATE = {
  isLoading: false,
  orderData: {},
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case ORDER_CLEAR_DATA:
      return {
        ...state,
        orderData: {},
        isLoading: true,
      };

    case ORDERS_DETAIL_REQUEST:
      return {
        ...state,
      };
    case ORDERS_DETAIL_UPDATE_REQUEST:
    case ORDERS_DETAIL_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ORDERS_DETAIL_SUCCESS:
    case ORDERS_DETAIL_UPDATE_SUCCESS:
    case ORDERS_DETAIL_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderData: cloneDeep(payload.order),
      };

    case ORDERS_DETAIL_FAILURE:
    case ORDERS_DETAIL_UPDATE_FAILURE:
    case ORDERS_DETAIL_UPDATE_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};