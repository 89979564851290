import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Button, CircularProgress, Typography } from "@material-ui/core";
import clsx from "clsx";
import DriverCard from "../DriverCard";
import styles from "./styles";
import { USER_STATUS } from "../../constants";
import { dateNow, exportManyToExcel } from '../../utils/utils';

const exportAndLog = (driversList) => {
  exportManyToExcel(driversList, `Motoristas_${dateNow()}`, 'drivers');
};

const DriversList = (props) => {
  const { classes, driversList, isLoading, hasMore, handleLoadMore } = props;

  return (
    <div id="scrollableDiv" className={classes.root}>
      {!isLoading && (!driversList || driversList.length === 0) ? (
        <Typography className={classes.title}>
          Nenhum motorista encontrado.
        </Typography>
      ) : (
        <div className={classes.infiniteScrollContainer}>
          <Grid container spacing={3}>
            {driversList.map((driver) => (
              <Grid
                key={driver.id}
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.relativePos}
              >
                {!driver.updateAcknowledge && (
                  <div className={classes.statusIcon}></div>
                )}

                {driver.status === USER_STATUS.PENDING && (
                  <div
                    className={clsx(
                      classes.statusMessageFlag,
                      classes.pendingMessageFlag
                    )}
                  >
                    Aprovação pendente
                  </div>
                )}

                {driver.status === USER_STATUS.DENIED && (
                  <div
                    className={clsx(
                      classes.statusMessageFlag,
                      classes.deniedMessageFlag
                    )}
                  >
                    Usuário bloqueado
                  </div>
                )}
                <DriverCard driver={driver} />
              </Grid>
            ))}
          </Grid>
          {isLoading && (
            <div className={classes.progress}>
              <CircularProgress alt={"Loading..."} />
            </div>
          )}
          {!isLoading && hasMore && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoadMore}
              className={classes.loadMoreButton}
            >
              Carregar Mais
            </Button>
          )}
          {!isLoading && (
            <Button
              onClick={() => exportAndLog(driversList)}
              className={classes.exportButton}
            > Exportar lista
            </Button>
          )}
          {!hasMore && (
            <Typography className={classes.noMoreItems}>
              Não há mais itens para carregar.
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles, { name: "DriversList" })(DriversList);
