export const LOGIN_REQUEST = 'AUTH/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'AUTH/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'AUTH/LOGIN_FAILURE';
export const LOGOUT = 'AUTH/LOGOUT';
export const TOGGLE_REMEMBER_USER = 'AUTH/TOGGLE_REMEMBER_USER';
export const CLEAR_ERROR_MESSAGE = 'AUTH/CLEAR_ERROR_MESSAGE';
export const REGISTER_REQUEST = 'AUTH/REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'AUTH/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'AUTH/REGISTER_FAILURE';
export const DRIVER_LIST_REQUEST = 'DRIVER/LIST_REQUEST';
export const DRIVER_LIST_SUCCESS = 'DRIVER/LIST_SUCCESS';
export const DRIVER_LIST_SUCCESS_POOLING = 'DRIVER/LIST_SUCCESS_POOLING';
export const DRIVER_LIST_FAILURE = 'DRIVER/LIST_FAILURE';
export const DRIVER_CLEAR_LIST = 'DRIVER/CLEAR_LIST';
export const DRIVERS_CLEAR_FILTERS = 'DRIVERS/CLEAR_FILTERS';
export const DRIVERS_SET_FILTER = 'DRIVERS/SET_FILTER';
export const DRIVER_DETAIL_REQUEST = 'DRIVER/DETAIL_REQUEST';
export const DRIVER_DETAIL_SUCCESS = 'DRIVER/DETAIL_SUCCESS';
export const DRIVER_DETAIL_FAILURE = 'DRIVER/DETAIL_FAILURE';
export const DRIVER_DELETE_REQUEST = 'DRIVER/DELETE_REQUEST';
export const DRIVER_DELETE_SUCCESS = 'DRIVER/DELETE_SUCCESS';
export const DRIVER_DELETE_FAILURE = 'DRIVER/DELETE_FAILURE';
export const DRIVER_UPDATE_REQUEST = 'DRIVER/UPDATE_REQUEST';
export const DRIVER_UPDATE_SUCCESS = 'DRIVER/UPDATE_SUCCESS';
export const DRIVER_UPDATE_FAILURE = 'DRIVER/UPDATE_FAILURE';
export const DRIVER_BLOCK_REQUEST = 'DRIVER/BLOCK_REQUEST';
export const DRIVER_BLOCK_SUCCESS = 'DRIVER/BLOCK_SUCCESS';
export const DRIVER_BLOCK_FAILURE = 'DRIVER/BLOCK_FAILURE';
export const DRIVER_APPROVE_REQUEST = 'DRIVER/APPROVE_REQUEST';
export const DRIVER_APPROVE_SUCCESS = 'DRIVER/APPROVE_SUCCESS';
export const DRIVER_APPROVE_FAILURE = 'DRIVER/APPROVE_FAILURE';
export const DRIVER_IMAGE_APPROVAL_REQUEST = 'DRIVER/IMAGE_APPROVAL_REQUEST';
export const DRIVER_IMAGE_APPROVAL_SUCCESS = 'DRIVER/IMAGE_APPROVAL_SUCCESS';
export const DRIVER_IMAGE_APPROVAL_FAILURE = 'DRIVER/IMAGE_APPROVAL_FAILURE';
export const DRIVER_IMAGE_BLOCKING_REQUEST = 'DRIVER/IMAGE_BLOCKING_REQUEST';
export const DRIVER_IMAGE_BLOCKING_SUCCESS = 'DRIVER/IMAGE_BLOCKING_SUCCESS';
export const DRIVER_IMAGE_BLOCKING_FAILURE = 'DRIVER/IMAGE_BLOCKING_FAILURE';
export const DRIVER_CLEAR_DETAIL_DATA = 'DRIVER/CLEAR_DETAIL_DATA';
export const FREIGHTS_LIST_REQUEST = 'FREIGHTS/LIST_REQUEST';
export const FREIGHTS_LIST_SUCCESS = 'FREIGHTS/LIST_SUCCESS';
export const FREIGHTS_FILTER_RUNING = 'FREIGHTS/FREIGHTS_FILTER_RUNING';
export const FREIGHTS_LIST_SUCCESS_POOLING = 'FREIGHTS/FREIGHTS_LIST_SUCCESS_POOLING';
export const FREIGHTS_LIST_FAILURE = 'FREIGHTS/LIST_FAILURE';
export const FREIGHTS_CLEAR_LIST = 'FREIGHTS/CLEAR_LIST';
export const FREIGHTS_CLEAR_FILTERS = 'FREIGHTS/CLEAR_FILTERS';
export const FREIGHTS_SET_FILTER = 'FREIGHTS/SET_FILTER';
export const FREIGHTS_DETAIL_REQUEST = 'FREIGHTS/DETAIL_REQUEST';
export const FREIGHTS_DETAIL_SUCCESS = 'FREIGHTS/DETAIL_SUCCESS';
export const FREIGHTS_DETAIL_FAILURE = 'FREIGHTS/DETAIL_FAILURE';
export const FREIGHTS_DETAIL_DUPLICATE_REQUEST = 'FREIGHTS/DETAIL/DUPLICATE_REQUEST';
export const FREIGHTS_DETAIL_DUPLICATE_SUCCESS = 'FREIGHTS/DETAIL/DUPLICATE_SUCCESS';
export const FREIGHTS_DETAIL_DUPLICATE_FAILURE = 'FREIGHTS/DETAIL/DUPLICATE_FAILURE';
export const FREIGHTS_DETAIL_DELETE_REQUEST = 'FREIGHTS/DETAIL/DELETE_REQUEST';
export const FREIGHTS_DETAIL_DELETE_SUCCESS = 'FREIGHTS/DETAIL/DELETE_SUCCESS';
export const FREIGHTS_DETAIL_DELETE_FAILURE = 'FREIGHTS/DETAIL/DELETE_FAILURE';
export const FREIGHTS_DETAIL_UPDATE_REQUEST = 'FREIGHTS/DETAIL/UPDATE_REQUEST';
export const FREIGHTS_DETAIL_UPDATE_SUCCESS = 'FREIGHTS/DETAIL/UPDATE_SUCCESS';
export const FREIGHTS_DETAIL_UPDATE_FAILURE = 'FREIGHTS/DETAIL/UPDATE_FAILURE';
export const FREIGHTS_DETAIL_UPDATE_STATUS_REQUEST = 'FREIGHTS/DETAIL/UPDATE_STATUS_REQUEST';
export const FREIGHTS_DETAIL_UPDATE_STATUS_SUCCESS = 'FREIGHTS/DETAIL/UPDATE_STATUS_SUCCESS';
export const FREIGHTS_DETAIL_UPDATE_STATUS_FAILURE = 'FREIGHTS/DETAIL/UPDATE_STATUS_FAILURE';
export const FREIGHTS_LIST_DETAIL_REQUEST = 'FREIGHTS/LIST_DETAIL_REQUEST';
export const FREIGHTS_LIST_DETAIL_SUCCESS = 'FREIGHTS/LIST_DETAIL_SUCCESS';
export const FREIGHTS_LIST_DETAIL_FAILURE = 'FREIGHTS/LIST_DETAIL_FAILURE';
export const FREIGHTS_LIST_FILTER_REQUEST = 'FREIGHTS/LIST_FILTER_REQUEST';
export const FREIGHTS_LIST_FILTER_SUCCESS = 'FREIGHTS/LIST_FILTER_SUCCESS';
export const FREIGHTS_LIST_FILTER_FAILURE = 'FREIGHTS/LIST_FILTER_FAILURE';
export const FREIGHT_LOCATION_SUCCESS = 'FREIGHT/LOCATION/SUCCESS';
export const ORDERS_LIST_REQUEST = 'ORDERS/LIST_REQUEST';
export const ORDERS_LIST_SUCCESS = 'ORDERS/LIST_SUCCESS';
export const ORDERS_FILTER_RUNING = 'ORDERS/ORDERS_FILTER_RUNING';
export const ORDERS_LIST_SUCCESS_POOLING = 'ORDERS/ORDERS_LIST_SUCCESS_POOLING';
export const ORDERS_LIST_FAILURE = 'ORDERS/LIST_FAILURE';
export const ORDERS_CLEAR_LIST = 'ORDERS/CLEAR_LIST';
export const ORDERS_CLEAR_FILTERS = 'ORDERS/CLEAR_FILTERS';
export const ORDERS_SET_FILTER = 'ORDERS/SET_FILTER';
export const ORDERS_DETAIL_REQUEST = 'ORDERS/DETAIL_REQUEST';
export const ORDERS_DETAIL_SUCCESS = 'ORDERS/DETAIL_SUCCESS';
export const ORDERS_DETAIL_FAILURE = 'ORDERS/DETAIL_FAILURE';
export const ORDERS_DETAIL_DUPLICATE_REQUEST = 'ORDERS/DETAIL/DUPLICATE_REQUEST';
export const ORDERS_DETAIL_DUPLICATE_SUCCESS = 'ORDERS/DETAIL/DUPLICATE_SUCCESS';
export const ORDERS_DETAIL_DUPLICATE_FAILURE = 'ORDERS/DETAIL/DUPLICATE_FAILURE';
export const ORDERS_DETAIL_DELETE_REQUEST = 'ORDERS/DETAIL/DELETE_REQUEST';
export const ORDERS_DETAIL_DELETE_SUCCESS = 'ORDERS/DETAIL/DELETE_SUCCESS';
export const ORDERS_DETAIL_DELETE_FAILURE = 'ORDERS/DETAIL/DELETE_FAILURE';
export const ORDERS_DETAIL_UPDATE_REQUEST = 'ORDERS/DETAIL/UPDATE_REQUEST';
export const ORDERS_DETAIL_UPDATE_SUCCESS = 'ORDERS/DETAIL/UPDATE_SUCCESS';
export const ORDERS_DETAIL_UPDATE_FAILURE = 'ORDERS/DETAIL/UPDATE_FAILURE';
export const ORDERS_DETAIL_UPDATE_STATUS_REQUEST = 'ORDERS/DETAIL/UPDATE_STATUS_REQUEST';
export const ORDERS_DETAIL_UPDATE_STATUS_SUCCESS = 'ORDERS/DETAIL/UPDATE_STATUS_SUCCESS';
export const ORDERS_DETAIL_UPDATE_STATUS_FAILURE = 'ORDERS/DETAIL/UPDATE_STATUS_FAILURE';
export const ORDERS_LIST_DETAIL_REQUEST = 'ORDERS/LIST_DETAIL_REQUEST';
export const ORDERS_LIST_DETAIL_SUCCESS = 'ORDERS/LIST_DETAIL_SUCCESS';
export const ORDERS_LIST_DETAIL_FAILURE = 'ORDERS/LIST_DETAIL_FAILURE';
export const ORDERS_LIST_FILTER_REQUEST = 'ORDERS/LIST_FILTER_REQUEST';
export const ORDERS_LIST_FILTER_SUCCESS = 'ORDERS/LIST_FILTER_SUCCESS';
export const ORDERS_LIST_FILTER_FAILURE = 'ORDERS/LIST_FILTER_FAILURE';
export const ORDER_CLEAR_DATA = 'ORDER/CLEAR_DATA';
export const OCCURRENCES_REQUEST = 'OCCURRENCES/LIST_REQUEST';
export const OCCURRENCES_SUCCESS = 'OCCURRENCES/LIST_SUCCESS';
export const OCCURRENCES_FAILURE = 'OCCURRENCES/LIST_FAILURE';
export const OCCURRENCES_CLEAR_LIST = 'OCCURRENCES/CLEAR_LIST';
export const OCCURRENCES_CLEAR_FILTERS = 'OCCURRENCES/CLEAR_FILTERS';
export const OCCURRENCES_SET_FILTER = 'OCCURRENCES/SET_FILTER';
export const CREATE_FREIGHT_REQUEST = 'FREIGHTS/CREATE_REQUEST';
export const CREATE_FREIGHT_SUCCESS = 'FREIGHTS/CREATE_SUCCESS';
export const CREATE_FREIGHT_FAILURE = 'FREIGHTS/CREATE_FAILURE';
export const CREATE_ORDER_REQUEST = 'ORDERS/CREATE_REQUEST';
export const CREATE_ORDER_SUCCESS = 'ORDERS/CREATE_SUCCESS';
export const CREATE_ORDER_FAILURE = 'ORDERS/CREATE_FAILURE';
export const ADD_NOTIFICATION = 'NOTIFIER/ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'NOTIFIER/REMOVE_NOTIFICATION';
export const NOTIFY_BROADCAST_REQUEST = 'NOTIFY_BROADCAST_REQUEST';
export const NOTIFY_BROADCAST_SUCCESS = 'NOTIFY_BROADCAST_SUCCESS';
export const NOTIFY_BROADCAST_FAILURE = 'NOTIFY_BROADCAST_FAILURE';
export const COUNTERS_REQUEST = 'COUNTERS_REQUEST';
export const COUNTERS_SUCCESS = 'COUNTERS_SUCCESS';
export const COUNTERS_FAILURE = 'COUNTERS_FAILURE';
export const OCCURRENCE_DETAIL_REQUEST = 'OCCURRENCE/DETAIL_REQUEST';
export const OCCURRENCE_DETAIL_SUCCESS = 'OCCURRENCE/DETAIL_SUCCESS';
export const OCCURRENCE_DETAIL_FAILURE = 'OCCURRENCE/DETAIL_FAILURE';
export const OCCURRENCE_REPLY_REQUEST = 'OCCURRENCE/REPLY_REQUEST';
export const OCCURRENCE_REPLY_SUCCESS = 'OCCURRENCE/REPLY_SUCCESS';
export const OCCURRENCE_REPLY_FAILURE = 'OCCURRENCE/REPLY_FAILURE';
export const CLEAR_OCCURRENCE_DATA = 'OCCURRENCE/CLEAR_DATA';
export const OCCURRENCE_MESSAGES_REQUEST = 'OCCURRENCE_MESSAGES_REQUEST';
export const OCCURRENCE_MESSAGES_SUCCESS = 'OCCURRENCE_MESSAGES_SUCCESS';
export const OCCURRENCE_MESSAGES_FAILURE = 'OCCURRENCE_MESSAGES_FAILURE';
export const OCCURRENCE_LIST_REQUEST = 'OCCURRENCE_LIST_REQUEST';
export const OCCURRENCE_LIST_SUCCESS = 'OCCURRENCE_LIST_SUCCESS';
export const OCCURRENCE_LIST_FAILURE = 'OCCURRENCE_LIST_FAILURE';
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const CLEAR_FREIGHT_DETAIL = 'CLEAR_FREIGHT_DETAIL';