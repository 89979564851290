import asideHeaderbackground from '../../assets/image/fretador-fundo-small.png';
import {
  COLOR_DEFAULT,
} from '../../globalTheme'
const asideHeaderbackgroundColor = '#1A5B76';

export default (theme) => {
  return {
    asideHeaderText: {
      textTransform: 'uppercase',
      fontWeight: 600,
      color: '#FFF',
    },
    modalHeaderContainer: {
      backgroundImage: `url(${asideHeaderbackground})`,
      backgroundSize: '120%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: asideHeaderbackgroundColor,
    },
    modalContentRoot: {
      padding: 12,
    },
    modalContentText: {
      marginTop: 10,
    },
    buttonConfirm: {
      fontSize: '12px',
      textAlign: 'center',
      fontWeight: 700,
      backgroundColor: '#FFF',
      color: COLOR_DEFAULT,
      border: '2px solid #1B556D',
    }
  }
};
