import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  NOTIFY_BROADCAST_REQUEST,
  NOTIFY_BROADCAST_SUCCESS,
  NOTIFY_BROADCAST_FAILURE
} from "../actions/actionsTypes";

const INITIAL_STATE = {
  notifications: [],
  isLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, { ...action.notification }]
      };

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key
        )
      };

    case NOTIFY_BROADCAST_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case NOTIFY_BROADCAST_SUCCESS:
    case NOTIFY_BROADCAST_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};
