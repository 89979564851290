import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, DirectionsRenderer } from '@react-google-maps/api';
import debounce from 'lodash/debounce';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const debouncedSetDirections = debounce((DirectionsService, options, setter) => {
  DirectionsService.route(options, (result, status) => {
    if (status === window.google.maps.DirectionsStatus.OK) {
      setter(result);
      console.log("Directions service returned success status: " + status + "/ Hora Atual: " + new Date());
    } else {
      console.error(`Failed to load directions: ${status}`);
    }
  });
}, 1000);

function FreightDirections({ freight, freightLocation }) {
  const { gatheringLatitude, gatheringLongitude, deliveryLatitude, deliveryLongitude } = freight;
  const [userLocation, setUserLocation] = useState(null); // eslint-disable-next-line
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);
  const [lastDriverLocation, setLastDriverLocation] = useState(null);

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => setUserLocation({ lat: latitude, lng: longitude }),
        (error) => console.error('Erro ao obter a localização do usuário:', error),
        { enableHighAccuracy: true }
      );
    } else {
      console.error('Geolocalização não suportada pelo navegador.');
    }
  }, []);

  const driver = useMemo(() => {
    if (freightLocation.length > 0 && freightLocation[0].location[0] && freightLocation[0].location[1]) {
      return { lat: parseFloat(freightLocation[0].location[0]), lng: parseFloat(freightLocation[0].location[1]) };
    }
    return null;
  }, [freightLocation]);

  useEffect(() => {
    if (driver && (!lastDriverLocation || (lastDriverLocation.lat !== driver.lat || lastDriverLocation.lng !== driver.lng))) {
      setLastDriverLocation(driver);
    }
  }, [driver, lastDriverLocation]);

  const origin = useMemo(() => (gatheringLatitude && gatheringLongitude ? { lat: parseFloat(gatheringLatitude), lng: parseFloat(gatheringLongitude) } : userLocation), [gatheringLatitude, gatheringLongitude, userLocation]);

  const destination = useMemo(() => (deliveryLatitude && deliveryLongitude ? { lat: parseFloat(deliveryLatitude), lng: parseFloat(deliveryLongitude) } : null), [deliveryLatitude, deliveryLongitude]);

  const directionsOptions = useMemo(() => ({
    origin: lastDriverLocation || userLocation,
    waypoints: origin ? [{ location: origin }] : [],
    destination: destination,
    travelMode: 'DRIVING',
    optimizeWaypoints: false,
  }), [lastDriverLocation, origin, destination, userLocation]);

  useEffect(() => {
    if (!origin || !destination || !lastDriverLocation) {
      return;
    }

    const DirectionsService = new window.google.maps.DirectionsService();
    debouncedSetDirections(DirectionsService, directionsOptions, setDirections);

    return () => debouncedSetDirections.cancel(); // eslint-disable-next-line
  }, [directionsOptions]);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={lastDriverLocation || origin}
      zoom={4}
      onLoad={setMap}
      onUnmount={() => setMap(null)}
    >
      {directions && <DirectionsRenderer directions={directions} />}
    </GoogleMap>
  );
}

export default React.memo(FreightDirections);