import api from '../api';
import { clearFreightList } from './freights';
import { enqueueSnackbar } from './notifications';

import {
  FREIGHTS_DETAIL_REQUEST,
  FREIGHTS_DETAIL_SUCCESS,
  FREIGHTS_DETAIL_FAILURE,
  FREIGHTS_DETAIL_DUPLICATE_REQUEST,
  FREIGHTS_DETAIL_DUPLICATE_SUCCESS,
  FREIGHTS_DETAIL_DUPLICATE_FAILURE,
  FREIGHTS_DETAIL_DELETE_REQUEST,
  FREIGHTS_DETAIL_DELETE_SUCCESS,
  FREIGHTS_DETAIL_DELETE_FAILURE,
  FREIGHTS_DETAIL_UPDATE_REQUEST,
  FREIGHTS_DETAIL_UPDATE_SUCCESS,
  FREIGHTS_DETAIL_UPDATE_FAILURE,
  FREIGHTS_DETAIL_UPDATE_STATUS_REQUEST,
  FREIGHTS_DETAIL_UPDATE_STATUS_SUCCESS,
  FREIGHTS_DETAIL_UPDATE_STATUS_FAILURE,
  FREIGHT_LOCATION_SUCCESS,
} from './actionsTypes';

export const requestFreightDetail = (id) => {
  return (dispatch) => {
    dispatch({ type: FREIGHTS_DETAIL_REQUEST, id });

    api
      .get(`/freight/${id}?shortHistory=true&expandUsers=true`)
      .then((res) => {
        dispatch(freightDetailSuccess(res.data));
      })
      .catch((err) => {
        dispatch(freightDetailFailure(err.message));
        dispatch(
          enqueueSnackbar({
            message: 'Frete excluído, inativo ou não encontrado',
            options: { variant: 'error' },
          }),
        );
      });
  };
};

export const freightDetailSuccess = (payload) => {
  return { type: FREIGHTS_DETAIL_SUCCESS, payload };
};

export const freightDetailFailure = (payload) => {
  return { type: FREIGHTS_DETAIL_FAILURE, payload };
};

export const freightDetailDeleteOfferSuccess = (id) => {
  return { type: FREIGHTS_DETAIL_DELETE_SUCCESS, payload: { id } };
};

export const freightDetailDeleteOfferFailure = () => {
  return { type: FREIGHTS_DETAIL_DELETE_FAILURE };
};

export const requestFreightDetailDeleteOffer = (id, history) => {
  return (dispatch) => {
    dispatch({ type: FREIGHTS_DETAIL_DELETE_REQUEST });

    api
      .delete(`/freight/${id}`)
      .then((res) => {
        dispatch(freightDetailDeleteOfferSuccess(id));

        dispatch(clearFreightList());

        dispatch(
          enqueueSnackbar({
            message: 'Frete excluído com sucesso',
            options: { variant: 'success' },
          }),
        );

        history.push('/offers');
      })
      .catch((err) => {
        dispatch(freightDetailDeleteOfferFailure(err));

        dispatch(
          enqueueSnackbar({
            message: 'Erro na exclusão do frete',
            options: { variant: 'error' },
          }),
        );
      });
  };
};

export const updateFreightDetailSuccess = (payload) => {
  return { type: FREIGHTS_DETAIL_UPDATE_SUCCESS, payload };
};

export const updateFreightDetailFailure = () => {
  return { type: FREIGHTS_DETAIL_UPDATE_FAILURE };
};

export const freightDetailDuplicateOfferSucess = (payload) => {
  return { type: FREIGHTS_DETAIL_DUPLICATE_SUCCESS, payload };
};

export const freightDetailDuplicateOfferFailure = () => {
  return { type: FREIGHTS_DETAIL_DUPLICATE_FAILURE };
};

export const requestFreightDetailDuplicateOffer = (freight, history) => {
  return (dispatch) => {
    dispatch({ type: FREIGHTS_DETAIL_DUPLICATE_REQUEST });

    api
      .post(`/freight`, freight)
      .then((res) => {
        dispatch(freightDetailDuplicateOfferSucess(res.data));

        dispatch(clearFreightList());

        dispatch(
          enqueueSnackbar({
            message: 'Frete duplicado com sucesso',
            options: { variant: 'success' },
          }),
        );

        history.push('/offers');
      })
      .catch((err) => {
        dispatch(freightDetailDuplicateOfferFailure(err));

        dispatch(
          enqueueSnackbar({
            message: 'Erro ao duplicar o frete',
            options: { variant: 'error' },
          }),
        );
      });
  };
};

export const requestUpdateFreightDetail = (payload, redirect) => {
  const { freight, data } = payload;

  if (data) {
    data.boardRequest = true;
  }

  return (dispatch) => {
    dispatch({ type: FREIGHTS_DETAIL_UPDATE_REQUEST, data });

    api
      .patch(`/freight/${freight.id}`, data)
      .then((res) => {
        dispatch(updateFreightDetailSuccess(freight));
        dispatch(clearFreightList());
        dispatch(
          enqueueSnackbar({
            message: 'Frete atualizado com sucesso',
            options: { variant: 'success' },
          }),
        );

        if (redirect) {
          window.location.replace(redirect);
        }
      })
      .catch((err) => {
        console.log("ERRO: ", err)
        dispatch(updateFreightDetailFailure(err));

        dispatch(
          enqueueSnackbar({
            message: 'Erro ao atualizar o frete',
            options: { variant: 'error' },
          }),
        );
      });
  };
};



export const requestFreightImageBlocking = payload => {
  return dispatch => {
    const { data, driverId } = payload;

    dispatch({ type: FREIGHTS_DETAIL_UPDATE_REQUEST, payload });

    api
      .patch(`/freight/${driverId}`, data)
      .then(res => {
        dispatch(updateFreightDetailSuccess(data));

        dispatch(clearFreightList());
        dispatch(
          enqueueSnackbar({
            message: "Nova foto solicitada.",
            options: { variant: "success" }
          })
        );
      })
      .catch(err => {
        dispatch(updateFreightDetailFailure(err));

        dispatch(
          enqueueSnackbar({
            message: 'Erro ao atualizar o frete',
            options: { variant: 'error' },
          }),
        );
      });
  };
};

export const requestUpdateStatusFreightDetail = (
  status,
  idFreight,
  content,
  history,
  showNotification,
) => {
  return (dispatch) => {
    const data = {
      userId: content.userId,
      pickupOrderPhoto: content.pickupOrderPhoto,
      invoicePhoto: content.invoicePhoto,
      status,
      boardRequest: true,
      boardUser: content.boardUser,
    };
    dispatch({ type: FREIGHTS_DETAIL_UPDATE_STATUS_REQUEST, payload: data });

    api
      .patch(`/freight/${idFreight}/status`, data)
      .then((res) => {
        dispatch(updateStatusFreightDetailSuccess(res.data));
        !!history && history.push('/offers');
        if (showNotification) {
          dispatch(
            enqueueSnackbar({
              message: 'Frete atualizado com sucesso.',
              options: { variant: 'success' },
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(updateStatusFreightDetailFailure());
        if (showNotification) {
          dispatch(
            enqueueSnackbar({
              message: 'Erro ao atualizar o frete. Tente novamente.',
              options: { variant: 'error' },
            }),
          );
        }
      });
  };
};

export const updateStatusFreightDetailSuccess = (payload) => {
  return { type: FREIGHTS_DETAIL_UPDATE_STATUS_SUCCESS, payload };
};

export const updateStatusFreightDetailFailure = () => {
  return { type: FREIGHTS_DETAIL_UPDATE_STATUS_FAILURE };
};

export const getFreightsLocations = (freightId, userId) => {
  return (dispatch) => {
    api.get(`/freight/${freightId}/driver/${userId}`).then((res) => {
      dispatch(requestFreightLocationSuccess(res.data));
    });
  };
};

export const requestFreightLocationSuccess = (payload) => {
  return { type: FREIGHT_LOCATION_SUCCESS, payload };
};

export const clearFreightDetail = () => {
  return { type: 'CLEAR_FREIGHT_DETAIL' };
};