import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import createGlobalTheme from './globalTheme';
import DateUtilsProvider from './DateUtilsProvider';
import Routes from './routes';
import Header from './components/Header';
import Notifier from './containers/Notifier';
import useSocketConnection from './hooks/useSocketConnection';
import { isUserLogged } from './utils/auth';
import UpdateNotification from './components/UpdateNotification';
import Footer from './components/Footer';
const versionApp = sessionStorage.getItem('versionApp');

function App() {
  const auth = useSelector((state: any) => state.auth);
  const { isConnected, connectSocket } = useSocketConnection();
  const userLogged = isUserLogged(auth);
  const socketInterval = useRef<null | NodeJS.Timeout>(null);

  const theme = createGlobalTheme();

  useEffect(() => {
    if (isConnected && socketInterval.current) {
      clearInterval(socketInterval.current);
    }
    if (userLogged && !isConnected) {
      connectSocket();
      socketInterval.current = setInterval(() => {
        connectSocket();
      }, 30000); // 30 seconds
    }

    return () => {
      if (socketInterval.current) {
        clearInterval(socketInterval.current);
      }
    };
  }, [userLogged, isConnected, connectSocket]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DndProvider backend={HTML5Backend}>
        <div style={appStyle}>
          <BrowserRouter>
            {auth.isAuthenticated && (
              <Header
                roles={auth && auth.userRoles ? auth.userRoles : []}
              />
            )}
            <DateUtilsProvider>
              <Routes />
            </DateUtilsProvider>
          </BrowserRouter>
          <Notifier />
          <UpdateNotification />
          <Footer versionApp={versionApp} />
        </div>
      </DndProvider>
    </ThemeProvider>
  );
}

const appStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
};

export default App;
