import {
  DRIVER_LIST_REQUEST,
  DRIVER_LIST_SUCCESS,
  DRIVER_LIST_SUCCESS_POOLING,
  DRIVER_LIST_FAILURE,
  DRIVER_CLEAR_LIST,
  DRIVERS_CLEAR_FILTERS,
  DRIVERS_SET_FILTER,
} from '../actions/actionsTypes';
import { uniqBy } from 'lodash';

import { ROWS_PER_PAGE_IN_CANVAS } from '../constants';

export const INITIAL_STATE = {
  driversList: [],
  filters: {
    name: '',
    cpf: '',
    vehicleType: '',
    vehicleOwnedByUser: '',
    walletType: '',
    status: '',
    limit: ROWS_PER_PAGE_IN_CANVAS,
  },
  isLoading: false,
  hasMore: true,
  skip: 0,
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case DRIVER_CLEAR_LIST:
      return {
        ...state,
        skip: 0,
        hasMore: true,
        driversList: [],
      };

    case DRIVERS_CLEAR_FILTERS:
      return {
        ...state,
        skip: 0,
        hasMore: true,
        driversList: [],
        filters: {
          name: '',
          cpf: '',
          vehicleType: '',
          vehicleOwnedByUser: '',
          walletType: '',
          status: '',
          limit: ROWS_PER_PAGE_IN_CANVAS,
        },
      };

    case DRIVERS_SET_FILTER:
      return {
        ...state,
        skip: 0,
        hasMore: true,
        driversList: [],
        filters: {
          ...state.filters,
          ...payload,
        },
      };

    case DRIVER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DRIVER_LIST_SUCCESS_POOLING:
      if (state.isLoading) {
        return { ...state };
      } else {
        return {
          ...state,
          isLoading: false,
          driversList: uniqBy([...state.driversList, ...payload.users], 'id'),
        };
      }

    case DRIVER_LIST_SUCCESS:
      const newDriversList = uniqBy([...state.driversList, ...payload.users], 'id');
      return {
        ...state,
        isLoading: false,
        skip: state.skip + payload.users.length,
        hasMore: payload.users.length === state.filters.limit,
        driversList: newDriversList,
      };

    case DRIVER_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
