const colors = {
  text: "#FFF",
  background: "#1A5B76",
};

const dimensions = {
  containerHeight: "7rem",
  containerHeightLarge: "7rem",
  marginTop: "5rem",
  paddingTop: "1rem",
  paddingLeft: "1rem",
  nameFilterInputWidth: "280px",
  imageFilterContainerWidth: "8rem",
  imageFilterHeight: "5rem",
  imageFilterSize: "2rem",
};

export default theme => ({
  container: {
    backgroundColor: colors.background,
    display: "flex",
    marginTop: dimensions.marginTop,
    paddingLeft: "0.5rem",
    paddingTop: "0.5rem",
    flexDirection: "row", // default to row
    justifyContent: "center",
    // Media queries
    "@media (max-width: 320px)": {
      // Adjustments for extra small devices
      flexDirection: "column",
      paddingLeft: "0.5rem",
      height: "auto",
    },
    "@media (max-width: 440px)": {
      // Adjustments for small devices
      flexDirection: "column",
    },
    "@media (max-width: 768px)": {
      // Adjustments for medium devices
    },
    "@media (max-width: 992px)": {
      // Adjustments for large devices
    },
    "@media (max-width: 1200px)": {
      // Adjustments for extra large devices
    },
  },
  nameFilterInputContainer: {
    width: dimensions.nameFilterInputWidth,
    display: "flex",
    flexDirection: "column",
  },
  filterLabel: {
    fontWeight: "bold",
    color: colors.text,
    fontSize: "0.8rem",
  },
  imgFilterContainer: {
    width: dimensions.imageFilterContainerWidth,
    paddingLeft: "1rem",
    height: dimensions.imageFilterHeight,
    display: "flex",
    flexDirection: "column",
    gap: "0.4rem",
  },
  imgFilter: {
    width: dimensions.imageFilterSize,
    height: dimensions.imageFilterSize,
  },
});
