import React, { Component } from 'react';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import {
  ADMIN_PROFILE,
  FINANCIAL_PROFILE,
  OPERATION_PROFILE,
  SAC_PROFILE
} from '../../constants';

import styles from './styles';
import Button from '../Button';

class RegisterComponent extends Component {

  state = {
    showPassword: false,
    isFormValid: false,
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  removeAccent = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }

  render() {
    const { onCancel, classes, ...props } = this.props;

    const profileList = [
      { id: 1, name: 'Administrador', key: ADMIN_PROFILE },
      { id: 2, name: 'Financeiro', key: FINANCIAL_PROFILE },
      { id: 3, name: 'SAQ', key: SAC_PROFILE },
      { id: 4, name: 'Operação', key: OPERATION_PROFILE },
    ];

    const handleSubmitAndSearch = async () => {
      const success = await props.handleSubmit();
      if (success) {
        console.log("Usuário criado com sucesso. Atualizando lista...");
        onCancel();
        props.handleSearch(`${props.filterName}=${props.filterValue}`);
      } else {
        console.error('Erro ao tentar atualizar lista de usuários.');
      }
    };


    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <div className={classes.inputsContainer}>
            <Typography className={classes.title}>Cadastro de usuários</Typography>
            <FormControl className={classes.formCtlInput}>
              <InputLabel htmlFor="nome">Nome</InputLabel>
              <Input
                id="nome"
                name="nome"
                autoComplete="nome"
                autoFocus
                onChange={props.handleChangeName}
                value={props.name}
              />
            </FormControl>

            <FormControl className={classes.formCtlInput}>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="email"
                onChange={props.handleChangeEmail}
                value={props.email}
              />
            </FormControl>

            <FormControl className={classes.formCtlInput}>
              <InputLabel htmlFor="password">Senha</InputLabel>
              <Input
                id="password"
                name="password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={props.password}
                onChange={props.handleChangePassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={this.handleClickShowPassword}>
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl className={clsx(classes.formCtlInput, classes.spacing)}>
              <InputLabel htmlFor="profile-select">Perfil</InputLabel>
              <Select
                id="profile-select"
                value={props.profile}
                onChange={props.handleChangeProfile}
                name="profile"
              >
                {profileList.map(profile =>
                  <MenuItem key={profile.id} value={profile.key}>{profile.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </div>

          <Button
            size="large"
            variant="contained"
            customColor="blue"
            onClick={handleSubmitAndSearch}
            isLoading={this.props.isLoading}
            className={classes.submitButton}
            disabled={props.disabled}
          >
            Registrar
          </Button>
          <br />
          <Button
            variant="contained"
            customColor="red"
            onClick={onCancel}>Cancelar</Button>
        </Paper>
      </main>
    );
  }

}

export default withStyles(styles)(RegisterComponent);