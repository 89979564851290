import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import { Person } from '@material-ui/icons';
import DescriptionIcon from '@mui/icons-material/Description';
import { DatePicker } from '@material-ui/pickers';
import { endOfDay, startOfDay, isAfter, isSameDay } from 'date-fns/esm';
import { formatCoordinate, parseNumber, formatCurrency } from '../../utils/utils';
import { Aside, AsideHeader, AsideRow } from '../Aside';
import { ELEGIBLE_VEHICLES, ELEGIBLE_TYPEBYBOARDS } from '../../constants';
import { getLatitudeLongitudePromise } from '../../googleApiUtils';
import { Weight, DeliveryPlace, GatheringPlace } from '../Icons';
import Paper from '@material-ui/core/Paper';
import AttachMoney from '@material-ui/icons/AttachMoney';
import DateRange from '@material-ui/icons/DateRange';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '../Button';
import TargetedUserModal from '../ModalTargetedUser';
import MaskedInput from '../MaskedInput';
import AddressModal from '../ModalAddress';
import WeightModal from '../ModalWeight';
import SelectGroups from '../SelectGroups';

class CreateOffer extends Component {
  state = {
    gatheringAddress: null,
    gatheringResume: null,
    gatheringState: null,
    gatheringCity: null,
    gatheringLatitude: null,
    gatheringLongitude: null,
    gatheringDeadline: null,
    gatheringCNPJ: null,
    gatheringCorporateName: null,
    deliveryAddress: null,
    deliveryResume: null,
    deliveryState: null,
    deliveryCity: null,
    deliveryLatitude: null,
    deliveryLongitude: null,
    deliveryDeadline: null,
    deliveryCNPJ: null,
    deliveryCorporateName: null,
    travelDistance: 1,
    packageWeight: null,
    packageDescription: null,
    clientName: null,
    OSCode: null,
    value: 0,
    valueOption: 'specified',
    paymentAdvance: 0,
    paymentDeadline: null,
    toll: true,
    elegibleVehicles: [],
    elegibleTypeByBoards: [],
    comment: null,
    TargetedUserModalData: null,
    freightType: 'OFFER',
    scheduleOffer: 'NO',
    isTargetedUserModalOpen: false,
    lotMode: false,
    isAddresModalOpen: false,
    addressModalTarget: 'gathering',
    existingAddressData: null,
    isWeightModalOpen: false,
    existingWeight: null,
    preservAll: false,
    checkAllPreservers: false,
    preservGathering: false,
    preservDelivery: false,
    preservWeight: false,
    preservRemuneration: false,
    preservPackageDescription: false,
    preservClientName: false,
    preservOSCode: false,
    preservDates: false,
    preservElegibleVehicles: false,
    preservElegibleTypeByBoards: false,
    errors: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { freight } = nextProps;

    if (prevState.id !== freight.id) {
      return {
        ...prevState,
        id: freight.id,
        _id: freight._id,
        paymentAdvance: freight.paymentAdvance,
        freightType: freight.freightType,
        OSCode: freight.OSCode,
      };
    }
    return null;
  }

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    if (name === "value") {
      this.setState(
        {
          [name]: formatCurrency(fieldValue),
        },
        () => {
          this.validateFields(name, fieldValue);
        }
      );
    } else {
      this.setState(
        {
          [name]: fieldValue,
        },
        () => {
          this.validateFields(name, fieldValue);
        }
      );
    }
  };

  toggleAllPreservers = (event) => {
    const value = event.target.checked;
    this.setState({
      checkAllPreservers: value,
      preservGathering: value,
      preservDelivery: value,
      preservWeight: value,
      preservRemuneration: value,
      preservPackageDescription: value,
      preservClientName: value,
      preservOSCode: value,
      preservDates: value,
      preservElegibleVehicles: value,
      preservElegibleTypeByBoards: value,
    });
  };

  handleDateChange = (targetName) => (date) => {
    let newDate = null;

    if (targetName === 'deliveryDeadline') {
      newDate = endOfDay(date);
      this.setState({ deliveryDeadline: newDate });
    } else {
      newDate = startOfDay(date);
      if (
        !isSameDay(newDate, this.state.deliveryDeadline) &&
        isAfter(newDate, this.state.deliveryDeadline)
      ) {
        this.setState({
          gatheringDeadline: newDate,
          deliveryDeadline: null,
        });
      } else {
        this.setState({ gatheringDeadline: newDate });
      }
    }
  };

  clearDate = () => {
    this.setState({
      gatheringDeadline: null,
      deliveryDeadline: null,
    });
  };

  handleVehiclesChange = (values) => {
    this.setState((prevState) => ({
      ...prevState,
      elegibleVehicles: values,
    }));
  };

  handleTypeByBoardsChange = (values) => {
    this.setState((prevState) => ({
      ...prevState,
      elegibleTypeByBoards: values,
    }));
  };

  handleVehicleRemove = (removeValue) => () => {
    const { elegibleVehicles } = this.state;
    const newValues = elegibleVehicles.filter((value) => value !== removeValue);
    this.setState((prevState) => ({
      ...prevState,
      elegibleVehicles: newValues,
    }));
  };

  handleTypeByBoardsRemove = (removeValue) => () => {
    const { elegibleTypeByBoards } = this.state;
    const newValues = elegibleTypeByBoards.filter((value) => value !== removeValue);
    this.setState((prevState) => ({
      ...prevState,
      elegibleTypeByBoards: newValues,
    }));
  };

  setLotMode = () => {
    this.setState({
      lotMode: true,
      preservAll: false,
      checkAllPreservers: false,
      preservGathering: false,
      preservDelivery: false,
      preservWeight: false,
      preservRemuneration: false,
      preservPackageDescription: false,
      preservClientName: false,
      preservOSCode: false,
      preservDates: false,
      preservElegibleVehicles: false,
      preservElegibleTypeByBoards: false,
    });
  };

  unsetLotMode = () => {
    this.setState({ lotMode: false });
  };

  validateFields = () => {
    const errors = {};
    const {
      gatheringState, gatheringCity, deliveryState, deliveryCity, OSCode, clientName,
      packageWeight, packageDescription, value, paymentDeadline, elegibleVehicles,
      elegibleTypeByBoards, TargetedUserModalData, gatheringDeadline, deliveryDeadline
    } = this.state;

    if (!gatheringState) errors.gatheringState = 'Campo obrigatório';
    if (!gatheringCity) errors.gatheringCity = 'Campo obrigatório';
    if (!deliveryState) errors.deliveryState = 'Campo obrigatório';
    if (!deliveryCity) errors.deliveryCity = 'Campo obrigatório';
    if (!OSCode) errors.OSCode = 'Campo obrigatório';
    if (!clientName) errors.clientName = 'Campo obrigatório';
    if (!packageWeight) errors.packageWeight = 'Campo obrigatório';
    if (!packageDescription) errors.packageDescription = 'Campo obrigatório';
    if (this.state.valueOption === 'specified' && !value) errors.value = 'Campo obrigatório';
    if (!paymentDeadline) errors.paymentDeadline = 'Campo obrigatório';
    if (!gatheringDeadline) errors.gatheringDeadline = 'Campo obrigatório';
    if (!deliveryDeadline) errors.deliveryDeadline = 'Campo obrigatório';

    if (this.state.freightType !== 'TARGETED') {
      if (elegibleVehicles.length === 0) errors.elegibleVehicles = 'Campo obrigatório';
      if (elegibleTypeByBoards.length === 0) errors.elegibleTypeByBoards = 'Campo obrigatório';
    }

    if (this.state.freightType === 'TARGETED' && !TargetedUserModalData?.cpf) {
      errors.TargetedUserModalData = 'Campo obrigatório';
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  validateField = (name, value) => {
    let error = '';

    switch (name) {
      case 'gatheringState':
        if (!value) error = 'Campo obrigatório';
        break;
      case 'gatheringCity':
        if (!value) error = 'Campo obrigatório';
        break;
      case 'deliveryState':
        if (!value) error = 'Campo obrigatório';
        break;
      case 'deliveryCity':
        if (!value) error = 'Campo obrigatório';
        break;
      case 'OSCode':
        if (!value) error = 'Campo obrigatório';
        break;
      case 'clientName':
        if (!value) error = 'Campo obrigatório';
        break;
      case 'packageWeight':
        if (!value) error = 'Campo obrigatório';
        break;
      case 'packageDescription':
        if (!value) error = 'Campo obrigatório';
        break;
      case 'value':
        if (this.state.valueOption === 'specified' && !value) error = 'Campo obrigatório';
        break;
      case 'paymentDeadline':
        if (!value) error = 'Campo obrigatório';
        break;
      case 'elegibleVehicles':
        if (this.state.freightType !== 'TARGETED') {
          if (value.length === 0) error = 'Campo obrigatório';
        }
        break;
      case 'elegibleTypeByBoards':
        if (this.state.freightType !== 'TARGETED') {
          if (value.length === 0) error = 'Campo obrigatório';
        }
        break;
      default:
        break;
    }

    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [name]: error,
      },
    }));
  };

  createFreight = async () => {
    if (!this.validateFields()) return;
    const newFreight = cloneDeep(this.state);

    newFreight.type = newFreight.lotMode ? 'Batch' : 'Unique';

    newFreight.packageWeight = parseNumber(newFreight.packageWeight);
    newFreight.paymentDeadline = parseNumber(newFreight.paymentDeadline);
    newFreight.comment = newFreight.comment || null;
    if (newFreight.TargetedUserModalData) {
      newFreight.userId = newFreight.TargetedUserModalData.id;
    }

    delete newFreight.id;
    delete newFreight.lotMode;
    delete newFreight.isAddresModalOpen;
    delete newFreight.addressModalTarget;
    delete newFreight.gatheringResume;
    delete newFreight.deliveryResume;
    delete newFreight.preservAll;
    delete newFreight.checkAllPreservers;
    delete newFreight.preservGathering;
    delete newFreight.preservDelivery;
    delete newFreight.preservWeight;
    delete newFreight.preservRemuneration;
    delete newFreight.preservPackageDescription;
    delete newFreight.preservClientName;
    delete newFreight.preservOSCode;
    delete newFreight.preservDates;
    delete newFreight.preservElegibleVehicles;
    delete newFreight.preservElegibleTypeByBoards;
    delete newFreight.isTargetedUserModalOpen;
    delete newFreight.TargetedUserModalData;
    delete newFreight.isWeightModalOpen;
    delete newFreight.errors;
    delete newFreight.scheduleOffer;
    delete newFreight.existingAddressData;
    delete newFreight.existingWeight;
    delete newFreight.valueOption;

    const {
      gatheringState,
      gatheringCity,
      deliveryState,
      deliveryCity,
    } = newFreight;

    if (gatheringState && gatheringCity) {
      const origin = `${gatheringCity} - ${gatheringState}`;

      const originCoordinates = await getLatitudeLongitudePromise(origin);

      newFreight.gatheringLatitude = formatCoordinate(originCoordinates.latitude);
      newFreight.gatheringLongitude = formatCoordinate(originCoordinates.longitude);
    }

    if (deliveryState && deliveryCity) {
      const destination = `${deliveryCity} - ${deliveryState}`;

      const destinationCoordinates = await getLatitudeLongitudePromise(destination);

      newFreight.deliveryLatitude = formatCoordinate(destinationCoordinates.latitude);
      newFreight.deliveryLongitude = formatCoordinate(destinationCoordinates.longitude);
    }

    if (!this.state.preservAll) {
      this.clearState();
      this.props.requestNewFreight(newFreight);
    } else {
      this.props.requestNewFreight(newFreight, this.props.history);
    }
  };

  clearState = () => {
    this.setState((prevState) => ({
      gatheringAddress: prevState.preservGathering ? prevState.gatheringAddress : null,
      gatheringState: prevState.preservGathering ? prevState.gatheringState : '',
      gatheringCity: prevState.preservGathering ? prevState.gatheringCity : '',
      gatheringCep: prevState.preservGathering ? prevState.gatheringCep : null,
      gatheringLatitude: prevState.preservGathering ? prevState.gatheringLatitude : '',
      gatheringLongitude: prevState.preservGathering ? prevState.gatheringLongitude : '',
      gatheringResume: prevState.preservGathering ? prevState.gatheringResume : '',
      gatheringCNPJ: prevState.preservGathering ? prevState.gatheringCNPJ : null,
      gatheringCorporateName: prevState.preservGathering ? prevState.gatheringCorporateName : null,
      deliveryAddress: prevState.preservDelivery ? prevState.deliveryAddress : null,
      deliveryState: prevState.preservDelivery ? prevState.deliveryState : '',
      deliveryCity: prevState.preservDelivery ? prevState.deliveryCity : '',
      deliveryCep: prevState.preservDelivery ? prevState.deliveryCep : null,
      deliveryLatitude: prevState.preservDelivery ? prevState.deliveryLatitude : '',
      deliveryLongitude: prevState.preservDelivery ? prevState.deliveryLongitude : '',
      deliveryResume: prevState.preservDelivery ? prevState.deliveryResume : '',
      deliveryCNPJ: prevState.preservDelivery ? prevState.deliveryCNPJ : null,
      deliveryCorporateName: prevState.preservDelivery ? prevState.deliveryCorporateName : null,
      gatheringDeadline: prevState.preservDates ? prevState.gatheringDeadline : null,
      deliveryDeadline: prevState.preservDates ? prevState.deliveryDeadline : null,
      packageWeight: prevState.preservWeight ? prevState.packageWeight : '',
      packageDescription: prevState.preservPackageDescription ? prevState.packageDescription : '',
      clientName: prevState.preservClientName ? prevState.clientName : '',
      OSCode: prevState.preservOSCode ? prevState.OSCode : '',
      value: prevState.preservRemuneration ? prevState.value : '',
      comment: '',
      paymentAdvance: prevState.preservRemuneration ? prevState.paymentAdvance : 0,
      paymentDeadline: prevState.preservRemuneration ? prevState.paymentDeadline : null,
      toll: prevState.preservRemuneration ? prevState.toll : true,
      elegibleVehicles: prevState.preservElegibleVehicles ? [...prevState.elegibleVehicles] : [],
      elegibleTypeByBoards: prevState.preservElegibleTypeByBoards ? [...prevState.elegibleTypeByBoards] : [],
    }));
  };

  isValidated = () => {
    const {
      gatheringState, gatheringCity, deliveryState, deliveryCity, gatheringDeadline, deliveryDeadline,
      travelDistance, packageWeight, packageDescription, clientName, OSCode, value,
      paymentDeadline, elegibleVehicles, elegibleTypeByBoards, TargetedUserModalData
    } = this.state;

    const hasRequiredInfo = !!gatheringState &&
      !!gatheringCity &&
      !!deliveryState &&
      !!deliveryCity &&
      !!gatheringDeadline &&
      !!deliveryDeadline &&
      !!travelDistance &&
      !!packageWeight &&
      !!packageDescription &&
      !!clientName &&
      !!OSCode &&
      (this.state.valueOption === 'toBeNegotiated' || !!value) &&
      !!paymentDeadline;

    if (this.state.freightType === 'TARGETED') {
      return hasRequiredInfo && !!TargetedUserModalData?.cpf;
    }

    return hasRequiredInfo && elegibleVehicles.length > 0 && elegibleTypeByBoards.length > 0;
  };

  openAddresModdal = (target) => {
    const {
      [`${target}Resume`]: resume,
      [`${target}CNPJ`]: cnpj,
      [`${target}CorporateName`]: corporateName,
      [`${target}Address`]: address,
    } = this.state;

    const existingData = {
      location: resume || '',
      informSender: cnpj || corporateName || address ? 'sim' : 'nao',
      cnpj: cnpj || '',
      corporateName: corporateName || '',
      address: address || '',
    };

    this.setState({
      isAddresModalOpen: true,
      addressModalTarget: target,
      existingAddressData: existingData,
    });
  };

  handleAddresModalClose = (addressData) => {
    const { addressModalTarget } = this.state;
    this.setState({
      isAddresModalOpen: false,
      [`${addressModalTarget}City`]: addressData[`${addressModalTarget}City`] || '',
      [`${addressModalTarget}State`]: addressData[`${addressModalTarget}State`] || '',
      [`${addressModalTarget}Resume`]: addressData[`${addressModalTarget}Resume`] || '',
      [`${addressModalTarget}CNPJ`]: addressData[`${addressModalTarget}CNPJ`] || null,
      [`${addressModalTarget}CorporateName`]: addressData[`${addressModalTarget}CorporateName`] || null,
      [`${addressModalTarget}Address`]: addressData[`${addressModalTarget}Address`] || null,
    });
  };

  openWeightModal = () => {
    const { packageWeight } = this.state;
    this.setState({
      isWeightModalOpen: true,
      existingWeight: packageWeight
    });
  };

  handleWeightModalClose = (weight) => {
    this.setState({
      isWeightModalOpen: false,
      packageWeight: weight || ''
    });
  };

  handleValueOptionChange = (event) => {
    this.setState({ valueOption: event.target.value });
  };

  render() {
    const { classes, createFreightState } = this.props;
    const { errors } = this.state;

    const inputLabelClasses = {
      root: classes.selectLabel,
      focused: classes.focused,
    };

    const inputLabelProps = {
      shrink: true,
      classes: inputLabelClasses,
    };

    const inputProps = {
      className: classes.input,
    };

    return (
      <div className={classes.root}>
        <AddressModal
          open={this.state.isAddresModalOpen}
          target={this.state.addressModalTarget}
          existingData={this.state.existingAddressData}
          onClose={this.handleAddresModalClose}
        />
        <WeightModal
          open={this.state.isWeightModalOpen}
          existingWeight={this.state.existingWeight}
          onClose={this.handleWeightModalClose}
        />
        <TargetedUserModal
          open={this.state.isTargetedUserModalOpen}
          onClose={(TargetedUserModalData) => {
            this.setState({ isTargetedUserModalOpen: false, TargetedUserModalData }, () => {
              this.validateField('TargetedUserModalData', TargetedUserModalData?.cpf);
            });
          }}
        />
        <Paper className={classes.paper}>
          <Aside>
            <AsideHeader backTo="/offers">
              <Typography className={classes.asideHeaderText}>Cadastro de Ofertas</Typography>
              <Checkbox
                disabled={!this.state.preservAll}
                checked={this.state.checkAllPreservers}
                onChange={this.toggleAllPreservers}
                classes={{
                  root: classes.mainCheckbox,
                  checked: classes.checked,
                  disabled: classes.disabled,
                }}
              />
            </AsideHeader>

            {/* Origem */}
            <AsideRow icon={<GatheringPlace />} className={classes.asideRow}>
              <div className={classes.inputsContainer}>
                <TextField
                  label="Origem"
                  id="gatheringResume"
                  name="gatheringResume"
                  value={this.state.gatheringResume || ''}
                  onClick={() => this.openAddresModdal('gathering')}
                  InputLabelProps={inputLabelProps}
                  InputProps={inputProps}
                  error={!!errors.gatheringState || !!errors.gatheringCity}
                  helperText={errors.gatheringState || errors.gatheringCity}
                />
              </div>
              <div className={classes.checkboxContainer}>
                <Checkbox
                  name="preservGathering"
                  disabled={!this.state.preservAll}
                  checked={this.state.preservGathering}
                  onChange={this.handleChange}
                  className={classes.checkboxRoot}
                />
              </div>
            </AsideRow>

            {/* Destino */}
            <AsideRow icon={<DeliveryPlace />} className={classes.asideRow}>
              <div className={classes.inputsContainer}>
                <TextField
                  label="Destino"
                  id="deliveryResume"
                  name="deliveryResume"
                  value={this.state.deliveryResume || ''}
                  onClick={() => this.openAddresModdal('delivery')}
                  InputLabelProps={inputLabelProps}
                  InputProps={inputProps}
                  error={!!errors.deliveryState || !!errors.deliveryCity}
                  helperText={errors.deliveryState || errors.deliveryCity}
                />
              </div>
              <div className={classes.checkboxContainer}>
                <Checkbox
                  name="preservDelivery"
                  disabled={!this.state.preservAll}
                  checked={this.state.preservDelivery}
                  onChange={this.handleChange}
                  className={classes.checkboxRoot}
                />
              </div>
            </AsideRow>

            {/* Tipo de Carga */}
            <AsideRow icon={<Weight />} className={clsx(classes.asideRow, classes.asideRowWeight)}>
              <div className={classes.inputsContainer}>
                <TextField
                  label="Tipo de Carga"
                  id="packageDescription"
                  name="packageDescription"
                  value={this.state.packageDescription || ''}
                  onChange={this.handleChange}
                  InputLabelProps={inputLabelProps}
                  InputProps={{ ...inputProps, inputProps: { maxLength: 150 } }}
                  error={!!errors.packageDescription}
                  helperText={errors.packageDescription}
                />
              </div>
              <div className={classes.checkboxContainer}>
                <Checkbox
                  name="preservPackageDescription"
                  disabled={!this.state.preservAll}
                  checked={this.state.preservPackageDescription}
                  onChange={this.handleChange}
                  className={classes.checkboxRoot}
                />
              </div>

              {/* Peso */}
              <div className={classes.inputsContainer}>
                <TextField
                  label="Peso"
                  id="packageWeight"
                  name="packageWeight"
                  value={this.state.packageWeight || ''}
                  onClick={this.openWeightModal}
                  InputLabelProps={inputLabelProps}
                  InputProps={inputProps}
                  error={!!errors.packageWeight}
                  helperText={errors.packageWeight}
                />
              </div>
              <div className={classes.checkboxContainer}>
                <Checkbox
                  name="preservWeight"
                  disabled={!this.state.preservAll}
                  checked={this.state.preservWeight}
                  onChange={this.handleChange}
                  className={classes.checkboxRoot}
                />
              </div>
            </AsideRow>

            {/* Nome do Cliente */}
            <AsideRow icon={<Person />} className={classes.asideRow}>
              <div className={classes.inputsContainer}>
                <TextField
                  label="Nome do Cliente"
                  id="clientName"
                  name="clientName"
                  value={this.state.clientName || ''}
                  onChange={this.handleChange}
                  InputLabelProps={inputLabelProps}
                  InputProps={{ ...inputProps, inputProps: { maxLength: 150 } }}
                />
              </div>
              <div className={classes.checkboxContainer}>
                <Checkbox
                  name="preservClientName"
                  disabled={!this.state.preservAll}
                  checked={this.state.preservClientName}
                  onChange={this.handleChange}
                  className={classes.checkboxRoot}
                />
              </div>
            </AsideRow>

            {/* Ordem de Serviço */}
            <AsideRow icon={<DescriptionIcon />} className={classes.asideRow}>
              <div className={classes.inputsContainer}>
                <TextField
                  label="Ordem de Serviço"
                  id="OSCode"
                  name="OSCode"
                  value={this.state.OSCode || ''}
                  onChange={this.handleChange}
                  InputLabelProps={inputLabelProps}
                  InputProps={{ ...inputProps, inputProps: { maxLength: 150 } }}
                />
              </div>
              <div className={classes.checkboxContainer}>
                <Checkbox
                  name="preservOSCode"
                  disabled={!this.state.preservAll}
                  checked={this.state.preservOSCode}
                  onChange={this.handleChange}
                  className={classes.checkboxRoot}
                />
              </div>
            </AsideRow>

            {/* Datas */}
            <AsideRow icon={<DateRange />} className={classes.asideRow}>
              <div className={classes.inputsContainer}>
                <div className={classes.inputsLine}>
                  <DatePicker
                    autoOk
                    disablePast
                    label="Data de coleta"
                    format="dd/MM/yyyy"
                    cancelLabel="Cancelar"
                    value={this.state.gatheringDeadline}
                    onAccept={this.handleDateChange('gatheringDeadline')}
                    onYearChange={this.handleDateChange('gatheringDeadline')}
                    onChange={() => null}
                    InputLabelProps={inputLabelProps}
                    InputProps={inputProps}
                    error={!!errors.gatheringDeadline}
                    helperText={errors.gatheringDeadline}
                  />
                  <DatePicker
                    autoOk
                    label="Data de entrega"
                    format="dd/MM/yyyy"
                    cancelLabel="Cancelar"
                    initialFocusedDate={this.state.gatheringDeadline || new Date()}
                    minDate={this.state.gatheringDeadline || new Date()}
                    value={this.state.deliveryDeadline}
                    onAccept={this.handleDateChange('deliveryDeadline')}
                    onYearChange={this.handleDateChange('deliveryDeadline')}
                    onChange={() => null}
                    InputLabelProps={inputLabelProps}
                    InputProps={inputProps}
                    error={!!errors.deliveryDeadline}
                    helperText={errors.deliveryDeadline}
                  />
                  <Button onClick={this.clearDate}>
                    <ClearIcon style={{ color: '#2B596F' }} />
                  </Button>
                </div>
              </div>
              <div className={classes.checkboxContainer}>
                <Checkbox
                  name="preservDates"
                  disabled={!this.state.preservAll}
                  checked={this.state.preservDates}
                  onChange={this.handleChange}
                  className={classes.checkboxRoot}
                />
              </div>
            </AsideRow>

            {/* Remuneração */}
            <AsideRow icon={<AttachMoney />} className={clsx(classes.asideRow, classes.asideRowRemunaration)}>
              <div className={classes.inputsContainer}>
                <div className={classes.inputsLine}>
                  <TextField
                    select
                    fullWidth
                    label="Opção de valor do frete"
                    id="valueOption"
                    name="valueOption"
                    value={this.state.valueOption}
                    onChange={this.handleValueOptionChange}
                    InputLabelProps={inputLabelProps}
                    InputProps={inputProps}
                  >
                    <MenuItem value="toBeNegotiated">A combinar</MenuItem>
                    <MenuItem value="specified">Valor especificado</MenuItem>
                  </TextField>
                </div>
                {this.state.valueOption === 'specified' && (
                  <div className={classes.inputsLine}>
                    <TextField
                      fullWidth
                      label="Valor do frete"
                      id="value"
                      name="value"
                      value={`R$ ${this.state.value}`}
                      onChange={this.handleChange}
                      InputLabelProps={inputLabelProps}
                      InputProps={inputProps}
                      error={!!errors.value}
                      helperText={errors.value}
                    />
                  </div>
                )}
                <div className={classes.inputsLine}>
                  <TextField
                    select
                    fullWidth
                    label="Adiantamento (%)"
                    id="paymentAdvance"
                    name="paymentAdvance"
                    value={this.state.paymentAdvance}
                    onChange={this.handleChange}
                    InputLabelProps={inputLabelProps}
                    InputProps={inputProps}
                    error={!!errors.paymentAdvance}
                    helperText={errors.paymentAdvance}
                  >
                    {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((value) => (
                      <MenuItem key={value} value={value}>
                        {value} %
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    select
                    fullWidth
                    label="Pedágio incluso?"
                    id="toll"
                    name="toll"
                    value={this.state.toll}
                    onChange={this.handleChange}
                    InputLabelProps={inputLabelProps}
                    InputProps={inputProps}
                  >
                    <MenuItem value={true}>Sim</MenuItem>
                    <MenuItem value={false}>Não</MenuItem>
                  </TextField>
                </div>
                <TextField
                  label="Prazo para pagamento"
                  id="paymentDeadline"
                  name="paymentDeadline"
                  value={this.state.paymentDeadline}
                  onChange={this.handleChange}
                  InputLabelProps={inputLabelProps}
                  InputProps={{
                    ...inputProps,
                    inputComponent: PaymentDeadlineInput,
                  }}
                  error={!!errors.paymentDeadline}
                  helperText={errors.paymentDeadline}
                />
              </div>
              <div className={classes.checkboxContainer}>
                <Checkbox
                  name="preservRemuneration"
                  disabled={!this.state.preservAll}
                  checked={this.state.preservRemuneration}
                  onChange={this.handleChange}
                  className={classes.checkboxRoot}
                />
              </div>
            </AsideRow>

            {/* Tipo de Frete */}
            <AsideRow icon={<Person />} className={classes.asideRow}>
              <div className={classes.inputsContainer}>
                <TextField
                  select
                  label="Enviar a oferta apenas para um motorista?"
                  id="freightType"
                  name="freightType"
                  value={this.state.freightType}
                  onChange={this.handleChange}
                  InputLabelProps={inputLabelProps}
                  InputProps={inputProps}
                >
                  <MenuItem value="OFFER">Não</MenuItem>
                  <MenuItem value="TARGETED">Sim</MenuItem>
                </TextField>
              </div>
            </AsideRow>

            {/* Campo condicional "Direcionar para motorista" */}
            {this.state.freightType === 'TARGETED' && (
              <AsideRow icon={<Person />} className={clsx(classes.asideRow)}>
                <div className={classes.inputsContainer}>
                  <TextField
                    label="Direcionado para o motorista:"
                    value={this.state.TargetedUserModalData ? this.state.TargetedUserModalData.name : ''}
                    onClick={() => this.setState({ isTargetedUserModalOpen: true })}
                    InputLabelProps={inputLabelProps}
                    InputProps={{
                      ...inputProps,
                      readOnly: true, // Torna o campo somente leitura
                    }}
                    placeholder="Clique para buscar"
                    error={!!errors.TargetedUserModalData}
                    helperText={errors.TargetedUserModalData}
                  />
                </div>
              </AsideRow>
            )}
          </Aside>

          <div className={classes.rightSideContainer}>
            <Grid container justifyContent="flex-start">
              {/* Caminhões elegíveis */}
              <Grid item sm={6} className={classes.gridItem}>
                <Typography noWrap className={classes.rightSideTitle}>
                  Veículos compatíveis:
                </Typography>
                <Checkbox
                  name="preservElegibleVehicles"
                  disabled={!this.state.preservAll}
                  checked={this.state.preservElegibleVehicles}
                  onChange={this.handleChange}
                  className={clsx(classes.checkboxRoot, classes.checkboxElegibleVehicles)}
                />
                <TextField
                  fullWidth
                  error={!!errors.elegibleVehicles}
                  helperText={errors.elegibleVehicles}
                  InputProps={{
                    inputComponent: () => (
                      <SelectGroups
                        fullWidth
                        placeholder="Selecione uma ou mais opções"
                        options={ELEGIBLE_VEHICLES}
                        values={this.state.elegibleVehicles}
                        onChange={this.handleVehiclesChange}
                        InputProps={inputProps}
                      />
                    )
                  }}
                />
                <div className={classes.chipsContainer}>
                  {this.state.elegibleVehicles.map((value, index) => (
                    <Chip
                      key={index}
                      label={value}
                      onDelete={this.handleVehicleRemove(value)}
                      className={classes.chip}
                    />
                  ))}
                </div>
              </Grid>

              {/* Carrocerias elegíveis */}
              <Grid item sm={6} className={classes.gridItem}>
                <Typography noWrap className={classes.rightSideTitle}>
                  Escolha a carroceria:
                </Typography>
                <Checkbox
                  name="preservElegibleTypeByBoards"
                  disabled={!this.state.preservAll}
                  checked={this.state.preservElegibleTypeByBoards}
                  onChange={this.handleChange}
                  className={clsx(classes.checkboxRoot, classes.checkboxElegibleVehicles)}
                />
                <TextField
                  fullWidth
                  error={!!errors.elegibleTypeByBoards}
                  helperText={errors.elegibleTypeByBoards}
                  InputProps={{
                    inputComponent: () => (
                      <SelectGroups
                        fullWidth
                        placeholder="Selecione uma ou mais opções"
                        options={ELEGIBLE_TYPEBYBOARDS}
                        values={this.state.elegibleTypeByBoards}
                        onChange={this.handleTypeByBoardsChange}
                        InputProps={inputProps}
                      />
                    )
                  }}
                />
                <div className={classes.chipsContainer}>
                  {this.state.elegibleTypeByBoards.map((value, index) => (
                    <Chip
                      key={index}
                      label={value}
                      onDelete={this.handleTypeByBoardsRemove(value)}
                      className={classes.chip}
                    />
                  ))}
                </div>
              </Grid>

              {/* Observações */}
              <Grid item sm={12} className={classes.gridItem}>
                <Typography noWrap className={classes.rightSideTitle}>
                  Digite as Observações do frete:
                </Typography>
                <textarea
                  name="comment"
                  className={classes.commentArea}
                  onChange={this.handleChange}
                  value={this.state.comment || ''}
                  maxLength={1024}
                />
              </Grid>

              {/* Manter dados para a próxima oferta? */}
              <Grid item sm={12} className={classes.gridItem}>
                <Typography noWrap className={classes.rightSideTitle}>
                  Deseja manter itens para a próxima oferta?
                </Typography>
                <TextField
                  select
                  fullWidth
                  name="preservAll"
                  placeholder="Selecione uma opção"
                  value={this.state.preservAll}
                  onChange={this.handleChange}
                  InputProps={inputProps}
                  style={{ maxWidth: '315px' }}
                >
                  <MenuItem value={true}>Sim</MenuItem>
                  <MenuItem value={false}>Não</MenuItem>
                </TextField>
              </Grid>

              {/* Botão de adicionar frete */}
              <Grid item sm={12}>
                <div className={classes.buttonLine}>
                  <Button
                    variant="contained"
                    size="large"
                    customColor="blue"
                    onClick={this.createFreight}
                    disabled={!this.isValidated()}
                    isLoading={createFreightState.isLoading}
                  >
                    {this.state.freightType === 'OFFER' ? 'Criar Oferta de Frete' : 'Criar Frete Direcionado'}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </div>
    );
  }
}

const PaymentDeadlineInput = (props) => (
  <MaskedInput
    {...props}
    onClick={({ target }) => {
      if (target.selectionStart > target.value.length - 37) {
        const pos = target.value.length - 37;
        target.setSelectionRange(pos, pos);
      }
    }}
    mask={{
      integerLimit: 3,
      prefix: '',
      suffix: ' dias após a aprovação de comprovante',
    }}
  />
);

export default withStyles(styles)(withRouter(CreateOffer));