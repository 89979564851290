import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './style';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';

const FilterClear = withStyles(styles)((props) => {
  const { classes } = props;

  return (
    <div className={classes.imgFilterContainer}>
      <Button
        aria-label="atualizar"
        disabled={props.disableRefresh}
        onClick={props.handleRefresh}
        size="small"
        startIcon={<RefreshIcon />}
        variant="contained"
      >
        Atualizar
      </Button>
      <Button
        aria-label="limpar-filtro"
        disabled={props.disableClear}
        onClick={props.handleClearFilters}
        size="small"
        startIcon={<DeleteIcon />}
        variant="contained"
      >
        Limpar
      </Button>
    </div>
  );
});

const FilterComponent = (props) => {
  return (
    <div className={props.classes.container}>
      {props.children}
      <FilterClear
        disableClear={props.disableClear}
        disableRefresh={props.disableRefresh}
        handleClearFilters={props.handleClearFilters}
        handleRefresh={props.handleRefresh}
      />
    </div>
  );
};

export default withStyles(styles)(FilterComponent);
