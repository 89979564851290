import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';    
import Card from '@material-ui/core/Card';    
import CardContent from '@material-ui/core/CardContent';    
// import Checkbox from '@material-ui/core/Checkbox';    
import Divider from '@material-ui/core/Divider';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PersonIcon from '@material-ui/icons/Person';
import { Smartphone } from '@material-ui/icons';
import PaymentIcon from '@material-ui/icons/Payment';
import Tooltip from '../Tooltip';
import { formatCPF } from '../../utils/utils';
import styles from './style';
import { formatCel } from '../../utils/utils';
import { VEHICLE_LABELS, TYPEBYBOARD_LABELS } from '../../constants';

class DriverCard extends Component {

  render() {

    const { driver, classes } = this.props;
    const vehicleType = driver.vehicle.type
      ? VEHICLE_LABELS[driver.vehicle.type]
      : "";
    const vehicleTypeByBoards = driver.vehicle.typeByBoard
      ? TYPEBYBOARD_LABELS[driver.vehicle.typeByBoard]
      : "";
    const ownedByUserSufix = driver.vehicle.ownedByUser ? "PROPRIETÁRIO" : "";

    return (
      <Card>
        <CardContent className={classes.cardContentRoot}>
          <div className={clsx(classes.row)}>
            <Link to={`/drivers/${driver.id}`} >
              <Tooltip text={driver.name && driver.name} minCharacters={29}>
                <Typography className={classes.title} variant="h6">
                  <strong>{driver.name && driver.name.toUpperCase()}</strong>
                </Typography>
              </Tooltip>
            </Link>
            {/* <Checkbox className={classes.checkboxRoot}/> */}
          </div>

          <Divider />

          <div className={classes.row}>
            <LocalShippingIcon className={classes.iconContainer}/>
            <Typography className={classes.textSecondary}>
              {`- ${vehicleType}  ${vehicleTypeByBoards}  ${ownedByUserSufix}`}
            </Typography>
          </div>
          
          <Divider />

          <div className={classes.row}>
            <PersonIcon className={classes.iconContainer} />
            <Typography className={classes.textSecondary}>
              - {formatCPF(driver.cpf)}
            </Typography>
          </div>

          <Divider />

          <div className={classes.row}>
            <PaymentIcon className={classes.iconContainer}/>
            <Typography className={classes.textSecondary}>
              - {driver.wallet.type}
            </Typography>
          </div>

          <Divider />

          <div className={classes.row}>
            <Smartphone className={classes.iconContainer} />
            <Typography className={classes.textSecondary}>
              - {formatCel(driver.phoneNumber)}
            </Typography>
          </div>

        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles, { name: 'DriverCard' })(DriverCard);