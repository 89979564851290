import React, { useState, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import { FILTER_LABEL_ORDER } from "../../constants";
import styles, { colors } from "./style";
import Filter from "../FilterOrder";
import FilterSearch from "../FilterOrder/FilterSearch";
import FilterSelect from "../FilterOrder/FilterSelect";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from "date-fns";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const OrderFilter = props => {
  const { classes } = props;
  const [timeoutId, setTimeoutId] = useState(null);
  const [dates, setDates] = useState({
    creationDateStart: null,
    creationDateEnd: null,
    updateDateStart: null,
    updateDateEnd: null,
    gatheringDateStart: null,
    gatheringDateEnd: null,
    deliveryDateStart: null,
    deliveryDateEnd: null,
  });

  useEffect(() => {
    setDates({
      creationDateStart: null,
      creationDateEnd: null,
      updateDateStart: null,
      updateDateEnd: null,
      gatheringDateStart: null,
      gatheringDateEnd: null,
      deliveryDateStart: null,
      deliveryDateEnd: null,
    });
  }, [props.resetId]); 

  const handleDateChange = (startOrEnd, filterBaseName, newValue) => {
    clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      setDates({ ...dates, [startOrEnd]: newValue });

      const startDate = startOrEnd.includes('Start') ? newValue : dates[`${filterBaseName}Start`];
      const endDate = startOrEnd.includes('End') ? newValue : dates[`${filterBaseName}End`];
      const dateRangeString = `${startDate ? format(startDate, "yyyy-MM-dd") : ''},${endDate ? format(endDate, "yyyy-MM-dd") : ''}`;

      props.handleFilterChange({ target: { name: filterBaseName, value: dateRangeString } });
    }, 500);
    setTimeoutId(newTimeoutId);
  };

  const selectLabelClasses = {
    root: classes.selectLabel,
    focused: classes.focused
  };

  const slotDateInput = {
    textField: {
      sx: {
        "& input": {
          color: colors.whiteAlpha,
        },
        "& .MuiInputLabel-root": {
          color: colors.whiteAlpha,
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: colors.whiteAlpha,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderRadius: '5px',
            border: '0.5px solid',
            borderColor: colors.whiteAlpha,
          },
          "&:hover fieldset": {
            borderColor: colors.whiteAlpha,
          },
          "&.Mui-focused fieldset": {
            borderColor: colors.whiteAlpha,
          },
        },
        marginBottom: '8px',
      }
    }
  }

  return (
    <Filter
      disableClear={props.disableClear}
      disableRefresh={props.disableRefresh}
      handleClearFilters={props.handleClearFilters}
      handleRefresh={props.handleRefresh}
    >
      <div className={clsx(classes.filterSearch)}>
        <FilterSearch>
          <FormControl
            fullWidth
            htmlFor="OSCode"
            className={clsx(classes.formCtlInput, classes.loteFilterContainer)}
          >
            <Input
              autoFocus
              id="OSCode"
              name="OSCode"
              autoComplete="OSCode"
              placeholder="Ordem de Serviço"
              onChange={props.handleFilterChange}
              value={props.OSCode}
              className={clsx(classes.input, classes.searchName)}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="Pesquisa"
                    onClick={props.clearAndSearch}
                    className={classes.iconButton}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl
            fullWidth
            htmlFor="numCte"
            className={clsx(classes.formCtlInput, classes.loteFilterContainer)}
          >
            <Input
              autoFocus
              id="numCte"
              name="numCte"
              autoComplete="numCte"
              placeholder="Número de CTE"
              onChange={props.handleFilterChange}
              value={props.numCte}
              className={classes.input}
              type="number"
              inputProps={{ min: "1" }}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="pesquisa-frete"
                    onClick={props.clearAndSearch}
                    className={[classes.iconButton, classes.loteSearchIcon].join(
                      " "
                    )}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl
            fullWidth
            htmlFor="driverName"
            className={clsx(classes.formCtlInput, classes.loteFilterContainer)}
          >
            <Input
              autoFocus
              id="driverName"
              name="driverName"
              autoComplete="driverName"
              placeholder="Nome do Motorista"
              onChange={props.handleFilterChange}
              value={props.driverName}
              className={clsx(classes.input, classes.searchName)}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="Pesquisa"
                    onClick={props.clearAndSearch}
                    className={classes.iconButton}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl
            fullWidth
            htmlFor="plate"
            className={clsx(classes.formCtlInput, classes.loteFilterContainer)}
          >
            <Input
              autoFocus
              id="plate"
              name="plate"
              autoComplete="plate"
              placeholder="Placa do Veículo"
              onChange={props.handleFilterChange}
              value={props.plate}
              className={clsx(classes.input, classes.searchName)}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="Pesquisa"
                    onClick={props.clearAndSearch}
                    className={classes.iconButton}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl
            fullWidth
            htmlFor="gatheringAddress"
            className={clsx(classes.formCtlInput, classes.loteFilterContainer)}
          >
            <Input
              autoFocus
              id="gatheringAddress"
              name="gatheringAddress"
              autoComplete="gatheringAddress"
              placeholder="Origem"
              onChange={props.handleFilterChange}
              value={props.gatheringAddress}
              className={clsx(classes.input, classes.searchName)}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="Pesquisa"
                    onClick={props.clearAndSearch}
                    className={classes.iconButton}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl
            fullWidth
            htmlFor="deliveryAddress"
            className={clsx(classes.formCtlInput, classes.loteFilterContainer)}
          >
            <Input
              autoFocus
              id="deliveryAddress"
              name="deliveryAddress"
              autoComplete="deliveryAddress"
              placeholder="Destino"
              onChange={props.handleFilterChange}
              value={props.deliveryAddress}
              className={clsx(classes.input, classes.searchName)}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="Pesquisa"
                    onClick={props.clearAndSearch}
                    className={classes.iconButton}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl fullWidth className={clsx(classes.dateFilterContainer)}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div
                className={clsx(classes.dateSelectContainer)}>
                {/* Criação */}
                <div className={clsx(classes.dateSelect)}>
                  <DatePicker
                    slotProps={slotDateInput} 
                    format="dd/MM/yyyy"
                    label="Início (Criação)"
                    value={dates.creationDateStart}
                    onChange={(newValue) => {
                      handleDateChange('creationDateStart', 'creationDate', newValue);
                    }}
                  />
                  <DatePicker
                    slotProps={slotDateInput} 
                    format="dd/MM/yyyy"
                    label="Fim (Criação)"
                    value={dates.creationDateEnd}
                    onChange={(newValue) => {
                      handleDateChange('creationDateEnd', 'creationDate', newValue);
                    }}
                  />
                </div>

                {/* Atualização */}
                <div className={clsx(classes.dateSelect)}>
                  <DatePicker
                    slotProps={slotDateInput} 
                    format="dd/MM/yyyy"
                    label="Início (Atualização)"
                    value={dates.updateDateStart}
                    onChange={(newValue) => {
                      handleDateChange('updateDateStart', 'updateDate', newValue);
                    }}
                  />
                  <DatePicker
                    slotProps={slotDateInput} 
                    format="dd/MM/yyyy"
                    label="Fim (Atualização)"
                    value={dates.updateDateEnd}
                    onChange={(newValue) => {
                      handleDateChange('updateDateEnd', 'updateDate', newValue);
                    }}
                  />
                </div>

                {/* Coleta */}
                <div className={clsx(classes.dateSelect)}>
                  <DatePicker
                    slotProps={slotDateInput} 
                    format="dd/MM/yyyy"
                    label="Início (Coleta)"
                    value={dates.gatheringDateStart}
                    onChange={(newValue) => {
                      handleDateChange('gatheringDateStart', 'gatheringDate', newValue);
                    }}
                  />
                  <DatePicker
                    slotProps={slotDateInput} 
                    format="dd/MM/yyyy"
                    label="Fim (Coleta)"
                    value={dates.gatheringDateEnd}
                    onChange={(newValue) => {
                      handleDateChange('gatheringDateEnd', 'gatheringDate', newValue);
                    }}
                  />
                </div>

                {/* Entrega */}
                <div className={clsx(classes.dateSelect)}>
                  <DatePicker
                    slotProps={slotDateInput} 
                    format="dd/MM/yyyy"
                    label="Início (Entrega)"
                    value={dates.deliveryDateStart}
                    onChange={(newValue) => {
                      handleDateChange('deliveryDateStart', 'deliveryDate', newValue);
                    }}
                  />
                  <DatePicker
                    slotProps={slotDateInput} 
                    format="dd/MM/yyyy"
                    label="Fim (Entrega)"
                    value={dates.deliveryDateEnd}
                    onChange={(newValue) => {
                      handleDateChange('deliveryDateEnd', 'deliveryDate', newValue);
                    }}
                  />
                </div>

              </div>

            </LocalizationProvider>

          </FormControl>

        </FilterSearch>
      </div>

      <div className={classes.asideRight}>
        <div className={clsx(classes.filterSelect)}>
          <FilterSelect>
            <FormControl className={classes.selectRoot}>
              <InputLabel htmlFor="status" classes={selectLabelClasses}>
                Status
              </InputLabel>

              <Select
                value={props.status}
                onChange={props.handleFilterChange}
                className={classes.input}
                inputProps={{ id: "status", name: "status" }}
              >
                {FILTER_LABEL_ORDER.status.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.selectRoot}>
              <InputLabel
                htmlFor="paymentSuccessfulBalance"
                classes={selectLabelClasses}
              >
                Saldo
              </InputLabel>

              <Select
                id="paymentSuccessfulBalance"
                name="paymentSuccessfulBalance"
                value={props.paymentSuccessfulBalance}
                onChange={props.handleFilterChange}
                className={classes.input}
                inputProps={{
                  id: "paymentSuccessfulBalance",
                  name: "paymentSuccessfulBalance"
                }}
              >
                {FILTER_LABEL_ORDER.paymentSuccessfulBalance.map(
                  (filter, i) => (
                    <MenuItem key={i} value={filter.value}>
                      {filter.label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl className={classes.selectRoot}>
              <InputLabel
                htmlFor="advancedRequestedPaymentDate"
                classes={selectLabelClasses}
              >
                Adiantamento
              </InputLabel>

              <Select
                id="advancedRequestedPaymentDate"
                name="advancedRequestedPaymentDate"
                value={props.advancedRequestedPaymentDate}
                onChange={props.handleFilterChange}
                className={classes.input}
                inputProps={{
                  id: "advancedRequestedPaymentDate",
                  name: "advancedRequestedPaymentDate"
                }}
              >
                {FILTER_LABEL_ORDER.advancedRequestedPaymentDate.map(
                  (filter, i) => (
                    <MenuItem key={i} value={filter.value}>
                      {filter.label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </FilterSelect>
        </div>

        <div className={classes.imgFilterContainer}>
          <Button
            aria-label="atualizar"
            disabled={props.disableRefresh}
            onClick={props.handleRefresh}
            size="small"
            startIcon={<RefreshIcon />}
            variant="contained"
          >
            Atualizar
          </Button>
          <Button
            aria-label="limpar-filtro"
            disabled={props.disableClear}
            onClick={props.handleClearFilters}
            size="small"
            startIcon={<DeleteIcon />}
            variant="contained"
          >
            Limpar
          </Button>
        </div>
      </div>
    </Filter>
  );
};

export default withStyles(styles)(OrderFilter);
