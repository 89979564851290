import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatDate } from '../../utils/utils'

const BoardUsersList = ({ users, handleDelete, onEdit, currentPage, setCurrentPage, pageNumbers }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Lista de Usuários
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Perfil</TableCell>
              <TableCell>Atualizado em:</TableCell>
              <TableCell>Criado em:</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((boardUser) => (
              <TableRow key={boardUser.id}>
                <TableCell padding={'normal'}>{boardUser.name}</TableCell>
                <TableCell padding={'normal'}>{boardUser.email}</TableCell>
                <TableCell padding={'normal'}>{boardUser.profile}</TableCell>
                <TableCell padding={'normal'}>{formatDate(boardUser.updateDate)}</TableCell>
                <TableCell padding={'normal'}>{formatDate(boardUser.creationDate)}</TableCell>
                <TableCell align="right" padding={'none'}>
                  <IconButton onClick={() => onEdit(boardUser)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(boardUser.id)} color="secondary">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        {currentPage > 1 && (
          <IconButton style={{ color: 'rgb(26, 91, 118)' }} onClick={() => setCurrentPage(currentPage - 1)}>
            &laquo;
          </IconButton>
        )}
        {pageNumbers.map(number => (
          <IconButton key={number} style={{ color: 'rgb(26, 91, 118)' }} onClick={() => setCurrentPage(number)}>
            {number}
          </IconButton>
        ))}
        {currentPage < pageNumbers.length && (
          <IconButton style={{ color: 'rgb(26, 91, 118)' }} onClick={() => setCurrentPage(currentPage + 1)}>
            &raquo;
          </IconButton>
        )}
      </div>
    </>
  );
};

export default BoardUsersList;
