import React, { useState, useEffect } from 'react';
import OrderForm from '../OrderForm';
import OrderProgress from '../OrderProgress';
import OrderRequester from '../OrderRequester';
import PhotosToPdf from '../PhotosToPdf/index.tsx';
import ModalRank from '../DriverRank';
import { CircularProgress } from '@mui/material';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import api from '../../api';
import OrderPhotos from '../OrderPhotos';

const OrderDetail = ({ classes, order, isLoading, onUpdate, onUpdateStatus, onRequestImageBlocking, orderId, driverData, handleGetOrder }) => {
  const [hasAcknowledged, setHasAcknowledged] = useState(false);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [intermediateOrder, setIntermediateOrder] = useState(order);

  useEffect(() => {
    if (order && !hasAcknowledged) {
      api.patch(`/order/${orderId}`, { updateAcknowledge: true })
        .then(() => setHasAcknowledged(true))
        .catch(err => console.error('Erro ao atualizar dado de visualização da ordem:', err.message));
    }
  }, [hasAcknowledged, order, orderId]);

  useEffect(() => {
    const updateOrderInfo = () => {
      handleGetOrder(orderId);
    };
    const intervalId = setInterval(updateOrderInfo, 5000);
    return () => clearInterval(intervalId);
  }, [orderId, handleGetOrder]);

  // Atualizar o estado intermediário apenas se a ordem mudar
  useEffect(() => {
    if (JSON.stringify(order) !== JSON.stringify(intermediateOrder)) {
      setIntermediateOrder(order);
    }
  }, [order, intermediateOrder]);

  return (
    <div className={classes.root}>
      <ModalRank driver={driverData} />
      <div className={classes.mainContainer}>
        <div className={classes.asideLeft}>
          <OrderForm order={order} orderId={orderId} onUpdateOrder={onUpdate} />
        </div>
        <div className={classes.asideRight}>
          <OrderRequester driver={driverData} />
          <OrderProgress onUpdateStatus={onUpdateStatus} onUpdateOrder={onUpdate} order={order} />
          <div className={classes.imagesContainer}>
            <OrderPhotos
              classes={classes}
              order={intermediateOrder}
              onUpdate={onUpdate}
              onRequestImageBlocking={onRequestImageBlocking}
              orderId={orderId}
              onUpdateStatus={onUpdateStatus}
            />
            <div className={classes.SequenceContainer}>
              <PhotosToPdf
                id={order?.id}
                type={"order"}
                onUpdate={onUpdate}
                isLoading={isLoading}
                OSCode={order?.OSCode}
                pdfUrlOld={order?.pdfUrl}
                isLoadingPdf={isLoadingPdf}
                setIsLoadingPdf={setIsLoadingPdf}
              />
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className={classes.loadingOverlay}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(OrderDetail);
