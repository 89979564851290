export default theme => ({
  main: {
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: '90vw',
    height: 'auto',
    paddingTop: theme.spacing(5),
    padding: theme.spacing(3),
    zIndex: 11,
    [theme.breakpoints.up('sm')]: {
      width: '70vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '50vw',
    }
  },
  title: {
    fontWeight: 600,
    color: "#585858",
    textTransform: "uppercase",
    marginBottom: theme.spacing(4),
    textAlign: "center",
    height: 'auto',
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: 'auto',
  },
  formCtlInput: {
    marginBottom: theme.spacing(2),
    height: 'auto',
  },
  submitButton: {
    marginTop: theme.spacing(2),
    height: "47px",
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
    }
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchButton: {
    marginLeft: theme.spacing(2),
  },
  searchInput: {
    width: '100%',
  },
  messageTitle: {
    color: "rgba(26, 91, 118, 1)",
    marginTop: theme.spacing(1),
  },
  messageArea: {
    resize: "none",
    "&:focus": {
      borderColor: "rgba(26, 91, 118, 1)"
    }
  },
  selectInput: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Roboto, sans-serif',
    borderRadius: '4px',
    border: '1px solid #ccc',
    "&:focus": {
      borderColor: "rgba(26, 91, 118, 1)"
    },
    "& option": {
      padding: "10px",
    }
  }
});
