import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import Button from '../Button';

const ModalConfirmation = (props) => {

  return (
    <Dialog
      disableEscapeKeyDown
      open={props.open}
      fullWidth={true}
      maxWidth={'xs'}
    >
      <DialogTitle className={props.classes.modalHeaderContainer}>
        <Typography variant="body1" className={props.classes.asideHeaderText}>
          {props.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" className={props.classes.modalContentText}>
          {props.message}
        </Typography>
      </DialogContent>

      <DialogActions className={props.classes.modalContentRoot}>
        <Button
          size="large"
          customColor="blue"
          variant="contained"
          onClick={props.handleCancel}
        >
          Não
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={props.handleConfirm}
          className={props.classes.buttonConfirm}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );

}

export default withStyles(styles)(ModalConfirmation);