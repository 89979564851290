const styles = (theme) => ({
  paper: {
    maxWidth: '100%',
  },
  title: {
    backgroundColor: '#2B596F',
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
  },
  button: {
    backgroundColor: '#2B596F',
    color: '#fff',
    position: 'relative',
    height: "56px",
    minWidth: '64px',
    margin: theme.spacing(0.5),
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0.5),
  },
  loading: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
  },
  driverData: {
    color: '#2B596F',
  },
  alerts: {
    color: '#f00e0e',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textField: {
    flex: 1,
    minWidth: '120px',
  }
});

export default styles;