const TEXT_COLOR = "#FFF";

export default theme => {
  return {
    container: {
      width: "100%",
      margin: "0 auto",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#1A5B76",
      paddingLeft: "24px"
    },
    nameFilterInptContainer: {
      width: "520px",
      display: "flex",
      flexDirection: "row",
      paddingLeft: "24px"
    },
    selectFiltersContainer: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "24px",
      maxWidth: "700px"
    },
    filterLabel: {
      marginTop: 10,
      fontWeight: "bold",
      color: TEXT_COLOR,
      fontSize: "0.8rem"
    },
    imgFilterContainer: {
      position: "absolute",
      top: 5,
      right: 25,
      display: 'flex',
      flexDirection: 'row',
      gap: '5px'
    },
    imgFilter: {
      width: 45,
      height: 40
    },
    "@media (max-width: 1024px)": {
      selectFiltersContainer: {
        height: "200px",
      },
      container: {
        flexDirection: "column",
        paddingLeft: "10px",
        backgroundColor: "#1A5B76",
        justifyContent: "flex-start",
      },
      nameFilterInptContainer: {
        width: 440,
      },
    },
  };
};
