import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ADMIN_PROFILE, FINANCIAL_PROFILE, OPERATION_PROFILE, SAC_PROFILE } from '../../constants';
import Button from '../Button';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 420px;
  height: 420px;
  padding: 20px;
  
  @media (max-width: 500px) {
    width: 300px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Select = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const BoardUserUpdate = ({ onSubmit, onCancel, user, loading }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    profile: '',
    showPassword: false,
  });

  useEffect(() => {
    if (user) {
      setFormData({ ...user });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setFormData(prevState => ({
      ...prevState,
      showPassword: !prevState.showPassword
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { showPassword, ...userDetails } = formData;
    onSubmit({ user: userDetails });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h4>Alterar Cadastro</h4>
      <Input
        type="text"
        name="name"
        placeholder="Nome"
        value={formData.name}
        onChange={handleChange}
        required
      />
      <Input
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <Input
        type={formData.showPassword ? "text" : "password"}
        name="password"
        placeholder="Informar caso precise alterar a senha"
        value={formData.password}
        onChange={handleChange}
      />
      <button type="button" onClick={togglePasswordVisibility}>
        {formData.showPassword ? "Ocultar Senha" : "Mostrar Senha"}
      </button>
      <Select name="profile" value={formData.profile} onChange={handleChange} required>
        <option value="">Selecione um perfil</option>
        <option value={ADMIN_PROFILE}>Administrador</option>
        <option value={FINANCIAL_PROFILE}>Financeiro</option>
        <option value={OPERATION_PROFILE}>Operação</option>
        <option value={SAC_PROFILE}>SAC</option>
      </Select>
      <Button
        size="large"
        variant="contained"
        customColor="blue"
        type="submit"
        isLoading={loading}>Salvar</Button>
      <Button
        variant="contained"
        customColor="red"
        type="button"
        onClick={onCancel}>Cancelar</Button>
    </Form>
  );
};

export default BoardUserUpdate;
