import api from "../api";

import {
  OCCURRENCES_REQUEST,
  OCCURRENCES_SUCCESS,
  OCCURRENCES_FAILURE,
  OCCURRENCES_CLEAR_LIST,
  OCCURRENCES_CLEAR_FILTERS,
  OCCURRENCES_SET_FILTER,
  OCCURRENCE_LIST_REQUEST,
  OCCURRENCE_LIST_SUCCESS,
  OCCURRENCE_LIST_FAILURE,
  REMOVE_NOTIFICATION,
  NOTIFY_BROADCAST_REQUEST,
  NOTIFY_BROADCAST_SUCCESS,
  NOTIFY_BROADCAST_FAILURE
} from "./actionsTypes";
import { buildUrl } from "../utils/utils";
import { enqueueSnackbar } from './notifications';

export const requestOccurrences = payload => {
  return dispatch => {
    dispatch({ type: OCCURRENCES_REQUEST, payload });

    const { limit, skip, filters } = payload;

    const query = { ...filters } || {};
    if (limit) query.limit = limit;
    if (skip) query.skip = skip;

    api
      .get(`/occurrences/${buildUrl(query)}`)
      .then(res => {
        dispatch(requestOccurrencesSuccess(res.data));
      })
      .catch(err => {
        dispatch(requestOccurrencesFailure(err.message));
      });
  };
};

export const requestListOccurrences = payload => {
  return dispatch => {
    dispatch({ type: OCCURRENCE_LIST_REQUEST, payload });

    const { limit, skip, filters } = payload;

    const query = { ...filters } || {};
    if (limit) query.limit = limit;
    if (skip) query.skip = skip;

    api
      .get(`/occurrences/${buildUrl(query)}`)
      .then(res => {
        dispatch(requestOccurrencesListSuccess(res.data));
      })
      .catch(err => {
        dispatch(requestOccurrencesListFailure(err.message));
      });
  };
};

export const requestOccurrencesSuccess = payload => {
  return { type: OCCURRENCES_SUCCESS, payload };
};

export const requestOccurrencesFailure = payload => {
  return { type: OCCURRENCES_FAILURE, payload };
};

export const requestOccurrencesListSuccess = payload => {
  return { type: OCCURRENCE_LIST_SUCCESS, payload };
};

export const requestOccurrencesListFailure = payload => {
  return { type: OCCURRENCE_LIST_FAILURE, payload };
};

export const clearOccurrencesList = () => {
  return { type: OCCURRENCES_CLEAR_LIST };
};

export const clearOccurrencesFilters = () => {
  return { type: OCCURRENCES_CLEAR_FILTERS };
};

export const setOccurrencesFilter = payload => {
  return { type: OCCURRENCES_SET_FILTER, payload };
};

export const removeSnackbar = key => ({
  type: REMOVE_NOTIFICATION,
  key
});

export const occurrenceBroadcastRequest = (payload) => {
  return async (dispatch) => {
    dispatch({ type: NOTIFY_BROADCAST_REQUEST, payload });

    api
      .post("/occurrence", payload)
      .then((res) => {
        dispatch(occurrenceBroadcastSuccess());

        dispatch(
          enqueueSnackbar({
            message: "Ocorrência enviada com sucesso",
            options: { variant: "success" }
          })
        );
      })
      .catch((err) => {
        dispatch(occurrenceBroadcastFailure());

        dispatch(
          enqueueSnackbar({
            message:
              "Problema ao enviar ocorrência. Favor entrar em contato com o suporte",
            options: { variant: "error" }
          })
        );
      });
  };
};

export const occurrenceBroadcastSuccess = () => {
  return { type: NOTIFY_BROADCAST_SUCCESS };
};

export const occurrenceBroadcastFailure = () => {
  return { type: NOTIFY_BROADCAST_FAILURE };
};