import {
  btnRed,
  btnRedHover,
  btnGreen,
  btnGreenHover,
  COLOR_DEFAULT,
  COLOR_DEFAULT_HOVER,
} from '../../globalTheme';
import { Theme, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxHeight: 'auto',
      margin: 'auto',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(10),
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        width: '80%',
        [theme.breakpoints.up('md')]: {
          width: '80%',
        },
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'column' as 'column', // Explicitly define the type as 'column'
    },
    headerTop: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.divider}`,
      position: 'relative',
    },
    leftColumn: {
      flex: 1,
      paddingRight: theme.spacing(2),
    },
    rightColumn: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column' as 'column',
      alignItems: 'flex-end',
    },
    statusButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      borderBottom: `1px solid ${theme.palette.divider}`,
      marginBottom: theme.spacing(1),
      position: 'relative',
    },
    messagesContainer: {
      maxHeight: '40vh',
      overflow: 'auto',
      scrollbarWidth: 'none' as 'none',
      msOverflowStyle: 'none' as 'none',
      '&::-webkit-scrollbar': {
        display: 'none' as 'none',
      },
    },
    message: {
      display: 'flex',
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
    },
    adminMessage: {
      justifyContent: 'flex-end',
      backgroundColor: '#70707070',
    },
    userMessage: {
      justifyContent: 'flex-start',
      backgroundColor: '#476bc56f',
    },
    fileItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      marginBottom: theme.spacing(1),
    },
    adminFile: {
      justifyContent: 'flex-end',
      backgroundColor: '#70707070',
    },
    userFile: {
      justifyContent: 'flex-start',
      backgroundColor: '#476bc56f',
    },
    form: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      width: '100%',
      marginTop: theme.spacing(2),
    },
    input: {
      flexGrow: 1,
      marginBottom: theme.spacing(1),
    },
    fileInput: {
      display: 'none',
    },
    fileInputLabel: {
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer',
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
      backgroundColor: COLOR_DEFAULT,
      color: theme.palette.primary.contrastText,
      borderRadius: theme.shape.borderRadius,
      '&:hover': {
        backgroundColor: COLOR_DEFAULT_HOVER,
      },
      height: '43px',
      width: '156px',
      fontSize: '12px',
      textAlign: 'center',
      fontWeight: 700,
      justifyContent: 'center',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    sendButton: {
      height: '43px',
      width: '156px',
      fontSize: '12px',
      textAlign: 'center',
      fontWeight: 700,
      backgroundColor: COLOR_DEFAULT,
      color: '#FFF',
      '&:hover': {
        backgroundColor: COLOR_DEFAULT_HOVER,
      },
    },
    saveButton: {
      height: '43px',
      width: '156px',
      fontSize: '12px',
      textAlign: 'center',
      fontWeight: 700,
      backgroundColor: btnGreen,
      color: '#FFF',
      '&:hover': {
        backgroundColor: btnGreenHover,
      },
    },
    updateButtonGreen: {
      height: '43px',
      width: '156px',
      fontSize: '12px',
      textAlign: 'center',
      fontWeight: 700,
      backgroundColor: btnGreen,
      margin: theme.spacing(1),
      color: '#FFF',
      '&:hover': {
        backgroundColor: btnGreenHover,
      },
    },
    updateButtonRed: {
      height: '43px',
      width: '156px',
      fontSize: '12px',
      textAlign: 'center',
      fontWeight: 700,
      backgroundColor: btnRed,
      margin: theme.spacing(1),
      color: '#FFF',
      '&:hover': {
        backgroundColor: btnRedHover,
      },
    },
    fileName: {
      margin: theme.spacing(1),
      textAlign: 'center',
    },
    downloadButton: {
      marginLeft: theme.spacing(1),
    },
    fileList: {
      marginTop: theme.spacing(1),
    },
  });

export default styles;
