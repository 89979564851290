import axios from "axios";

import storage from "./store/sessionStorage";
import { STORAGE_USER_KEY } from "./constants";
let _token = null;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 1000,
  headers: {
    // "authorization": "DEVTOKEN",
    // "Content-Type": "application/json"
  }
});

api.interceptors.request.use(async req => {
  let newReq = req;
  const user = storage.parse(STORAGE_USER_KEY);

  _token = user ? user.token : null;
  if (_token && !req.baseURL.includes("s3.amazonaws.com")) {
    newReq.headers.Authorization = _token;
  }
  
  return newReq;
});

export default api;
