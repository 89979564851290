export default theme => {
  return {
    backdrop: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.7)",
      zIndex: "9",
      margin: "0",
      padding: "0",
      left: "0",
      top: "0"
    },
    main: {
      position: "absolute",
      zIndex: "10",
      top: "2%",
      left: "50%",
      transform: "translateX(-50%)"
    }
  };
};
