import { uniqBy } from 'lodash';
import {
  ORDERS_LIST_REQUEST,
  ORDERS_LIST_SUCCESS,
  ORDERS_LIST_SUCCESS_POOLING,
  ORDERS_LIST_FAILURE,
  ORDERS_CLEAR_LIST,
  ORDERS_CLEAR_FILTERS,
  ORDERS_SET_FILTER,
} from '../actions/actionsTypes';

import { ROWS_PER_PAGE_IN_CANVAS } from '../constants';

const INITIAL_STATE = {
  ordersList: [],
  filters: {
    status: '',
    paymentSuccessfulBalance: '',
    advancedRequestedPaymentDate: '',
    OSCode: '',
    numCte: '',
    driverName: '',
    plate: '',
    gatheringAddress: '',
    deliveryAddress: '',
    creationDate: '',
    updateDate: '',
    gatheringDate: '',
    deliveryDate: '',
    limit: ROWS_PER_PAGE_IN_CANVAS,
  },
  isLoading: false,
  hasMore: true,
  skip: 0,
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case ORDERS_CLEAR_LIST:
      return {
        ...state,
        skip: 0,
        hasMore: true,
        ordersList: [],
      };

    case ORDERS_CLEAR_FILTERS:
      return {
        ...state,
        skip: 0,
        hasMore: true,
        ordersList: [],
        filters: {
          status: '',
          paymentSuccessfulBalance: '',
          advancedRequestedPaymentDate: '',
          OSCode: '',
          numCte: '',
          driverName: '',
          plate: '',
          gatheringAddress: '',
          deliveryAddress: '',
          creationDate: '',
          updateDate: '',
          gatheringDate: '',
          deliveryDate: '',
          limit: ROWS_PER_PAGE_IN_CANVAS,
        },
      };

    case ORDERS_SET_FILTER:
      return {
        ...state,
        skip: 0,
        hasMore: true,
        ordersList: [],
        filters: {
          ...state.filters,
          ...payload,
        },
      };

    case ORDERS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ORDERS_LIST_SUCCESS_POOLING:
      if (state.isLoading) {
        return { ...state };
      }
      return {
        ...state,
        isLoading: false,
        ordersList: uniqBy([...state.ordersList, ...payload.orders], 'id'),
      };

    case ORDERS_LIST_SUCCESS:
      const ordersList = uniqBy([...state.ordersList, ...payload.orders], 'id');
      return {
        ...state,
        isLoading: false,
        skip: ordersList.length,
        hasMore: payload.orders.length === state.filters.limit,
        ordersList,
      };

    case ORDERS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
