import api from '../api';

import {
  DRIVER_LIST_REQUEST,
  DRIVER_LIST_SUCCESS,
  DRIVER_LIST_FAILURE,
  DRIVER_CLEAR_LIST,
  DRIVERS_CLEAR_FILTERS,
  DRIVERS_SET_FILTER,
  DRIVER_LIST_SUCCESS_POOLING,
} from './actionsTypes';

import { buildUrl } from '../utils/utils';

function handleErros(err) {
  if (!!err.response && !err.response.data) {
    // Retorna a mensagem de erro padrão do http
    return err.response.statusText;
  } else if (!!err.response && !!err.response.data) {
    switch (err.response.status) {
      case 403:
        return 'Sem permissão';

      // Retorna a mensagem de erro do servidor
      default:
        return err.response.data.message || 'Resposta inesperada';
    }
  } else if (!!err.message) {
    // Retorna a mensagem de erro em tempo de execução
    return err.message;
  } else {
    return 'Erro desconhecido';
  }
}

export const checkIfRegistred = async (cpf) => {
  const resp = await api.get(`/user/validate/cpf/${cpf.replace(/\D/g, '')}`);

  return resp.data.userExists;
};

export const requestDriverList = (payload = {}) => {
  return (dispatch) => {
    if (!payload.pooling) {
      dispatch({ type: DRIVER_LIST_REQUEST, payload });
    }

    const { limit, skip, filters } = payload;

    const query = { ...filters } || {};
    if (limit) query.limit = limit;
    if (skip) query.skip = skip;

    api
      .get(`/users/${buildUrl(query)}`)
      .then((res) => {
        if (!payload.pooling) {
          dispatch(requestDriverListSuccess(res.data));
        } else {
          dispatch(requestDriverListSuccessPooling(res.data));
        }
      })
      .catch((err) => {
        dispatch(requestDriverListFailure(handleErros(err)));
      });
  };
};

export const requestDriverListSuccess = (payload) => {
  return { type: DRIVER_LIST_SUCCESS, payload };
};
export const requestDriverListSuccessPooling = (payload) => {
  return { type: DRIVER_LIST_SUCCESS_POOLING, payload };
};

export const requestDriverListFailure = (payload) => {
  return { type: DRIVER_LIST_FAILURE, payload };
};

export const clearDriversList = () => {
  return { type: DRIVER_CLEAR_LIST };
};

export const clearDriversFilters = () => {
  return { type: DRIVERS_CLEAR_FILTERS };
};

export const setDriversFilter = (payload) => {
  return { type: DRIVERS_SET_FILTER, payload };
};
