import api from '../api';
import { enqueueSnackbar } from './notifications';

import {
  OCCURRENCE_DETAIL_REQUEST,
  OCCURRENCE_DETAIL_SUCCESS,
  OCCURRENCE_DETAIL_FAILURE,
  OCCURRENCE_REPLY_REQUEST,
  OCCURRENCE_REPLY_SUCCESS,
  OCCURRENCE_REPLY_FAILURE,
  OCCURRENCE_MESSAGES_REQUEST,
  OCCURRENCE_MESSAGES_SUCCESS,
  OCCURRENCE_MESSAGES_FAILURE,
  CLEAR_OCCURRENCE_DATA,
} from './actionsTypes';

export const requestOccurrenceDetail = (id) => {
  return (dispatch) => {
    dispatch({ type: OCCURRENCE_DETAIL_REQUEST, id });

    api
      .get(`/occurrence/${id}`)
      .then((res) => {
        dispatch(occurrenceDetailSuccess(res.data.occurrence));
      })
      .catch((err) => {
        dispatch(occurrenceDetailFailure(err.message));
      });
  };
};

export const replyToOccurrence = (id, payload) => {
  return (dispatch) => {
    dispatch({ type: OCCURRENCE_REPLY_REQUEST });

    api
      .patch(`/occurrence/${id}`, { id, payload, boardRequest: true, updateAcknowledge: false })
      .then((res) => {
        dispatch({
          type: OCCURRENCE_REPLY_SUCCESS,
          payload: res.data,
        });
        dispatch(enqueueSnackbar({
          message: 'Resposta enviada com sucesso',
          options: { variant: 'success' },
        }));
        dispatch(requestOccurrenceDetail(id));
      })
      .catch((err) => {
        dispatch({
          type: OCCURRENCE_REPLY_FAILURE,
          payload: err.message,
        });
        dispatch(enqueueSnackbar({
          message: 'Erro ao enviar resposta',
          options: { variant: 'error' },
        }));
      });
  };
};

export const UpdateOccurrenceDetail = (id, payload) => {
  return (dispatch) => {
    dispatch({ type: OCCURRENCE_REPLY_REQUEST });
    
    api
      .patch(`/occurrence/${id}`, { id, payload, boardRequest: true, updateAcknowledge: false })
      .then((res) => {
        dispatch({
          type: OCCURRENCE_REPLY_SUCCESS,
          payload: res.data,
        });
        dispatch(enqueueSnackbar({
          message: 'Ocorrência atualizada com sucesso',
          options: { variant: 'success' },
        }));
        dispatch(requestOccurrenceDetail(id));
      })
      .catch((err) => {
        dispatch({
          type: OCCURRENCE_REPLY_FAILURE,
          payload: err.message,
        });
        dispatch(enqueueSnackbar({
          message: 'Erro ao atualizar ocorrência',
          options: { variant: 'error' },
        }));
      });
  };
};

export const requestOccurrenceMessages = (id) => {
  return (dispatch) => {
    dispatch({ type: OCCURRENCE_MESSAGES_REQUEST, id });

    return api
      .get(`/occurrence/${id}`)
      .then((res) => {
        dispatch(occurrenceMessagesSuccess(res.data.occurrence));
      })
      .catch((err) => {
        dispatch(occurrenceMessagesFailure(err.message));
      });
  };
};

export const occurrenceDetailSuccess = (payload) => {
  return { type: OCCURRENCE_DETAIL_SUCCESS, payload };
};

export const occurrenceDetailFailure = (payload) => {
  return { type: OCCURRENCE_DETAIL_FAILURE, payload };
};

export const clearOccurrenceData = () => {
  return { type: CLEAR_OCCURRENCE_DATA };
};

export const occurrenceMessagesSuccess = (payload) => {
  return { type: OCCURRENCE_MESSAGES_SUCCESS, payload };
};

export const occurrenceMessagesFailure = (payload) => {
  return { type: OCCURRENCE_MESSAGES_FAILURE, payload };
}