import React from 'react';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Button, CircularProgress, Typography, Fab } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { dateNow, exportManyToExcel } from '../../utils/utils';
import { FREIGHT_STATUS } from '../../constants';
import FreightCard from '../FreightCard';
import styles from './styles';
import MoneySquareIcon from '../../assets/svg/money-square-solid.svg';

const exportAndLog = (freightsList) => {
  exportManyToExcel(freightsList, `Fretes_${dateNow()}`, 'freights');
};

const FreightsList = (props) => {
  const { classes, freightsList, isLoading, hasMore, handleLoadMore } = props;
  return (
    <div id="scrollableDiv" className={classes.root}>
      <div className={classes.infiniteScrollContainer}>
        <Grid container spacing={3}>
          {freightsList.map((freight) => (
            <Grid key={freight.id} item xs={12} sm={6} md={3} className={classes.relativePos}>
              {!freight.updateAcknowledge && <div className={classes.statusIcon} />}
              {((freight.status === FREIGHT_STATUS.FINISHED && !freight.paymentSuccessfulBalance)
                || freight.status === FREIGHT_STATUS.INVOICE_COUPON_REFUSED
                || freight.status === FREIGHT_STATUS.INVOICE_COUPON_SENT) && (
                  <img src={MoneySquareIcon} className={classes.moneySquareIcon} alt="Money Icon" />
                )}
              <FreightCard freight={freight} />
            </Grid>
          ))}
        </Grid>
        {isLoading && (
          <div className={classes.progress}>
            <CircularProgress alt={"Loading..."} />
          </div>
        )}
        {!isLoading && hasMore && (
          <Button
            onClick={handleLoadMore}
            className={classes.loadMoreButton}
          >
            Carregar Mais
          </Button>
        )}
        {!isLoading && (
          <Button
            onClick={() => exportAndLog(freightsList)}
            className={classes.exportButton}
          > Exportar lista
          </Button>
        )}
        {!hasMore && (
          <Typography className={classes.noMoreItems}>
            Não há mais itens para carregar.
          </Typography>
        )}
        <Link to="/offers/create">
          <Fab aria-label="Adicionar frete" className={classes.addButton}>
            <AddIcon />
          </Fab>
        </Link>
      </div>
    </div>
  );
};

export default withStyles(styles, { name: 'FreightsList' })(FreightsList);
