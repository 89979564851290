import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './style';

const FilterSelect = (props) => {
  return (
    <div className={props.classes.selectFiltersContainer}>
      { props.children }
    </div>
  );
}

export default withStyles(styles)(FilterSelect);