import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './style';

const FilterComponent = (props) => {
  return (
    <div className={props.classes.container}>
      {props.children}
    </div>
  );
};

export default withStyles(styles)(FilterComponent);
