import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import useSocketConnection from '../../hooks/useSocketConnection';
import styles from './styles';
import driversIcon from '../../assets/svg/cliente.svg';
import offersIcon from '../../assets/svg/Carroceria.svg';
import ordersIcon from '../../assets/svg/Carroceria_.svg';
import occurrencesIcon from '../../assets/svg/ocurrence.svg';
import configIcon from '../../assets/svg/configuracoes.svg';
import InvoiceCoupons from '../InvoiceCoupons';

const Card = withStyles(styles)(({ classes, link, label, icon, updates }) => (
  <div className={classes.cardContainer}>
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Badge className={classes.statusIcon} badgeContent={updates} max={999} color="error" overlap="rectangular">
        <Paper className={classes.card}>
          <div className={classes.cardImage}>
            <img src={icon} alt={label} />
          </div>
          <div className={classes.cardLabel}>
            <Typography className={classes.cardLabelTypography}>{label}</Typography>
          </div>
        </Paper>
      </Badge>
    </Link>
  </div>
));

const Home = ({ classes, roles }) => {
  const { isConnected } = useSocketConnection();
  const counters = useSelector((state) => state.counter);
  const profile = useSelector((state) => state.auth.user.profile);

  return isConnected ? (
    <div className={classes.root}>
      {profile === 'FINANCEIRO' ? (
        <InvoiceCoupons />
      ) : (
        <>
          {roles.includes('/drivers') && (
            <Card
              updates={counters.users}
              link="/drivers"
              label="Cadastro de Motoristas"
              icon={driversIcon}
            />
          )}
          {roles.includes('/offers') && (
            <Card
              updates={counters.freights}
              link="/offers"
              label="Ofertas"
              icon={offersIcon} />
          )}
          {roles.includes('/orders') && (
            <Card
              updates={counters.orders}
              link="/orders"
              label="Ordens de Serviço"
              icon={ordersIcon} />
          )}
          {roles.includes('/occurrences') && (
            <Card
              updates={counters.occurrences}
              link="/occurrences"
              label="Ocorrências"
              icon={occurrencesIcon}
            />
          )}
          {roles.includes('/configuration') && (
            <Card
              link="/configuration"
              label="Configurações"
              icon={configIcon} />
          )}
        </>
      )}
    </div>
  ) : (
    <><h1>Atualize a pagina</h1></>
  );
};

export default withStyles(styles)(Home);
