import { makeStyles } from '@material-ui/styles';

const getStatusStyles = (status) => {
  switch (status) {
    case "ALTERED":
      return { borderColor: '#fbe40a', labelColor: '#fbe40a' };
    case "DENIED":
      return { borderColor: '#A33C3C', labelColor: '#A33C3C' };
    case "APPROVED":
      return { borderColor: '#3B9B58', labelColor: '#3B9B58' };
    default:
      return { borderColor: '#1A5B76', labelColor: '#1A5B76' };
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    textAlign: 'center',
    width: 85,
    height: 130,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  image: {
    cursor: 'grab',
    backgroundImage: props => `url(${props.photo.thumbnailUrl || props.photo.imageUrl})`,
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
    backgroundPosition: 'center center',
    backgroundClip: 'padding-box',
    backgroundOrigin: 'border-box',
    backgroundSize: 'cover',
    border: props => `solid 4px ${getStatusStyles(props.photo.status).borderColor}`,
    width: 85,
    height: 85,
    position: 'relative',
    zIndex: 2,
  },
  imageError: {
    position: 'absolute',
    top: 31,
    left: 31,
    zIndex: 1,
    color: 'rgba(0,0,0,0.3)',
  },
  label: {
    fontSize: '10px',
    color: props => getStatusStyles(props.photo.status).labelColor,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  openButton: {
    cursor: 'pointer',
    color: '#ffffff',
    width: '100%',
    backgroundColor: '#1A5B76',
    fontSize: '16px',
    border: 'none',
    borderRadius: '5px 5px 0 0',
    padding: '1px',
    bottom: '0',
    left: '0',
  },
}), { name: 'ModalImage' });

export default useStyles;
