import api from "../api";

import {
  DRIVER_DETAIL_REQUEST,
  DRIVER_DETAIL_SUCCESS,
  DRIVER_DETAIL_FAILURE,
  DRIVER_DELETE_REQUEST,
  DRIVER_DELETE_SUCCESS,
  DRIVER_DELETE_FAILURE,
  DRIVER_UPDATE_REQUEST,
  DRIVER_UPDATE_SUCCESS,
  DRIVER_UPDATE_FAILURE,
  DRIVER_BLOCK_REQUEST,
  DRIVER_BLOCK_SUCCESS,
  DRIVER_BLOCK_FAILURE,
  DRIVER_APPROVE_REQUEST,
  DRIVER_APPROVE_SUCCESS,
  DRIVER_APPROVE_FAILURE,
  DRIVER_IMAGE_APPROVAL_REQUEST,
  DRIVER_IMAGE_APPROVAL_SUCCESS,
  DRIVER_IMAGE_APPROVAL_FAILURE,
  DRIVER_IMAGE_BLOCKING_REQUEST,
  DRIVER_IMAGE_BLOCKING_SUCCESS,
  DRIVER_IMAGE_BLOCKING_FAILURE,
  DRIVER_CLEAR_DETAIL_DATA
} from "../actions/actionsTypes";
import { clearDriversList } from "./drivers";
import { enqueueSnackbar } from "./notifications";
import { handleErros } from "../utils/utils";

export const requestDriver = id => {
  return dispatch => {
    dispatch({ type: DRIVER_DETAIL_REQUEST, id });
    api
      .get(`/user/${id}`)
      .then(res => {
        dispatch(requestDriverSuccess(res.data));
      })
      .catch(err => {
        const message = handleErros(err);
        dispatch(requestDriverFailure(message));
        dispatch(
          enqueueSnackbar({
            message,
            options: { variant: "error" }
          })
        );
      });
  };
};

export const requestDriverSuccess = payload => {
  return { type: DRIVER_DETAIL_SUCCESS, payload };
};

export const requestDriverFailure = payload => {
  return { type: DRIVER_DETAIL_FAILURE, payload };
};

export const requestDeleteDriver = (id, history) => {
  return dispatch => {
    dispatch({ type: DRIVER_DELETE_REQUEST, payload: id });

    api
      .patch(`/user/${id}`, { active: false, boardRequest: true })
      .then(res => {
        dispatch(requestDeleteDriverSuccess({ id }));

        // Limpa a lista de motoristas para que o componente DriverList...
        // entenda que é necessário atualizar os dados
        dispatch(clearDriversList());

        dispatch(
          enqueueSnackbar({
            message: "Usuário removido com sucesso.",
            options: { variant: "success" }
          })
        );

        history && history.push("/drivers");
      })
      .catch(err => {
        const message = handleErros(err);
        dispatch(requestDeleteDriverFailure());
        dispatch(
          enqueueSnackbar({
            message,
            options: { variant: "error" }
          })
        );
      });
  };
};

export const requestDeleteDriverSuccess = payload => {
  return { type: DRIVER_DELETE_SUCCESS, payload };
};

export const requestDeleteDriverFailure = () => {
  return { type: DRIVER_DELETE_FAILURE };
};

export const requestUpdateDriver = payload => {
  return dispatch => {
    payload.boardRequest = true;
    payload.updateAcknowledge = false;

    dispatch({ type: DRIVER_UPDATE_REQUEST, payload });

    api
      .patch(`/user/${payload.id}`, payload)
      .then(res => {
        dispatch(requestUpdateDriverSuccess(res.data));

        // Limpa a lista de motoristas para que o componente DriverList...
        // entenda que é necessário atualizar os dados
        dispatch(clearDriversList());

        dispatch(
          enqueueSnackbar({
            message: "Usuário atualizado com sucesso.",
            options: { variant: "success" }
          })
        );
      })
      .catch(err => {
        const message = handleErros(err);
        dispatch(requestUpdateDriverFailure());
        dispatch(
          enqueueSnackbar({
            message,
            options: { variant: "error" }
          })
        );
      });
  };
};

export const requestUpdateDriverSuccess = payload => {
  return { type: DRIVER_UPDATE_SUCCESS, payload };
};

export const requestUpdateDriverFailure = () => {
  return { type: DRIVER_UPDATE_FAILURE };
};

export const requestDriverApproval = id => {
  return dispatch => {
    dispatch({ type: DRIVER_APPROVE_REQUEST });

    api
      .patch(`/user/${id}/status`, { status: "APPROVED" })
      .then(res => {
        dispatch(requestDriverApprovalSuccess(res.data));

        // Limpa a lista de motoristas para que o componente DriverList...
        // entenda que é necessário atualizar os dados
        dispatch(clearDriversList());

        dispatch(
          enqueueSnackbar({
            message: "Usuário aprovado com sucesso.",
            options: { variant: "success" }
          })
        );
      })
      .catch(err => {
        const message = handleErros(err);
        dispatch(requestDriverApproveFailure(message));
        dispatch(
          enqueueSnackbar({
            message,
            options: { variant: "error" }
          })
        );
      });
  };
};

export const requestDriverApprovalSuccess = payload => {
  return { type: DRIVER_APPROVE_SUCCESS, payload };
};

export const requestDriverApproveFailure = payload => {
  return { type: DRIVER_APPROVE_FAILURE, payload };
};

export const requestDriverBlocking = (id, reason) => {
  return dispatch => {
    dispatch({ type: DRIVER_BLOCK_REQUEST });

    api
      .patch(`/user/${id}/status`, { status: "DENIED", reason })
      .then(res => {
        dispatch(requestDriverBlockingSuccess(res.data));

        // Limpa a lista de motoristas para que o componente DriverList...
        // entenda que é necessário atualizar os dados
        dispatch(clearDriversList());

        dispatch(
          enqueueSnackbar({
            message: "Usuário bloqueado com sucesso.",
            options: { variant: "success" }
          })
        );
      })
      .catch(err => {
        const message = handleErros(err);
        dispatch(requestDriverBlockingFailure(message));
        dispatch(
          enqueueSnackbar({
            message,
            options: { variant: "error" }
          })
        );
      });
  };
};

export const requestDriverBlockingSuccess = payload => {
  return { type: DRIVER_BLOCK_SUCCESS, payload };
};

export const requestDriverBlockingFailure = payload => {
  return { type: DRIVER_BLOCK_FAILURE, payload };
};

export const requestDriverImageApproval = payload => {
  return dispatch => {
    const { driverId, photoPath, data } = payload;

    dispatch({ type: DRIVER_IMAGE_APPROVAL_REQUEST });

    api
      .patch(`/user/${driverId}/photo`, data)
      .then(res => {
        dispatch(requestDriverImageApprovalSuccess({ photoPath, data }));

        // Limpa a lista de motoristas para que o componente DriverList...
        // entenda que é necessário atualizar os dados
        dispatch(clearDriversList());

        dispatch(
          enqueueSnackbar({
            message: "Foto aprovada com sucesso.",
            options: { variant: "success" }
          })
        );
      })
      .catch(err => {
        const message = handleErros(err);
        dispatch(requestDriverImageApprovalFailure(message));
        dispatch(
          enqueueSnackbar({
            message,
            options: { variant: "error" }
          })
        );
      });
  };
};

export const requestDriverImageApprovalSuccess = payload => {
  return { type: DRIVER_IMAGE_APPROVAL_SUCCESS, payload };
};

export const requestDriverImageApprovalFailure = payload => {
  return { type: DRIVER_IMAGE_APPROVAL_FAILURE, payload };
};

export const requestDriverImageBlocking = payload => {
  return dispatch => {
    const { driverId, photoPath, data } = payload;

    dispatch({ type: DRIVER_IMAGE_BLOCKING_REQUEST, payload });

    api
      .patch(`/user/${driverId}/photo`, data)
      .then(res => {
        dispatch(requestDriverImageBlockingSuccess({ photoPath, data }));

        // Limpa a lista de motoristas para que o componente DriverList...
        // entenda que é necessário atualizar os dados
        dispatch(clearDriversList());

        dispatch(
          enqueueSnackbar({
            message: "Foto bloqueada com sucesso.",
            options: { variant: "success" }
          })
        );
      })
      .catch(err => {
        const message = handleErros(err);
        dispatch(requestDriverImageBlockingFailure(message));
        dispatch(
          enqueueSnackbar({
            message,
            options: { variant: "error" }
          })
        );
      });
  };
};

export const requestDriverImageBlockingSuccess = payload => {
  return { type: DRIVER_IMAGE_BLOCKING_SUCCESS, payload };
};

export const requestDriverImageBlockingFailure = payload => {
  return { type: DRIVER_IMAGE_BLOCKING_FAILURE, payload };
};

export const clearDriverData = () => {
  return { type: DRIVER_CLEAR_DETAIL_DATA };
};
