import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OrderDetail from '../components/OrderDetail';
import {
  requestOrderDetail,
  requestUpdateOrderDetail,
  requestUpdateStatusOrderDetail,
  requestOrderImageBlocking,
  clearOrderData
} from '../actions/orderDetail';
import { requestDriver, clearDriverData } from '../actions/driverDetail';
import { enqueueSnackbar } from '../actions/notifications';
import { useParams } from 'react-router-dom';
import VersionChecker, { useVersionChecker } from '../components/VersionChecker/VersionChecker';

const OrdersDetailScreen = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { orderData, isLoading } = useSelector((state) => state.orderDetail);
  const driverData = useSelector((state) => state.driverDetail.driverData);
  const isLoadingDriver = useSelector((state) => state.driverDetail.isLoading);

  useVersionChecker('OrdersDetailScreen');

  useEffect(() => {
    dispatch(requestOrderDetail(id));

    return () => {
      dispatch(clearOrderData());
      dispatch(clearDriverData());
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (orderData.driver) {
      dispatch(requestDriver(orderData.driver));
    }
  }, [orderData, dispatch]);


  const handleGetOrder = (payload) => dispatch(requestOrderDetail(payload));
  const handleUpdate = (payload) => dispatch(requestUpdateOrderDetail(payload));
  const handleUpdateStatus = (status, orderId, content) => dispatch(requestUpdateStatusOrderDetail(status, orderId, content));
  const handleRequestImageBlocking = (payload) => dispatch(requestOrderImageBlocking(payload));
  const handleEnqueueSnackbar = (message, options) => dispatch(enqueueSnackbar(message, options));

  return (
    <>
      <VersionChecker />
      <OrderDetail
        key={id}
        order={orderData}
        handleGetOrder={handleGetOrder}
        isLoading={isLoading}
        driverData={driverData}
        isLoadingDriver={isLoadingDriver}
        onUpdate={handleUpdate}
        onUpdateStatus={handleUpdateStatus}
        onRequestImageBlocking={handleRequestImageBlocking}
        onEnqueueSnackbar={handleEnqueueSnackbar}
        orderId={id}
      />
    </>
  );
};

export default OrdersDetailScreen;


