import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  requestFreights,
  clearFreightList,
} from '../actions/freights';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FreightDetail from '../components/FreightDetail';
import {
  requestFreightDetail,
  requestUpdateFreightDetail,
  requestFreightDetailDeleteOffer,
  requestFreightDetailDuplicateOffer,
  requestUpdateStatusFreightDetail,
  getFreightsLocations,
  requestFreightImageBlocking,
  clearFreightDetail,
} from '../actions/freightDetail';
import { enqueueSnackbar } from "../actions/notifications";
import { occurrenceBroadcastRequest } from "../actions/occurrences";
import VersionChecker, { useVersionChecker } from '../components/VersionChecker/VersionChecker';

const OffersDetailScreen = ({
  match,
  freightDetail,
  boardUser,
  isOccurrenceLoading,
  requestFreightDetail,
  clearFreightDetail,
  getFreightsLocations,
  requestUpdateFreightDetail,
  requestUpdateStatusFreightDetail,
  requestFreightDetailDuplicateOffer,
  requestFreightDetailDeleteOffer,
  requestFreightImageBlocking,
  enqueueSnackbar,
  occurrenceBroadcastRequest
}) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const freightsCount = useSelector((state) => state.counter.freights);
  const filters = useSelector((state) => state.freights.filters);
  const skip = useSelector((state) => state.freights.skip);
  const limit = useSelector((state) => state.freights.filters.limit);
  const isLoading = useSelector((state) => state.freightDetail.isLoading);

  const [counter, setCounter] = useState(freightsCount);
  const debouncedGetData = debounce((props) => dispatch(requestFreights(props)), 1000);

  useVersionChecker('OffersDetailScreen');

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    dispatch(clearFreightList());
    getFreights(); // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (freightsCount > counter && mounted.current) {
      getFreights(0);
    }
    setCounter(freightsCount); // eslint-disable-next-line
  }, [freightsCount]);

  useEffect(() => {
    requestFreightDetail(id);

    return () => {
      clearFreightDetail();
    };
  }, [id, requestFreightDetail, clearFreightDetail]);

  const getFreights = (skipProp = skip) => {
    debouncedGetData({ limit, skip: skipProp, filters });
  };

  const handleRefresh = () => {
    dispatch(clearFreightList());
    getFreights("0");
  };

  return (
    <main className="main-content">
      <VersionChecker />
      <FreightDetail
        freight={freightDetail.freightData}
        freightLocation={freightDetail.freightLocation}
        getFreightsLocations={getFreightsLocations}
        update={requestUpdateFreightDetail}
        updateStatus={requestUpdateStatusFreightDetail}
        duplicateOffer={requestFreightDetailDuplicateOffer}
        deleteOffer={requestFreightDetailDeleteOffer}
        requestFreight={requestFreightDetail}
        freightId={id}
        requestFreightImageBlocking={requestFreightImageBlocking}
        enqueueSnackbar={enqueueSnackbar}
        isLoading={isLoading}
        isOccurrenceLoading={isOccurrenceLoading}
        occurrenceBroadcastRequest={occurrenceBroadcastRequest}
        handleRefresh={handleRefresh}
        boardUser={boardUser}
      />
    </main>
  );
};

const mapStateToProps = (state) => ({
  boardUser: state.auth.user,
  freightDetail: state.freightDetail,
  isLoading: state.freightDetail.isLoading,
  isOccurrenceLoading: state.occurrences.isLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestFreightDetail,
      requestUpdateFreightDetail,
      requestFreightDetailDeleteOffer,
      requestFreightDetailDuplicateOffer,
      requestUpdateStatusFreightDetail,
      getFreightsLocations,
      requestFreightImageBlocking,
      enqueueSnackbar,
      clearFreightDetail,
      occurrenceBroadcastRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OffersDetailScreen);
