import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import brLocale from 'date-fns/locale/pt-BR';

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

export default (props) => (
  <MuiPickersUtilsProvider utils={LocalizedUtils} locale={brLocale}>
    {props.children}
  </MuiPickersUtilsProvider>
)