import { makeStyles } from '@material-ui/core/styles';
import {
  btnRed,
  btnRedHover,
  btnGreen,
  btnGreenHover,
  COLOR_DEFAULT,
  COLOR_DEFAULT_HOVER,
  COLOR_BLUE,
} from '../../globalTheme';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(1),
    position: 'relative',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[800],
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  statusBubble: {
    position: 'absolute',
    top: 10,
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    padding: '5px 10px',
    borderRadius: '5px',
    zIndex: 1,
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 700,
  },
  photoLabel: {
    padding: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: '14px',
    color: theme.palette.primary.main,
  },
  dialogActions: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  approveButton: {
    height: '43px',
    width: '156px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 700,
    backgroundColor: btnGreen,
    color: '#FFF',
    '&:hover': {
      backgroundColor: btnGreenHover,
    },
  },
  downloadButton: {
    height: '43px',
    width: '156px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 700,
    backgroundColor: COLOR_DEFAULT,
    color: '#FFF',
    '&:hover': {
      backgroundColor: COLOR_DEFAULT_HOVER,
    },
  },
  rejectButton: {
    height: '43px',
    width: '156px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 700,
    backgroundColor: btnRed,
    color: '#FFF',
    '&:hover': {
      backgroundColor: btnRedHover,
    },
  },
  closeButton: {
    height: '43px',
    width: '156px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 700,
    backgroundColor: '#FFF',
    color: COLOR_BLUE,
    border: '2px solid #1B556D',
  },
  confirmRejectButton: {
    height: '43px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 700,
    backgroundColor: COLOR_DEFAULT,
    color: '#FFF',
    marginTop: theme.spacing(1),
    '&:hover': {
      backgroundColor: COLOR_DEFAULT_HOVER,
    },
  },
  messageContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  messageLabel: {
    fontSize: '11px',
    textTransform: 'uppercase',
    fontWeight: '600',
    color: '#565656',
    paddingLeft: '2px',
    paddingBottom: '2px',
  },
  messageInput: {
    borderRadius: theme.shape.borderRadius,
    resize: 'none',
    width: '100%',
    height: '70px',
    border: '1px solid #ccc',
  },
  goBackButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 12,
    paddingTop: 0,
  },
  navigationButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  confirmRejectButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  currentMessageContainer: {
    position: 'absolute',
    bottom: 10,
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    padding: '5px 10px',
    borderRadius: '5px',
    zIndex: 1,
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 700,
  },
  currentMessageLabel: {
    marginBottom: theme.spacing(1),
    fontSize: '12px',
    fontWeight: '600',
    color: 'white',
  },
  currentMessageText: {
    fontSize: '12px',
    fontWeight: '400',
    color: 'white',
  },
}), { name: 'PhotosModal' });

export default useStyles;