import React, { useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useDispatch } from 'react-redux';
import { clearDriversList } from '../../actions/drivers';
import { clearFreightList } from '../../actions/freights';
import { clearOrdersList } from '../../actions/orders';
import { clearOccurrencesList } from '../../actions/occurrences';
import styles from './styles';

const menuItens = [
  { route: '/home', label: 'HOME' },
  { route: '/drivers', label: 'CADASTRO DE MOTORISTAS' },
  { route: '/offers', label: 'OFERTAS' },
  { route: '/orders', label: 'ORDENS DE SERVIÇO' },
  { route: '/occurrences', label: 'OCORRÊNCIAS' },
  { route: '/configuration', label: 'CONFIGURAÇÕES' },
  { route: '/notifications', label: 'NOTIFICAÇÕES' },
];

interface HeaderProps {
  classes?: {
    tabRoot: string;
    tabsRoot: string;
    tabsIndicator: string;
    tabSelected: string;
    menuButton: string;
    drawer: string;
    listItem: string;
  };
  roles?: Array<string>;
  toggleDarkMode?: () => void;
}

function Header({ classes, roles = [] }: HeaderProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(
    location.pathname === '/' ? '/home' : location.pathname.replace(/^(\/\w+?)\/.*$/, '$1'),
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleTabChange = useCallback((e, nextTab) => {
    setCurrentTab(nextTab);
  }, []);

  return roles.length && roles.some((role: string) => location.pathname.match(role)) ? (
    <header className="main-header">
      <Tabs
        centered
        indicatorColor="primary"
        textColor="primary"
        value={currentTab}
        onChange={handleTabChange}
        classes={{
          root: classes?.tabsRoot || '',
          indicator: classes?.tabsIndicator || '',
        }}
      >
        {menuItens
          .filter((item) => roles.includes(item.route))
          .map((item, index) => (
            <Tab
              key={index}
              label={item.label}
              value={item.route}
              classes={{
                root: classes?.tabRoot || '',
                selected: classes?.tabSelected || '',
              }}
              component="a"
              onClick={(event) => {
                event.preventDefault();
                dispatch(clearDriversList());
                dispatch(clearFreightList());
                dispatch(clearOrdersList());
                dispatch(clearOccurrencesList());
                history.push(item.route);
              }}
            />
          ))}
        <Tab
          label="SAIR"
          value="/logout"
          classes={{
            root: classes?.tabRoot || '',
            selected: classes?.tabSelected || '',
          }}
          component="a"
          onClick={(event) => {
            event.preventDefault();
            history.push('/logout');
          }}
        />
      </Tabs>


      <IconButton
        className={classes?.menuButton || ''}
        color="inherit"
        aria-label="Open Menu"
        onClick={handleDrawerToggle}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        classes={{ paper: classes?.drawer || '' }}
      >
        <List>
          {menuItens
            .filter((item) => roles.includes(item.route))
            .map((item, index) => (
              <ListItem
                key={index}
                button
                className={classes?.listItem || ''}
                onClick={() => {
                  dispatch(clearDriversList());
                  dispatch(clearFreightList());
                  dispatch(clearOrdersList());
                  dispatch(clearOccurrencesList());
                  history.push(item.route);
                  setIsDrawerOpen(false);
                }}
              >
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          <ListItem
            button
            className={classes?.listItem || ''}
            onClick={() => {
              history.push('/logout');
              setIsDrawerOpen(false);
            }}
          >
            <ListItemText primary="SAIR" />
          </ListItem>
        </List>
      </Drawer>
    </header>
  ) : (
    <></>
  );
}

export default withStyles(styles)(Header);

