import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { requestInvoiceCouponsSent, clearFreightList } from '../../actions/freights';

const statusLabels = {
  INVOICE_COUPON_SENT: 'Comprovantes Recebidos',
  INVOICE_COUPON_REFUSED: 'Comprovantes Rejeitados',
};

const getPhotoSummary = (photos) => {
  const summary = {
    approved: 0,
    altered: 0,
    denied: 0,
    waiting: 0,
  };

  photos.forEach((photo) => {
    if (photo.status === 'APPROVED') summary.approved += 1;
    if (photo.status === 'ALTERED') summary.altered += 1;
    if (photo.status === 'DENIED') summary.denied += 1;
    if (photo.status === 'WAITING') summary.waiting += 1;
  });

  return summary;
};

const InvoiceCoupons = ({ classes }) => {
  const [visibleItems, setVisibleItems] = useState(5);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const dispatch = useDispatch();
  const freightsList = useSelector((state) => state.freights.freightsList);
  const isLoading = useSelector((state) => state.freights.isLoading);

  useEffect(() => {
    dispatch(clearFreightList());
    loadFreights(0); // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (freightsList.length < visibleItems) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [freightsList.length, visibleItems]);

  const loadFreights = (newSkip) => {
    dispatch(requestInvoiceCouponsSent({ limit: 5, skip: newSkip }));
    setSkip(newSkip + 5);
  };

  const handleLoadMore = () => {
    loadFreights(skip);
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 5);
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant="h6" className={classes.title}>
          Comprovantes de Descarga para aprovar
        </Typography>
      </div>
      <Grid container spacing={1}>
        {freightsList.slice(0, visibleItems).map((item) => {
          const summary = getPhotoSummary(item.invoiceCouponPhotos || []);
          return (
            <Grid item xs={12} sm={12} key={item.id}>
              <Paper className={classes.invoiceCoupon}>
                <div className={classes.topSection}>
                  <div className={classes.fieldContainer}>
                    <Typography variant="h6" className={classes.fieldName}>Frete:</Typography>
                    <Typography variant="h6" className={classes.fieldValue}>
                      {`#${item.freightCode}`}
                    </Typography>
                  </div>
                  <div className={classes.fieldContainer}>
                    <Typography variant="h6" className={classes.fieldName}>OS:</Typography>
                    <Typography variant="h6" className={classes.fieldValue}>
                      {item.OSCode ? item.OSCode : "Não informado"}
                    </Typography>
                  </div>
                  <div className={classes.statusContainer}>
                    <Typography variant="h6" className={classes.statusLabel}>
                      {statusLabels[item.status] || 'Desconhecido'}
                    </Typography>
                    <Button variant="contained" href={`/offers/${item.id}`} className={classes.button}>
                      IR PARA O FRETE
                    </Button>
                  </div>
                </div>
                <div className={classes.photoSummary}>
                  <div className={`${classes.summaryItem} ${classes.approved}`}>
                    <Typography variant="body2">Fotos aprovadas</Typography>
                    <Typography variant="h6">{summary.approved}</Typography>
                  </div>
                  <div className={`${classes.summaryItem} ${classes.altered}`}>
                    <Typography variant="body2">Fotos alteradas</Typography>
                    <Typography variant="h6">{summary.altered}</Typography>
                  </div>
                  <div className={`${classes.summaryItem} ${classes.denied}`}>
                    <Typography variant="body2">Fotos rejeitadas</Typography>
                    <Typography variant="h6">{summary.denied}</Typography>
                  </div>
                  <div className={`${classes.summaryItem} ${classes.waiting}`}>
                    <Typography variant="body2">Fotos aguardando análise</Typography>
                    <Typography variant="h6">{summary.waiting}</Typography>
                  </div>
                </div>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      <Button
        onClick={handleLoadMore}
        variant="contained"
        color="primary"
        className={classes.loadMoreButton}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : hasMore ? 'Carregar Mais' : 'Não há mais itens'}
      </Button>
    </div>
  );
};

export default withStyles(styles)(InvoiceCoupons);
