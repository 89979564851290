import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import Tooltip from '../Tooltip';

import styles from './styles';
import { FILTER_LABEL_OCCURRENCE } from '../../constants';

const OccurrenceCard = (props) => {
  const { classes, occurrence, history } = props;

  const handleViewMoreClick = () => {
    history.push(`/occurrence/${occurrence.id}`);
  };

  const getStatusStyles = (status) => {
    switch (status) {
      case 'RESOLVED':
        return {
          backgroundColor: 'rgba(94, 97, 98, 0.30)',
          color: '#FFFFFF'
        };
      case 'UNRESOLVED':
        return {
          backgroundColor: '#FFFFFF',
          color: '#FFFFFF'
        };
      default:
        return {
          backgroundColor: '#FFFFFF',
          color: '#000000'
        };
    }
  };

  const statusStyles = getStatusStyles(occurrence.status);

  return (
    <Paper className={classes.root} style={statusStyles}>
      <div className={classes.mainContent}>
        <section className={classes.container}>
          <Typography noWrap className={classes.labels}>
            <strong>usuário</strong>
          </Typography>
          <Tooltip text={occurrence.user.name && occurrence.user.name} minCharacters={29}>
            <Typography noWrap className={[classes.text, classes.userName].join(' ')}>
              <strong>
                {occurrence.user.name
                  ? occurrence.user.name
                  : 'Usuário não encontrado'
                }
              </strong>
            </Typography>
          </Tooltip>
        </section>
        {occurrence.messages[0].message && occurrence.messages[0].message.trim() !== "" ?
          <section className={classes.container}>
            <Typography noWrap className={classes.labels}>
              <strong>motivo</strong>
            </Typography>

            <Tooltip text={occurrence.messages[0].message && occurrence.messages[0].message} minCharacters={43}>
              <Typography noWrap className={classes.text}>
                {occurrence.messages[0].message}
              </Typography>
            </Tooltip>
          </section>
          : null}
        <section className={[classes.container, classes.horizontalFlex].join(' ')}>
          <Typography noWrap className={classes.labels}>
            <strong>status</strong>
          </Typography>
          <Typography noWrap className={classes.labels}>
            <strong>categoria</strong>
          </Typography>
          <Typography noWrap className={classes.text}>
            {occurrence.status === 'UNRESOLVED' ?
              <span className={[classes.statusIcon, classes.negative].join(' ')}></span>
              :
              <span className={[classes.statusIcon, classes.positive].join(' ')}></span>
            }
            {occurrence.status === 'UNRESOLVED' ? 'Não resolvido' : 'Resolvido'}
          </Typography>
          <Tooltip text={FILTER_LABEL_OCCURRENCE.category[occurrence.type]} minCharacters={8}>
            <Typography noWrap className={classes.text}>
              {FILTER_LABEL_OCCURRENCE.category[occurrence.type]}
            </Typography>
          </Tooltip>
        </section>
        <Typography
          noWrap
          className={[classes.labels, classes.moreDetails].join(' ')}
          style={{ cursor: 'pointer' }}
          onClick={handleViewMoreClick}>
          <strong>VER MAIS</strong>
        </Typography>
      </div>

    </Paper>
  )
};

export default withRouter(withStyles(styles)(OccurrenceCard));
