import api from '../api';
import {
  getOneDistancePromise,
  getLatitudeLongitudePromise,
} from '../googleApiUtils';
import { formatCoordinate, handleErros } from '../utils/utils';
import {
  CREATE_FREIGHT_REQUEST,
  CREATE_FREIGHT_SUCCESS,
  CREATE_FREIGHT_FAILURE,
} from './actionsTypes';
import { clearFreightList } from './freights';
import { enqueueSnackbar } from './notifications';

// Função utilitária para obter coordenadas e distância
const getCoordinatesAndDistance = async (origin, destination) => {
  try {
    const [originCoords, destCoords, distance] = await Promise.all([
      getLatitudeLongitudePromise(origin),
      getLatitudeLongitudePromise(destination),
      getOneDistancePromise(origin, destination),
    ]);

    return {
      gatheringLatitude: formatCoordinate(originCoords.latitude),
      gatheringLongitude: formatCoordinate(originCoords.longitude),
      deliveryLatitude: formatCoordinate(destCoords.latitude),
      deliveryLongitude: formatCoordinate(destCoords.longitude),
      travelDistance: distance.distance.value / 1000,
    };
  } catch (error) {
    throw error;
  }
};

export const requestNewFreight = (payload, history) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_FREIGHT_REQUEST, payload });

    const {
      gatheringAddress,
      gatheringState,
      gatheringCity,
      gatheringCep,
      deliveryAddress,
      deliveryState,
      deliveryCity,
      deliveryCep,
    } = payload;

    const origin = `${gatheringAddress} - ${gatheringCep} - ${gatheringCity}/${gatheringState}`;
    const destination = `${deliveryAddress} - ${deliveryCep} - ${deliveryCity}/${deliveryState}`;

    dispatch(
      enqueueSnackbar({
        message: 'Processando a distância do frete...',
        options: { variant: 'info' },
      }),
    );

    try {
      const coordinatesAndDistance = await getCoordinatesAndDistance(
        origin,
        destination,
      );

      dispatch(
        enqueueSnackbar({
          message: 'Salvando o frete...',
          options: { variant: 'info' },
        }),
      );

      const res = await api.post('/freight', { ...payload, ...coordinatesAndDistance });
      const freightCode = res.data.freightCode;

      dispatch(requestNewFreightSuccess(res.data));

      // Limpa a lista de fretes para que o componente FreightList...
      // entenda que é necessário atualizar os dados
      dispatch(clearFreightList());

      dispatch(
        enqueueSnackbar({
          message: `Frete código #${freightCode} criado com sucesso`,
          options: { variant: 'success' },
        }),
      );

      history && history.push('/offers');
    } catch (error) {
      const message = handleErros(error);
      dispatch(requestNewFreightsFailure(message));
      dispatch(
        enqueueSnackbar({
          message,
          options: { variant: 'error' },
        }),
      );
    }
  };
};

export const requestNewFreightSuccess = (payload) => {
  return { type: CREATE_FREIGHT_SUCCESS, payload };
};

export const requestNewFreightsFailure = (payload) => {
  return { type: CREATE_FREIGHT_FAILURE, payload };
};
