import React, { useState, useEffect } from 'react';
import { validateDocumentType, sendToAWS, isDocumentValid, getFromAWS, downloadFromBuffer } from '../../utils/utils';
import { IconButton, Typography, Paper, Button, CircularProgress, Modal } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DownloadIcon from '@material-ui/icons/GetApp';
import DocumentIcon from '@material-ui/icons/Description';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import useStyles from './styles';
import api from '../../api';

interface Document {
  id: string;
  name: string;
  url: string;
  dateOfSubmission: string;
}

interface Payload {
  id: string;
  data: {
    otherDocuments: Document[];
    boardRequest?: boolean;
  };
}

interface DocumentUploadProps {
  onDocumentsChange: (otherDocuments: Document[]) => void;
  freight: { id: string; otherDocuments: Document[] };
}

const OthersDocuments: React.FC<DocumentUploadProps> = ({ onDocumentsChange, freight }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [otherDocuments, setOtherDocuments] = useState<Document[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);

  useEffect(() => {
    if (freight && freight.otherDocuments) {
      setOtherDocuments(freight.otherDocuments);
    } // eslint-disable-next-line
  }, [freight.id]);

  useEffect(() => {
    if (freight && freight.otherDocuments) {
      const newDocuments = freight.otherDocuments.filter(
        doc => !otherDocuments.some(localDoc => localDoc.id === doc.id)
      );
      setOtherDocuments([...otherDocuments, ...newDocuments]);
    } // eslint-disable-next-line
  }, [freight.id]);

  const handleAddDocument = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (!isDocumentValid(file)) {
        enqueueSnackbar('Os formatos permitidos são: PNG, JPG e PDF.', { variant: 'error' });
        setIsLoading(false);
        return;
      }

      try {
        const response = await validateDocumentType(file);
        if (response?.data && response.data.signedUrl) {
          const awsResponse = await sendToAWS(response.data.signedUrl, file);
          if (!awsResponse || awsResponse.status !== 200) {
            throw new Error('Erro ao enviar o documento para o AWS, verifique o CloudWatch');
          }
          const newDocument: Document = {
            id: uuidv4(),
            name: file.name,
            url: response.data.imageUrl,
            dateOfSubmission: new Date().toISOString(),
          };
          const updatedDocuments = [...otherDocuments, newDocument];
          setOtherDocuments(updatedDocuments);
          onDocumentsChange(updatedDocuments);
          setIsDirty(true);
          enqueueSnackbar('Documento adicionado com sucesso!', { variant: 'success' });
        } else {
          throw new Error('Sem URL assinada disponível');
        }
      } catch (error) {
        enqueueSnackbar('Não foi possível enviar o documento. Tente novamente.', { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleDeleteDocument = (id: string) => {
    const updatedDocuments = otherDocuments.filter((doc) => doc.id !== id);
    setOtherDocuments(updatedDocuments);
    onDocumentsChange(updatedDocuments);
    setIsDirty(true);
  };

  const handleViewDocument = (doc: Document) => {
    setSelectedDocument(doc);
  };

  const handleDownloadDocument = async (doc: Document) => {
    try {
      const fileNameAux = doc.url.split('/');
      const fileName = fileNameAux[fileNameAux.length - 1];
      const response = await getFromAWS(fileName);
      const contentType = response.data.headers['Content-Type'];
      if (response.status === 200) {
        const base64 = response.data.body;
        downloadFromBuffer(base64, fileName, contentType);
        enqueueSnackbar('Documento baixado com sucesso!', { variant: 'success' });
      } else {
        throw new Error('Falha ao obter o documento do servidor.');
      }
    } catch (err) {
      enqueueSnackbar('Não foi possível fazer o download do documento. Tente novamente.', { variant: 'error' });
    }
  };

  const requestUpdateFreightDetail = async (payload: Payload) => {
    const { id, data } = payload;

    if (data) {
      data.boardRequest = true;
    }

    try {
      await api.patch(`/freight/${id}`, data);
      enqueueSnackbar('Frete atualizado com sucesso!', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Erro ao atualizar o frete', { variant: 'error' });
    }
  };

  const handleSaveDocuments = async () => {
    const { id } = freight;
    const data = { otherDocuments };
    try {
      setIsLoading(true);
      await requestUpdateFreightDetail({ id, data });
      onDocumentsChange(otherDocuments);
      setIsDirty(false);
      enqueueSnackbar('Documentos salvos com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Erro ao salvar documentos', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const formatDocumentName = (name: string) => {
    const nameParts = name.split('.');
    const extension = nameParts.pop();
    const baseName = nameParts.join('.');
    return `${baseName.substring(0, 12)}...${extension}`;
  };

  return (
    <Paper className={classes.paper}>
      <div
        className={classes.actions}>
        <input
          accept="application/pdf,image/*"
          className={classes.uploadButton}
          id="upload-button-file"
          type="file"
          onChange={handleAddDocument}
        />
        <label htmlFor="upload-button-file" className={classes.uploadLabel}>
          <AddIcon /> Adicionar outros documentos
        </label>
        {isLoading ? <CircularProgress size={24} className={classes.buttonProgress} /> :
          <Button
            variant="contained"
            color="primary"
            className={classes.saveButton}
            onClick={handleSaveDocuments}
            disabled={!isDirty}
          >
            Salvar Documentos
          </Button>}
        {isDirty && (
          <label htmlFor="upload-button-file">
            <Typography variant="body1" component="p" className={classes.labelAlert}>Existe documentos não salvos*</Typography>
          </label>
        )}
      </div>
      <div className={classes.documentContainer}>
        {otherDocuments.map((doc) => (
          <div key={doc.id} className={classes.documentItem}>
            <DocumentIcon className={classes.documentIcon} />
            <Typography variant="caption" display="block" className={classes.documentName}>{formatDocumentName(doc.name)}</Typography>
            <div className={classes.documentActions}>
              <IconButton className={classes.viewButton} onClick={() => handleViewDocument(doc)}>
                <VisibilityIcon />
              </IconButton>
              <IconButton className={classes.downloadButton} onClick={() => handleDownloadDocument(doc)}>
                <DownloadIcon />
              </IconButton>
              <IconButton className={classes.deleteButton} onClick={() => handleDeleteDocument(doc.id)}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
      {selectedDocument && (
        <Modal
          open={Boolean(selectedDocument)}
          onClose={() => setSelectedDocument(null)}
          className={classes.modal}
        >
          <div className={classes.modalContent}>
            <Typography variant="h6" gutterBottom>
              {selectedDocument.name}
            </Typography>
            {selectedDocument.url.endsWith('.pdf') ? (
              <iframe src={selectedDocument.url} className={classes.modalIframe} title="Document Preview" />
            ) : (
              <img src={selectedDocument.url} alt={selectedDocument.name} className={classes.modalImage} />
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setSelectedDocument(null)}
              className={classes.closeButton}
            >
              Fechar
            </Button>
          </div>
        </Modal>
      )}
    </Paper>
  );
};

export default OthersDocuments;
