import { COLOR_ERROR, COLOR_WARNING, COLOR_SUCCESS } from "../../globalTheme";

const COLOR_DEFAULT = "#1A5B76";
const COLOR_DEFAULT_HOVER = "#3896BB";

const styles = (theme) => ({
  root: {
    justifyContent: "center",
    width: "100%",
    padding: theme.spacing(1),
    overflow: "hidden !important",
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"'
  },
  container: {
    width: "100%",
    maxWidth: "1024px",
  },
  progress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(2, "auto"),
    color: COLOR_DEFAULT,
  },
  addButton: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(4),
    color: theme.palette.common.white,
    backgroundColor: COLOR_DEFAULT,
    "&:hover": {
      backgroundColor: COLOR_DEFAULT_HOVER,
    },
  },
  loadMoreButton: {
    display: "block",
    margin: theme.spacing(1, "auto"),
    padding: theme.spacing(1, 3),
    color: theme.palette.common.white,
    border: "2px solid #1A5B76",
    backgroundColor: COLOR_DEFAULT,
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLOR_DEFAULT_HOVER,
    },
  },
  noMoreItems: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  relativePos: {
    position: "relative",
  },
  infiniteScrollContainer: {
    padding: theme.spacing(2),
    overflow: "hidden !important",
  },
  statusIcon: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    backgroundColor: COLOR_ERROR,
    position: "absolute",
    top: "8px",
    right: "8px",
    zIndex: 1,
  },
  statusIconPaymentSuccessfulBalance: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    backgroundColor: COLOR_SUCCESS,
    position: "absolute",
    top: "8px",
    right: "8px",
    zIndex: 1,
  },
  statusIconPaymentSuccessfulAdvance: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    backgroundColor: COLOR_WARNING,
    position: "absolute",
    top: "8px",
    right: "8px",
    zIndex: 1,
  },
  statusIconPaymentUnsuccessful: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    backgroundColor: COLOR_ERROR,
    position: "absolute",
    top: "8px",
    right: "8px",
    zIndex: 1,
  },
  exportButton: {
    display: "block",
    margin: theme.spacing(1, "auto"),
    padding: theme.spacing(1, 3),
    backgroundColor: theme.palette.common.white,
    color: COLOR_DEFAULT,
    border: "2px solid #1A5B76",
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    gap: theme.spacing(4),
    "&:hover": {
      backgroundColor: COLOR_DEFAULT_HOVER,
    },
  },
  scrollToTopButton: {
    position: "fixed",
    bottom: theme.spacing(12),
    right: theme.spacing(5),
    color: theme.palette.common.white,
    backgroundColor: COLOR_DEFAULT,
    "&:hover": {
      backgroundColor: COLOR_DEFAULT_HOVER,
    },
  },
  historyHeader: {
    fontWeight: 600,
    textTransform: "uppercase",
    textAlign: "center",
    color: "#868686",
  },
  title: {
    fontWeight: 600,
    color: "#585858",
    textTransform: "uppercase",
    marginBottom: theme.spacing(4),
    textAlign: "center",
    marginTop: "30px",
  },
  statusMessageFlag: {
    padding: "4px 8px",
    display: "flex",
    justifyContent: "center",
    color: "#fff",
    borderRadius: "3px",
    fontWeight: "bold",
    fontSize: "8px",
    textTransform: "uppercase",
    position: "absolute",
    bottom: "15px",
    right: "15px",
  },
  pendingMessageFlag: {
    backgroundColor: "#ff8900",
  },
  deniedMessageFlag: {
    backgroundColor: COLOR_ERROR,
  },
  moneySquareIcon: {
    width: "24px",
    height: "24px",
    position: "absolute",
    top: "18px",
    right: "18px",
    zIndex: 1,
  },
});

export default styles;
