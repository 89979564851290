const COLOR_DEFAULT = '#1A5B76';

export default (theme) => ({
  cardContentRoot: {
    position: 'relative',
    fontSize: '0.8em',
    padding: theme.spacing(0.8),
    height: '150px',
    '&:last-child': {
      paddingBottom: theme.spacing(1)
    }
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 3px',
    margin: '0.5% 0',
    '&:first-child': {
      justifyContent: 'space-between',
      marginTop: 0
    },
    '&:last-child': {
      marginBottom: 0
    },
    '& > a': {
      textDecoration: 'none'
    }
  },
  iconContainer: {
    marginRight: '2%',
    color: COLOR_DEFAULT,
    padding: '2px',
  },
  title: {
    color: COLOR_DEFAULT,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '280px',
    fontSize: '15px'
  },
  textSecondary: {
    color: '#707070',
    fontSize: '12px'
  },
  tooltipContainer: {
    backgroundColor: COLOR_DEFAULT,
    color: '#FFF'
  },
  checkboxRoot: {
    position: 'absolute',
    right: 0
  },
  tooltip: {
    width: '430px',
    backgroundColor: 'rgba(0,0,0,0.9)',
    borderRadius: '10px',
    padding: '10px',
    position: 'absolute',
    top: '50px',
    left: '10px',
    zIndex: '10',
    display: 'inline-block',
    transition: 'all 1s ease'
  }
});