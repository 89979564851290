import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  requestOccurrences,
  clearOccurrencesList,
  clearOccurrencesFilters,
  setOccurrencesFilter,
  requestListOccurrences
} from '../actions/occurrences';
import { isFiltersEmpty } from '../utils/utils';
import Occurrences from '../components/OccurrencesList/index';
import OccurrenceFilter from '../components/OccurrenceFilter';
import VersionChecker, { useVersionChecker } from '../components/VersionChecker/VersionChecker';

const OccurrencesScreen = () => {
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const occurrenceList = useSelector((state) => state.occurrences.occurrenceList);
  const filters = useSelector((state) => state.occurrences.filters);
  const skip = useSelector((state) => state.occurrences.skip);
  const limit = useSelector((state) => state.occurrences.filters.limit);
  const hasMore = useSelector((state) => state.occurrences.hasMore);
  const isLoading = useSelector((state) => state.occurrences.isLoading);

  const [message, setMessage] = useState(filters.message);

  const debouncedSetFilter = debounce((props) => dispatch(setOccurrencesFilter(props)), 500);
  const debouncedGetData = debounce((props) => dispatch(requestOccurrences(props)), 500);

  useVersionChecker('OccurrencesScreen');

  useEffect(() => {
    mounted.current = true;

    dispatch(clearOccurrencesList());
    getOccurrences();

    const updateInterval = setInterval(() => {
      if (mounted.current) {
        updateOccurrences();
      }
    }, 15000);

    return () => {
      mounted.current = false;
      clearInterval(updateInterval);
    }; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(clearOccurrencesList());
    getOccurrences(); // eslint-disable-next-line
  }, [filters]);

  const getOccurrences = (skipProp = skip) => {
    debouncedGetData({ limit, skip: skipProp, filters });
  };

  const updateOccurrences = () => {
    dispatch(requestListOccurrences({ limit, skip, filters }));
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    if (name === 'message') {
      setMessage(value);
      debouncedSetFilter({ [name]: value });
    } else {
      dispatch(setOccurrencesFilter({ [name]: value }));
    }
  };

  const handleClearFilters = () => {
    setMessage('');
    dispatch(clearOccurrencesFilters());
    dispatch(requestOccurrences({ filters: filters }));
  };

  const handleLoadMore = () => {
    if (hasMore && !isLoading) {
      getOccurrences(skip);
    }
  };

  const handleRefresh = () => {
    dispatch(clearOccurrencesList());
    getOccurrences("0");
  };

  return (
    <>
      <VersionChecker />
      <div className="main-filter">
        <OccurrenceFilter
          disableClear={isFiltersEmpty(filters) || isLoading}
          disableRefresh={isLoading}
          message={message}
          type={filters.type}
          status={filters.status}
          handleClearFilters={handleClearFilters}
          handleRefresh={handleRefresh}
          handleFilterChange={handleFilterChange}
        />
      </div>
      <main className="main-content">
        <Occurrences
          occurrencesList={occurrenceList}
          hasMore={hasMore}
          getOccurrences={getOccurrences}
          isLoading={isLoading}
          handleLoadMore={handleLoadMore}
        />
      </main>
    </>
  );
};

export default OccurrencesScreen;
