// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withSnackbar } from 'notistack';

import { removeSnackbar } from '../actions/notifications';

class Notifier extends Component {
  displayed = [];

  componentDidMount() {
    if ("Notification" in window && Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }

  storeDisplayed = (id) => {
    this.displayed = [...this.displayed, id];
  };

  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    const { notifications: currentSnacks } = this.props;

    let notExists = false;
    for (let i = 0; i < newSnacks.length; i += 1) {
      if (notExists) continue;
      notExists = notExists || !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
    }
    return notExists;
  };

  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach((notification) => {
      if (this.displayed.includes(notification.key)) return;

      if (document.visibilityState === "visible") {
        this.props.enqueueSnackbar(notification.message, notification.options);
      } else if (Notification.permission === "granted") {
        new Notification(notification.message);
      }

      this.storeDisplayed(notification.key);
      this.props.removeSnackbar(notification.key);
    });
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.notifications,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  removeSnackbar
}, dispatch);

export default withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifier));
