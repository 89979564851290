import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";

import { FILTER_LABEL_DRIVER } from "../../constants";
import styles from "./style";
import Filter from "../Filter";
import FilterSearch from "../Filter/FilterSearch";
import FilterSelect from "../Filter/FilterSelect";

const DriversFilter = props => {
  const { classes } = props;

  const selectLabelClasses = {
    root: classes.selectLabel,
    focused: classes.focused
  };

  return (
    <Filter
      disableClear={props.disableClear}
      disableRefresh={props.disableRefresh}
      handleClearFilter={props.handleClearFilters}
      handleRefresh={props.handleRefresh}
    >
      <FilterSearch>
        <FormControl
          fullWidth
          htmlFor="name"
          className={clsx(classes.formCtlInput, classes.searchNameContainer)}
        >
          <Input
            autoFocus
            id="name"
            name="name"
            autoComplete="pesquisa"
            placeholder="Pesquisa por nome"
            onChange={props.handleFilterChange}
            onKeyDown={props.handleSerchNameKeyDown}
            value={props.name}
            className={clsx(classes.input, classes.searchName)}
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  aria-label="Pesquisa"
                  onClick={props.clearAndSearch}
                  className={classes.iconButton}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl
          fullWidth
          htmlFor="cpf"
          className={clsx(classes.formCtlInput, classes.searchNameContainer)}
        >
          <Input
            id="cpf"
            name="cpf"
            autoComplete="cpf"
            placeholder="Pesquisa por CPF"
            onChange={props.handleFilterChange}
            value={props.cpf}
            className={clsx(classes.input, classes.searchName)}
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  aria-label="Pesquisa por CPF"
                  onClick={props.clearAndSearch} 
                  className={classes.iconButton}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </FilterSearch>

      <FilterSelect>
        <div className={classes.filterList}>
          <FormControl className={classes.selectRoot}>
            <InputLabel htmlFor="vehicleType" classes={selectLabelClasses}>
              Tipo de caminhão
            </InputLabel>

            <Select
              value={props.vehicleType}
              onChange={props.handleFilterChange}
              className={classes.input}
              inputProps={{ id: "vehicleType", name: "vehicleType" }}
            >
              {FILTER_LABEL_DRIVER.truckType.map((profile, i) => (
                <MenuItem key={i} value={profile.value}>
                  {profile.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.selectRoot}>
            <InputLabel
              htmlFor="vehicleOwnedByUser"
              classes={selectLabelClasses}
            >
              Proprietário
            </InputLabel>

            <Select
              value={props.vehicleOwnedByUser}
              onChange={props.handleFilterChange}
              className={classes.input}
              inputProps={{
                id: "vehicleOwnedByUser",
                name: "vehicleOwnedByUser"
              }}
            >
              {FILTER_LABEL_DRIVER.truckOwner.map((profile, i) => (
                <MenuItem key={i} value={profile.value}>
                  {profile.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.selectRoot}>
            <InputLabel htmlFor="walletType" classes={selectLabelClasses}>
              Pagamento
            </InputLabel>

            <Select
              value={props.walletType}
              onChange={props.handleFilterChange}
              className={classes.input}
              inputProps={{ id: "walletType", name: "walletType" }}
            >
              {FILTER_LABEL_DRIVER.paymentType.map((profile, i) => (
                <MenuItem key={i} value={profile.value}>
                  {profile.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.selectRoot}>
            <InputLabel htmlFor="status" classes={selectLabelClasses}>
              Cadastro
            </InputLabel>

            <Select
              value={props.status}
              onChange={props.handleFilterChange}
              className={classes.input}
              inputProps={{ id: "status", name: "status" }}
            >
              {FILTER_LABEL_DRIVER.registerStatus.map((profile, i) => (
                <MenuItem key={i} value={profile.value}>
                  {profile.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </FilterSelect>
    </Filter>
  );
};

export default withStyles(styles)(DriversFilter);
